import styled from 'styled-components';

export const DropZoneStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;

  .tooltipIcon {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .deleteButton {
    position: absolute;
    right: 30px;
    top: 9px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;

    transition: .3s transform;
    background: var(--gray-gray-3, #F5F5F5);
    border-radius: 100%;

    &:hover {
      transform: rotate(10deg);
    }

    svg {
      width: 16px;
      height: 16px;

      path {
        stroke: #CF1322;
      }
    }
  }

  .dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 200px;
  }

  .dropzoneContent {
    position: relative;

    cursor: pointer;
    width: 100%;
    height: 200px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    //padding: 57px 16px;
    box-sizing: border-box;
    border-radius: 2px;
    border: 1px dashed var(--gray-gray-5, #D9D9D9);
    background: var(--gray-gray-3, #F5F5F5);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    text-align: center;

    .dropIcon {
      path {
        transition: .3s ease fill;
      }
    }

    &:hover {
      .dropIcon {
        path {
          fill: #3e92ee;
        }
      }
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
`;
