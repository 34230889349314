export default (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <g clipPath="url(#clip0_862_10761)">
      <path d="M21.831 21.8305V13.8969C21.831 9.99787 20.9916 7.01941 16.4427 7.01941C14.2494 7.01941 12.7873 8.21079 12.1916 9.34802H12.1374V7.37141H7.8322V21.8305H12.327V14.6551C12.327 12.7597 12.679 10.9456 15.0076 10.9456C17.3091 10.9456 17.3362 13.0846 17.3362 14.7634V21.8034H21.831V21.8305ZM0.521434 7.37141H5.0162V21.8305H0.521434V7.37141ZM2.76882 0.168945C1.33374 0.168945 0.169434 1.33325 0.169434 2.76833C0.169434 4.20341 1.33374 5.39479 2.76882 5.39479C4.2039 5.39479 5.3682 4.20341 5.3682 2.76833C5.3682 1.33325 4.2039 0.168945 2.76882 0.168945Z" fill="#005273" />
    </g>
    <defs>
      <clipPath id="clip0_862_10761">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
