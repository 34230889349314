import React, { useRef, useState } from 'react';
import { HeaderStyles } from './HeaderStyles';
import { Link } from 'react-router-dom';
import Logo from '../../assets/Logo';
import MenuIcon from '../../assets/icons/header/MenuIcon';
import { useAppDispatch } from '../../state/hooks';
import { changeUser } from '../../state/slices/userSlice';

function Header({ setSidebarOpen }: {setSidebarOpen(): void }) {
  const [userMenuOpen, setUserMenuOpen] = useState<boolean>(false);

  const userMenuButtonRef = useRef<any>();

  const dispatch = useAppDispatch();

  const userName = 'Oleksii Pochkaiev';

  const logout = () => {
    localStorage.removeItem('logged_in');
    localStorage.removeItem('access_token');
    localStorage.removeItem('access_token_expires_at');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('refresh_token_expires_at');
    dispatch(changeUser(null));
  };

  // useClickOutside({ current: (activeMenuItemIndex || activeMenuItemIndex === 0) && menuRef.current[activeMenuItemIndex] }, () => setActiveMenuItemIndex(null), { current: [...menuRef.current, backgroundRef.current] });

  return (
    <HeaderStyles className="boxShadow1">
      <div className="logoContainer">
        <button type="button" onClick={setSidebarOpen} className="menuButton">
          <MenuIcon />
        </button>

        <Link to="/">
          <Logo />
        </Link>
      </div>
    </HeaderStyles>
  );
}

export default Header;
