import styled from 'styled-components';

export const DesktopFiltersStyles = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  
  width: auto;
  
  padding: 20px;
  margin-bottom: 24px;

  border-radius: 8px;
  border: 1px solid #E5E7E7;
  background: #FEFEFE;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  
  max-width: 1012px;
  
  .filtersFields {
    width: 100%;
    
    display: grid;

    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px;
  }
  
  .buttons {
    position: relative;
    
    display: flex;
    gap: 8px;
    
    .notAppliedText {
      position: absolute;
      bottom: 0;
      left: 50%;
      white-space: nowrap;
      
      transform: translate(-50%, 20px);
    }
  }
`;
