import React, { useCallback, useEffect, useState } from 'react';
import { LeadStyles } from './LeadStyles';
import { notification } from '../../../helpers/notifications/toastify';
import workWithResponse from '../../../helpers/workWithResponse';
import { Api } from '../../../api';
import { useNavigate, useParams } from 'react-router-dom';
import { TApiForm } from '../../../enteties/types/forms.types';
import Loader from '../../../components/loader/Loader';
import LinksPath from '../../../components/other/linksPath/LinksPath';
import ErrorComponent from '../../../components/other/error/ErrorComponent';
import Answers from '../../../components/lead/answers/Answers';
import { TApiLead } from '../../../enteties/types/lead.types';
import Properties from '../../../components/lead/properties/Properties';
import Comments from '../../../components/lead/comments/Comments';
import Utm from '../../../components/lead/utm/UTM';

function Lead() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataLoadingError, setDataLoadingError] = useState<boolean>(false);

  const [formData, setFormData] = useState<TApiForm | null>(null);
  const [ticketData, setTicketData] = useState<TApiLead | null>(null);

  const handleDataLoadingError = useCallback(() => {
    setDataLoadingError(true);
  }, []);

  const handleUpdateTicketData = useCallback((values: Partial<TApiLead>) => {
    if (ticketData) {
      setTicketData({ ...ticketData, ...values });
    }
  }, [ticketData]);

  async function getInitialData() {
    if (id && !Number.isNaN(id)) {
      try {
        setIsLoading(true);

        const ticketDataFromServer = await workWithResponse(() => Api.getLead(+id)).then((res) => {
          if (res.error) {
            notification.error(res.error);
            setDataLoadingError(true);

            return;
          }

          if (res.data) {
            return res.data;
          }
        });

        const formDataFromServer = await workWithResponse(() => Api.getForm(ticketDataFromServer!.form_id)).then((res) => {
          if (res.error) {
            notification.error(res.error);
            setDataLoadingError(true);

            return;
          }

          if (res.data) {
            return res.data;
          }
        });

        if (formDataFromServer && ticketDataFromServer) {
          setFormData(formDataFromServer);
          setTicketData(ticketDataFromServer);
        }
      } catch (e) {
        notification.error(`Error: ${e}`);
        setDataLoadingError(true);
      } finally {
        setIsLoading(false);
      }
    } else {
      navigate('/404');
    }
  }

  useEffect(() => {
    getInitialData();
  }, []);

  if (dataLoadingError) {
    return <ErrorComponent />;
  }

  return (
    <LeadStyles>
      {isLoading && <Loader />}

      {!isLoading && formData && ticketData && (
        <div className="contentWrapper">
          <div className="leadHead">
            <div className="titleWrapper">
              <h1>{formData.name}</h1>

              <LinksPath
                pathArr={[{ path: '/leads', name: 'Leads' }, { path: '', name: formData.name }]}
              />
            </div>
          </div>

          <div className="contentWrapper">
            <div className="contentContainer">
              <div className="content">
                <Answers
                  answers={ticketData.answers}
                  created_at={ticketData.created_at}
                  handleDataLoadingError={handleDataLoadingError}
                />

                <div className="splitter" />

                <Properties
                  formData={formData}
                  ticketData={ticketData}
                  handleDataLoadingError={handleDataLoadingError}
                  handleUpdateTicketData={handleUpdateTicketData}
                />

                <div className="splitter" />

                <Comments
                  handleDataLoadingError={handleDataLoadingError}
                />

                <div className="splitter" />

                <Utm
                  utm_source={ticketData.utm_source}
                  utm_campaign={ticketData.utm_campaign}
                  utm_medium={ticketData.utm_medium}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </LeadStyles>
  );
}

export default Lead;
