import styled from 'styled-components';
import { TThankYou } from '../../../../enteties/types/forms.types';
import { convertColorToCss } from '../../../../utils/convertRGBAToCssColor';

interface Props {
  thankYouData: TThankYou;
}

export const ThankYouPreviewStyles = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .formPreview {
    padding: 24px;

    border-radius: ${(props) => `${props.thankYouData.form.border_radius}px`};
    border: 1px solid var(--Stroke, #EEE);
    background: ${(props) => convertColorToCss(props.thankYouData.form.background_color)};
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);

    .formHead {
      display: flex;
      flex-direction: column;
      gap: 6px;

      margin-bottom: 10px;

      text-align: center;

      .title {
        color: ${(props) => convertColorToCss(props.thankYouData.title.color)};

        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px;
      }

      .description {
        color: ${(props) => convertColorToCss(props.thankYouData.description.color)};
      }

      .image {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;

        object-fit: contain;
        border-radius: ${(props) => `${props.thankYouData.image.border_radius}px`};
      }
    }
    
    .fieldsWrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
`;
