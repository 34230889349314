import React, { useMemo } from 'react';

function HearthIcon({ animatedHearth }: {animatedHearth?: boolean}) {
  const size = useMemo(() => {
    if (animatedHearth) {
      const randomSize = Math.random() * 24;

      if (randomSize >= 12) {
        return randomSize;
      }

      return 12 + Math.random() * 10;
    }

    return 24;
  }, [animatedHearth]);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size || '24'} height={size || '24'} viewBox="0 0 24 24" fill="none">
      <path d="M11.6878 6.98226L12.5 8.11297L13.3122 6.98228C14.1358 5.83568 15.4165 5 16.7585 5C19.101 5 21 6.899 21 9.24145C21 9.4835 20.9785 9.72241 20.9384 9.95765L20.9242 10.041V10.1256V10.1273C20.9242 12.7283 18.9094 15.1393 16.5846 17.0131C15.4555 17.9231 14.3211 18.6517 13.4662 19.1537C13.077 19.3822 12.7481 19.5624 12.507 19.69C12.2651 19.5583 11.9343 19.3722 11.5427 19.1367C10.6865 18.622 9.5503 17.8779 8.41936 16.9569C7.28439 16.0326 6.18328 14.9531 5.37227 13.7739C4.56114 12.5945 4.07579 11.3684 4.07579 10.1273V10.1256V10.041L4.06159 9.95768C4.0215 9.72237 4 9.48348 4 9.24145C4 6.899 5.89899 5 8.24149 5C9.5835 5 10.8642 5.83568 11.6878 6.98226Z" stroke="white" strokeWidth="2" />
    </svg>
  );
}

export default HearthIcon;
