import styled from 'styled-components';

export const ErrorTextStyles = styled.div`
  padding-top: 2px;

  display: flex;
  gap: 8px;

  color: #00000080;

`;
