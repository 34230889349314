import styled from 'styled-components';

export const LeadsStickersVersionStyles = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  max-width: calc(100dvw - 32px);
  
  @media (min-width: 1024px) {
    max-width: calc(100dvw - 78px);
  }
  
  @media (min-width: 1200px) {
    max-width: calc(100dvw - 393px);
  }
  
  .dragDropWrapper {
    padding-top: 24px;
    
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-grow: 1;
    
    @media (min-width: 1024px) {
      padding-top: 0;
    }
  }
  
  .listsWrapper {
    overflow: hidden;
    
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 20px;
    padding-bottom: 20px;
    
    @media (min-width: 1024px) {
      flex-direction: row;
      overflow: auto;
    }
    
    .loadMoreButton {
      color: var(--Blue, #2A7BCA);
      
      transition: 0.3s ease color;
      
      &:hover {
        color: #1A4F7F;
      }
    }
    
    .statusesList {
      display: flex;
      gap: 8px;
      overflow: auto;
      
      min-height: 50px;
      padding: 4px;
      
      @media (min-width: 1024px) {
        width: 238px;
        overflow: initial;
        flex-direction: column;
      }
    }
    
    .listWrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      
      .titleWrapper {
        text-align: left;
        
        @media (min-width: 1024px) {
          text-align: center;
        }
      }
      
      .listTitle {
        width: fit-content;
        padding: 4px 8px;
        border-radius: 4px;
      }
      
      li {
        width: 238px;
        
        a {
          display: flex;
          flex-direction: column;
          gap: 12px;
          
          width: 238px;

          border-radius: 8px;
          background: #FEFEFE;
          /* sm */
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
          padding: 16px;
          box-sizing: border-box;
          color: var(--grey-8, #1C1C1C);
          
          .block {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        
        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }
  }
`;
