import styled from 'styled-components';

export const NumberInputStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  
  .inputContainer {
    position: relative;
    width: fit-content;
  }

  .numberChangeButtonsWrapper {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    height: 40px;

    button {
      position: relative;
      width: 20px;
      height: 21px;
      border-left: 1px solid #C2C9D1;
      border-right: 1px solid #C2C9D1;
      box-sizing: border-box;
      
      overflow: hidden;

      transition: 0.3s ease all;

      svg {
        path {
          transition: 0.3s ease all;
        }
      }

      &:hover {
        border-color: #FC812B;

        svg {
          path {
            fill: #FC812B;
          }
        }
      }

      &:focus {
        border-color: #FC812B;
      }

      &:nth-child(1) {
        border-radius: 0 4px 0 0;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          transform: translateY(100%);
          width: 100%;
          height: 1px;
          background: #C2C9D1;
        }
      }

      &:nth-child(2) {
        border-radius: 4px 0 0 0;

        transform: rotate(180deg);
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    font-size: 16px;
    background-color: inherit;
    box-sizing: border-box;
    padding: 10px 24px 10px 8px;
    transition: 0.3s all;
    font-family: 'Inter', sans-serif;
    outline: none;

    border-radius: var(--corners_btn, 4px);
    border: 1px solid var(--gray-light, #C2C9D1);
    background: var(--white-default, #FFF);

    &:hover {
      border: 1px solid var(--orange-2, #FEC9A4);
    }

    &:focus {
      border: 1px solid var(--orange-1, #FC812B);
    }

    &::placeholder {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      line-height: 22px;
      color: var(--black-light, rgba(0, 0, 0, 0.50));
    }

    &.filled {
      border-radius: var(--corners_btn, 4px);
      border: 1px solid var(--gray-light, #C2C9D1);
      background: var(--gray-lighter, linear-gradient(0deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.75) 100%), #C2C9D1);
    }

    &.disabled {
      border: 1px solid var(--gray-light, #C2C9D1);
      background: var(--gray-lighter, linear-gradient(0deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.75) 100%), #C2C9D1);
    }
  }
`;
