import styled from 'styled-components';

export const TableStyles = styled.div`
  width: 100%;
  
  overflow: auto;
  
  .cursor-pointer {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 18px;
  }

  .arrowDownActive {
    transform: rotate(180deg);
  }
  
  svg {
    &.active {
      transform: rotate(180deg);
    }
  }
  
  table {
    width: 100%;
    
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);

    thead {
      height: 52px;

      background-color: #FAFAFA;
      
      th {
        padding: 0 30px !important;
      }
      
      th {
        text-align: left;
        padding: 0 10px;
        box-sizing: border-box;
        border-bottom: 1px solid #E5E7E7;
        border-top: 1px solid #E5E7E7;

        &:nth-child(1) {
          border-left: 1px solid #E5E7E7;
          border-top-left-radius: 8px;
        }

        &:last-child {
          border-right: 1px solid #E5E7E7;
          border-top-right-radius: 8px;
        }
      }
    }
    
    tbody {
      tr {
        background: #FFF;
        
        transition: 0.3s ease all;
        cursor: pointer;

        &:hover {
          background: var(--grey-2, #FAFAFA);
        }
      }

      td {
        border-bottom: 1px solid #EAECF0;

        &:nth-child(1) {
          border-left: 1px solid #EAECF0;
        }

        &:last-child {
          border-right: 1px solid #EAECF0;
        }
      }
      
      td {
        padding: 14px 30px;
        box-sizing: border-box;
      }
    }
  }
`;
