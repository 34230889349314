import styled from 'styled-components';

export const ConstructorStyles = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 1012px;

  .head {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;

    @media (min-width: 768px) {
      align-items: center;
      flex-direction: row;
    }

    margin-bottom: 24px;
    
    .buttonsContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;
      
      @media (min-width: 600px) {
        flex-direction: row;
      }
    }

    .closeEditingButton {
      &:hover {
        svg {
          path {
            stroke: #DC6F23;
          }
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    max-width: calc(100vw - 32px);
    width: 100%;
    //overflow: hidden;
  }
  
  .stepsWrapper {
    max-width: calc(100vw - 32px);
    width: 100%;
    overflow: auto;
    margin-bottom: 32px;
  }
  
  .publicationErrorModal {
    display: flex;
    flex-direction: column;
    
    max-width: 504px;
    
    .head {
      display: flex;
      flex-direction: column;
      gap: 32px;
      
      margin-bottom: 24px;
      
      text-align: center;
    }
  }
`;
