import React, { useEffect, useMemo, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { TApiForm, TForm } from '../../enteties/types/forms.types';
import { FormsStyles } from './FormsStyles';
import workWithResponse from '../../helpers/workWithResponse';
import { Api } from '../../api';
import { notification } from '../../helpers/notifications/toastify';
import Loader from '../../components/loader/Loader';
import ErrorComponent from '../../components/other/error/ErrorComponent';
import Pagination from '../../components/table/pagination/Pagination';
import { Table } from '../../components/table/Table';
import EmptyDataIcon from '../../assets/icons/forms/EmptyDataIcon';
import TextButton from '../../components/buttons/button/TextButton';
import { useLocation, useNavigate } from 'react-router-dom';
import Menu from '../../components/forms/menu/Menu';
import { purposes } from '../../constants/constants';
import ListMobile from '../../components/forms/listMobile/ListMobile';
import { useWindowSize } from 'usehooks-ts';
import { useAppSelector } from '../../state/hooks';

function Forms() {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useWindowSize();

  const [tableData, setTableData] = useState<TApiForm[]>([]);
  const [activePage, setActivePage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [dataLoadingError, setDataLoadingError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data: user } = useAppSelector((state) => state.user);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const pageParam = searchParams.get('page');

    if (pageParam && !Number.isNaN(Number(pageParam))) {
      setActivePage(Number(pageParam) - 1);
    } else {
      setActivePage(0);
    }
  }, []);

  async function getForms() {
    try {
      setIsLoading(true);

      await workWithResponse(() => Api.getUserForms({ userID: user!.id, offset: (activePage) * 12, limit: 12 })).then((res) => {
        if (res.data) {
          if (total !== res.data!.count) {
            setTotal(res.data!.count);
          }

          setTableData(res.data.data);

          return;
        }

        if (res.error) {
          notification.error(res.error);
        }
      });
    } catch (e) {
      notification.error('Oops something goes wrong');
      setDataLoadingError(true);
      console.error(`Error: ${e}`);
    } finally {
      setIsLoading(false);
    }
  }

  async function afterFormDelete(id: number) {
    try {
      setIsLoading(true);

      await workWithResponse(() => Api.getForms({ offset: ((activePage) * 12) + 11, limit: 1 })).then((res) => {
        if (res.data) {
          if (total !== res.data!.count) {
            setTotal(res.data!.count);
          }

          const newForm = res.data.data[0];

          const prevFormsWithoutDeletedForm = tableData.filter((form) => form.id !== id);

          const formWithNewForm = [...prevFormsWithoutDeletedForm, newForm];

          setTableData(formWithNewForm);

          return;
        }

        if (res.error) {
          notification.error(res.error);
        }
      });
    } catch (e) {
      notification.error('Oops something goes wrong');
      setDataLoadingError(true);
      console.error(`Error: ${e}`);
    } finally {
      setIsLoading(false);
    }
  }

  const cols = useMemo<ColumnDef<TApiForm>[]>(
    () => [
      {
        header: 'Name',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (row) => (
          <span className="textBase16">
            {row.cell.row.original.name}
          </span>
        ),
        accessorKey: 'name',
        enableSorting: false,
      },
      {
        header: 'Purpose',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (row) => (
          <span className="textBase16">
            {purposes[row.cell.row.original.purpose]}
          </span>
        ),
        accessorKey: 'purpose',
        enableSorting: false,
      },
      {
        header: undefined,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (row) => (
          <Menu
            form={row.cell.row.original}
            onFormDeleted={(id) => afterFormDelete(id)}
          />
        ),
        accessorKey: 'menu',
        enableSorting: false,
        size: 80,
      },
    ],
    [tableData],
  );

  useEffect(() => {
    const searchParams = new URLSearchParams();
    searchParams.set('page', String(activePage + 1));

    navigate({ search: `?${searchParams.toString()}` });

    getForms();
  }, [activePage, navigate]);

  if (dataLoadingError) {
    return <ErrorComponent />;
  }

  return (
    <FormsStyles>
      <div className="head">
        <h1 className="textBold28">Forms</h1>

        <TextButton
          text="Create"
          icon={(
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
              <path d="M8.5 3.33325V12.6666" stroke="#FCFCFC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M3.83337 8H13.1667" stroke="#FCFCFC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          )}
          iconPosition="iconLeft"
          onClick={() => navigate('/forms/constructor/new')}
          theme="mainFilled"
        />
      </div>

      <div className="content">
        {!dataLoadingError && (
          <>
            {isLoading && <Loader />}

            {!isLoading && total > 0 && (
              <>
                {width > 600 ? (
                  <Table
                    data={tableData}
                    columns={cols}
                    withSorting={false}
                  />
                ) : (
                  <ListMobile
                    forms={tableData}
                    onFormDeleted={afterFormDelete}
                  />
                )}

                <Pagination
                  activePage={activePage}
                  total={total}
                  setActivePage={(pageNumber) => setActivePage(pageNumber)}
                />
              </>
            )}

            {!isLoading && total === 0 && (
              <div className="noDataContainer">
                <div className="noDataTextContainer">
                  <h3 className="textSemiBold22 textBold">Your forms will be here.</h3>

                  <span className="textBase16">Press the ‘Create’ button to start building the form.</span>
                </div>

                <EmptyDataIcon />
              </div>
            )}
          </>
        )}
      </div>

    </FormsStyles>
  );
}

export default Forms;
