import React from 'react';
import {
  PropertyTypes,
  TApiProperty,
  TProperty,
  TPropertyValuesData,
} from '../../../../enteties/types/formProperties.types';
import { PropertyStyles } from './PropertyStyles';
import Text from './byType/Text';
import Date from './byType/Date';
import Number from './byType/Number';

export type DropdownProps<TPropertyFieldTypes extends TApiProperty, TPropertyValue extends TPropertyValuesData> = {
  property: TPropertyFieldTypes;
  onPropertyChanged(data: TApiProperty): void;
  onPropertyDeleted(id: number): void;
}

interface PropertyInterface {
  property: TApiProperty;
  onPropertyChanged(data: TApiProperty): void;
  onPropertyDeleted(id: number): void;
}

function getPropertyFieldByType({
  onPropertyDeleted, onPropertyChanged, property,
}: PropertyInterface) {
  if (property.type === PropertyTypes.text) return <Text onPropertyChanged={onPropertyChanged} onPropertyDeleted={onPropertyDeleted} property={property} />;
  if (property.type === PropertyTypes.date) return <Date onPropertyChanged={onPropertyChanged} onPropertyDeleted={onPropertyDeleted} property={property} />;
  if (property.type === PropertyTypes.number) return <Number onPropertyChanged={onPropertyChanged} onPropertyDeleted={onPropertyDeleted} property={property} />;
}

const Property = React.memo(({
  onPropertyDeleted, onPropertyChanged, property,
}: PropertyInterface) => (
  <PropertyStyles>
    {getPropertyFieldByType({
      property,
      onPropertyChanged(data: TApiProperty) {
        onPropertyChanged(data);
      },
      onPropertyDeleted(id: number) {
        onPropertyDeleted(id);
      },
    })}
  </PropertyStyles>
));

export default Property;
