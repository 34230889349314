import React from 'react';
import avatar from '../../../assets/icons/other/avatar.svg';
import { AvatarStyles } from './AvatarStyles';

interface AvatarProps {
  url: string | null;
  size?: number;
}

function Avatar({ size = 32, url }: AvatarProps) {
  return (
    <AvatarStyles style={{ width: size, height: size }}>
      <img src={url || avatar} loading="lazy" alt="Avatar" />
    </AvatarStyles>
  );
}

export default Avatar;
