import React, { useState } from 'react';
import { UTMStyles } from './UTMStyles';
import classNames from 'classnames';

const ArrowIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M18 15L12 9L6 15" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

interface UTMProps {
  utm_source: string | null,
  utm_medium: string | null,
  utm_campaign: string | null,
}

function Utm({ utm_medium, utm_source, utm_campaign }: UTMProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <UTMStyles>
      <button onClick={() => setIsOpen(!isOpen)} className={classNames('openButton', { open: isOpen })} type="button">
        <span className="textSemiBold16">
          UTM
        </span>

        {ArrowIcon}
      </button>

      <div className={classNames('utmFields', { open: isOpen })}>
        <div className="utmFieldWrapper">
          <span className="textSemiBold12">utm_source</span>

          <div className={classNames('value textBase14', { withValue: utm_source })}>
            {utm_source || 'Is empty'}
          </div>
        </div>

        <div className="utmFieldWrapper">
          <span className="textSemiBold12">utm_medium</span>

          <div className={classNames('value textBase14', { withValue: utm_medium })}>
            {utm_medium || 'Is empty'}
          </div>
        </div>

        <div className="utmFieldWrapper">
          <span className="textSemiBold12">utm_campaign</span>

          <div className={classNames('value textBase14', { withValue: utm_campaign })}>
            {utm_campaign || 'Is empty'}
          </div>
        </div>
      </div>
    </UTMStyles>
  );
}

export default Utm;
