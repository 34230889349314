import React from 'react';
import TextWithDot from '../../../components/other/textWithDot/TextWithDot';
import { PrivacyPolicyStyles } from './PrivacyPolicyStyles';

const background = (
  <svg width="1440" height="497" viewBox="0 0 1440 497" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.25">
      <path d="M-15.3711 158.477C84.1058 150.76 154.312 126.215 200.322 104.966C246.848 83.4769 280.356 59.9038 341.585 47.2301C409.119 33.1483 453.244 39.4569 470.697 43.0056C494.894 47.9342 493.391 51.2857 532.975 62.7202C609.014 84.6879 623.46 74.9151 657.53 89.4757C714.02 113.612 697.175 150.239 756.262 190.865C799.052 220.296 869.896 243.714 933.981 227.478C994.33 212.185 1001.12 173.784 1064.61 168.334C1090.69 166.095 1112.38 171.066 1155.75 181.008C1211.33 193.682 1248.65 209.312 1263.6 214.804C1307.87 231.097 1379.34 241.349 1496 209.172" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-16.0352 170.897C80.1912 164.237 152.129 142.015 202.316 119.991C243.085 101.854 274.178 81.9703 317.028 67.7617C326.4 64.6637 336.273 61.6643 346.906 59.0732C413.908 42.7664 457.866 45.4561 476.215 47.1036C500.823 49.3004 500.823 51.807 539.617 62.9176C566.958 70.733 586.431 75.197 601.515 78.126C627.155 83.0969 639.99 83.4349 660.223 92.9965C667.772 96.5348 674.857 100.868 681.336 105.91C711.305 129.215 710.03 158.759 759.745 190.077C803.02 217.339 873.394 237.955 936.385 221.831C941.376 220.551 946.294 219.038 951.119 217.297C999.817 199.709 1010.75 168.771 1066.56 163.913C1072.44 163.393 1078.35 163.238 1084.26 163.448C1104.09 164.096 1124.6 168.686 1157.08 176.122C1210.88 188.514 1247.62 203.849 1264.1 209.918C1276.17 214.322 1288.62 217.78 1301.32 220.254C1345.24 228.929 1407.34 229.83 1494.7 207.693" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-16.707 184.416C76.2536 178.783 149.999 159.068 204.302 136.002C247.426 117.696 279.962 96.7842 322.022 81.8574C331.622 78.4355 341.571 74.9714 352.249 72.0001C418.719 53.4543 462.45 51.2153 481.771 52.2855C506.667 53.6937 508.323 53.4262 546.267 64.1988C572.833 71.7607 592.23 76.8725 607.025 80.4633C631.951 86.4763 643.936 87.6873 662.862 97.6151C670.074 101.438 676.852 105.925 683.095 111.007C712.046 134.157 713.474 161.927 763.251 190.429C807.012 215.508 876.915 233.322 938.812 217.311C944.053 215.903 948.898 214.495 953.44 212.805C1003.67 194.893 1014.79 165.391 1068.55 160.702C1074.38 160.169 1080.25 159.995 1086.11 160.181C1106.28 160.73 1126.75 165.194 1158.44 172.559C1210.48 184.613 1246.62 199.695 1264.65 206.2C1276.62 210.565 1288.95 214.03 1301.53 216.565C1345.06 225.436 1406.87 227.309 1493.36 207.369" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-17.3555 198.849C72.3394 194.301 147.847 176.91 206.312 152.999C251.729 134.411 285.754 112.542 326.994 96.8825C336.837 93.1367 346.877 89.2219 357.57 85.8564C423.509 65.0997 466.936 60.7484 487.305 58.3827C512.505 55.4677 515.801 55.9606 552.909 66.3952C578.732 73.6756 598.053 79.4491 612.635 83.7019C636.832 90.7428 647.996 92.8551 665.616 103.135C672.435 107.249 678.868 111.889 684.846 117.005C712.78 139.987 717.003 165.968 766.719 191.639C811.027 214.536 880.398 229.562 941.202 213.649C946.397 212.241 951.212 210.833 955.723 209.185C1007.46 190.879 1018.81 162.87 1070.48 158.209C1076.27 157.663 1082.1 157.471 1087.92 157.632C1108.42 158.068 1128.93 162.434 1159.78 169.658C1210.03 181.36 1245.59 196.174 1265.15 203.229C1277.05 207.527 1289.28 210.996 1301.74 213.607C1344.88 222.676 1406.4 225.534 1492.01 207.777" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-18.0234 213.311C68.4208 209.819 145.66 194.779 208.302 170.009C256.028 151.126 291.526 128.285 331.976 111.922C342.047 107.852 352.163 103.473 362.902 99.7268C428.309 76.8016 471.341 69.0002 492.85 64.5222C518.262 59.2274 523.229 58.5374 559.563 68.6341C584.701 75.675 603.856 82.0682 618.195 86.9405C641.663 94.9953 651.991 98.037 668.32 108.655C674.734 113.051 680.824 117.84 686.548 122.99C713.479 145.775 720.497 169.981 770.167 192.907C815.022 213.607 883.846 225.858 943.572 210.058C948.706 208.65 953.491 207.242 957.987 205.622C1011.24 186.936 1022.79 160.56 1072.4 155.843C1078.15 155.305 1083.93 155.093 1089.71 155.209C1110.52 155.547 1130.97 159.8 1161.1 166.883C1209.59 178.261 1244.55 192.822 1265.65 200.384C1277.47 204.64 1289.6 208.118 1301.94 210.791C1344.7 220.07 1405.93 223.887 1490.67 208.34" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-18.6641 222.634C64.5296 220.198 143.516 207.524 210.32 181.895C260.339 162.701 297.326 138.847 336.986 121.836C347.27 117.428 357.492 112.64 368.262 108.472C433.106 83.3783 475.789 72.1973 498.376 65.5084C524.016 57.9324 530.745 55.9468 566.198 65.7197C585.776 71.1361 604.994 77.6156 623.751 85.1245C646.536 94.1791 655.999 98.1784 670.991 109.064C677.538 113.837 682.733 118.921 688.353 123.864C714.282 146.395 724.109 168.926 773.688 189.035C819.136 207.524 887.383 217.015 946.015 201.328C951.103 199.92 955.858 198.512 960.323 196.92C1015.11 177.896 1026.85 152.915 1074.4 148.338C1080.1 147.798 1085.84 147.572 1091.58 147.662C1112.72 147.93 1133.14 152.027 1162.45 158.928C1209.16 169.94 1243.55 184.275 1266.17 192.344C1277.91 196.555 1289.94 200.036 1302.18 202.764C1344.55 212.256 1405.47 217.057 1489.35 203.694" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-19.3633 229.28C60.5646 227.872 141.298 217.55 212.279 191.09C264.592 171.587 303.037 146.69 341.937 129.13C352.449 124.37 362.747 119.16 373.562 114.598C437.921 87.3774 480.27 72.8309 503.92 63.903C529.742 54.1161 538.218 50.7646 572.85 60.1994C592.256 65.4868 611.139 72.2989 629.295 80.5618C651.335 90.5881 659.993 95.5731 673.679 106.839C679.755 111.809 684.54 117.104 690.114 122.103C715.056 144.493 727.709 165.081 777.166 182.529C823.236 198.779 890.891 205.538 948.429 189.95C953.457 188.542 958.166 187.133 962.632 185.57C1018.95 166.25 1030.94 142.705 1076.36 138.297C1082.02 137.743 1087.72 137.499 1093.41 137.565C1114.86 137.734 1135.28 141.705 1163.79 148.521C1208.72 159.209 1242.52 173.277 1266.68 181.881C1278.34 186.038 1290.27 189.523 1302.39 192.315C1344.38 202.018 1405.01 207.806 1488.03 196.61" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-20.0156 236.025C56.6465 235.702 139.096 227.675 214.285 200.384C268.876 180.557 308.764 154.59 346.905 136.396C357.644 131.256 368.049 125.624 378.895 120.695C442.737 91.3765 484.782 73.5207 509.45 62.2552C535.621 50.3138 545.692 45.5541 579.489 54.651C598.754 59.883 617.325 67.1004 634.855 76.1681C656.121 87.1379 663.989 93.1649 676.384 104.712C681.943 109.894 686.348 115.358 691.862 120.456C715.831 142.606 731.355 161.363 780.661 176.121C827.369 190.104 894.385 194.146 950.785 178.684C955.767 177.276 960.445 175.868 964.881 174.319C1022.74 154.604 1034.86 132.538 1078.27 128.158C1083.89 127.604 1089.54 127.341 1095.19 127.37C1116.97 127.37 1137.36 131.285 1165.06 137.987C1208.23 148.309 1241.42 162.152 1267.14 171.249C1278.72 175.371 1290.55 178.869 1302.56 181.726C1344.15 191.583 1404.5 198.356 1486.63 189.386" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-20.6797 242.799C52.7318 243.531 136.913 237.828 216.279 209.707C273.164 189.556 314.495 162.504 351.892 143.761C362.828 138.283 373.355 132.27 384.215 126.863C447.556 95.4464 489.328 74.2814 514.998 60.6783C541.489 46.5964 553.17 40.4004 586.146 49.1593C605.265 54.3309 623.526 61.912 640.419 71.6903C661.031 83.6176 667.973 90.6867 679.077 102.515C684.135 107.923 688.19 113.528 693.628 118.724C716.625 140.649 734.99 157.533 784.143 169.644C831.52 181.318 897.883 182.698 953.234 167.334C958.171 165.926 962.819 164.518 967.224 163.011C1026.63 143.001 1038.92 122.343 1080.28 118.048C1085.86 117.492 1091.47 117.21 1097.08 117.203C1119.18 117.076 1139.61 120.906 1166.48 127.469C1207.86 137.453 1240.47 151.056 1267.72 160.66C1279.22 164.753 1290.94 168.274 1302.84 171.207C1344.05 181.318 1404.11 189.02 1485.37 182.247" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-21.3516 249.544C48.8094 251.29 134.752 247.896 218.295 218.958C277.535 198.469 320.218 170.319 356.901 151.055C368.05 145.197 378.683 138.804 389.574 133.003C452.398 99.5299 493.911 75.0697 520.569 59.0586C547.44 42.9067 560.685 35.218 592.826 43.639C611.812 48.7926 629.767 56.7732 645.99 67.2684C665.888 80.111 671.964 88.2785 681.777 100.375C686.334 106.008 689.994 111.739 695.447 117.048C717.503 138.734 738.738 153.661 787.709 163.208C835.754 172.544 901.449 171.291 955.722 156.04C960.598 154.632 965.215 153.224 969.605 151.816C1030.55 131.496 1043.02 112.246 1082.3 108.064C1087.84 107.478 1093.42 107.177 1099.01 107.162C1121.43 107.021 1141.78 110.641 1167.92 117.02C1207.52 126.666 1239.54 140.029 1268.34 150.14C1278.74 153.801 1290.27 157.42 1303.17 160.659C1343.98 170.967 1403.76 179.656 1484.14 175.065" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-22.0195 256.092C44.8149 258.909 132.505 257.839 220.255 228.098C281.712 207.271 325.869 177.994 361.853 158.223C373.2 151.985 383.954 145.226 394.875 139.002C457.244 103.473 498.529 75.7319 526.068 57.3269C553.318 39.0205 568.113 29.9236 599.434 38.0066C618.308 43.147 635.979 51.5147 651.535 62.678C670.749 76.4501 675.838 85.73 684.451 98.0657C688.506 103.924 691.802 109.796 697.134 115.203C718.247 136.622 742.338 149.676 791.112 156.604C839.886 163.532 904.867 159.702 958.046 144.564C962.877 143.156 967.464 141.748 971.823 140.339C1034.31 119.71 1046.97 101.868 1084.23 97.7841C1089.72 97.1893 1095.24 96.8743 1100.77 96.8406C1123.55 96.5731 1143.85 100.094 1169.12 106.388C1206.96 115.682 1238.37 128.821 1268.72 139.424C1279.08 143.057 1290.52 146.662 1303.26 149.972C1343.68 160.477 1403.16 170.137 1482.68 167.757" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-22.6758 262.556C40.9536 266.457 130.299 267.653 222.257 237.124C286.054 216.001 331.531 185.528 366.817 165.306C378.376 158.674 389.252 151.563 400.234 144.916C462.117 107.317 503.19 76.3372 531.594 55.4538C559.285 35.1478 575.644 24.4737 606.023 32.2328C626.195 37.3868 644.514 48.0468 657.045 57.9463C675.592 72.6196 679.83 83.0402 687.106 95.6153C690.63 101.699 693.576 107.712 698.832 113.232C719.019 134.355 745.92 145.465 794.527 149.845C843.939 154.337 908.297 147.972 960.382 132.946C965.152 131.538 969.709 130.13 974.053 128.722C1038.08 107.768 1050.9 91.3344 1086.06 87.3633C1091.51 86.7597 1096.99 86.426 1102.48 86.3635C1125.55 85.9551 1145.85 89.3911 1170.41 95.559C1206.47 104.515 1237.24 117.414 1269.14 128.525C1279.46 132.115 1290.8 135.735 1303.38 139.086C1343.42 149.802 1402.61 160.434 1481.25 160.209" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-23.3438 268.935C37.0351 273.906 128.036 277.215 224.247 246.052C290.216 224.422 337.182 192.963 371.8 172.249C383.541 165.208 394.584 157.759 405.536 150.69C466.993 111.105 507.868 76.7738 537.139 53.5246C565.285 31.1626 583.042 18.9959 612.722 26.4029C632.18 31.3034 650.408 42.5267 662.651 53.1585C680.529 68.733 683.749 80.2802 689.84 93.1087C692.878 99.4174 695.445 105.543 700.64 111.176C719.9 132.073 749.657 141.142 798.082 143.099C848.207 145.113 911.867 136.256 962.859 121.329C967.588 119.93 972.109 118.522 976.423 117.104C1042.01 95.8688 1055 80.8153 1088.1 76.9568C1093.5 76.337 1098.94 75.9844 1104.38 75.9007C1127.77 75.3797 1148.07 78.7171 1171.84 84.7441C1206.11 93.3481 1236.32 106.078 1269.78 117.639C1280.05 121.216 1291.29 124.807 1303.73 128.215C1343.37 139.142 1402.28 150.746 1480.05 152.745" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-24.0117 275.215C33.1013 281.228 125.849 286.861 226.238 254.881C294.5 232.927 342.848 200.257 376.782 179.135C388.706 171.714 399.87 163.898 410.868 156.407C471.884 114.809 512.638 77.182 542.668 51.4826C571.301 27.0505 590.515 13.3348 619.3 20.418C638.059 25.0509 656.211 36.8515 668.134 48.2437C685.375 64.7195 687.577 77.4214 692.438 90.4893C694.899 97.0374 697.192 103.29 702.296 109.021C720.751 129.637 753.348 138.086 801.576 136.185C852.279 134.185 915.376 124.37 965.289 109.556C969.968 108.157 974.454 106.749 978.747 105.332C1045.92 83.899 1059.04 70.127 1090.07 66.3812C1095.44 65.7563 1100.83 65.3896 1106.23 65.2828C1129.95 64.6632 1150.28 67.8739 1173.19 73.7319C1205.62 82.0684 1235.3 94.6435 1270.3 106.627C1280.52 110.176 1291.67 113.767 1303.94 117.231C1343.21 128.37 1401.82 140.945 1478.71 145.141" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-24.7383 281.426C29.1242 288.467 123.604 296.451 228.169 263.64C298.725 241.363 348.365 207.496 381.691 185.894C393.843 178.065 405.128 169.926 416.126 161.955C476.732 118.301 517.258 77.464 548.154 49.3003C577.333 22.8264 598.037 7.64615 626.016 14.3773C644.062 18.7427 662.137 31.1065 673.757 43.2592C690.36 60.6081 691.499 74.4646 695.22 87.7579C697.134 94.5454 699.124 100.91 704.152 106.768C721.62 127.103 756.92 132.116 805.056 129.187C856.428 126.103 918.857 112.401 967.677 97.6997C972.234 96.2915 976.791 94.8833 981.028 93.4751C1049.73 71.7186 1063.05 59.3407 1092.02 55.7216C1097.34 55.0797 1102.68 54.6943 1108.04 54.5669C1132.07 53.8347 1152.34 56.9468 1174.51 62.6922C1205.24 70.6203 1234.27 82.8856 1270.8 95.4466C1280.99 98.9671 1292.07 102.488 1304.22 106.078C1343.09 117.344 1401.43 130.961 1477.38 137.354" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-25.3281 287.565C25.2686 295.691 121.465 305.97 230.238 272.343C303.148 249.741 354.033 214.607 386.691 192.625C398.964 184.373 410.478 175.924 421.475 167.56C481.763 122.047 522.03 77.7174 553.625 47.1315C583.275 18.5313 605.346 1.88651 632.611 8.30784C649.942 12.4057 667.973 25.3328 679.274 38.2318C695.253 56.5382 695.299 71.465 697.881 85.0118C699.202 92.0527 700.919 98.5163 705.855 104.487C722.443 124.54 760.538 127.412 808.492 122.16C860.486 116.527 922.323 100.403 970.034 85.8004C974.591 84.3922 979.041 82.984 983.279 81.6885C1053.53 59.6081 1067 48.582 1093.92 45.0756C1099.23 44.4428 1104.58 44.0668 1109.93 43.949C1132.21 43.1711 1154.48 45.7875 1175.85 51.6941C1204.8 59.2842 1233.24 71.3101 1271.32 84.3781C1281.47 87.8563 1292.43 91.419 1304.39 95.024C1360.27 111.603 1417.74 123.208 1476 129.679" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-26 293.663C21.3461 302.844 119.274 315.447 232.224 280.989C307.367 258.064 359.604 221.747 391.715 199.314C534.239 99.6568 579.353 -11.6463 639.306 2.16806C655.924 5.99832 673.908 19.4888 684.875 33.1482C708.297 62.2976 693.533 84.4062 707.659 102.149C742.292 145.662 898.077 97.0657 985.63 69.761C1071 43.1322 1074.16 34.5563 1111.7 33.1482C1177.25 30.6557 1211.32 55.0595 1304.61 83.8429C1360.08 100.874 1416.99 113.592 1474.74 121.864" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-26 297.69C22.6068 307.73 117.497 319.235 225.48 286.255C300.015 263.429 350.035 226.759 384.5 203.172C522.179 109.063 565.12 7.13857 626.198 16.2355C650.061 19.7841 674.288 26.8954 690.283 37.7948C721.71 59.2274 710.029 78.3928 728.257 95.5304C768.16 133.073 913.464 90.9257 996.825 65.874C1078.03 41.3857 1081.31 34.4574 1121 35.8656C1186.95 38.2032 1219.47 59.8048 1307.36 87.4334C1362.19 104.662 1418.66 117.066 1476 124.483" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-26 301.887C55.0614 319.186 139.678 315.656 218.735 291.677C292.663 268.963 340.374 231.914 377.285 207.186C509.936 118.386 551.465 24.3189 613.104 30.4586C644.38 33.5848 673.787 34.3733 695.69 42.6112C733.269 56.6931 725.948 72.324 748.854 89.0814C792.615 121.061 928.851 84.9413 1008.03 62.1428C1084.94 39.7949 1088.36 34.3733 1130.31 38.7387C1196.51 45.6107 1227.63 64.8465 1310.11 91.1937C1364.29 108.607 1420.29 120.699 1477.2 127.271" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-26 309.35C52.701 326.857 135.101 323.747 212.006 300.366C285.312 277.75 330.729 240.321 370.07 214.466C497.42 130.792 538.249 54.5246 599.995 47.9484C638.623 43.8365 672.784 45.5263 701.113 50.6803C743.917 58.4535 741.426 69.5359 769.466 85.8849C815.886 112.95 944.269 82.3645 1019.23 61.6782C1091.96 41.6115 1095.34 37.4292 1139.61 44.8645C1206.02 56.0173 1235.77 73.1268 1312.88 98.2066C1366.41 115.825 1421.97 127.611 1478.44 133.327" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-26 320.714C50.3346 338.419 130.518 335.714 205.262 312.913C277.96 290.382 321.068 252.572 362.855 225.605C484.812 146.901 525.217 77.9144 586.902 69.2963C632.729 62.9032 673.194 53.1303 706.52 62.6074C752.378 75.6473 754.094 76.5485 790.063 86.5466C833.339 116.978 959.671 83.6035 1030.44 65.0718C1098.94 47.1878 1102.32 44.2306 1148.92 54.8624C1215.49 70.0568 1243.9 85.3215 1315.62 109.078C1368.51 126.896 1423.64 138.372 1479.67 143.226" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-26 332.346C47.9646 350.26 125.935 348.001 198.548 325.84C270.638 303.421 311.423 265.189 355.67 237.124C472.114 163.251 512.062 100.91 573.732 91.0247C626.896 82.5193 671.128 69.0289 711.958 74.9292C764.605 82.5334 769.269 79.548 810.691 87.6028C856.093 114.682 975.058 85.1104 1041.57 68.8458C1105.86 53.1445 1109.23 51.2716 1158.15 65.2408C1224.99 84.2795 1251.95 97.9248 1318.31 120.329C1370.56 138.349 1425.26 149.516 1480.84 153.506" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-26 344.203C45.5776 362.291 121.314 360.454 191.774 338.922C263.256 316.602 301.686 277.947 348.425 248.798C459.309 179.642 498.802 123.61 560.7 112.908C621.048 102.473 669.853 93.9115 717.335 87.3916C775.967 79.3367 784.382 82.6741 831.258 88.7998C878.862 112.612 990.445 86.8283 1052.84 72.7746C1112.9 59.256 1116.28 58.3829 1167.53 75.774C1234.48 98.5022 1260.11 110.739 1321.06 131.749C1372.67 149.964 1426.96 160.822 1482.07 163.955" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-26 356.214C30.551 371.38 106.909 376.492 185.045 352.173C255.965 330.092 291.98 290.846 341.21 260.64C446.428 196.089 485.511 146.155 547.591 134.96C615.276 122.765 669.108 110.838 722.758 100.023C787.01 87.0533 800.985 77.9986 851.87 90.1654C904.593 102.769 1005.83 88.7572 1064.04 76.8721C1119.88 65.5221 1123.28 65.6066 1176.82 86.4618C1244.11 112.753 1268.2 123.751 1323.89 143.324C1358.51 155.491 1410.73 171.404 1483.38 174.558" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-26 368.437C31.8725 384.434 105.132 388.476 178.301 365.621C248.613 343.653 282.243 303.9 333.994 272.681C433.471 212.65 471.992 168.672 534.498 157.209C609.534 143.437 668.501 128.046 728.166 112.852C798.205 95.0099 815.688 81.4349 872.467 91.7288C926.588 101.586 1021.2 90.7712 1075.25 81.1674C1126.89 71.986 1130.42 72.8309 1186.13 97.3615C1253.76 127.117 1276.27 137.016 1326.64 155.097C1360.75 167.348 1412.2 183.261 1484.61 185.373" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-26 380.801C33.2396 397.699 103.355 400.6 171.556 379.224C241.262 357.368 272.476 317.094 326.779 284.875C420.469 229.28 458.473 191.202 521.389 179.613C603.853 164.419 667.969 145.45 733.573 125.834C809.521 103.134 830.407 85.349 893.064 93.4461C948.658 100.628 1036.58 93.0096 1086.44 85.6166C1133.8 78.5757 1137.56 80.1951 1195.43 108.415C1263.48 141.578 1284.32 150.478 1329.39 167.024C1363.02 179.374 1413.66 195.343 1485.84 196.328" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-26 393.349C34.5003 411.049 101.593 412.894 164.812 392.997C233.91 371.24 262.679 330.403 319.564 297.24C407.406 246.01 443.861 206.961 508.296 202.187C599.646 195.414 667.483 163.04 738.981 138.988C820.898 111.444 848.346 75.6194 913.661 95.334C968.223 111.852 1051.89 95.334 1097.65 90.2364C1140.76 85.3781 1144.74 87.6735 1204.74 119.625C1273.26 156.14 1292.34 164.166 1332.14 179.121C1365.26 191.584 1415.13 207.566 1487.07 207.468" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-26 406.303C35.8218 424.863 99.8158 425.567 158.083 407.261C226.558 385.603 252.851 344.146 312.349 310.096C394.373 263.161 430.327 230.998 495.187 225.253C593.92 216.48 667.058 181.134 744.403 152.632C832.351 120.244 861.925 82.0682 934.274 97.7131C991.66 110.133 1067.3 98.2482 1108.85 95.3474C1147.72 92.6296 1152.05 95.5867 1214.04 131.341C1283.1 171.164 1300.36 178.388 1334.9 191.71C1367.51 204.299 1416.61 220.268 1488.32 219.085" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-26 420.076C37.1281 439.494 98.0234 439.114 151.339 422.188C219.206 400.628 242.978 358.496 305.134 323.615C381.173 280.933 416.778 255.698 482.093 248.981C588.239 238.067 666.663 199.905 749.811 166.954C843.865 129.679 875.885 89.9543 954.871 100.769C1014.93 108.993 1082.65 101.755 1120.06 101.121C1154.66 100.53 1159.38 104.135 1223.35 143.719C1292.99 186.823 1308.41 193.329 1337.65 204.975C1369.75 217.733 1418.15 233.646 1489.55 231.378" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-26 434.087C38.4496 454.365 96.2462 452.872 144.595 437.438C211.855 415.963 233.059 373.07 297.919 337.457C368.019 298.957 403.183 280.693 468.985 273.032C582.482 259.824 666.298 218.986 755.218 181.585C855.47 139.438 890.193 98.7412 975.468 104.135C1038.05 108.092 1098 105.543 1131.25 107.218C1161.57 108.725 1166.77 112.95 1232.64 156.421C1302.96 202.792 1316.31 208.706 1340.4 218.564C1371.95 231.533 1419.54 247.361 1490.78 243.996" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-26 449.338C39.7711 470.461 94.4234 467.785 137.851 453.914C204.457 432.397 223.064 388.758 290.704 352.525C354.835 318.165 389.573 306.9 455.891 298.31C576.679 282.665 665.964 239.307 760.565 197.441C866.892 150.422 905.155 115.147 996.004 108.726C1060.74 104.163 1113.28 110.556 1142.4 114.541C1168.48 118.118 1174.15 122.991 1241.89 170.334C1312.89 219.986 1324.19 225.253 1343.09 233.35C1374.04 246.629 1420.95 262.274 1491.95 257.824" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-26 464.433C41.0774 486.415 92.6463 482.641 131.122 470.249C197.105 448.831 212.979 404.149 283.489 367.452C341.635 337.19 375.933 332.951 442.783 323.446C570.846 305.238 665.645 259.472 766.048 213.171C878.589 161.251 920.451 123.047 1016.68 113.189C1083.51 106.374 1128.73 115.344 1153.66 121.723C1175.52 127.356 1181.7 132.848 1251.25 184.12C1323.01 237.011 1332.15 241.757 1345.91 248.023C1376.17 261.795 1422.48 277.046 1493.25 271.512" stroke="#00C8CB" strokeMiterlimit="10" />
      <path d="M-26 479.543C42.3534 502.37 90.9602 497.511 124.377 486.584C189.693 465.264 202.786 419.315 276.274 382.378C328.435 356.158 362.278 359.002 429.689 348.582C564.953 327.67 665.341 279.58 771.456 228.886C890.209 172.079 935.793 130.89 1037.27 117.639C1106.46 108.612 1144.16 120.061 1164.87 128.904C1182.53 136.452 1189.17 142.691 1260.56 197.906C1333.09 254.05 1340 258.317 1348.66 262.682C1377.93 277.454 1423.94 291.832 1494.48 285.213" stroke="#00C8CB" strokeMiterlimit="10" />
    </g>
  </svg>
);

const backgroundTablet = (
  <svg width="768" height="497" viewBox="0 0 768 497" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M-366.371 158.477C-266.894 150.76 -196.688 126.215 -150.678 104.966C-104.152 83.4769 -70.644 59.9038 -9.4146 47.2301C58.1185 33.1483 102.244 39.4569 119.697 43.0056C143.894 47.9342 142.391 51.2857 181.975 62.7202C258.014 84.6879 272.46 74.9151 306.53 89.4757C363.02 113.612 346.175 150.239 405.262 190.865C448.052 220.296 518.896 243.714 582.981 227.478C643.33 212.185 650.119 173.784 713.612 168.334C739.693 166.095 761.384 171.066 804.75 181.008C860.329 193.682 897.65 209.312 912.596 214.804C956.874 231.097 1028.34 241.349 1145 209.172" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-367.035 170.897C-270.809 164.237 -198.871 142.015 -148.684 119.991C-107.915 101.854 -76.8219 81.9703 -33.972 67.7617C-24.6 64.6637 -14.7267 61.6643 -4.09397 59.0732C62.9075 42.7664 106.866 45.4561 125.215 47.1036C149.823 49.3004 149.823 51.807 188.617 62.9176C215.958 70.733 235.431 75.197 250.515 78.126C276.155 83.0969 288.99 83.4349 309.223 92.9965C316.772 96.5348 323.857 100.868 330.336 105.91C360.305 129.215 359.03 158.759 408.745 190.077C452.02 217.339 522.394 237.955 585.385 221.831C590.376 220.551 595.294 219.038 600.119 217.297C648.817 199.709 659.754 168.771 715.561 163.913C721.442 163.393 727.353 163.238 733.257 163.448C753.094 164.096 773.6 168.686 806.076 176.122C859.877 188.514 896.621 203.849 913.102 209.918C925.173 214.322 937.622 217.78 950.317 220.254C994.245 228.929 1056.34 229.83 1143.7 207.693" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-367.707 184.416C-274.746 178.783 -201.001 159.068 -146.698 136.002C-103.574 117.696 -71.0382 96.7842 -28.9781 81.8574C-19.3782 78.4355 -9.42902 74.9714 1.24929 72.0001C67.7191 53.4543 111.45 51.2153 130.771 52.2855C155.667 53.6937 157.323 53.4262 195.267 64.1988C221.833 71.7607 241.23 76.8725 256.025 80.4633C280.951 86.4763 292.936 87.6873 311.862 97.6151C319.074 101.438 325.852 105.925 332.095 111.007C361.046 134.157 362.474 161.927 412.251 190.429C456.012 215.508 525.915 233.322 587.812 217.311C593.053 215.903 597.898 214.495 602.44 212.805C652.672 194.893 663.791 165.391 717.547 160.702C723.381 160.169 729.247 159.995 735.106 160.181C755.278 160.73 775.754 165.194 807.439 172.559C859.479 184.613 895.615 199.695 913.645 206.2C925.62 210.565 937.953 214.03 950.526 216.565C994.059 225.436 1055.87 227.309 1142.36 207.369" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-368.355 198.849C-278.661 194.301 -203.153 176.91 -144.688 152.999C-99.271 134.411 -65.2462 112.542 -24.0063 96.8825C-14.1634 93.1367 -4.12308 89.2219 6.57043 85.8564C72.5087 65.0997 115.936 60.7484 136.305 58.3827C161.505 55.4677 164.801 55.9606 201.909 66.3952C227.732 73.6756 247.053 79.4491 261.635 83.7019C285.832 90.7428 296.996 92.8551 314.616 103.135C321.435 107.249 327.868 111.889 333.846 117.005C361.78 139.987 366.003 165.968 415.719 191.639C460.027 214.536 529.398 229.562 590.202 213.649C595.397 212.241 600.212 210.833 604.723 209.185C656.459 190.879 667.806 162.87 719.481 158.209C725.274 157.663 731.099 157.471 736.919 157.632C757.425 158.068 777.931 162.434 808.781 169.658C859.028 181.36 894.587 196.174 914.151 203.229C926.053 207.527 938.285 210.996 950.743 213.607C993.882 222.676 1055.4 225.534 1141.01 207.777" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-369.023 213.311C-282.579 209.819 -205.34 194.779 -142.698 170.009C-94.972 151.126 -59.4738 128.285 -19.0238 111.922C-8.95307 107.852 1.16325 103.473 11.9023 99.7268C77.3089 76.8016 120.341 69.0002 141.85 64.5222C167.262 59.2274 172.229 58.5374 208.563 68.6341C233.701 75.675 252.856 82.0682 267.195 86.9405C290.663 94.9953 300.991 98.037 317.32 108.655C323.734 113.051 329.824 117.84 335.548 122.99C362.479 145.775 369.497 169.981 419.167 192.907C464.022 213.607 532.846 225.858 592.572 210.058C597.706 208.65 602.491 207.242 606.987 205.622C660.242 186.936 671.786 160.56 721.395 155.843C727.148 155.305 732.931 155.093 738.711 155.209C759.521 155.547 779.966 159.8 810.103 166.883C858.588 178.261 893.555 192.822 914.653 200.384C926.47 204.64 938.596 208.118 950.941 210.791C993.7 220.07 1054.93 223.887 1139.67 208.34" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-369.664 222.634C-286.47 220.198 -207.484 207.524 -140.68 181.895C-90.6608 162.701 -53.674 138.847 -14.0139 121.836C-3.73049 117.428 6.49215 112.64 17.2616 108.472C82.1062 83.3783 124.789 72.1973 147.376 65.5084C173.016 57.9324 179.745 55.9468 215.198 65.7197C234.776 71.1361 253.994 77.6156 272.751 85.1245C295.536 94.1791 304.999 98.1784 319.991 109.064C326.538 113.837 331.733 118.921 337.353 123.864C363.282 146.395 373.109 168.926 422.688 189.035C468.136 207.524 536.383 217.015 595.015 201.328C600.103 199.92 604.858 198.512 609.323 196.92C664.112 177.896 675.854 152.915 723.397 148.338C729.105 147.798 734.841 147.572 740.577 147.662C761.721 147.93 782.136 152.027 811.452 158.928C858.16 169.94 892.549 184.275 915.167 192.344C926.909 196.555 938.94 200.036 951.181 202.764C993.545 212.256 1054.47 217.057 1138.35 203.694" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-370.363 229.28C-290.435 227.872 -209.702 217.55 -138.721 191.09C-86.4082 171.587 -47.9632 146.69 -9.06252 129.13C1.44871 124.37 11.7473 119.16 22.5623 114.598C86.9208 87.3774 129.27 72.8309 152.92 63.903C178.742 54.1161 187.218 50.7646 221.85 60.1994C241.256 65.4868 260.139 72.2989 278.295 80.5618C300.335 90.5881 308.993 95.5731 322.679 106.839C328.755 111.809 333.54 117.104 339.114 122.103C364.056 144.493 376.709 165.081 426.166 182.529C472.236 198.779 539.891 205.538 597.429 189.95C602.457 188.542 607.166 187.133 611.632 185.57C667.955 166.25 679.94 142.705 725.357 138.297C731.022 137.743 736.718 137.499 742.415 137.565C763.862 137.734 784.277 141.705 812.788 148.521C857.719 159.209 891.516 173.277 915.683 181.881C927.343 186.038 939.269 189.523 951.394 192.315C993.378 202.018 1054.01 207.806 1137.03 196.61" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-371.016 236.025C-294.354 235.702 -211.904 227.675 -136.715 200.384C-82.1239 180.557 -42.2359 154.59 -4.09473 136.396C6.64434 131.256 17.0493 125.624 27.8947 120.695C91.7367 91.3765 133.782 73.5207 158.45 62.2552C184.621 50.3138 194.692 45.5541 228.489 54.651C247.754 59.883 266.325 67.1004 283.855 76.1681C305.121 87.1379 312.989 93.1649 325.384 104.712C330.943 109.894 335.348 115.358 340.862 120.456C364.831 142.606 380.355 161.363 429.661 176.121C476.369 190.104 543.385 194.146 599.785 178.684C604.767 177.276 609.445 175.868 613.881 174.319C671.738 154.604 683.859 132.538 727.271 128.158C732.891 127.604 738.541 127.341 744.192 127.37C765.974 127.37 786.359 131.285 814.065 137.987C857.234 148.309 890.423 162.152 916.139 171.249C927.719 175.371 939.546 178.869 951.561 181.726C993.151 191.583 1053.5 198.356 1135.63 189.386" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-371.68 242.799C-298.268 243.531 -214.087 237.828 -134.721 209.707C-77.8361 189.556 -36.5051 162.504 0.891784 143.761C11.8283 138.283 22.3547 132.27 33.2153 126.863C96.5561 95.4464 138.328 74.2814 163.998 60.6783C190.489 46.5964 202.17 40.4004 235.146 49.1593C254.265 54.3309 272.526 61.912 289.419 71.6903C310.031 83.6176 316.973 90.6867 328.077 102.515C333.135 107.923 337.19 113.528 342.628 118.724C365.625 140.649 383.99 157.533 433.143 169.644C480.52 181.318 546.883 182.698 602.234 167.334C607.171 165.926 611.819 164.518 616.224 163.011C675.631 143.001 687.919 122.343 729.281 118.048C734.861 117.492 740.468 117.21 746.08 117.203C768.181 117.076 788.611 120.906 815.482 127.469C856.858 137.453 889.471 151.056 916.721 160.66C928.216 164.753 939.939 168.274 951.839 171.207C993.049 181.318 1053.11 189.02 1134.37 182.247" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-372.352 249.544C-302.191 251.29 -216.248 247.896 -132.705 218.958C-73.465 198.469 -30.7822 170.319 5.90081 151.055C17.05 145.197 27.6827 138.804 38.5737 133.003C101.398 99.5299 142.911 75.0697 169.569 59.0586C196.44 42.9067 209.685 35.218 241.826 43.639C260.812 48.7926 278.767 56.7732 294.99 67.2684C314.888 80.111 320.964 88.2785 330.777 100.375C335.334 106.008 338.994 111.739 344.447 117.048C366.503 138.734 387.738 153.661 436.709 163.208C484.754 172.544 550.449 171.291 604.722 156.04C609.598 154.632 614.215 153.224 618.605 151.816C679.546 131.496 692.017 112.246 731.297 108.064C736.845 107.478 742.423 107.177 748.006 107.162C770.426 107.021 790.78 110.641 816.921 117.02C856.521 126.666 888.54 140.029 917.34 150.14C927.745 153.801 939.274 157.42 952.17 160.659C992.984 170.967 1052.76 179.656 1133.14 175.065" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-373.02 256.092C-306.185 258.909 -218.495 257.839 -130.745 228.098C-69.2875 207.271 -25.1312 177.994 10.853 158.223C22.1997 151.985 32.9539 145.226 43.8753 139.002C106.244 103.473 147.529 75.7319 175.068 57.3269C202.318 39.0205 217.113 29.9236 248.434 38.0066C267.308 43.147 284.979 51.5147 300.535 62.678C319.749 76.4501 324.838 85.73 333.451 98.0657C337.506 103.924 340.802 109.796 346.134 115.203C367.247 136.622 391.338 149.676 440.112 156.604C488.886 163.532 553.867 159.702 607.046 144.564C611.877 143.156 616.464 141.748 620.823 140.339C683.314 119.71 695.967 101.868 733.227 97.7841C738.718 97.1893 744.24 96.8743 749.768 96.8406C772.553 96.5731 792.846 100.094 818.122 106.388C855.959 115.682 887.371 128.821 917.72 139.424C928.079 143.057 939.517 146.662 952.261 149.972C992.681 160.477 1052.16 170.137 1131.68 167.757" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-373.676 262.556C-310.046 266.457 -220.701 267.653 -128.743 237.124C-64.9464 216.001 -19.4686 185.528 15.817 165.306C27.3763 158.674 38.2521 151.563 49.2342 144.916C111.117 107.317 152.19 76.3372 180.594 55.4538C208.285 35.1478 224.644 24.4737 255.023 32.2328C275.195 37.3868 293.514 48.0468 306.045 57.9463C324.592 72.6196 328.83 83.0402 336.106 95.6153C339.63 101.699 342.576 107.712 347.832 113.232C368.019 134.355 394.92 145.465 443.527 149.845C492.939 154.337 557.297 147.972 609.382 132.946C614.152 131.538 618.709 130.13 623.053 128.722C687.077 107.768 699.898 91.3344 735.062 87.3633C740.512 86.7597 745.993 86.426 751.482 86.3635C774.555 85.9551 794.848 89.3911 819.41 95.559C855.47 104.515 886.244 117.414 918.142 128.525C928.456 132.115 939.803 135.735 952.38 139.086C992.42 149.802 1051.61 160.434 1130.25 160.209" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-374.344 268.935C-313.965 273.906 -222.964 277.215 -126.753 246.052C-60.784 224.422 -13.8177 192.963 20.7995 172.249C32.5411 165.208 43.5839 157.759 54.5357 150.69C115.993 111.105 156.868 76.7738 186.139 53.5246C214.285 31.1626 232.042 18.9959 261.722 26.4029C281.18 31.3034 299.408 42.5267 311.651 53.1585C329.529 68.733 332.749 80.2802 338.84 93.1087C341.878 99.4174 344.445 105.543 349.64 111.176C368.9 132.073 398.657 141.142 447.082 143.099C497.207 145.113 560.867 136.256 611.859 121.329C616.588 119.93 621.109 118.522 625.423 117.104C691.012 95.8688 703.999 80.8153 737.097 76.9568C742.501 76.337 747.936 75.9844 753.381 75.9007C776.773 75.3797 797.066 78.7171 820.838 84.7441C855.106 93.3481 885.318 106.078 918.781 117.639C929.049 121.216 940.289 124.807 952.73 128.215C992.374 139.142 1051.28 150.746 1129.05 152.745" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-375.012 275.215C-317.899 281.228 -225.151 286.861 -124.762 254.881C-56.5002 232.927 -8.1516 200.257 25.7821 179.135C37.7059 171.714 48.8703 163.898 59.8676 156.407C120.884 114.809 161.638 77.182 191.668 51.4826C220.301 27.0505 239.515 13.3348 268.3 20.418C287.059 25.0509 305.211 36.8515 317.134 48.2437C334.375 64.7195 336.577 77.4214 341.438 90.4893C343.899 97.0374 346.192 103.29 351.296 109.021C369.751 129.637 402.348 138.086 450.576 136.185C501.279 134.185 564.376 124.37 614.289 109.556C618.968 108.157 623.454 106.749 627.747 105.332C694.916 83.899 708.04 70.127 739.072 66.3812C744.435 65.7563 749.83 65.3896 755.234 65.2828C778.945 64.6632 799.284 67.8739 822.19 73.7319C854.62 82.0684 884.3 94.6435 919.297 106.627C929.52 110.176 940.669 113.767 952.942 117.231C992.208 128.37 1050.82 140.945 1127.71 145.141" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-375.738 281.426C-321.876 288.467 -227.396 296.451 -122.831 263.64C-52.2749 241.363 -2.63519 207.496 30.6909 185.894C42.8426 178.065 54.1285 169.926 65.1258 161.955C125.732 118.301 166.258 77.464 197.154 49.3003C226.333 22.8264 247.037 7.64615 275.016 14.3773C293.062 18.7427 311.137 31.1065 322.757 43.2592C339.36 60.6081 340.499 74.4646 344.22 87.7579C346.134 94.5454 348.124 100.91 353.152 106.768C370.62 127.103 405.92 132.116 454.056 129.187C505.428 126.103 567.857 112.401 616.677 97.6997C621.234 96.2915 625.791 94.8833 630.028 93.4751C698.731 71.7186 712.052 59.3407 741.019 55.7216C746.336 55.0797 751.685 54.6943 757.044 54.5669C781.074 53.8347 801.337 56.9468 823.514 62.6922C854.243 70.6203 883.27 82.8856 919.801 95.4466C929.993 98.9671 941.067 102.488 953.218 106.078C992.089 117.344 1050.43 130.961 1126.38 137.354" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-376.328 287.565C-325.731 295.691 -229.535 305.97 -120.762 272.343C-47.8522 249.741 3.03306 214.607 35.6908 192.625C47.964 184.373 59.4778 175.924 70.4751 167.56C130.763 122.047 171.03 77.7174 202.625 47.1315C232.275 18.5313 254.346 1.88651 281.611 8.30784C298.942 12.4057 316.973 25.3328 328.274 38.2318C344.253 56.5382 344.299 71.465 346.881 85.0118C348.202 92.0527 349.919 98.5163 354.855 104.487C371.443 124.54 409.538 127.412 457.492 122.16C509.486 116.527 571.323 100.403 619.034 85.8004C623.591 84.3922 628.041 82.984 632.279 81.6885C702.531 59.6081 716.004 48.582 742.92 45.0756C748.232 44.4428 753.576 44.0668 758.93 43.949C781.214 43.1711 803.484 45.7875 824.853 51.6941C853.805 59.2842 882.24 71.3101 920.32 84.3781C930.467 87.8563 941.434 91.419 953.388 95.024C1009.27 111.603 1066.74 123.208 1125 129.679" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-377 293.663C-329.654 302.844 -231.726 315.447 -118.776 280.989C-43.633 258.064 8.60412 221.747 40.715 199.314C183.239 99.6568 228.353 -11.6463 288.306 2.16806C304.924 5.99832 322.908 19.4888 333.875 33.1482C357.297 62.2976 342.533 84.4062 356.659 102.149C391.292 145.662 547.077 97.0657 634.63 69.761C719.996 43.1322 723.155 34.5563 760.704 33.1482C826.247 30.6557 860.317 55.0595 953.612 83.8429C1009.08 100.874 1065.99 113.592 1123.74 121.864" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-377 297.69C-328.393 307.73 -233.503 319.235 -125.52 286.255C-50.9847 263.429 -0.96526 226.759 33.5 203.172C171.179 109.063 214.12 7.13857 275.198 16.2355C299.061 19.7841 323.288 26.8954 339.283 37.7948C370.71 59.2274 359.029 78.3928 377.257 95.5304C417.16 133.073 562.464 90.9257 645.825 65.874C727.029 41.3857 730.31 34.4574 770 35.8656C835.954 38.2032 868.475 59.8048 956.362 87.4334C1011.19 104.662 1067.66 117.066 1125 124.483" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-377 301.887C-295.939 319.186 -211.322 315.656 -132.265 291.677C-58.3366 268.963 -10.6259 231.914 26.2849 207.186C158.936 118.386 200.465 24.3189 262.104 30.4586C293.38 33.5848 322.787 34.3733 344.69 42.6112C382.269 56.6931 374.948 72.324 397.854 89.0814C441.615 121.061 577.851 84.9413 657.035 62.1428C733.94 39.7949 737.358 34.3733 779.311 38.7387C845.508 45.6107 876.631 64.8465 959.111 91.1937C1013.29 108.607 1069.29 120.699 1126.2 127.271" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-377 309.35C-298.299 326.857 -215.899 323.747 -138.994 300.366C-65.6884 277.75 -20.2713 240.321 19.0698 214.466C146.42 130.792 187.249 54.5246 248.995 47.9484C287.623 43.8365 321.784 45.5263 350.113 50.6803C392.917 58.4535 390.426 69.5359 418.466 85.8849C464.886 112.95 593.269 82.3645 668.229 61.6782C740.957 41.6115 744.345 37.4292 788.607 44.8645C855.016 56.0173 884.773 73.1268 961.875 98.2066C1015.41 115.825 1070.97 127.611 1127.44 133.327" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-377 320.714C-300.665 338.419 -220.482 335.714 -145.738 312.913C-73.0401 290.382 -29.9319 252.572 11.8548 225.605C133.812 146.901 174.217 77.9144 235.902 69.2963C281.729 62.9032 322.194 53.1303 355.52 62.6074C401.378 75.6473 403.094 76.5485 439.063 86.5466C482.339 116.978 608.671 83.6035 679.439 65.0718C747.945 47.1878 751.317 44.2306 797.919 54.8624C864.495 70.0568 892.9 85.3215 964.625 109.078C1017.51 126.896 1072.64 138.372 1128.67 143.226" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-377 332.346C-303.035 350.26 -225.065 348.001 -152.452 325.84C-80.3616 303.421 -39.5774 265.189 4.67001 237.124C121.114 163.251 161.062 100.91 222.732 91.0247C275.896 82.5193 320.128 69.0289 360.958 74.9292C413.605 82.5334 418.269 79.548 459.691 87.6028C505.093 114.682 624.058 85.1104 690.573 68.8458C754.856 53.1445 758.228 51.2716 807.154 65.2408C873.988 84.2795 900.95 97.9248 967.313 120.329C1019.56 138.349 1074.26 149.516 1129.84 153.506" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-377 344.203C-305.422 362.291 -229.686 360.454 -159.226 338.922C-87.7437 316.602 -49.3139 277.947 -2.57541 248.798C108.309 179.642 147.802 123.61 209.7 112.908C270.048 102.473 318.853 93.9115 366.335 87.3916C424.967 79.3367 433.382 82.6741 480.258 88.7998C527.862 112.612 639.445 86.8283 701.844 72.7746C761.904 59.256 765.276 58.3829 816.526 75.774C883.482 98.5022 909.107 110.739 970.063 131.749C1021.67 149.964 1075.96 160.822 1131.07 163.955" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-377 356.214C-320.449 371.38 -244.091 376.492 -165.955 352.173C-95.0347 330.092 -59.0201 290.846 -9.79047 260.64C95.4282 196.089 134.511 146.155 196.591 134.96C264.276 122.765 318.108 110.838 371.758 100.023C436.01 87.0533 449.985 77.9986 500.87 90.1654C553.593 102.769 654.832 88.7572 713.039 76.8721C768.876 65.5221 772.278 65.6066 825.822 86.4618C893.112 112.753 917.203 123.751 972.888 143.324C1007.51 155.491 1059.73 171.404 1132.38 174.558" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-377 368.437C-319.127 384.434 -245.868 388.476 -172.699 365.621C-102.387 343.653 -68.7567 303.9 -17.0056 272.681C82.4714 212.65 120.992 168.672 183.498 157.209C258.534 143.437 317.501 128.046 377.166 112.852C447.205 95.0099 464.688 81.4349 521.467 91.7288C575.588 101.586 670.204 90.7712 724.249 81.1674C775.894 71.986 779.418 72.8309 835.133 97.3615C902.757 127.117 925.268 137.016 975.637 155.097C1009.75 167.348 1061.2 183.261 1133.61 185.373" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-377 380.801C-317.76 397.699 -247.645 400.6 -179.444 379.224C-109.738 357.368 -78.5236 317.094 -24.2207 284.875C69.469 229.28 107.473 191.202 170.389 179.613C252.853 164.419 316.969 145.45 382.573 125.834C458.521 103.134 479.407 85.349 542.064 93.4461C597.658 100.628 685.576 93.0096 735.443 85.6166C782.805 78.5757 786.557 80.1951 844.429 108.415C912.479 141.578 933.319 150.478 978.386 167.024C1012.02 179.374 1062.66 195.343 1134.84 196.328" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-377 393.349C-316.5 411.049 -249.407 412.894 -186.188 392.997C-117.09 371.24 -88.3209 330.403 -31.4357 297.24C56.4059 246.01 92.8611 206.961 157.296 202.187C248.646 195.414 316.483 163.04 387.981 138.988C469.898 111.444 497.346 75.6194 562.661 95.334C617.223 111.852 700.887 95.334 746.653 90.2364C789.762 85.3781 793.741 87.6735 853.74 119.625C922.261 156.14 941.339 164.166 981.136 179.121C1014.26 191.584 1064.13 207.566 1136.07 207.468" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-377 406.303C-315.178 424.863 -251.184 425.567 -192.917 407.261C-124.442 385.603 -98.1486 344.146 -38.6508 310.096C43.3732 263.161 79.3271 230.998 144.187 225.253C242.92 216.48 316.058 181.134 393.403 152.632C481.351 120.244 510.925 82.0682 583.274 97.7131C640.66 110.133 716.305 98.2482 757.848 95.3474C796.719 92.6296 801.048 95.5867 863.036 131.341C932.104 171.164 949.359 178.388 983.9 191.71C1016.51 204.299 1065.61 220.268 1137.32 219.085" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-377 420.076C-313.872 439.494 -252.977 439.114 -199.661 422.188C-131.794 400.628 -108.022 358.496 -45.8659 323.615C30.1734 280.933 65.778 255.698 131.093 248.981C237.239 238.067 315.663 199.905 398.811 166.954C492.865 129.679 524.885 89.9543 603.871 100.769C663.931 108.993 731.646 101.755 769.058 101.121C803.66 100.53 808.384 104.135 872.348 143.719C941.992 186.823 957.41 193.329 986.65 204.975C1018.75 217.733 1067.15 233.646 1138.55 231.378" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-377 434.087C-312.55 454.365 -254.754 452.872 -206.405 437.438C-139.145 415.963 -117.941 373.07 -53.081 337.457C17.0192 298.957 52.1832 280.693 117.985 273.032C231.482 259.824 315.298 218.986 404.218 181.585C504.47 139.438 539.193 98.7412 624.468 104.135C687.049 108.092 747.003 105.543 780.253 107.218C810.571 108.725 815.766 112.95 881.644 156.421C951.957 202.792 965.308 208.706 989.399 218.564C1020.95 231.533 1068.54 247.361 1139.78 243.996" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-377 449.338C-311.229 470.461 -256.577 467.785 -213.149 453.914C-146.543 432.397 -127.936 388.758 -60.2961 352.525C3.83458 318.165 38.5733 306.9 104.891 298.31C225.679 282.665 314.964 239.307 409.565 197.441C515.892 150.422 554.155 115.147 645.004 108.726C709.743 104.163 762.284 110.556 791.402 114.541C817.483 118.118 823.148 122.991 890.894 170.334C961.891 219.986 973.192 225.253 992.088 233.35C1023.04 246.629 1069.95 262.274 1140.95 257.824" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-377 464.433C-309.923 486.415 -258.354 482.641 -219.878 470.249C-153.895 448.831 -138.021 404.149 -67.5111 367.452C-9.36519 337.19 24.933 332.951 91.7826 323.446C219.846 305.238 314.645 259.472 415.048 213.171C527.589 161.251 569.451 123.047 665.678 113.189C732.512 106.374 777.731 115.344 802.658 121.723C824.516 127.356 830.698 132.848 900.251 184.12C972.007 237.011 981.151 241.757 994.913 248.023C1025.17 261.795 1071.48 277.046 1142.25 271.512" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-377 479.543C-308.647 502.37 -260.04 497.511 -226.623 486.584C-161.307 465.264 -148.214 419.315 -74.7262 382.378C-22.565 356.158 11.2775 359.002 78.6891 348.582C213.953 327.67 314.341 279.58 420.456 228.886C539.209 172.079 584.793 130.89 686.275 117.639C755.463 108.612 793.164 120.061 813.868 128.904C831.533 136.452 838.171 142.691 909.562 197.906C982.093 254.05 989.004 258.317 997.662 262.682C1026.93 277.454 1072.94 291.832 1143.48 285.213" stroke="#00C8CB" strokeMiterlimit="10" />
  </svg>
);

const backgroundMobile = (
  <svg width="375" height="330" viewBox="0 0 375 330" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M-244.957 105.404C-179.006 100.288 -132.46 84.015 -101.957 69.9269C-71.1107 55.6801 -48.8952 40.0516 -8.30122 31.6492C36.472 22.3132 65.7267 26.4957 77.2977 28.8484C93.3399 32.116 92.343 34.338 118.587 41.9188C168.999 56.483 178.576 50.0038 201.164 59.6573C238.616 75.6592 227.448 99.9422 266.622 126.877C294.991 146.389 341.959 161.915 384.447 151.15C424.457 141.011 428.958 115.552 471.053 111.939C488.344 110.455 502.724 113.75 531.475 120.341C568.323 128.744 593.066 139.107 602.976 142.748C632.331 153.55 679.713 160.346 757.054 139.014" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-245.391 113.639C-181.594 109.223 -133.901 94.4909 -100.628 79.8894C-73.5987 67.8646 -52.9844 54.6821 -24.5756 45.2621C-18.3622 43.2081 -11.8164 41.2196 -4.76706 39.5017C39.6537 28.6906 68.7976 30.4738 80.9627 31.5661C97.2769 33.0225 97.2769 34.6843 122.997 42.0505C141.124 47.232 154.034 50.1915 164.034 52.1334C181.033 55.429 189.542 55.6531 202.956 61.9922C207.961 64.3381 212.658 67.2108 216.954 70.5534C236.823 86.0045 235.977 105.591 268.938 126.355C297.629 144.429 344.285 158.097 386.047 147.408C389.356 146.559 392.616 145.555 395.816 144.401C428.102 132.741 435.352 112.229 472.351 109.008C476.25 108.664 480.17 108.561 484.083 108.7C497.235 109.13 510.83 112.173 532.361 117.103C568.031 125.318 592.391 135.485 603.318 139.509C611.321 142.429 619.574 144.721 627.99 146.362C657.114 152.113 698.282 152.71 756.197 138.034" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-245.836 122.602C-184.205 118.868 -135.313 105.797 -99.3107 90.5047C-70.7207 78.3679 -49.1498 64.5039 -21.2647 54.6077C-14.9001 52.339 -8.30396 50.0424 -1.22443 48.0725C42.8439 35.7769 71.8367 34.2925 84.6464 35.002C101.152 35.9356 102.25 35.7583 127.406 42.9003C145.019 47.9138 157.879 51.3027 167.687 53.6834C184.213 57.6699 192.159 58.4728 204.706 65.0547C209.488 67.5895 213.982 70.564 218.12 73.9333C237.315 89.2817 238.261 107.692 271.262 126.588C300.275 143.216 346.619 155.026 387.656 144.411C391.131 143.477 394.343 142.544 397.354 141.423C430.657 129.548 438.029 109.989 473.668 106.88C477.536 106.527 481.425 106.412 485.31 106.535C498.683 106.899 512.258 109.858 533.265 114.741C567.767 122.733 591.724 132.732 603.678 137.045C611.617 139.938 619.793 142.236 628.129 143.916C656.991 149.798 697.968 151.04 755.309 137.82" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-246.27 132.171C-186.803 129.155 -136.743 117.625 -97.982 101.773C-67.8713 89.4493 -45.3135 74.9504 -17.9722 64.5687C-11.4465 62.0854 -4.78992 59.4899 2.29969 57.2586C46.0156 43.4973 74.807 40.6125 88.3115 39.044C105.018 37.1115 107.204 37.4382 131.806 44.3562C148.926 49.183 161.735 53.0107 171.403 55.8302C187.445 60.4982 194.847 61.8986 206.529 68.7139C211.049 71.4418 215.314 74.518 219.278 77.9099C237.797 93.1463 240.597 110.371 273.558 127.391C302.933 142.571 348.925 152.533 389.237 141.983C392.681 141.049 395.873 140.116 398.864 139.024C433.164 126.887 440.687 108.317 474.947 105.227C478.787 104.865 482.649 104.738 486.508 104.844C500.103 105.134 513.698 108.028 534.151 112.817C567.464 120.576 591.039 130.397 604.01 135.074C611.901 137.924 620.01 140.224 628.27 141.955C656.87 147.967 697.655 149.863 754.412 138.09" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-246.715 141.759C-189.404 139.443 -138.196 129.472 -96.665 113.05C-65.0236 100.531 -41.4889 85.3876 -14.6713 74.5392C-7.99459 71.8411 -1.28765 68.9376 5.83216 66.4542C49.1956 51.2551 77.7251 46.083 91.9849 43.1141C108.833 39.6038 112.126 39.1463 136.214 45.8402C152.881 50.5082 165.58 54.7468 175.086 57.9771C190.645 63.3173 197.493 65.3338 208.319 72.3732C212.571 75.2877 216.608 78.4628 220.403 81.8773C238.258 96.983 242.911 113.032 275.841 128.231C305.579 141.955 351.209 150.077 390.806 139.602C394.21 138.668 397.382 137.735 400.363 136.661C435.67 124.272 443.323 106.786 476.213 103.658C480.027 103.301 483.861 103.161 487.694 103.238C501.49 103.462 515.045 106.282 535.025 110.978C567.17 118.521 590.352 128.175 604.34 133.188C612.174 136.01 620.213 138.315 628.398 140.087C656.746 146.24 697.34 148.77 753.523 138.463" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-247.137 147.94C-191.981 146.324 -139.614 137.922 -95.3245 120.93C-62.1625 108.205 -37.6409 92.3901 -11.3469 81.1122C-4.52924 78.19 2.24819 75.0158 9.38815 72.2523C52.3789 55.6155 80.6769 48.2027 95.6517 43.7681C112.651 38.7453 117.112 37.4289 140.616 43.9081C153.596 47.4992 166.337 51.7949 178.773 56.7732C193.879 62.7763 200.153 65.4277 210.092 72.6444C214.433 75.8094 217.877 79.1797 221.603 82.4566C238.793 97.3943 245.309 112.332 278.179 125.664C308.31 137.922 353.556 144.214 392.428 133.814C395.802 132.88 398.954 131.947 401.915 130.892C438.239 118.279 446.023 101.717 477.544 98.6826C481.327 98.3246 485.131 98.1749 488.933 98.2345C502.951 98.4119 516.486 101.129 535.922 105.703C566.889 113.004 589.688 122.508 604.683 127.858C612.468 130.649 620.445 132.957 628.56 134.766C656.647 141.059 697.039 144.242 752.649 135.383" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-247.598 152.346C-194.607 151.413 -141.082 144.569 -94.0231 127.027C-59.3405 114.097 -33.8521 97.5904 -8.06167 85.9484C-1.09291 82.7928 5.73485 79.3385 12.905 76.3136C55.5736 58.2671 83.65 48.623 99.3297 42.7039C116.45 36.2154 122.069 33.9934 145.029 40.2486C157.895 43.754 170.414 48.2703 182.451 53.7485C197.064 60.3957 202.804 63.7007 211.877 71.1695C215.905 74.4651 219.078 77.9755 222.773 81.2897C239.309 96.134 247.698 109.783 280.487 121.351C311.031 132.124 355.885 136.606 394.032 126.271C397.365 125.337 400.487 124.404 403.448 123.367C440.789 110.558 448.734 94.9484 478.845 92.0262C482.601 91.6588 486.378 91.4967 490.154 91.5407C504.374 91.6527 517.908 94.2855 536.811 98.8041C566.599 105.89 589.006 115.217 605.028 120.921C612.759 123.677 620.666 125.988 628.704 127.839C656.538 134.272 696.74 138.109 751.775 130.687" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-248.031 156.818C-197.206 156.603 -142.543 151.282 -92.6944 133.189C-56.5012 120.044 -30.0561 102.828 -4.76922 90.7658C2.35059 87.3581 9.24888 83.6237 16.4392 80.3561C58.7653 60.9185 86.6403 49.0804 102.995 41.6116C120.346 33.6946 127.023 30.5391 149.43 36.5701C162.202 40.0389 174.514 44.8239 186.136 50.8356C200.235 58.1084 205.452 62.1042 213.669 69.7597C217.355 73.1954 220.275 76.8178 223.931 80.1974C239.822 94.883 250.114 107.319 282.803 117.103C313.769 126.373 358.2 129.053 395.592 118.802C398.895 117.868 401.997 116.935 404.937 115.908C443.296 102.837 451.332 88.2077 480.113 85.3042C483.839 84.9366 487.585 84.762 491.332 84.7814C505.773 84.7814 519.287 87.3768 537.656 91.8208C566.276 98.6641 588.28 107.841 605.329 113.872C613.007 116.605 620.848 118.925 628.814 120.818C656.387 127.354 696.397 131.844 750.848 125.897" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-248.477 161.308C-199.806 161.794 -143.996 158.012 -91.3774 139.368C-53.6635 126.009 -26.2618 108.074 -1.4683 95.6478C5.78244 92.0161 12.7613 88.0296 19.9616 84.4446C61.9555 63.6159 89.6492 49.5838 106.668 40.5652C124.231 31.2292 131.975 27.1214 153.838 32.9284C166.513 36.357 178.62 41.3832 189.82 47.866C203.486 55.7736 208.088 60.4603 215.449 68.3026C218.803 71.8876 221.492 75.6034 225.097 79.0483C240.344 93.5845 252.519 104.778 285.107 112.807C316.517 120.547 360.514 121.462 397.211 111.276C400.484 110.343 403.566 109.409 406.486 108.41C445.872 95.1437 454.019 81.4477 481.441 78.6002C485.14 78.2317 488.858 78.0447 492.578 78.0401C507.231 77.956 520.776 80.4954 538.59 84.846C566.022 91.4653 587.644 100.484 605.71 106.851C613.331 109.565 621.103 111.899 628.993 113.844C656.314 120.547 696.133 125.654 750.01 121.163" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-248.922 165.78C-202.406 166.937 -145.428 164.688 -90.0402 145.502C-50.7654 131.918 -22.4674 113.255 1.85275 100.484C9.24447 96.5999 16.2938 92.3613 23.5143 88.5149C65.1657 66.3231 92.6883 50.1064 110.362 39.4914C128.177 28.7829 136.958 23.6855 158.267 29.2684C170.854 32.6852 182.758 37.9762 193.514 44.9343C206.706 53.4487 210.734 58.8636 217.24 66.8833C220.261 70.6177 222.688 74.4175 226.303 77.9371C240.925 92.3146 255.004 102.211 287.471 108.541C319.324 114.73 362.879 113.9 398.861 103.789C402.093 102.855 405.155 101.921 408.065 100.988C448.468 87.5159 456.735 74.7535 482.778 71.9807C486.456 71.5927 490.154 71.3933 493.855 71.3832C508.719 71.2899 522.214 73.6892 539.545 77.9185C565.799 84.3136 587.027 93.1735 606.121 99.8768C613.019 102.304 620.662 104.704 629.212 106.851C656.272 113.685 695.899 119.445 749.192 116.402" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-249.359 170.122C-205.049 171.989 -146.913 171.28 -88.7355 151.562C-47.9905 137.754 -18.7156 118.344 5.14129 105.237C12.6639 101.101 19.7938 96.6195 27.0345 92.493C68.3838 68.9382 95.7553 50.5462 114.013 38.344C132.079 26.2072 141.888 20.1761 162.653 25.535C175.167 28.943 186.882 34.4906 197.195 41.8917C209.934 51.0223 213.308 57.1748 219.018 65.3531C221.707 69.2369 223.892 73.13 227.427 76.7151C241.424 90.9152 257.396 99.5697 289.732 104.163C322.069 108.756 365.15 106.217 400.407 96.1807C403.609 95.2471 406.651 94.3135 409.541 93.3799C450.971 79.7026 459.359 67.8738 484.062 65.1664C487.703 64.7721 491.364 64.5632 495.029 64.5409C510.135 64.3635 523.589 66.6975 540.346 70.8707C565.431 77.0325 586.257 85.743 606.378 92.773C613.246 95.1817 620.829 97.5718 629.278 99.7657C656.076 106.73 695.512 113.135 748.23 111.557" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-249.793 174.407C-207.608 176.993 -148.373 177.787 -87.4068 157.546C-45.1108 143.542 -14.9599 123.339 8.43381 109.932C16.0974 105.535 23.3079 100.82 30.5888 96.4138C71.6159 71.4866 98.8464 50.9473 117.678 37.102C136.037 23.6395 146.883 16.5627 167.023 21.7069C180.397 25.1239 192.542 32.1913 200.85 38.7545C213.146 48.4826 215.956 55.3913 220.78 63.7284C223.116 67.7615 225.07 71.748 228.554 75.4077C241.938 89.4118 259.772 96.7779 291.998 99.6814C324.757 102.66 367.426 98.4397 401.957 88.4782C405.119 87.5446 408.14 86.611 411.021 85.6774C453.468 71.7853 461.967 60.8902 485.28 58.2574C488.894 57.8573 492.527 57.636 496.166 57.5946C511.463 57.3238 524.918 59.6018 541.201 63.691C565.109 69.6287 585.512 78.1805 606.659 85.5467C613.497 87.9273 621.02 90.3267 629.358 92.5487C655.904 99.6534 695.149 106.702 747.283 106.553" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-250.238 178.636C-210.208 181.931 -149.876 184.125 -86.0898 163.465C-42.3538 149.125 -11.2159 128.268 11.7347 114.535C19.5191 109.867 26.8404 104.928 34.1012 100.241C74.8462 73.9976 101.946 51.2363 121.352 35.8225C140.012 20.9969 151.785 12.9306 171.462 17.8414C184.363 21.0903 196.447 28.5311 204.564 35.5798C216.417 45.9055 218.552 53.561 222.59 62.0661C224.604 66.2487 226.306 70.3098 229.75 74.0442C242.519 87.8989 262.248 93.9113 294.352 95.209C327.585 96.5441 369.79 90.6717 403.596 80.7755C406.732 79.8481 409.729 78.9145 412.589 77.9747C456.074 63.896 464.684 53.9158 486.627 51.3577C490.21 50.9467 493.814 50.713 497.423 50.6575C512.931 50.3121 526.386 52.5247 542.146 56.5205C564.865 62.2248 584.895 70.6646 607.08 78.3295C613.888 80.7008 621.34 83.0815 629.588 85.3408C655.872 92.5856 694.925 100.278 746.486 101.604" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-250.684 182.8C-212.819 186.786 -151.328 190.521 -84.7728 169.319C-39.5161 154.764 -7.46186 133.104 15.0355 119.1C22.9409 114.18 30.3426 108.999 37.6337 104.032C78.0867 76.4532 105.106 51.5074 125.015 34.4691C143.998 18.2711 156.737 9.17781 175.821 13.8738C188.258 16.9454 200.292 24.769 208.197 32.3218C219.627 43.245 221.087 51.6661 224.31 60.3299C225.941 64.6712 227.462 68.8164 230.846 72.6161C243.081 86.2841 264.692 91.8857 296.666 90.6253C330.281 89.2996 372.114 82.7924 405.206 72.9709C408.307 72.0435 411.281 71.1099 414.128 70.1701C458.659 55.9606 467.36 46.83 487.934 44.3466C491.49 43.9323 495.066 43.6893 498.649 43.6184C514.369 43.2076 527.854 45.3362 543.04 49.22C564.54 54.747 584.218 63.084 607.42 71.029C614.198 73.3817 621.589 75.7624 629.726 78.059C655.758 85.4438 694.62 93.7809 745.597 96.563" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-251.164 186.918C-215.454 191.586 -152.816 196.879 -83.4909 175.126C-36.7136 160.357 -3.80335 137.903 18.2912 123.582C26.3476 118.391 33.8299 112.995 41.121 107.711C81.3021 78.7691 108.17 51.6946 128.653 33.0225C147.999 15.4708 161.725 5.40657 180.275 9.8692C192.238 12.7634 204.222 20.9604 211.926 29.0174C222.933 40.5194 223.688 49.706 226.156 58.5192C227.424 63.0192 228.744 67.2391 232.077 71.1229C243.658 84.6041 267.062 87.9277 298.975 85.9858C333.033 83.9412 374.423 74.8573 406.789 65.1105C409.811 64.1769 412.832 63.2433 415.641 62.3097C461.19 47.8855 470.022 39.6791 489.226 37.2798C492.751 36.8542 496.297 36.5986 499.851 36.5142C515.782 36.0287 529.216 38.092 543.919 41.9011C564.291 47.1573 583.536 55.289 607.756 63.6167C614.513 65.9507 621.854 68.2847 629.911 70.6654C655.681 78.1342 694.361 87.1622 744.714 91.4007" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-251.555 190.987C-218.01 196.374 -154.234 203.189 -82.1192 180.895C-33.781 165.911 -0.0449827 142.617 21.6065 128.044C29.7434 122.573 37.3769 116.971 44.6679 111.426C84.6375 81.2515 111.334 51.8617 132.281 31.5838C151.938 12.6223 166.571 1.58715 184.647 5.84438C196.138 8.56116 208.091 17.1316 215.584 25.6834C226.178 37.8203 226.208 47.7165 227.92 56.6977C228.796 61.3657 229.934 65.651 233.207 69.6094C244.204 82.9039 269.461 84.8085 301.253 81.3262C335.724 77.5918 376.721 66.902 408.352 57.2205C411.374 56.2869 414.324 55.3533 417.134 54.4944C463.71 39.8555 472.642 32.5454 490.487 30.2207C494.009 29.8012 497.552 29.5519 501.101 29.4739C515.875 28.9581 530.639 30.6927 544.807 34.6087C564.001 39.6408 582.853 47.6138 608.1 56.2776C614.827 58.5836 622.098 60.9456 630.023 63.3356C667.075 74.3274 705.173 82.0208 743.799 86.3116" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-252 195.03C-220.61 201.117 -155.686 209.473 -80.8022 186.628C-30.9836 171.429 3.64869 147.351 24.9376 132.479C119.429 66.4077 149.338 -7.38424 189.086 1.7744C200.103 4.3138 212.027 13.2577 219.298 22.3137C234.826 41.6392 225.038 56.2968 234.403 68.0602C257.364 96.9085 360.646 64.6899 418.693 46.5873C475.289 28.9329 477.383 23.2473 502.277 22.3137C545.731 20.6612 568.32 36.8405 630.172 55.9234C666.948 67.215 704.675 75.6466 742.961 81.1306" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-252 197.7C-219.775 204.357 -156.864 211.984 -85.2734 190.119C-35.8577 174.985 -2.69564 150.674 20.1542 135.037C111.433 72.6439 139.902 5.06977 180.395 11.1008C196.216 13.4535 212.279 18.1682 222.883 25.3943C243.719 39.6037 235.974 52.3101 248.059 63.672C274.514 88.5619 370.848 60.6191 426.115 44.0103C479.951 27.775 482.127 23.1817 508.441 24.1153C552.167 25.665 573.728 39.9865 631.995 58.3038C668.349 69.7259 705.781 77.9499 743.798 82.8669" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-252 200.483C-198.258 211.952 -142.159 209.612 -89.7447 193.714C-40.7318 178.655 -9.10048 154.092 15.3707 137.698C103.316 78.8251 130.849 16.4604 171.715 20.5309C192.45 22.6035 211.946 23.1263 226.468 28.5879C251.382 37.9239 246.528 48.2869 261.714 59.3968C290.727 80.5989 381.049 56.652 433.547 41.537C484.533 26.7207 486.799 23.1263 514.614 26.0205C558.501 30.5765 579.135 43.3295 633.818 60.7972C669.738 72.3418 706.867 80.3587 744.593 84.7161" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-252 205.431C-199.823 217.038 -145.193 214.975 -94.2059 199.474C-45.6059 184.481 -15.4952 159.666 10.5873 142.525C95.018 87.05 122.087 36.4861 163.024 32.1261C188.633 29.4 211.281 30.5203 230.063 33.9373C258.441 39.0908 256.79 46.4383 275.38 57.2774C306.155 75.2212 391.271 54.9434 440.969 41.2288C489.186 27.9249 491.432 25.1521 520.777 30.0815C564.805 37.4757 584.533 48.8189 635.651 65.4464C671.142 77.1274 707.979 84.9413 745.419 88.7305" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-252 212.964C-201.392 224.702 -148.232 222.909 -98.6772 207.792C-50.48 192.855 -21.9 167.787 5.80383 149.909C86.6596 97.7297 113.447 51.9926 154.343 46.2789C184.726 42.0403 211.553 35.5611 233.648 41.8443C264.051 50.4894 265.189 51.087 289.036 57.7155C317.726 77.8907 401.482 55.7643 448.401 43.4781C493.818 31.6213 496.054 29.6608 526.95 36.7095C571.089 46.783 589.921 56.9033 637.474 72.6532C672.538 84.4667 709.088 92.075 746.235 95.293" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-252 220.676C-202.963 232.553 -151.27 231.055 -103.128 216.363C-55.334 201.5 -28.2948 176.152 1.04045 157.546C78.2406 108.569 104.726 67.2382 145.612 60.6843C180.859 55.0454 210.184 46.1014 237.253 50.0132C272.157 55.0547 275.249 53.0755 302.711 58.4157C332.812 76.3688 411.684 56.7632 455.782 45.9801C498.4 35.5704 500.636 34.3287 533.073 43.59C577.383 56.2124 595.258 65.259 639.256 80.1126C673.894 92.0596 710.162 99.463 747.01 102.108" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-252 228.537C-204.545 240.529 -154.333 239.312 -107.62 225.036C-60.2282 210.239 -34.7499 184.611 -3.76313 165.286C69.7512 119.436 95.9344 82.2883 136.972 75.193C176.981 68.275 209.338 62.5987 240.818 58.2761C279.69 52.9359 285.269 55.1485 316.347 59.2097C347.907 74.9969 421.885 57.9026 463.254 48.5853C503.073 39.6227 505.309 39.0439 539.286 50.5739C583.677 65.6422 600.666 73.7552 641.079 87.6846C675.293 99.7604 711.284 106.959 747.826 109.036" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-252 236.501C-214.508 246.555 -163.883 249.944 -112.081 233.821C-65.062 219.182 -41.185 193.163 -8.5466 173.137C61.2115 130.341 87.1228 97.235 128.281 89.8129C173.155 81.7279 208.844 73.8203 244.413 66.6502C287.011 58.0517 296.276 52.0486 330.012 60.115C364.967 68.4707 432.086 59.1814 470.676 51.3018C507.695 43.7769 509.951 43.8329 545.45 57.6596C590.062 75.09 606.033 82.3814 642.952 95.3585C665.902 103.425 700.525 113.975 748.692 116.066" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-252 244.605C-213.632 255.211 -165.062 257.89 -116.552 242.738C-69.9361 228.173 -47.6401 201.818 -13.3301 181.12C52.6214 141.32 78.1601 112.164 119.6 104.564C169.348 95.4338 208.442 85.2295 247.998 75.1559C294.433 63.3272 306.024 54.3272 343.668 61.1519C379.549 67.6871 442.278 60.517 478.108 54.1499C512.348 48.0628 514.684 48.6229 551.623 64.8863C596.456 84.6133 611.381 91.1766 644.775 103.164C667.393 111.286 701.501 121.836 749.507 123.236" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-252 252.801C-212.725 264.004 -166.24 265.928 -121.024 251.755C-74.8103 237.266 -54.1155 210.565 -18.1136 189.204C44.001 152.345 69.1973 127.101 110.909 119.417C165.582 109.344 208.089 96.7681 251.583 83.763C301.936 68.7133 315.783 56.9219 357.323 62.2901C394.181 67.0515 452.469 62.0007 485.53 57.0993C516.93 52.4313 519.417 53.5049 557.786 72.2143C602.901 94.2006 616.718 100.101 646.597 111.071C668.893 119.259 702.468 129.846 750.323 130.499" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-252 261.12C-211.889 272.856 -167.408 274.079 -125.495 260.887C-79.6844 246.463 -60.6109 219.388 -22.897 197.402C35.3404 163.437 59.5095 137.548 102.228 134.384C162.792 129.893 207.767 108.429 255.168 92.4834C309.478 74.2222 327.676 50.4713 370.979 63.5417C407.152 74.4929 462.62 63.5417 492.962 60.1621C521.542 56.9412 524.181 58.4629 563.959 79.6464C609.387 103.855 622.035 109.176 648.42 119.091C670.384 127.354 703.445 137.95 751.139 137.885" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-252 269.709C-211.013 282.014 -168.586 282.48 -129.956 270.344C-84.5584 255.985 -67.1265 228.499 -27.6805 205.925C26.7 174.808 50.5368 153.484 93.5377 149.675C158.996 143.859 207.485 120.426 258.764 101.529C317.072 80.0566 336.679 54.7466 384.644 65.119C422.691 73.3533 472.842 65.4737 500.384 63.5505C526.155 61.7487 529.025 63.7092 570.122 87.4134C615.913 113.816 627.353 118.605 650.253 127.437C671.874 135.783 704.422 146.37 751.965 145.586" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-252 278.84C-210.147 291.714 -169.775 291.462 -134.427 280.24C-89.4325 265.947 -73.6723 238.014 -32.4639 214.888C17.9488 186.591 41.5539 169.861 84.8569 165.407C155.229 158.172 207.223 132.871 262.349 111.025C324.705 86.3125 345.934 59.9755 398.3 67.1456C438.119 72.5978 483.013 67.7991 507.816 67.379C530.757 66.9869 533.889 69.3769 576.295 95.6205C622.468 124.198 632.69 128.511 652.076 136.232C673.355 144.691 705.449 155.24 752.78 153.737" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-252 288.129C-209.271 301.573 -170.953 300.583 -138.899 290.351C-94.3066 276.113 -80.2483 247.676 -37.2474 224.065C9.22776 198.54 32.5409 186.431 76.1661 181.352C151.413 172.595 206.981 145.521 265.934 120.724C332.399 92.7816 355.42 65.8005 411.956 69.3762C453.446 71.9996 493.194 70.3098 515.238 71.4208C535.339 72.4197 538.783 75.2205 582.459 104.041C629.075 134.784 637.927 138.706 653.898 145.241C674.815 153.839 706.365 164.333 753.596 162.102" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-252 298.24C-208.395 312.244 -172.161 310.47 -143.37 301.274C-99.211 287.009 -86.8747 258.076 -42.0309 234.055C0.48657 211.275 23.5177 203.806 67.4853 198.111C147.566 187.739 206.76 158.993 269.479 131.237C339.972 100.064 365.339 76.6774 425.571 72.4201C468.491 69.3953 503.325 73.6338 522.63 76.2759C539.921 78.6473 543.677 81.8775 588.591 113.265C635.661 146.184 643.153 149.676 655.681 155.044C676.204 163.848 707.302 174.22 754.371 171.27" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-252 308.249C-207.529 322.822 -173.34 320.32 -147.831 312.104C-104.085 297.904 -93.5614 268.281 -46.8144 243.951C-8.26463 223.888 14.4745 221.078 58.7946 214.776C143.699 202.705 206.548 172.363 273.114 141.666C347.726 107.244 375.48 81.9152 439.277 75.38C483.587 70.8613 513.567 76.8084 530.092 81.0376C544.584 84.772 548.682 88.413 594.795 122.406C642.368 157.472 648.43 160.618 657.554 164.772C677.614 173.903 708.319 184.014 755.237 180.345" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-252 318.265C-206.683 333.399 -174.457 330.178 -152.302 322.933C-108.999 308.799 -100.319 278.335 -51.5978 253.847C-17.0159 236.463 5.42112 238.349 50.1138 231.44C139.791 217.576 206.347 185.694 276.699 152.084C355.43 114.423 385.652 87.1147 452.932 78.3295C498.803 72.3451 523.798 79.9353 537.524 85.7983C549.236 90.8024 553.637 94.9383 600.968 131.545C649.055 168.768 653.637 171.596 659.377 174.491C678.783 184.284 709.286 193.816 756.053 189.428" stroke="#00C8CB" strokeMiterlimit="10" />
  </svg>
);

function PrivacyPolicy() {
  return (
    <PrivacyPolicyStyles className="blockPaddingBottom">
      <div className="background">
        {background}
        {backgroundTablet}
        {backgroundMobile}
      </div>

      <div className="container">
        <div className="titleWrapper">
          <h1>Privacy Policy</h1>
        </div>

        <div className="textWrapper">
          <p className="textBase18">
            At QuST, accessible from qust.app, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by QuST and how we use it.
          </p>
          <p className="textBase18">
            If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
          </p>
          <p className="textBase18">
            This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in QuST. This policy is not applicable to any information collected offline or via channels other than this website.
          </p>
        </div>

        <div className="textWrapper">
          <h2 className="textSemiBold32">Consent</h2>

          <p className="textBase18">
            By using our website, you hereby consent to our Privacy Policy and agree to its terms.
          </p>
        </div>

        <div className="textWrapper">
          <h2 className="textSemiBold32">Information we collect</h2>

          <p className="textBase18">
            The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
          </p>

          <p className="textBase18">
            If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
          </p>

          <p className="textBase18">
            When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
          </p>
        </div>

        <div className="textWrapper">
          <h2 className="textSemiBold32">How we use your information</h2>

          <p className="textBase18">We use the information we collect in various ways, including to:</p>

          <ul>
            <li className="textBase18"><TextWithDot text="Provide, operate, and maintain our website" /></li>
            <li className="textBase18"><TextWithDot text="Improve, personalize, and expand our website" /></li>
            <li className="textBase18"><TextWithDot text="Understand and analyze how you use our website" /></li>
            <li className="textBase18"><TextWithDot text="Develop new products, services, features, and functionality" /></li>
            <li className="textBase18"><TextWithDot text="Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes" /></li>
            <li className="textBase18"><TextWithDot text="Send you emails" /></li>
            <li className="textBase18"><TextWithDot text="Find and prevent fraud" /></li>
          </ul>
        </div>

        <div className="textWrapper">
          <h2 className="textSemiBold32">Log Files</h2>

          <p className="textBase18">
            {'QuST follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services\' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users\' movement on the website, and gathering demographic information.'}
          </p>
        </div>

        <div className="textWrapper">
          <h2 className="textSemiBold32">Cookies and Web Beacons</h2>
          <p className="textBase18">
            {'Like any other website, QuST uses "cookies". These cookies are used to store information including visitors\' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users\' experience by customizing our web page content based on visitors\' browser type and/or other information.\n'}
          </p>
        </div>

        <div className="textWrapper">
          <h2 className="textSemiBold32">Advertising Partners Privacy Policies</h2>

          <p className="textBase18">You may consult this list to find the Privacy Policy for each of the advertising partners of QuST.</p>

          <p className="textBase18">{'Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on QuST, which are sent directly to users\' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.'}</p>

          <p className="textBase18">Note that QuST has no access to or control over these cookies that are used by third-party advertisers.</p>
        </div>

        <div className="textWrapper">
          <h2 className="textSemiBold32">Third Party Privacy Policies</h2>
          <p className="textBase18">
            {'QuST\'s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.'}
          </p>
          <p className="textBase18">
            {'You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers\' respective websites.'}
          </p>
        </div>

        <div className="textWrapper">
          <h2 className="textSemiBold32">CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>
          <p className="textBase18">
            Under the CCPA, among other rights, California consumers have the right to:
          </p>

          <p className="textBase18">
            {'Request that a business that collects a consumer\'s personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.'}
          </p>

          <p className="textBase18">
            Request that a business delete any personal data about the consumer that a business has collected.
          </p>

          <p className="textBase18">
            Request that a business that sells a consumer&quot;s personal data, not sell the consumer&quot;s personal data.
          </p>

          <p className="textBase18">
            If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
          </p>
        </div>

        <div className="textWrapper">
          <h2 className="textSemiBold32">GDPR Data Protection Rights</h2>

          <p className="textBase18">We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>

          <p className="textBase18">The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</p>

          <p className="textBase18">The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</p>

          <p className="textBase18">The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</p>

          <p className="textBase18">The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</p>

          <p className="textBase18">The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</p>

          <p className="textBase18">The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>

          <p className="textBase18">If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
        </div>

        <div className="textWrapper">
          <h2 className="textSemiBold32">{'Children\'s Information'}</h2>

          <p className="textBase18">Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
          <p className="textBase18">
            QuST does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
          </p>
        </div>

        <div className="textWrapper">
          <h2 className="textSemiBold32">
            Changes to This Privacy Policy
          </h2>

          <p className="textBase18">
            We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.
          </p>

          <p className="textBase18">
            Our Privacy Policy was created with the help of the
            {' '}
            <a className="link" href="https://www.termsfeed.com/privacy-policy-generator/" target="_blank" rel="noreferrer">Privacy Policy Generator.</a>
          </p>
        </div>

        <div className="textWrapper">
          <h2 className="textSemiBold32">
            Contact Us
          </h2>

          <p className="textBase18">
            If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.
          </p>
        </div>
      </div>
    </PrivacyPolicyStyles>
  );
}

export default PrivacyPolicy;
