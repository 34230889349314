import React, { useEffect, useRef, useState } from 'react';
import {
  PropertyTypes,
  TDateAcceptedFormats,
  TDateTimeProperty,
  TLeadPropertyDateFieldTypes,
  TTimeAcceptedFormats,
} from '../../../../../enteties/types/formProperties.types';
import DropDown from '../../../../other/dropDown/DropDown';
import InputField from '../../../../form/input/InputField';
import { DropdownProps } from '../Property';
import { useParams } from 'react-router-dom';
import workWithResponse from '../../../../../helpers/workWithResponse';
import { Api } from '../../../../../api';
import { TrashIconWithTwoLinesInCenter } from '../../../../../assets/icons/other/TrashIconWithTwoLinesInCenter';
import { notification } from '../../../../../helpers/notifications/toastify';
import { DateIcon } from '../../../../../assets/icons/properties/DateIcon';
import { ArrowRightIcon } from '../../../../../assets/icons/other/ArrowRightIcon';
import classNames from 'classnames';
import { dateFormatNamesBook, timeFormatNamesBook } from '../../../../../constants/properties';
import DateTimePicker from '../../../../form/dateTimePicker/DateTimePicker';
import { CheckMark } from '../../../../../assets/icons/properties/CheckMark';
import ModalConfirmDecline from '../../../../modals/modalConfirmDecline/ModalConfirmDecline';
import { deleteProperty } from './helpers';

type DateDropdownProps = DropdownProps<TDateTimeProperty, TLeadPropertyDateFieldTypes>;

let initialValue: TLeadPropertyDateFieldTypes | null = null;
let initialPropertyValue: TDateTimeProperty | null = null;

function Date({ property, onPropertyChanged, onPropertyDeleted }: DateDropdownProps) {
  const { id: ticketId } = useParams();

  const editingButtonRef = useRef<HTMLButtonElement | null>(null);
  const dateFormatRef = useRef<HTMLButtonElement | null>(null);
  const timeFormatRef = useRef<HTMLButtonElement | null>(null);

  const [stateProperty, setStateProperty] = useState<TDateTimeProperty>(property);
  const [statePropertyValue, setStatePropertyValue] = useState<TLeadPropertyDateFieldTypes | null>(null);
  const [editingOpen, setEditingOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeDropdownName, setActiveDropdownName] = useState<null | 'dateFormat' | 'timeFormat'>(null);

  const [activeModalName, setActiveModalName] = useState<'deleting' | null>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  async function getPropertyValueData() {
    try {
      setIsLoading(true);

      await workWithResponse(() => Api.getTicketPropertyValue(property.id!, +ticketId!)).then((res) => {
        if (res.data && res.data.type === PropertyTypes.date) {
          setStatePropertyValue(res.data);
          initialValue = res.data;
        } else if (res.error === 'Not Found') {
          initialValue = {
            value: '',
            property_id: property.id,
            type: PropertyTypes.date,
            id: 0,
            lead_id: +ticketId!,
          };

          setStatePropertyValue(initialValue);
        }
      });
    } catch (e) {
      console.error(`Error: ${e}`);
    } finally {
      setIsLoading(false);
    }
  }

  async function changePropertyValue() {
    try {
      if (statePropertyValue) {
        if (statePropertyValue.id) {
          await workWithResponse(() => Api.updateTicketPropertyValue(statePropertyValue)).then((res) => {
            if (res.error) {
              notification.error(res.error);
              return;
            }

            if (res.data && res.data.type === PropertyTypes.date) {
              initialValue = res.data;
            }
          });
        } else {
          await workWithResponse(() => Api.createTicketPropertyValue(statePropertyValue)).then((res) => {
            if (res.error) {
              notification.error(res.error);

              return;
            }

            if (res.data && res.data.type === PropertyTypes.date) {
              initialValue = res.data;
            }
          });
        }
      }
    } catch (e) {
      console.error(`Error: ${e}`);
    }
  }

  async function handleCloseEditingModal() {
    try {
      if (JSON.stringify(stateProperty) !== JSON.stringify(initialPropertyValue)) {
        await workWithResponse(() => Api.updateFormProperty(stateProperty)).then((res) => {
          if (res.error) {
            notification.error(res.error);

            return;
          }

          if (res.statusCode >= 200 && res.statusCode < 300) {
            initialPropertyValue = stateProperty;

            onPropertyChanged(stateProperty);
          }
        });
      }
    } catch (e) {
      console.error(`Error: ${e}`);
    } finally {
      setEditingOpen(false);
    }
  }

  const handleChangeStateProperty = (value: Partial<TDateTimeProperty>) => {
    setStateProperty({ ...stateProperty, ...value });
  };

  const handleActiveDropdownName = (name: 'dateFormat' | 'timeFormat' | null) => {
    setActiveDropdownName(activeDropdownName === name ? null : name);
  };

  useEffect(() => {
    initialPropertyValue = property;

    if (ticketId && +ticketId) {
      getPropertyValueData();
    }
  }, []);

  useEffect(() => {
    if (!isLoading && statePropertyValue && initialValue && statePropertyValue.value !== initialValue.value) {
      const timer = setTimeout(() => {
        changePropertyValue();
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [statePropertyValue]);

  return (
    <li className="propertyItem">
      {activeModalName === 'deleting' && (
        <ModalConfirmDecline
          onClose={() => setActiveModalName(null)}
          title="Are you sure you want to delete property?"
          buttons={
            [
              {
                text: 'Cancel',
                onClick() {
                  setActiveModalName(null);
                },
                theme: 'mainFilled',
                fullWidth: true,
                isLoading: isDeleting,
              },
              {
                text: 'Delete',
                onClick() {
                  setIsDeleting(true);

                  deleteProperty(property.id!).then((res) => {
                    setIsDeleting(false);

                    if (res === 'Deleted') {
                      onPropertyDeleted(property.id!);
                    }
                  });
                },
                theme: 'grey',
                fullWidth: true,
                isLoading: isDeleting,
              },
            ]
          }
        />
      )}

      <div className="propertyItemHead">
        <button ref={editingButtonRef} className="openButton textBase14" type="button" onClick={() => setEditingOpen(!editingOpen)}>
          {DateIcon}
          {' '}
          <span>{property.name}</span>
        </button>

        <DropDown exclude={editingButtonRef} className="propertyDropdown" isOpened={editingOpen} onClose={handleCloseEditingModal}>
          <InputField
            name="name"
            placeholder="Enter property name"
            value={stateProperty.name}
            onChange={(e: React.BaseSyntheticEvent) => setStateProperty({ ...stateProperty, name: e.target.value })}
          />

          <div className="settings">
            <div className="settingOptionWrapper">
              <span className="textSemiBold12">Date format</span>

              <button ref={dateFormatRef} onClick={() => handleActiveDropdownName('dateFormat')} className={classNames('textBase14 settingOptionOpenButton', { active: activeDropdownName === 'dateFormat' })} type="button">
                {dateFormatNamesBook[stateProperty.date_format]}

                {ArrowRightIcon}

                <DropDown className="greyButtonOnHover propertyInnerDropdown" exclude={dateFormatRef} isOpened={activeDropdownName === 'dateFormat'} onClose={() => handleActiveDropdownName(null)}>
                  {Object.entries(dateFormatNamesBook).map((item) => (
                    <button className="" onClick={() => handleChangeStateProperty({ date_format: item[0] as TDateAcceptedFormats })} type="button">
                      {item[1]}

                      {item[0] === stateProperty.date_format && CheckMark}
                    </button>
                  ))}
                </DropDown>
              </button>
            </div>

            <div className="settingOptionWrapper">
              <span className="textSemiBold12">Time format</span>

              <button ref={timeFormatRef} onClick={() => handleActiveDropdownName('timeFormat')} className={classNames('textBase14 settingOptionOpenButton', { active: activeDropdownName === 'timeFormat' })} type="button">
                {timeFormatNamesBook[stateProperty.time_format]}

                {ArrowRightIcon}

                <DropDown className="greyButtonOnHover propertyInnerDropdown" exclude={timeFormatRef} isOpened={activeDropdownName === 'timeFormat'} onClose={() => handleActiveDropdownName(null)}>
                  {Object.entries(timeFormatNamesBook).map((item) => (
                    <button className="" onClick={() => handleChangeStateProperty({ time_format: item[0] as TTimeAcceptedFormats })} type="button">
                      {item[1]}

                      {item[0] === stateProperty.time_format && CheckMark}
                    </button>
                  ))}
                </DropDown>
              </button>
            </div>
          </div>

          <div className="splitter" />

          <div className="footer">
            <button type="button" onClick={() => setActiveModalName('deleting')}>
              {TrashIconWithTwoLinesInCenter}

              <span className="textSemiBold12">Delete property</span>
            </button>
          </div>
        </DropDown>
      </div>

      {statePropertyValue && (
        <DateTimePicker
          setValue={(date) => setStatePropertyValue({ ...statePropertyValue, value: date })}
          timeFormat={stateProperty.time_format}
          dateFormat={stateProperty.date_format}
          value={statePropertyValue.value ? statePropertyValue.value : null}
        />
      )}
    </li>
  );
}

export default Date;
