import React, { useRef, useState } from 'react';
import workWithResponse from '../../../../../helpers/workWithResponse';
import { notification } from '../../../../../helpers/notifications/toastify';
import { Api } from '../../../../../api';
import { unexpectedErrorMessage } from '../../../../../constants/constants';
import ModalConfirmDecline from '../../../../modals/modalConfirmDecline/ModalConfirmDecline';
import { MenuStyles } from './MenuStyles';
import DropDown from '../../../../other/dropDown/DropDown';
import EditIcon from '../../../../../assets/icons/table/EditIcon';
import { TrashIconWithTwoLinesInCenter } from '../../../../../assets/icons/other/TrashIconWithTwoLinesInCenter';
import { MenuIcon } from '../../../../../assets/icons/other/OpenMenuIcon';

interface MenuProps {
  leadId: number;
  onLeadDeleted(id: number): void;
}

function Menu({ leadId, onLeadDeleted }: MenuProps) {
  const openButtonRef = useRef<null | HTMLButtonElement>(null);

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteFormModalOpen, setDeleteFormModalOpen] = useState<boolean>(false);

  async function handleDeleteForm() {
    try {
      setIsDeleting(true);

      await workWithResponse(() => Api.deleteForm(leadId)).then((res) => {
        if (res.error) {
          notification.error(res.error);

          return;
        }

        setDeleteFormModalOpen(false);
        onLeadDeleted(leadId);
      });
    } catch (e) {
      notification.error(unexpectedErrorMessage);
      console.error(`Error: ${e}`);
    } finally {
      setIsDeleting(false);
    }
  }

  return (
    <MenuStyles>
      {deleteFormModalOpen && (
        <ModalConfirmDecline
          onClose={() => (isDeleting ? null : setDeleteFormModalOpen(false))}
          title="Are you sure you want delete lead?"
          buttons={
            [
              {
                text: 'Delete',
                onClick() {
                  setIsDeleting(true);
                  handleDeleteForm();
                },
                theme: 'grey',
                fullWidth: true,
                isLoading: isDeleting,
              },
              {
                text: 'Cancel',
                onClick() {
                  setDeleteFormModalOpen(false);
                },
                theme: 'mainFilled',
                fullWidth: true,
                isLoading: isDeleting,
              },
            ]
          }
        />
      )}

      <DropDown exclude={openButtonRef} isOpened={menuOpen} onClose={() => setMenuOpen(false)}>
        <button
          className="textBase12 textBold"
          onClick={() => {
            window.open(`${process.env.REACT_APP_BASE_URL}/leads/${leadId}`, '_blank');
            setMenuOpen(false);
          }}
          type="button"
        >
          <EditIcon />

          Edit
        </button>

        <button
          className="textBase12 textBold red"
          onClick={() => {
            setDeleteFormModalOpen(true);
            setMenuOpen(false);
          }}
          type="button"
        >
          {TrashIconWithTwoLinesInCenter}

          Delete
        </button>
      </DropDown>

      <button className="openButton" ref={openButtonRef} type="button" onClick={() => setMenuOpen(!menuOpen)}>
        {MenuIcon}
      </button>
    </MenuStyles>
  );
}

export default Menu;
