import React from 'react';

function ArrowLeftIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#FC812B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12.667 8H3.334M8 12.667L3.335 8l4.667-4.667"
      />
    </svg>
  );
}

export default ArrowLeftIcon;
