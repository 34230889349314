import React, { ChangeEvent, useEffect, useState } from 'react';
import { PropertiesStyles } from './PropertiesStyles';
import { TApiStatus } from '../../../enteties/types/statuses.types';
import { notification } from '../../../helpers/notifications/toastify';
import { unexpectedErrorMessage } from '../../../constants/constants';
import { TApiLead } from '../../../enteties/types/lead.types';
import { useIsFirstRender } from 'usehooks-ts';
import workWithResponse from '../../../helpers/workWithResponse';
import { Api } from '../../../api';
import CustomSelect from '../../form/selects/customSelect/CustomSelect';
import InputField from '../../form/input/InputField';
import { TApiForm } from '../../../enteties/types/forms.types';
import PropertiesList from './propertiesList/PropertiesList';
import { useAppSelector } from '../../../state/hooks';

interface PropertiesProps {
  formData: TApiForm;
  ticketData: TApiLead;
  handleDataLoadingError(): void;
  handleUpdateTicketData: (values: Partial<TApiLead>) => void
}

function Properties({
  formData, ticketData, handleDataLoadingError, handleUpdateTicketData,
}: PropertiesProps) {
  const isFirst = useIsFirstRender();

  const [statuses, setStatuses] = useState<TApiStatus[]>();
  const { data: user } = useAppSelector((state) => state.user);

  const [statusesLoading, setStatusesLoading] = useState<boolean>(true);
  const [price, setPrice] = useState<null | number>(ticketData.price);
  const [status, setStatus] = useState<TApiStatus | null>();

  async function getStatuses() {
    try {
      setStatusesLoading(true);

      if (user) {
        workWithResponse(() => Api.getUserStatuses({ userID: user.id })).then((res) => {
          if (res.data) {
            setStatuses(res.data.data);
            setStatus(res.data.data.find((status) => status.id === ticketData.status_id));

            setStatusesLoading(false);
            return;
          }

          if (res.error) {
            handleDataLoadingError();
            notification.error(res.error);
          }
        });
      }
    } catch (e) {
      handleDataLoadingError();
      notification.error(unexpectedErrorMessage);
    } finally {
      setStatusesLoading(false);
    }
  }

  useEffect(() => {
    getStatuses();
  }, []);

  useEffect(() => {
    if (!isFirst) console.log('test');
  }, [price]);

  function handleChangePrice(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.value.length === 0) {
      setPrice(null);
    }

    if (!Number.isNaN(+event.target.value) && Number.isFinite(+event.target.value) && Math.abs(+event.target.value) <= 999999999) {
      setPrice(+event.target.value);
    }
  }

  async function changeTicketPrice() {
    await workWithResponse(() => Api.updateLead({
      ...ticketData,
      price: price || null,
    })).then((res) => {
      if (res.error) {
        notification.error(res.error);

        return;
      }

      handleUpdateTicketData({ ...ticketData, price });
    });
  }

  async function changeTicketStatus(status: TApiStatus) {
    try {
      setStatusesLoading(true);

      await workWithResponse(() => Api.updateLead({
        ...ticketData,
        status_id: status.id,
      })).then((res) => {
        if (res.error) {
          notification.error(res.error);

          return;
        }

        handleUpdateTicketData({ ...ticketData, status_id: status.id });
        setStatus(status);
      });
    } catch (e) {
      notification.error(unexpectedErrorMessage);
    } finally {
      setStatusesLoading(false);
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (price && !Number.isNaN(+price) && price !== ticketData.price) {
        changeTicketPrice();
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [price]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (price && !Number.isNaN(+price) && price !== ticketData.price) {
  //       changeTicketPrice();
  //     }
  //   }, 500);
  //
  //   return () => clearTimeout(timer);
  // }, [status]);

  return (
    <PropertiesStyles>
      <span className="blockTitle textSemiBold16">Properties</span>

      <CustomSelect
        value={status}
        label="Status"
        placeholder="Status"
        valueKey="id"
        nameKey="name"
        options={statuses || []}
        isLoading={statusesLoading}
        onChange={(option) => changeTicketStatus(option)}
        disabled={statusesLoading}
      />

      <InputField
        name="price"
        label="Lead value"
        placeholder="Enter lead value"
        value={price || undefined}
        onChange={handleChangePrice}
      />

      <PropertiesList formId={formData.id} />
    </PropertiesStyles>
  );
}

export default Properties;
