import React from 'react';
import { ButtonStyles } from '../ButtonStyles';
import Loader from '../../loader/Loader';
import { ButtonProps, IconButtonProps, TextButtonProps } from '../../../enteties/button/ButtonProps';

const buttonLoaderColors = {
  grey: '#8C8C8C',
  mainFilled: '#FDFDFD',
  mainNotFilled: '#FDB17B',
  red: '#FF7875',
};

function IconButton({
  error,
  icon,
  theme = 'grey',
  size = 'XL',
  onClick,
  isLoading,
  type = 'button',
  disabled,
  fullWidth,
  extraClasses,
  title,
}: IconButtonProps) {
  return (
    <ButtonStyles title={title} onClick={onClick} className={`button textSemiBold16 ${error ? 'red' : theme} ${size} ${isLoading ? 'loading' : ''} ${extraClasses ?? ''}`} style={fullWidth ? { width: '100%' } : {}} disabled={disabled} type={type}>
      {isLoading && <Loader size={16} color={buttonLoaderColors[theme]} />}

      {icon}

      {isLoading && <Loader size={16} color={buttonLoaderColors[theme]} />}
    </ButtonStyles>
  );
}

export default IconButton;
