import React from 'react';
import { ErrorComponentStyles } from './ErrorComponentStyles';

function ErrorComponent() {
  return (
    <ErrorComponentStyles>
      <div className="errorContainer">
        {/* <ErrorIcon /> */}
        <span className="textBold28">An error occurred while loading data</span>
      </div>
    </ErrorComponentStyles>
  );
}

export default ErrorComponent;
