import React, {
  useState, ChangeEvent, MouseEvent, useEffect, useMemo,
} from 'react';
import { NumberInputStyles } from './NumberInputStyles';

interface BorderRadiusInputProps {
  name: string;
  value: string | number | undefined;
  min?: number;
  max?: number;
  label?: string;
  placeholder?: string;
  handleChange: ({ target: { name, value } }: { target: { name: string; value: number | string } }) => void;
}

const arrowUp = (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
    <path d="M7.82591 6.63838L4.72689 2.36602C4.61439 2.21133 4.38412 2.21133 4.2725 2.36602L1.17259 6.63838C1.16497 6.64889 1.1604 6.6613 1.15939 6.67425C1.15839 6.68719 1.16099 6.70016 1.1669 6.71172C1.17282 6.72328 1.18182 6.73298 1.19291 6.73973C1.204 6.74649 1.21674 6.75004 1.22972 6.75H1.8889C1.93373 6.75 1.97591 6.72803 2.00228 6.69199L4.49925 3.2502L6.99623 6.69199C7.02259 6.72803 7.06478 6.75 7.10961 6.75H7.76879C7.82591 6.75 7.85931 6.68496 7.82591 6.63838Z" fill="#8C8C8C" />
  </svg>
);

const NumberInput: React.FC<BorderRadiusInputProps> = ({
  name,
  value,
  min,
  max,
  label,
  placeholder,
  handleChange,
}) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const numericValue = parseFloat(value.length > 1 && value[0] === '0' ? value.slice(1) : value);

    if (value === '' || (min && parseFloat(value) < min)) {
      handleChange({ ...event, target: { ...event.target, value: 0, name } });
      return;
    }

    if (max && parseFloat(value) > max) {
      handleChange({ ...event, target: { ...event.target, value: 10000, name } });
      return;
    }

    if (!Number.isNaN(numericValue) && numericValue >= 0) {
      handleChange({ ...event, target: { ...event.target, value: String(numericValue), name } });
    }
  };

  const handleIncrement = () => {
    const newValue = Number(value) + 1;
    if ((max === undefined || newValue <= max)) {
      handleChange({ target: { name, value: newValue } });
    }
  };

  const handleDecrement = () => {
    const newValue = Number(value) - 1;
    if ((min === undefined || newValue >= min)) {
      handleChange({ target: { name, value: newValue } });
    }
  };

  return (
    <NumberInputStyles>
      {label && <label htmlFor={name}><span className="textSemiBold16">{label}</span></label>}
      <div className="inputContainer">
        <input
          name={name}
          type="number"
          id={name}
          placeholder={placeholder}
          value={value}
          onChange={handleInputChange}
          min={min}
          max={max}
        />

        <div className="numberChangeButtonsWrapper">
          <button
            type="button"
            onClick={handleIncrement}
          >
            {arrowUp}
          </button>

          <button type="button" onClick={handleDecrement}>
            {arrowUp}
          </button>
        </div>
      </div>
    </NumberInputStyles>
  );
};

export default NumberInput;
