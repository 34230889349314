import React from 'react';

function DeleteIcon({ size = 20 }) {
  return (
    <svg
      className="deleteIcon"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M2.5 5h15M15.834 5v11.667a1.667 1.667 0 01-1.667 1.667H5.834a1.667 1.667 0 01-1.667-1.667V5m2.5 0V3.334a1.667 1.667 0 011.667-1.667h3.333a1.667 1.667 0 011.667 1.667V5"
      />
    </svg>
  );
}

export default DeleteIcon;
