import React from 'react';

export default (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_1626_9934)">
      <path d="M7.33337 2.66602H2.66671C2.31309 2.66602 1.97395 2.80649 1.7239 3.05654C1.47385 3.30659 1.33337 3.64573 1.33337 3.99935V13.3327C1.33337 13.6863 1.47385 14.0254 1.7239 14.2755C1.97395 14.5255 2.31309 14.666 2.66671 14.666H12C12.3537 14.666 12.6928 14.5255 12.9428 14.2755C13.1929 14.0254 13.3334 13.6863 13.3334 13.3327V8.66602" stroke="#FCFCFC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.3334 1.66617C12.5986 1.40095 12.9583 1.25195 13.3334 1.25195C13.7084 1.25195 14.0682 1.40095 14.3334 1.66617C14.5986 1.93138 14.7476 2.29109 14.7476 2.66617C14.7476 3.04124 14.5986 3.40095 14.3334 3.66617L8.00004 9.9995L5.33337 10.6662L6.00004 7.9995L12.3334 1.66617Z" stroke="#FCFCFC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1626_9934">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
