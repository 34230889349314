import styled from 'styled-components';

export const SidebarStyles = styled.div`
  position: fixed;
  left: 0;
  top: 0;

  background: rgba(96, 96, 96, 0.40);
  width: 100vw;
  height: 100vh;
  z-index: 3;
  transform: translateX(-100%);
  transition: 0.3s ease transform;
  
  @media (min-width: 1201px) {
    width: 280px;
    height: auto;
    background: none;
    transform: translateX(0);
  }
  
  &.open {
    transform: translateX(0);
    
    nav {
      transform: translateX(0);
    }
  }

  nav {
    position: fixed;
    top: 0;
    z-index: 1;

    transform: translateX(-100%);

    display: flex;
    flex-direction: column;

    padding: 16px;
    width: 100%;
    max-width: 320px;
    box-sizing: border-box;
    height: 100vh;
    background: var(--blue-1, #005273);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    transition: 0.3s ease all;
    color: var(--Black, #141414);
    overflow-y: hidden;

    @media (min-width: 768px) {
      padding: 13px 32px 16px 32px;
    }
    
    @media (min-width: 1201px) {
      padding-top: 30px;
      max-width: 280px;
    }
  }

  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 28px;

    position: relative;
    margin: 0 0 30px 0;

    @media (min-width: 1201px) {
      justify-content: center;
      margin-bottom: 55px;
    }
  }
  
  .contentWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    
    overflow-y: auto;
    flex-grow: 1;
  }
  
  .menuButton {
    @media (min-width: 1201px) {
      display: none;
    }
  }
  
  .optionsWrapper {
    flex-grow: 1;
  }
  
  .bottomOptions, .options {
    a {
      position: relative;
      
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 12px 16px;

      color: var(--grey-1, #FCFCFC);

      transition: 0.3s ease all;
      
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        
        width: 4px;
        height: 100%;
        
        background: none;
        
        transition: .3s ease background-color;
      }
      
      &.orange {
        color: #FF8D3A;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.10);
      }

      &.active {
        background: rgba(255, 255, 255, 0.20);
        border-radius: 4px;
        
        &::after {
          background: #00C8CB;
        }
      }
    }
  }
  
  .bottomOptions {
    padding-top: 24px;
  }
  
  .options {
    position: relative;
    padding-bottom: 24px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: rgba(252, 252, 252, 0.30);
    }
  }
  
  .voteBannerWrapper {
    border-radius: 4px;
    background: #004263;
    padding: 12px;
    box-sizing: border-box;
    
    margin-bottom: 16px;
    
    @media (min-width: 768px) {
      margin-bottom: 24px;
    }
    
    picture {
      display: flex;
    }
    
    img {
      width: 100%;
      height: 140px;
    }
    
    .textWrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      
      margin-bottom: 8px;

      color: #FFF;
    }
  }
`;
