export const palette = [
  {
    name: 'Light gray',
    color: '#F1F0F0',
  },
  {
    name: 'Gray',
    color: '#E3E2E0',
  },
  {
    name: 'Brown',
    color: '#E9D8D1',
  },
  {
    name: 'Orange',
    color: '#FBDBBF',
  },
  {
    name: 'Yellow',
    color: '#FFF3CA',
  },
  {
    name: 'Green',
    color: '#DBFAD6',
  },
  {
    name: 'Blue',
    color: '#E1F3FF',
  },
  {
    name: 'Violet',
    color: '#F4EDFF',
  },
  {
    name: 'Pink',
    color: '#FFDFEF',
  },
  {
    name: 'Red',
    color: '#FCD9D2',
  },
];
