import React from 'react';
import { TForm, TFormDesign, TThankYou } from '../../../../enteties/types/forms.types';
import PreviewInput from '../inputsPreview/input/PreviewInput';
import { ThankYouPreviewStyles } from './ThankYouPreviewStyles';
import PreviewButton from '../previewElements/button/PreviewButton';

interface ThankYouPreviewProps {
  thankYouData: TThankYou;
}

function ThankYouPreview({ thankYouData }: ThankYouPreviewProps) {
  return (
    <ThankYouPreviewStyles thankYouData={thankYouData}>
      <span className="textSemiBold22 title">Preview</span>

      <div className="formPreview">
        <div className="formHead">
          {thankYouData.image.url && (
            <div>
              <img className="image" src={thankYouData.image.url} alt="" />
            </div>
          )}

          <span className="title">{thankYouData.title.text}</span>

          <span className="description textBase14">{thankYouData.description.text}</span>
        </div>
      </div>
    </ThankYouPreviewStyles>
  );
}

export default ThankYouPreview;
