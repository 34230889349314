import styled, { keyframes } from 'styled-components';

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const CustomSelectStyles = styled.div`
  .customSelect {
    position: relative;

    .customSelectTrigger {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      
      padding: 10px 8px;
      box-sizing: border-box;
      min-height: 40px;
      background: #FFFFFF;

      border-radius: var(--corners_btn, 4px);
      border: 1px solid var(--gray-light, #C2C9D1);
      cursor: pointer;
      transition: 0.3s ease all;
      
      &.disabled {
        cursor: not-allowed;

        background: var(--gray-lighter, linear-gradient(0deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.75) 100%), #C2C9D1);
      }
      
      &:not(&.disabled) {
        &:hover {
          border: 1px solid var(--Blue, #FC812B);
        }

        &.error {
          border: 1px solid var(--Blue, #FC812B);
        }
      }

      span {
        color: rgba(0, 0, 0, 0.5);
        
        &.withValue {
          color: #000;
        }
      }
    }

    .customOptions {
      position: absolute;
      z-index: 1;
      width: 100%;
      
      display: none;

      box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
      border-radius: 2px;
      background: #FFF;
      max-height: 180px;
      overflow-y: auto;

      animation: ${fadeInAnimation} 0.3s ease forwards;

      .customOption {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        padding: 7px 12px;
        cursor: pointer;
        
        transition: 0.3s ease-in background;
        
        &:hover {
          background: #F5F5F5;
        }
        
        &.selected {
          background: #F5F5F5;
        }
      }
    }
    
    .arrow {
      display: flex;
      align-items: center;
      
      transition: 0.3s ease transform;
    }
    
    &.open {
      .arrow {
        transform: rotate(180deg);
      }
      
      .customOptions {
        display: grid;
      }
    }
  }

  .formErrorContainer {
    position: absolute;

    padding-top: 2px;

    display: flex;
    gap: 4px;

    color: #00000080;
  }
`;
