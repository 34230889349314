import React, { useEffect, useRef } from 'react';
import TextButton from '../../../../buttons/button/TextButton';
import { StepReadyQrStyles } from './StepReadyQrStyles';
import { useNavigate, useParams } from 'react-router-dom';
import QRCodeStyling from 'qr-code-styling';

interface StepReadyQrProps {
  id: number;
}

const qrCode = new QRCodeStyling({
  width: 256,
  height: 256,
  dotsOptions: {
    color: '#000',
    type: 'rounded',
  },
  imageOptions: {
    crossOrigin: 'anonymous',
    margin: 10,
  },
});

function StepReadyQr({ id }: StepReadyQrProps) {
  const navigate = useNavigate();

  const { id: paramsId } = useParams();

  const qrCodeRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    qrCode.append(qrCodeRef.current!);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: `${process.env.REACT_APP_CLIENT_URL}/${id}`,
    });
  }, [id]);

  const onDownloadClick = () => {
    qrCode.download({
      extension: 'png',
    });
  };

  return (
    <StepReadyQrStyles>
      <div className="content">
        <div className="qrCode" ref={qrCodeRef} />

        <div className="head">
          <h2 className="textBold24">
            Your QR code form has been
            {' '}
            {Number.isNaN(paramsId) ? 'created' : 'updated'}
          </h2>

          <p className="textBase16">
            Here you can copy it to clipboard or download it on your device. QR
            code will be saved, so you will be able to do it later.
          </p>
        </div>

        <div className="buttonsContainer">
          <TextButton
            text="Download QR code"
            icon={(
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 16"
                fill="none"
              >
                <path
                  d="M14.5 10V12.6667C14.5 13.0203 14.3595 13.3594 14.1095 13.6095C13.8594 13.8595 13.5203 14 13.1667 14H3.83333C3.47971 14 3.14057 13.8595 2.89052 13.6095C2.64048 13.3594 2.5 13.0203 2.5 12.6667V10"
                  stroke="#FCFCFC"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.16699 6.6665L8.50033 9.99984L11.8337 6.6665"
                  stroke="#FCFCFC"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.5 10V2"
                  stroke="#FCFCFC"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
            iconPosition="iconLeft"
            theme="mainFilled"
            onClick={onDownloadClick}
          />

          <TextButton
            text="Go to list of forms"
            theme="mainNotFilled"
            onClick={() => navigate('/forms')}
          />
        </div>
      </div>
    </StepReadyQrStyles>
  );
}

export default StepReadyQr;
