import styled from 'styled-components';

export const AnswersStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  .textWrapper {
    display: flex;
    justify-content: space-between;
  }

  .answersList {
    display: flex;
    flex-direction: column;
    gap: 16px;
    
    .answerWrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      
      .fakeTextInput {
        padding: 12px;
        
        border-radius: 4px;
        border: 1px solid var(--Gray-Light, #C2C9D1);
        background: var(--White-Default, #FFF);
        
        a {
          color: #000;
          
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
`;
