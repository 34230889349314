import React, { Fragment } from 'react';
import { LinksPathStyles } from './LinksPathStyles';
import { Link } from 'react-router-dom';

const arrow = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M6 12L10 8L6 4" stroke="#595959" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

function LinksPath({ pathArr }: {pathArr: {path: string, name: string}[]}) {
  return (
    <LinksPathStyles className="linksPath">
      <div>
        <ul>
          {pathArr.map((pathPart, index) => {
            if (pathArr.length !== index + 1) {
              return (
                <Fragment key={pathPart.path}>
                  <li className="textBase14">
                    <Link to={pathPart.path} title={`Go to ${pathPart.name} page`}>
                      <span>
                        {pathPart.name}
                      </span>
                    </Link>
                  </li>

                  {index + 1 < pathArr.length && arrow}
                </Fragment>
              );
            }

            return (
              <Fragment key={pathPart.path}>
                <li className="textBase14">
                  <span>
                    {pathPart.name}
                  </span>
                </li>
              </Fragment>
            );
          })}
        </ul>
      </div>
    </LinksPathStyles>
  );
}

export default LinksPath;
