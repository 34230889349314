import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { TApiStatus } from '../../../enteties/types/statuses.types';
import { StatusesSelectStyles } from './StatusesSelectStyles';
import { Gear } from '../../../assets/icons/statuses/Gear';
import workWithResponse from '../../../helpers/workWithResponse';
import { Api } from '../../../api';
import classNames from 'classnames';
import Loader from '../../loader/Loader';
import { ArrowLeft } from '../../../assets/icons/other/ArrowLeft';
import StatusChanging from './statusChanging/StatusChanging';
import { notification } from '../../../helpers/notifications/toastify';
import { unexpectedErrorMessage } from '../../../constants/constants';
import StatusesList from './statusesList/StatusesList';
import DropDown from '../../other/dropDown/DropDown';
import { useWindowSize } from 'usehooks-ts';
import { useAppSelector } from '../../../state/hooks';

interface StatusesSelectProps {
  statuses: TApiStatus[];
  updateStatuses(updatedStatuses: TApiStatus[]): void;
}

function StatusesSelect({ statuses, updateStatuses }: StatusesSelectProps) {
  const { width } = useWindowSize();
  const { data: user } = useAppSelector((state) => state.user);

  const [selectOpen, setSelectOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [statusToUpdateIndex, setStatusToUpdateIndex] = useState<number | null>(null);
  const [statusCreating, setStatusCreating] = useState<boolean>(false);

  const statusesSelectRef = useRef<HTMLDivElement | null>(null);
  const openButtonRef = useRef<HTMLButtonElement | null>(null);

  const statusToUpdate = useMemo(() => {
    setStatusCreating(false);

    if (statusToUpdateIndex !== null) {
      return statuses[statusToUpdateIndex];
    }
  }, [statusToUpdateIndex]);

  const newStatus = useMemo(() => {
    if (statuses && statuses.length > 0) {
      let higherStatusPriority: number = 0;

      statuses.forEach((status) => {
        if (status.priority > higherStatusPriority) {
          higherStatusPriority = status.priority;
        }
      });

      return {
        id: 0,
        name: '',
        color: '#F1F0F0',
        priority: higherStatusPriority + 1,
        user_id: user!.id,
      };
    }

    return null;
  }, [statuses]);

  const handleStatusChangingClose = useCallback(() => {
    if (statusToUpdateIndex || statusToUpdateIndex === 0) {
      setStatusToUpdateIndex(null);
    }

    if (statusCreating) {
      setStatusCreating(false);
    }
  }, [statusToUpdateIndex, statusCreating]);

  const handleDeleteStatus = useCallback((deletedStatusId: number) => {
    updateStatuses(statuses.filter(({ id }) => deletedStatusId !== id));
  }, [statuses]);

  const handleCreateOrUpdateStatus = useCallback(async (status: TApiStatus) => {
    setIsLoading(true);

    try {
      if (status.id === 0) {
        await workWithResponse(() => Api.createStatus({
          user_id: user!.id, color: status.color, name: status.name, priority: status.priority,
        })).then((res) => {
          if (res.error) {
            notification.error(res.error);
          }

          if (res.data) {
            notification.success('Status successfully created');
            setStatusCreating(false);

            return updateStatuses([...statuses, res.data]);
          }
        });
      } else {
        await workWithResponse(() => Api.updateStatus(status)).then((res) => {
          if (res.error) {
            notification.error(res.error);
          }

          if (res.data) {
            notification.success('Status successfully updated');

            setStatusToUpdateIndex(null);

            return updateStatuses(statuses.map((mappedStatus) => (mappedStatus.id === status.id ? status : mappedStatus)));
          }
        });
      }
    } catch (e) {
      notification.error(unexpectedErrorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [statuses]);

  useEffect(() => {
    if (statusCreating && statusToUpdateIndex !== null) {
      setStatusToUpdateIndex(null);
    }
  }, [statusCreating]);

  return (
    <StatusesSelectStyles ref={statusesSelectRef}>
      <button ref={openButtonRef} className="openerButton" type="button" onClick={() => setSelectOpen(!selectOpen)}>
        {Gear}
      </button>

      {width >= 1024 && (
        <DropDown exclude={openButtonRef} isOpened={selectOpen} onClose={() => setSelectOpen(false)} className={classNames('desktop', { open: selectOpen })}>
          {isLoading ? <Loader size={80} /> : (
            <>
              <StatusesList updateStatuses={updateStatuses} statuses={statuses} setStatusToUpdateIndex={setStatusToUpdateIndex} setIsLoading={setIsLoading} />

              {!statusCreating && !statusToUpdateIndex && statusToUpdateIndex !== 0 && (
                // eslint-disable-next-line react/button-has-type
                <button onClick={() => setStatusCreating(true)} className="addStatusButton textSemiBold16">
                  Add status
                </button>
              )}

              {(((statusToUpdateIndex || statusToUpdateIndex === 0) && statusToUpdate) || (statusCreating && newStatus)) && (
                <StatusChanging
                  status={statusCreating ? newStatus! : statusToUpdate!}
                  handleClose={handleStatusChangingClose}
                  handleSave={handleCreateOrUpdateStatus}
                />
              )}
            </>
          )}
        </DropDown>
      )}

      {width < 1024 && (
        <div className={classNames('mobile', { open: selectOpen })}>
          {isLoading ? <Loader size={80} /> : (
            <>
              <div className="statusesSelectHead">
                <button type="button" className="closeButton" onClick={() => setSelectOpen(false)}>
                  {ArrowLeft}
                </button>

                <span className="textSemiBold18">Status settings</span>

                <div style={{ width: 24 }} />
              </div>

              <StatusesList updateStatuses={updateStatuses} statuses={statuses} setStatusToUpdateIndex={setStatusToUpdateIndex} setIsLoading={setIsLoading} />

              {!statusCreating && !statusToUpdateIndex && statusToUpdateIndex !== 0 && (
                // eslint-disable-next-line react/button-has-type
                <button onClick={() => setStatusCreating(true)} className="addStatusButton textSemiBold16">
                  Add status
                </button>
              )}

              {(((statusToUpdateIndex || statusToUpdateIndex === 0) && statusToUpdate) || (statusCreating && newStatus)) && (
                <StatusChanging
                  status={statusCreating ? newStatus! : statusToUpdate!}
                  handleClose={handleStatusChangingClose}
                  handleSave={handleCreateOrUpdateStatus}
                />
              )}
            </>
          )}
        </div>
      )}
    </StatusesSelectStyles>
  );
}

export default StatusesSelect;
