import styled from 'styled-components';

export const LeadStyles = styled.div`
  flex-grow: 1;
  
  padding-bottom: 60px;
  
  .leadHead {
    margin-bottom: 32px;
  }
  
  .contentWrapper {
    @media (min-width: 1024px) {
      width: calc(100vw - 78px);
    }

    @media (min-width: 1200px) {
      width: calc(100vw - 420px);
    }
  }
  
  .contentContainer {
    width: 100%;
    max-width: 1654px;
    
    overflow: auto;
    
    margin-bottom: -290px;
    padding-bottom: 290px;
  }

  .content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;

    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
    padding: 16px 8px;
    
    box-sizing: border-box;
    
    @media (min-width: 1024px) {
      width: 1654px;

      padding: 32px;
      
      grid-template-columns: 360px 2px 360px 2px 360px 2px 360px;
    }
    
    .splitter {
      height: 100%;
      background: #EEE;
      width: 2px;
    }
  }
`;
