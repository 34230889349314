import ApiUser from '../api/entities/ApiUser';
import { FormFieldsTypes, TApiField, TApiForm } from '../enteties/types/forms.types';
import { TUpgradeItem } from '../enteties/types/upgrades.types';

import upgradesImage from '../assets/fakeImages/upgradesImage.jpg';
import { ApiFile } from '../api/entities/ApiFile';

import fakeFileImage from '../assets/fakeImages/avatar.png';
import { TApiLead } from '../enteties/types/lead.types';
import { TLeadApiComment } from '../enteties/types/comments.types';
import { TApiStatus } from '../enteties/types/statuses.types';
import ApiAuthResponse from '../enteties/types/auth.types';
import { PropertyTypes, TApiProperty, TApiPropertyValueData } from '../enteties/types/formProperties.types';

export const fakeUserResponse: ApiUser = {
  name: 'Користувач Адмін',
  email: 'admin@gmail.com',
  id: 1,
  created_at: '2023-08-30T09:02:11.578Z',
  roles: [{ name: 'ADMIN' }],
};

export const fakeLoginResponse: ApiAuthResponse = {
  id: 1,
  refresh_token_expired_at: 123,
  refresh_token: '123',
  access_token_expired_at: 123,
  access_token: '123',
};

export const fakeFormField: TApiField = {
  id: 1,
  type: FormFieldsTypes.text_input,
  validation: {
    required: true,
  },
  label: 'Full name',
  placeholder: 'Type your full name here',
};

export const fakeFile: ApiFile = {
  url: fakeFileImage,
  name: 'file',
  created_at: '',
  hash: '',
  ext: '',
  size: 1000,
  ID: 1,
};

export const fakeComment: TLeadApiComment = {
  id: 1,
  message: 'Lorem ipsum',
  created_at: '2023-08-30T09:02:11.578Z',
  user_id: 1,
  lead_id: 1,
};

export const fakeLeadData: TApiLead = {
  id: 1,
  form_id: 1,
  created_at: '2023-12-13T15:47:26.605411Z',
  answers: [
    {
      type: FormFieldsTypes.text_input,
      field_id: 1,
      value: 'test',
    },
    {
      type: FormFieldsTypes.textarea,
      field_id: 2,
      value: 'test',
    },
    {
      type: FormFieldsTypes.phone,
      field_id: 3,
      value: '+380505555555',
    },
    {
      type: FormFieldsTypes.email,
      field_id: 4,
      value: 'test@mail.com',
    },
  ],
  utm_campaign: 'test',
  utm_medium: 'test',
  utm_source: 'test',
  price: 0,
  status_id: 1,
};

export const fakeLeadData2: TApiLead = {
  id: 2,
  form_id: 1,
  created_at: '2023-12-13T15:47:26.605411Z',
  answers: [
    {
      type: FormFieldsTypes.email,
      field_id: 1,
      value: 'test@email.com',
    },
  ],
  utm_campaign: 'test',
  utm_medium: 'test',
  utm_source: 'test',
  price: 12034,
  status_id: 2,
};

export const fakeLeadData3: TApiLead = {
  id: 3,
  form_id: 1,
  created_at: '2023-12-13T15:47:26.605411Z',
  answers: [
    {
      type: FormFieldsTypes.text_input,
      field_id: 1,
      value: 'test',
    },
  ],
  utm_campaign: 'test',
  utm_medium: 'test',
  utm_source: 'test',
  price: 12034,
  status_id: 3,
};

export const fakeLeadData4: TApiLead = {
  id: 4,
  form_id: 1,
  created_at: '2023-12-13T15:47:26.605411Z',
  answers: [
    {
      type: FormFieldsTypes.phone,
      field_id: 1,
      value: '+380685303513',
    },
  ],
  utm_campaign: 'test',
  utm_medium: 'test',
  utm_source: 'test',
  price: 12034,
  status_id: 4,
};

export const fakePropertyResponse: TApiProperty = {
  id: 1,
  name: 'Date',
  type: PropertyTypes.date,
  time_format: 'HH:mm',
  date_format: 'dd/MM/yyyy',
  form_id: 1,
};

export const fakeStatusResponse: TApiStatus = {
  id: 1,
  color: '#ededed',
  name: 'New',
  priority: 1,
  user_id: null,
};

export const fakeTicketPropertyValue: TApiPropertyValueData = {
  property_id: 1,
  lead_id: 1,
  value: '2023-12-13T15:47:26.605411Z',
  type: PropertyTypes.date,
  id: 1,
};

export const fakeStatusesResponse: TApiStatus[] = [
  {
    id: 1,
    color: '#ededed',
    name: 'New',
    priority: 1,
    user_id: 1,
  },
  {
    id: 2,
    color: '#E1F3FF',
    name: 'In work',
    priority: 2,
    user_id: 1,
  },
  {
    id: 3,
    color: '#FCD9D2',
    name: 'Canceled',
    priority: 3,
    user_id: 1,
  },
  {
    id: 4,
    color: '#DBFAD6',
    name: 'Done',
    priority: 4,
    user_id: 1,
  },
];

export const fakeForm: TApiForm = {
  name: 'Form 1',
  title: 'Test',
  description: 'Description lorem ala trem buen dior savag',
  user_id: 1,
  fields: [
    1,
    2,
    3,
    4,
  ],
  thank_you: {
    description: {
      text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquid dolorum in neque velit? Accusantium alias cumque doloremque in praesentium!',
      color: {
        r: 0, g: 0, b: 0, a: 1,
      },
    },
    image: {
      url: null,
      border_radius: '0',
    },
    title: {
      text: 'Lorem ipsum dolor sit.',
      color: {
        r: 0, g: 0, b: 0, a: 1,
      },
    },
    form: {
      background_color: {
        r: 255, g: 255, b: 255, a: 1,
      },
      border_radius: '4',
    },
    page: {
      background_color: {
        r: 255, g: 255, b: 255, a: 1,
      },
    },
  },
  purpose: 1,
  id: 1,
  design: {
    form: {
      background_color: {
        r: 255, g: 255, b: 255, a: 1,
      },
      border_radius: '4',
    },
    image: {
      url: null,
      border_radius: '0',
    },
    inputs: {
      background_color: {
        r: 255, g: 255, b: 255, a: 1,
      },
      text_color: {
        r: 0, g: 0, b: 0, a: 1,
      },
      border_color: {
        r: 255, g: 255, b: 255, a: 1,
      },
      border_radius: '10',
      label_color: {
        r: 255, g: 255, b: 255, a: 1,
      },
      placeholder_color: {
        r: 255, g: 255, b: 255, a: 1,
      },
    },
    page: {
      background_color: {
        r: 255, g: 255, b: 255, a: 1,
      },
    },
    title: {
      color: {
        r: 0, g: 0, b: 0, a: 1,
      },
    },
    description: {
      color: {
        r: 0, g: 0, b: 0, a: 1,
      },
    },
    submit_button: {
      background_color: {
        r: 255, g: 255, b: 255, a: 1,
      },
      text_color: {
        r: 255, g: 255, b: 255, a: 1,
      },
      border_radius: '10',
    },
  },
  submit_button: {
    text: 'Send',
  },
};

export const upgradesFakeData: TUpgradeItem[] = [
  {
    id: 1,
    created_at: '',
    updated_at: '',
    title: 'Advanced CRM',
    description: 'Elevate your customer relationship management with our advanced CRM system, offering comprehensive client data management, automated communication, and in-depth insights for personalized interactions.',
    image_url: upgradesImage,
    wishlist_additions: 25,
    added_to_wish_list: true,
  },
  {
    id: 2,
    created_at: '',
    updated_at: '',
    title: 'Advanced forms',
    description: 'Streamline data collection and engagement with advanced forms, equipped with customizable fields, conditional logic, and integration capabilities, ensuring a seamless user experience.',
    image_url: upgradesImage,
    wishlist_additions: 25,
    added_to_wish_list: true,
  },
  {
    id: 3,
    created_at: '',
    updated_at: '',
    title: 'Advanced analytics',
    description: 'Gain a competitive edge with our advanced analytics tools, providing real-time performance metrics and data visualization to empower data-driven decision-making.',
    image_url: upgradesImage,
    wishlist_additions: 25,
    added_to_wish_list: true,
  },
];
