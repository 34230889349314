import React, { useEffect, useRef, useState } from 'react';
import {
  FieldArray, FieldArrayRenderProps, FormikProvider, getIn, useFormik,
} from 'formik';
import * as Yup from 'yup';
import {
  FieldTypes, FormFieldsTypes, TField, TForm,
} from '../../../../enteties/types/forms.types';
import { StepFieldsStyles } from './StepFieldsStyles';
import Rocket from '../../../../assets/icons/forms/constructor/Rocket';
import InputField from '../../../form/input/InputField';
import TrashIcon from '../../../../assets/icons/other/TrashIcon';
import DeleteIcon from '../../../../assets/icons/table/DeleteIcon';
import ToggleButton from '../../../form/toggleButton/ToggleButton';
import TextButton from '../../../buttons/button/TextButton';
import { array, lazy, object } from 'yup';
import { validationSchema } from './validationSchema';
import ErrorText from '../../../other/errorText/ErrorText';
import classNames from 'classnames';
import { TSteps } from '../../../../pages/forms/constructor/Constructor';
import { useParams } from 'react-router-dom';

const fields: Record<FieldTypes, TField> = {
  email: {
    type: FormFieldsTypes.email,
    validation: {
      required: true,
    },
    label: '',
    placeholder: '',
  },
  phone: {
    type: FormFieldsTypes.phone,
    label: '',
    placeholder: '',
    validation: {
      required: true,
    },
  },
  text_input: {
    type: FormFieldsTypes.text_input,
    label: '',
    placeholder: '',
    validation: {
      required: false,
    },
  },
  textarea: {
    type: FormFieldsTypes.textarea,
    label: '',
    placeholder: '',
    validation: {
      required: false,
    },
  },
};

const fieldsTitles: Record<FormFieldsTypes, string> = {
  [FormFieldsTypes.email]: 'Email',
  [FormFieldsTypes.phone]: 'Phone',
  [FormFieldsTypes.text_input]: 'Short answer',
  [FormFieldsTypes.textarea]: 'Long answer',
};

// eslint-disable-next-line no-undef
const addFieldButtons: {type: FieldTypes, icon: JSX.Element, name: string}[] = [
  {
    type: 'email',
    name: 'email',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M22 6L12 13L2 6" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    ),
  },
  {
    type: 'text_input',
    name: 'short answer',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <rect x="1" y="9" width="22" height="6" rx="1" stroke="black" strokeWidth="2" />
      </svg>
    ),
  },
  {
    type: 'textarea',
    name: 'long answer',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <rect x="1" y="5" width="22" height="14" rx="1" stroke="black" strokeWidth="2" />
      </svg>
    ),
  },
  {
    type: 'phone',
    name: 'phone',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M21.9999 16.9201V19.9201C22.0011 20.1986 21.944 20.4743 21.8324 20.7294C21.7209 20.9846 21.5572 21.2137 21.352 21.402C21.1468 21.5902 20.9045 21.7336 20.6407 21.8228C20.3769 21.912 20.0973 21.9452 19.8199 21.9201C16.7428 21.5857 13.7869 20.5342 11.1899 18.8501C8.77376 17.3148 6.72527 15.2663 5.18993 12.8501C3.49991 10.2413 2.44818 7.27109 2.11993 4.1801C2.09494 3.90356 2.12781 3.62486 2.21643 3.36172C2.30506 3.09859 2.4475 2.85679 2.6347 2.65172C2.82189 2.44665 3.04974 2.28281 3.30372 2.17062C3.55771 2.05843 3.83227 2.00036 4.10993 2.0001H7.10993C7.59524 1.99532 8.06572 2.16718 8.43369 2.48363C8.80166 2.80008 9.04201 3.23954 9.10993 3.7201C9.23656 4.68016 9.47138 5.62282 9.80993 6.5301C9.94448 6.88802 9.9736 7.27701 9.89384 7.65098C9.81408 8.02494 9.6288 8.36821 9.35993 8.6401L8.08993 9.9101C9.51349 12.4136 11.5864 14.4865 14.0899 15.9101L15.3599 14.6401C15.6318 14.3712 15.9751 14.1859 16.3491 14.1062C16.723 14.0264 17.112 14.0556 17.4699 14.1901C18.3772 14.5286 19.3199 14.7635 20.2799 14.8901C20.7657 14.9586 21.2093 15.2033 21.5265 15.5776C21.8436 15.9519 22.0121 16.4297 21.9999 16.9201Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    ),
  },
];

const AddFieldPanel = ({ arrayHelpers, formikValuesFields }: {arrayHelpers: FieldArrayRenderProps, formikValuesFields: TField[]}) => {
  function isDisabled(buttonType: FieldTypes): { disabled: boolean, text?: string } {
    if (buttonType === 'email' && formikValuesFields.some((field) => field.type === FormFieldsTypes.email)) {
      return { disabled: true, text: 'You cannot add more than 1 email field' };
    }

    if (buttonType === 'phone' && formikValuesFields.some((field) => field.type === FormFieldsTypes.phone)) {
      return { disabled: true, text: 'You cannot add more than 1 phone field' };
    }

    return { disabled: false };
  }

  return (
    <div className="addFieldPanel">
      {addFieldButtons.map((buttonData) => {
        const isDisabledAndDisabledText = isDisabled(buttonData.type);

        const title = isDisabledAndDisabledText.disabled ? isDisabledAndDisabledText.text : `Add ${buttonData.name} field`;

        return (
          <button
            title={title}
            disabled={isDisabledAndDisabledText.disabled}
            onClick={() => {
              arrayHelpers.push(fields[buttonData.type]);

              // setTimeout(() => {
              //   window.scrollTo({
              //     top: document.body.scrollHeight,
              //     behavior: 'smooth',
              //   });
              // }, 10);
            }}
            type="button"
            key={buttonData.type}
          >
            {buttonData.icon}
          </button>
        );
      })}
    </div>
  );
};

let fieldsLength: number = 0;

interface StepFieldsProps {
  stepFieldsData: Pick<TForm, 'fields' | 'title' | 'submit_button' | 'description' | 'name'>;
  handleChangeFormData: (data: Partial<TForm>) => void;
  setActiveStep: React.Dispatch<React.SetStateAction<TSteps>>;
}

function StepFields({ stepFieldsData, handleChangeFormData, setActiveStep }: StepFieldsProps) {
  const { id } = useParams();

  const lastAddedFieldRef = useRef<HTMLDivElement | null>(null);

  const [initialValues, setInitialValues] = useState<Pick<TForm, 'fields' | 'title' | 'submit_button' | 'description' | 'name'>>(stepFieldsData);

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      formik.setSubmitting(true);

      if (values) {
        handleChangeFormData(values);
        if (id === 'new') {
          setActiveStep('purpose');
        } else {
          setActiveStep('design');
        }
      }

      formik.setSubmitting(false);
    },
    validationSchema,
  });

  useEffect(() => {
    formik.setValues(stepFieldsData);
    fieldsLength = stepFieldsData.fields.length;
  }, [stepFieldsData]);

  useEffect(() => {
    if (fieldsLength < formik.values.fields.length && lastAddedFieldRef && lastAddedFieldRef.current) {
      const headerHeight = 80;
      const scrollOffset = lastAddedFieldRef.current.offsetTop - headerHeight;

      setTimeout(() => {
        window.scrollTo({ top: scrollOffset, behavior: 'smooth' });
      }, 20);
    }

    fieldsLength = formik.values.fields.length;
  }, [lastAddedFieldRef.current]);

  function handleChangePosition(position: 'top' | 'bottom', index: number) {
    const itemToChange = formik.values.fields[index];

    if (position === 'top' && itemToChange) {
      const item2ToChange = formik.values.fields[index - 1];

      const fieldsWithChangedOrder = [...formik.values.fields];

      fieldsWithChangedOrder[index] = item2ToChange;
      fieldsWithChangedOrder[index - 1] = itemToChange;

      formik.setFieldValue('fields', fieldsWithChangedOrder);

      return;
    }

    if (position === 'bottom' && itemToChange) {
      const item2ToChange = formik.values.fields[index + 1];

      const fieldsWithChangedOrder = [...formik.values.fields];

      fieldsWithChangedOrder[index] = item2ToChange;
      fieldsWithChangedOrder[index + 1] = itemToChange;

      formik.setFieldValue('fields', fieldsWithChangedOrder);
    }
  }

  return (
    <StepFieldsStyles>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className="contentWrapper">
          <div className="formWrapper">
            <div className="step1Head">
              <div className="block">
                <span className="headTitle">
                  Name
                </span>

                <div className="inputWrapper">
                  <input type="text" placeholder="Name is only displayed in your personal account." name="name" className={classNames('titleInput', { error: formik.errors && formik.errors.name && formik.touched && formik.touched.name })} value={formik.values.name} onChange={formik.handleChange} />
                  {formik.errors
                    && formik.errors.name
                    // @ts-ignore
                    && formik.touched
                    && formik.touched.name
                    && <ErrorText error={formik.errors.name} />}
                </div>
              </div>

              <div className="splitter" />

              <div className="block">
                <span className="headTitle">
                  Title
                </span>

                <div className="inputWrapper">
                  <input type="text" placeholder="Enter title here" name="title" className={classNames('titleInput', { error: formik.errors && formik.errors.title && formik.touched && formik.touched.title })} value={formik.values.title} onChange={formik.handleChange} />
                  {formik.errors
                    && formik.errors.title
                    // @ts-ignore
                    && formik.touched
                    && formik.touched.title
                    && <ErrorText error={formik.errors.title} />}
                </div>
              </div>

              <div className="block">
                <span className="headTitle">
                  Description
                </span>

                <div className="inputWrapper">
                  <input type="text" placeholder="Enter description here" name="description" className={classNames('titleInput', { error: formik.errors && formik.errors.description && formik.touched && formik.touched.description })} value={formik.values.description} onChange={formik.handleChange} />
                  {formik.errors
                    && formik.errors.description
                    // @ts-ignore
                    && formik.touched
                    && formik.touched.description
                    && <ErrorText error={formik.errors.description} />}
                </div>
              </div>
            </div>

            <FieldArray
              name="fields"
              render={(arrayHelpers) => (
                <div className="formFields">
                  <div className="fieldsWrapperContainer">
                    {formik.values.fields.length > 0 && <span className="textBase16 caption">Color settings will be on the next stage.</span>}

                    <div className="fieldsWrapper">
                      {formik.values.fields.length === 0 && (
                        <>
                          <div className="emptyDiv" />

                          <div className="emptyListContent">
                            <span className="textSemiBold22 textBold">Start creating the form</span>

                            <Rocket />
                          </div>
                        </>
                      )}

                      {formik.values.fields.length > 0 && (
                        <div className="fieldsContainer">
                            {formik.values.fields.map((item, index) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <div className="fieldArrayFieldWrapper" key={index} ref={index === formik.values.fields.length - 1 ? lastAddedFieldRef : null}>
                                <div className="fieldArrayHead">
                                  <span className="fieldArrayFieldTitle textSemiBold22 textBold">{fieldsTitles[item.type]}</span>
                                </div>

                                <div className="fieldArrayFields">
                                  <InputField
                                    label="Label"
                                    placeholder="Type here"
                                    name={`fields.${index}.label`}
                                    onChange={formik.handleChange}
                                    value={getIn(formik.values, `fields.${index}.label`)}
                                    error={
                                        formik.errors
                                        && formik.errors.fields
                                        && formik.errors.fields[index]
                                        // @ts-ignore
                                        && formik.errors.fields[index].label
                                        && formik.touched
                                        && formik.touched.fields
                                        && formik.touched.fields[index]
                                          // @ts-ignore
                                          ? formik.errors.fields[index].label
                                          : undefined
                                      }
                                    disabled={formik.isSubmitting}
                                  />

                                  <InputField
                                    label="Placeholder"
                                    placeholder="Type here"
                                    name={`fields.${index}.placeholder`}
                                    onChange={formik.handleChange}
                                    value={getIn(formik.values, `fields.${index}.placeholder`)}
                                    error={
                                      formik.errors
                                      && formik.errors.fields
                                      && formik.errors.fields[index]
                                      // @ts-ignore
                                      && formik.errors.fields[index].placeholder
                                      && formik.touched
                                      && formik.touched.fields
                                      && formik.touched.fields[index]
                                        // @ts-ignore
                                        ? formik.errors.fields[index].placeholder
                                        : undefined
                                    }
                                    disabled={formik.isSubmitting}
                                  />
                                </div>

                                <div className="splitter" />

                                <div className="fieldArrayFieldFooter">
                                  <div className="validationWrapper">
                                    <ToggleButton
                                      name={`fields.${index}.validation.required`}
                                      onChange={(newValue) => formik.setFieldValue(`fields.${index}.validation.required`, newValue)}
                                      value={formik.values.fields[index].validation!.required!}
                                      label="Required question"
                                    />
                                  </div>

                                  <div className="fieldArrayFieldButtons">
                                    <button
                                      title="Delete field"
                                      className="deleteButton"
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}
                                      aria-label="Delete field"
                                    >
                                      <DeleteIcon size={24} />
                                    </button>

                                    <div className="changePositionButtonsContainer">
                                      <button
                                        title="Change field position to top"
                                        disabled={index === 0}
                                        type="button"
                                        onClick={() => handleChangePosition('top', index)}
                                        aria-label="Move field to top"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14"
                                          height="8"
                                          viewBox="0 0 14 8"
                                          fill="none"
                                        >
                                          <path
                                            d="M13 7L7 1L1 7"
                                            stroke="#141414"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button>

                                      <button
                                        title="Change field position to bottom"
                                        disabled={index === formik.values.fields.length - 1}
                                        type="button"
                                        onClick={() => handleChangePosition('bottom', index)}
                                        aria-label="Move field to bottom"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14"
                                          height="8"
                                          viewBox="0 0 14 8"
                                          fill="none"
                                        >
                                          <path
                                            d="M1 1L7 7L13 1"
                                            stroke="#141414"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}

                          <div className="fieldArrayFieldWrapper">
                            <div className="fieldArrayHead">
                              <span className="fieldArrayFieldTitle textSemiBold22 textBold">
                                Call to action button text
                              </span>
                            </div>

                            <div className="fieldArrayFields">
                              <InputField
                                label="Button text"
                                placeholder="Type here"
                                name="submit_button.text"
                                onChange={formik.handleChange}
                                value={formik.values.submit_button.text}
                                error={
                                  formik.errors
                                  && formik.errors.submit_button
                                  // @ts-ignore
                                  && formik.errors.submit_button.text
                                  && formik.touched
                                  && formik.touched.submit_button
                                  && formik.touched.submit_button.text
                                  // @ts-ignore
                                    ? formik.errors.submit_button.text
                                    : undefined
                                  }
                                disabled={formik.isSubmitting}
                              />
                            </div>
                          </div>

                        </div>

                      )}

                      <AddFieldPanel arrayHelpers={arrayHelpers} formikValuesFields={formik.values.fields} />
                    </div>
                  </div>
                </div>
              )}
            />

            {formik.values.fields.length > 0 && (
              <TextButton
                text="Save"
                // icon={(
                //   <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                //     <path d="M6.5 12L10.5 8L6.5 4" stroke="#FC812B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                //   </svg>
                // )}
                onClick={() => {}}
                type="submit"
                theme={Object.values(formik.touched).length > 0 && Object.values(formik.errors).length > 0 ? 'red' : 'mainNotFilled'}
              />
            )}
          </div>
        </form>
      </FormikProvider>

    </StepFieldsStyles>
  );
}

export default StepFields;
