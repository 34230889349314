import React, { useEffect, useState } from 'react';
import Modal from '../../modal/Modal';
import TextButton from '../../buttons/button/TextButton';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import workWithResponse from '../../../helpers/workWithResponse';
import { Api } from '../../../api';
import { notification } from '../../../helpers/notifications/toastify';
import { unexpectedErrorMessage } from '../../../constants/constants';
import TextArea from '../../form/textArea/TextArea';

import { SuggestFeatureModalStyles } from './SuggestFeatureModalStyles';
import { TSuggestion } from '../../../enteties/types/upgrades.types';
import { useAppSelector } from '../../../state/hooks';

interface SuggestFeatureModalProps {
  open: boolean;
  handleCloseModal: () => void;
}

function SuggestFeatureModal({ open, handleCloseModal }: SuggestFeatureModalProps) {
  const [isFormSubmit, setIsFormSubmit] = useState<boolean>(false);
  const { data: user } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (open && isFormSubmit) {
      setIsFormSubmit(false);
    }
  }, [open]);

  const {
    handleChange, handleSubmit, handleBlur, setValues, values, errors, touched, isSubmitting, setSubmitting,
  } = useFormik<TSuggestion>({
    initialValues: {
      description: '',
    },
    onSubmit: async (values) => {
      if (values) {
        try {
          await workWithResponse(() => Api.suggestFeature(values)).then((res) => {
            if (res.data) {
              setIsFormSubmit(true);
              setValues({ description: '' });
            }

            if (res.error) {
              throw new Error(res.error);
            }
          });
        } catch (e) {
          notification.error(unexpectedErrorMessage);
          console.error(`Error: ${e}`);
        } finally {
          setSubmitting(false);
        }
      }
    },
    validationSchema: Yup.object().shape({
      description: Yup.string().required('Required field').max(10000, 'The maximum number of characters allowed is 10,000'),
    }),
  });

  return (
    open ? (
      <SuggestFeatureModalStyles>
        <Modal onClose={isSubmitting ? () => null : () => handleCloseModal()}>
          {isFormSubmit ? (
            <>
              <div className="modalHead">
                <span className="textBold28">Thank you for suggesting a new feature</span>
                <span className="textBase16">We greatly appreciate your interest in QuST. Our team is dedicated to crafting a top-quality product that will meet and exceed your expectations.</span>
              </div>

              <TextButton
                onClick={isSubmitting ? () => null : () => {
                  handleCloseModal();
                }}
                text="Back to upgrades"
                theme="mainFilled"
              />
            </>
          ) : (
            <>
              <div className="modalHead">
                <span className="textBold28">Suggest new feature</span>
              </div>

              <div>
                <TextArea
                  placeholder="Type here"
                  name="description"
                  onChange={handleChange}
                  value={values.description}
                  label="Suggest your idea"
                  error={touched && touched.description && errors && errors.description ? errors.description : undefined}
                  disabled={isSubmitting}
                />
              </div>

              <TextButton type="button" onClick={handleSubmit} text="Send" theme="mainFilled" />
            </>
          )}
        </Modal>
      </SuggestFeatureModalStyles>
    ) : null
  );
}

export default SuggestFeatureModal;
