import React, { useMemo } from 'react';
import TableDesktopFilters from '../../../filters/table/desktop/TableDesktopFilters';
import { Table } from '../../../../table/Table';
import { ColumnDef } from '@tanstack/react-table';
import { TApiLead } from '../../../../../enteties/types/lead.types';
import { format } from 'date-fns';
import Menu from '../menu/Menu';
import { AvailableFormPurpose, FormFieldsTypes, TApiForm } from '../../../../../enteties/types/forms.types';
import { TApiStatus } from '../../../../../enteties/types/statuses.types';

interface Props {
  leads: TApiLead[];
  formsBook: {[key: string]: TApiForm};
  statusesBook: {[key: string]: TApiStatus};
  statuses: TApiStatus[];
  forms: TApiForm[];
  onLeadDeleted(id: number): void;
  setFilters: (data: {
    form: number | undefined,
    statuses: number[],
  }) => void;
}

function LeadsTableVersionTable({
  leads, formsBook, statusesBook, statuses, forms, onLeadDeleted, setFilters,
}: Props) {
  const cols = useMemo<ColumnDef<TApiLead>[]>(
    () => [
      {
        header: 'Form name',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (row) => (
          <span className="textBase16">
            {formsBook[row.cell.row.original.form_id].name}
          </span>
        ),
        accessorKey: 'name',
        enableSorting: false,
      },
      {
        header: 'Email',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (row) => (
          <span className="textBase16">
            {row.cell.row.original.answers.find((answer) => answer.type === FormFieldsTypes.email)?.value || 'No data'}
          </span>
        ),
        accessorKey: 'email',
        enableSorting: false,
      },
      {
        header: 'Purpose',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (row) => (
          <span className="textBase16">
            {formsBook[row.cell.row.original.form_id].purpose === AvailableFormPurpose.link_qr ? 'QR/LINK' : 'Embedded'}
          </span>
        ),
        accessorKey: 'purpose',
        enableSorting: false,
      },
      {
        header: 'Date',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (row) => (
          <span className="textBase16">
            {format(new Date(row.cell.row.original.created_at), 'dd/LL/Y')}
          </span>
        ),
        accessorKey: 'date',
        enableSorting: false,
      },
      {
        header: 'Time',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (row) => (
          <span className="textBase16">
            {format(new Date(row.cell.row.original.created_at), 'h:mm a')}
          </span>
        ),
        accessorKey: 'time',
        enableSorting: false,
      },
      {
        header: undefined,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (row) => (
          <Menu
            leadId={row.cell.row.original.id}
            onLeadDeleted={(id) => onLeadDeleted(id)}
          />
        ),
        accessorKey: 'menu',
        enableSorting: false,
        size: 80,
      },
    ],
    [formsBook, statusesBook],
  );

  return (
    <>
      <TableDesktopFilters setFilters={setFilters} statuses={statuses} forms={forms} />
      <Table
        data={leads}
        columns={cols}
        withSorting={false}
        backgroundByStatus
        statusesBook={statusesBook}
      />
    </>
  );
}

export default LeadsTableVersionTable;
