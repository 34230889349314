import React, { useMemo, useState } from 'react';
import {
  DragDropContext, Draggable, Droppable, DropResult,
} from 'react-beautiful-dnd';
import classNames from 'classnames';
import EditIcon from '../../../../assets/icons/table/EditIcon';
import DeleteIcon from '../../../../assets/icons/table/DeleteIcon';
import workWithResponse from '../../../../helpers/workWithResponse';
import { Api } from '../../../../api';
import { TApiStatus } from '../../../../enteties/types/statuses.types';
import ModalConfirmDecline from '../../../modals/modalConfirmDecline/ModalConfirmDecline';
import { notification } from '../../../../helpers/notifications/toastify';
import { unexpectedErrorMessage } from '../../../../constants/constants';

interface StatusesSelectProps {
  statuses: TApiStatus[];
  updateStatuses(updatedStatuses: TApiStatus[]): void;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setStatusToUpdateIndex: React.Dispatch<React.SetStateAction<number | null>>;
}

function StatusesList({
  statuses, updateStatuses, setIsLoading, setStatusToUpdateIndex,
}: StatusesSelectProps) {
  const [activeModalName, setActiveModalName] = useState<'deleting' | null>(null);
  const [statusToRemoveId, setStatusToRemoveId] = useState<number>();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  async function handleOnDragEnd(result: DropResult) {
    if (!result.destination || result.destination.index === result.source.index) return;

    setIsLoading(true);

    const status2 = statuses[result.source.index + 1];
    const status1 = statuses[result.destination.index + 1];

    const status1WithUpdatedPriority = await workWithResponse(() => Api.updateStatus({ ...status1, priority: status2.priority + 1 })).then((res) => {
      if (res.data) {
        return res.data;
      }
    });

    const status2WithUpdatedPriority = await workWithResponse(() => Api.updateStatus({ ...status2, priority: status1.priority + 1 })).then((res) => {
      if (res.data) {
        return res.data;
      }
    });

    const statusesWithNewOrdered = statuses.map((status) => {
      if (status.id === status1.id && status2WithUpdatedPriority && status1WithUpdatedPriority) {
        return {
          ...status,
          priority: status2.priority,
        };
      }

      if (status.id === status2.id && status1WithUpdatedPriority && status2WithUpdatedPriority) {
        return {
          ...status,
          priority: status1.priority,
        };
      }

      return status;
    }).sort((a, b) => a.priority - b.priority);

    updateStatuses(statusesWithNewOrdered);

    setIsLoading(false);
  }

  async function deleteStatus() {
    if (statusToRemoveId) {
      setIsDeleting(true);

      try {
        await workWithResponse(() => Api.deleteStatus(statusToRemoveId)).then((res) => {
          if (res.data) {
            updateStatuses(statuses.filter((({ id }) => id !== statusToRemoveId)));
            setActiveModalName(null);

            return;
          }

          if (res.error) {
            notification.error(res.error);
          }
        });
      } catch (e) {
        console.error(`Error: ${e}`);
        notification.error(unexpectedErrorMessage);
      } finally {
        setIsDeleting(false);
      }
    }
  }

  const statusesFilteredFromDefaultStatuses = useMemo(() => {
    const defaultStatusesIds = [1];

    return statuses.filter((status) => !defaultStatusesIds.includes(status.id));
  }, [statuses]);

  return (
    <>
      {activeModalName === 'deleting' && (
        <ModalConfirmDecline
          onClose={() => setActiveModalName(null)}
          title="Are you sure you want to delete status?"
          buttons={
            [
              {
                text: 'Delete',
                onClick() {
                  deleteStatus();
                },
                theme: 'grey',
                fullWidth: true,
                isLoading: isDeleting,
              },
              {
                text: 'Cancel',
                onClick() {
                  setActiveModalName(null);
                },
                theme: 'mainFilled',
                fullWidth: true,
                isLoading: isDeleting,
              },
            ]
          }
        />
      )}

      <DragDropContext onDragEnd={(res) => handleOnDragEnd(res)}>
        <Droppable droppableId="statuses">
          {(provided) => (
            <ul className={classNames('statusesList')} {...provided.droppableProps} ref={provided.innerRef}>
              {statusesFilteredFromDefaultStatuses.map((status, index) => (
                <Draggable key={status.id} draggableId={String(status.id)} index={index}>
                  {(provided) => (
                    <li onClick={() => null} className="statusesListItem textBase14" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <div className="leftSide">
                        {/* <DragIcon /> */}

                        <div className="textWrapper">
                          <span>{status.name}</span>
                        </div>
                      </div>
                      {status.id !== 1 && (
                      <div className="statusesListItemButtons">
                        <button
                          className="editButton"
                          type="button"
                          onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();

                            setStatusToUpdateIndex(index + 1);
                          }}
                        >
                          <EditIcon />
                        </button>

                        <button
                          className="deleteButton"
                          type="button"
                          onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();

                            setStatusToRemoveId(status.id);
                            setActiveModalName('deleting');
                          }}
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                      )}
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default StatusesList;
