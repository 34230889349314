import React, { useEffect, useRef, useState } from 'react';
import { AvailableFormPurpose, TApiForm } from '../../../enteties/types/forms.types';
import { MenuIcon } from '../../../assets/icons/other/OpenMenuIcon';
import DropDown from '../../other/dropDown/DropDown';
import EditIcon from '../../../assets/icons/table/EditIcon';
import { MenuStyles } from './MenuStyles';
import { TrashIconWithTwoLinesInCenter } from '../../../assets/icons/other/TrashIconWithTwoLinesInCenter';
import { QRIcon } from '../../../assets/icons/other/QRIcon';
import ModalConfirmDecline from '../../modals/modalConfirmDecline/ModalConfirmDecline';
import { notification } from '../../../helpers/notifications/toastify';
import { unexpectedErrorMessage } from '../../../constants/constants';
import workWithResponse from '../../../helpers/workWithResponse';
import { Api } from '../../../api';
import QRCodeStyling, { FileExtension } from 'qr-code-styling';

interface MenuProps {
  form: TApiForm;
  onFormDeleted(id: number): void;
}

const qrCode = new QRCodeStyling({
  width: 256,
  height: 256,
  dotsOptions: {
    color: '#000',
    type: 'rounded',
  },
  imageOptions: {
    crossOrigin: 'anonymous',
    margin: 10,
  },
});

function Menu({ form, onFormDeleted }: MenuProps) {
  const openButtonRef = useRef<null | HTMLButtonElement>(null);

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteFormModalOpen, setDeleteFormModalOpen] = useState<boolean>(false);

  useEffect(() => {
    qrCode.update({
      data: `${process.env.REACT_APP_CLIENT_URL}/${form.id}`,
    });
  }, [form]);

  const onDownloadClick = () => {
    qrCode.download({
      extension: 'png',
    });
  };

  async function handleDeleteForm() {
    try {
      setIsDeleting(true);

      await workWithResponse(() => Api.deleteForm(form.id)).then((res) => {
        if (res.error) {
          notification.error(res.error);

          return;
        }

        onFormDeleted(form.id);
      });
    } catch (e) {
      notification.error(unexpectedErrorMessage);
      console.error(`Error: ${e}`);
    } finally {
      setIsDeleting(false);
    }
  }

  return (
    <MenuStyles>
      {deleteFormModalOpen && (
        <ModalConfirmDecline
          onClose={() => (isDeleting ? null : setDeleteFormModalOpen(false))}
          title="Are you sure you want delete form?"
          buttons={
            [
              {
                text: 'Delete',
                onClick() {
                  setIsDeleting(true);
                  handleDeleteForm();
                },
                theme: 'grey',
                fullWidth: true,
                isLoading: isDeleting,
              },
              {
                text: 'Cancel',
                onClick() {
                  setDeleteFormModalOpen(false);
                },
                theme: 'mainFilled',
                fullWidth: true,
                isLoading: isDeleting,
              },
            ]
          }
        />
      )}

      <DropDown exclude={openButtonRef} isOpened={menuOpen} onClose={() => setMenuOpen(false)}>
        <button
          className="textBase12 textBold"
          onClick={() => {
            window.open(`${process.env.REACT_APP_BASE_URL}/forms/constructor/${form.id}`, '_blank');
            setMenuOpen(false);
          }}
          type="button"
        >
          <EditIcon />

          Edit
        </button>

        {form.purpose === AvailableFormPurpose.link_qr && (
          <button
            className="textBase12 textBold"
            onClick={() => {
              onDownloadClick();
              setMenuOpen(false);
            }}
            type="button"
          >
            {QRIcon}

            Download QR
          </button>
        )}

        <button
          className="textBase12 textBold red"
          onClick={() => {
            setDeleteFormModalOpen(true);
            setMenuOpen(false);
          }}
          type="button"
        >
          {TrashIconWithTwoLinesInCenter}

          Delete
        </button>
      </DropDown>

      <button className="openButton" ref={openButtonRef} type="button" onClick={() => setMenuOpen(!menuOpen)}>
        {MenuIcon}
      </button>
    </MenuStyles>
  );
}

export default Menu;
