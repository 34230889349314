import styled from 'styled-components';

export const MainLayoutStyles = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  
  header {
    //position: absolute;
    width: 100%;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    padding: 0 16px 0 16px;
    box-sizing: border-box;
    display: flex;

    @media (min-width: 768px) {
      padding: 0 39px 0 39px;
    }
    
    @media (min-width: 1201px) {
      padding: 0 74px 0 50px;
      display: none;
    }
  }
  
  main {
    flex-grow: 1;
    display: flex;
    width: 100%;
    min-height: 100vh;
    padding-top: 88px;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    background-color: #FCFCFC;
    
    overflow: clip;

    @media (min-width: 768px) {
      padding-left: 39px;
      padding-right: 39px;
    }

    @media (min-width: 1201px) {
      padding-left: 354px;
      padding-top: 32px;
    }
  }
`;
