import type { ColumnDef } from '@tanstack/react-table';
import {
  ColumnResizeMode,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { TableStyles } from './TableStyles';
import { useState } from 'react';
import ArrowDownIcon from '../../assets/icons/table/ArrowDownIcon';
import { TApiStatus } from '../../enteties/types/statuses.types';

interface ReactTableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T>[];
  withSorting?: boolean;
  onClickToRow?: (data: T) => void;
  backgroundByStatus?: boolean;
  statusesBook?: {[key: string]: TApiStatus};
}

export const Table = <T extends object>({
  data, columns, withSorting = false, onClickToRow, backgroundByStatus, statusesBook,
}: ReactTableProps<T>) => {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnResizeMode, setColumnResizeMode] = useState<ColumnResizeMode>('onChange');

  const table = useReactTable({
    data,
    columns,
    defaultColumn: {
      minSize: 0,
      size: 0,
    },
    state: {
      sorting,
    },
    columnResizeMode,
    onSortingChange: setSorting,
    enableSorting: withSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <TableStyles>
      <table className="boxShadow1" style={{ marginBottom: '40px' }}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                    width: header.getSize() !== 0 ? header.getSize() : undefined,
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className: header.column.getCanSort()
                          ? 'cursor-pointer select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      <span className="textSemiBold16">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </span>
                      {{
                        asc: <ArrowDownIcon />,
                        desc: <ArrowDownIcon active />,
                      }[header.column.getIsSorted() as string] ?? null}
                      {withSorting && !header.column.getIsSorted() && <div style={{ width: '14px' }} />}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              onClick={() => (onClickToRow ? onClickToRow(row.original) : null)}
              style={backgroundByStatus && statusesBook && 'status_id' in row.original && typeof row.original.status_id === 'number' && row.original.status_id && statusesBook[row.original.status_id] ? { background: statusesBook[row.original.status_id].color } : {}}
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  style={{
                    width:
                    cell.column.getSize() !== 0
                      ? cell.column.getSize()
                      : undefined,
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </TableStyles>
  );
};
