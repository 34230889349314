import React, { useEffect, useState } from 'react';
import { CommentsStyles } from './CommentsStyles';
import ApiUser from '../../../api/entities/ApiUser';
import { useParams } from 'react-router-dom';
import { Api } from '../../../api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Textarea from '../../form/textArea/TextArea';
import { useAppSelector } from '../../../state/hooks';
import { ApiFile } from '../../../api/entities/ApiFile';
import { toast } from 'react-toastify';
import { TLeadApiComment } from '../../../enteties/types/comments.types';
import workWithResponse from '../../../helpers/workWithResponse';
import { notification } from '../../../helpers/notifications/toastify';
import Loader from '../../loader/Loader';
import InputField from '../../form/input/InputField';
import { SendMessageIcon } from '../../../assets/icons/lead/SendMessageIcon';
import CommentsList from './commentsList/CommentsList';

type FormValues = {message: string, files: ApiFile[]}

function Comments({ handleDataLoadingError }: {handleDataLoadingError: () => void}) {
  const [comments, setComments] = useState<TLeadApiComment[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const { data: user } = useAppSelector((state) => state.user);

  const { id } = useParams();

  async function createComment(values: FormValues) {
    const newComment = await workWithResponse(() => Api.createComment({
      message: values.message, user_id: user!.id!, lead_id: +id!,
    })).then((res) => {
      if (!res.error && res.data) {
        return res.data;
      }
    });
    if (newComment) {
      setComments((oldComments) => [...oldComments, { ...newComment }]);

      return true;
    }
    return false;
  }

  const {
    handleChange, handleSubmit, handleBlur, setValues, values, errors, touched, isSubmitting, resetForm, setFieldValue, setSubmitting,
  } = useFormik<FormValues>({
    initialValues: {
      message: '',
      files: [],
    },
    onSubmit: (values) => {
      if (values) {
        setSubmitting(true);

        createComment(values).then((success) => {
          if (success) {
            resetForm();
          } else {
            notification.error('An error occurred while saving the comment');
          }
        });
      }

      setSubmitting(false);
    },
    validationSchema: Yup.object().shape({
      message: Yup.string().required('Comment cannot be empty'),
    }),
  });

  async function getInitialData() {
    setIsLoading(true);

    try {
      const commentsFromServer = await workWithResponse(() => Api.getComments(+id!)).then((res) => {
        if (!res.error && res.data) {
          return res.data;
        }

        notification.error(res.error!);
        handleDataLoadingError();
      });

      if (commentsFromServer) {
        setComments(commentsFromServer.data);
      }
    } catch (e) {
      console.log(e);
      handleDataLoadingError();
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      getInitialData();
    }
  }, []);

  return (
    <CommentsStyles>
      <span className="textSemiBold16">Comments</span>

      {isLoading && <Loader />}

      {!isLoading && comments && (
      <>
        <CommentsList comments={comments} />

        <InputField
          name="message"
          label="Leave your comment"
          placeholder="Type here"
          value={values.message}
          onChange={handleChange}
          extraClassName="messageInput"
          error={touched && touched.message && errors && errors.message ? errors.message : undefined}
          elementInInput={(
            <button className="sendMessageButton" type="button" onClick={() => handleSubmit()}>
              {SendMessageIcon}
            </button>
            )}
        />
      </>
      )}

    </CommentsStyles>
  );
}

export default Comments;
