import React from 'react';
import Menu from '../menu/Menu';
import { TApiForm } from '../../../enteties/types/forms.types';
import { ListMenuStyles } from './ListMenuStyles';

interface ListMobileProps {
  forms: TApiForm[];
  onFormDeleted(id: number): void;
}

function ListMobile({ forms, onFormDeleted }:ListMobileProps) {
  return (
    <ListMenuStyles>
      {forms.map((form) => (
        <li>
          <div className="head">
            <span className="title textSemiBold16">Form name</span>

            <Menu form={form} onFormDeleted={onFormDeleted} />
          </div>

          <span className="textBase16">{form.name}</span>
        </li>
      ))}

    </ListMenuStyles>
  );
}

export default ListMobile;
