import React from 'react';
import Modal from '../../../modal/Modal';
import { PublicationErrorIcon } from '../../../../assets/icons/constructor/PublicationErrorIcon';
import TextButton from '../../../buttons/button/TextButton';

interface PublicationErrorModalProps {
  handleSaveForm(): Promise<void>;
  handleClose(): void;
}

function PublicationErrorModal({ handleSaveForm, handleClose }:PublicationErrorModalProps) {
  return (
    <Modal onClose={handleClose} className="publicationErrorModal">
      <div className="head">
        <h4 className="textBold28">Publication error</h4>

        {PublicationErrorIcon}

        <p className="textBase16">
          We apologize, but it seems there was an error during the publication process. Our team is working diligently to resolve this issue. Please try again later. Thank you for your understanding.
        </p>
      </div>

      <TextButton
        text="Try again"
        theme="mainFilled"
        onClick={handleSaveForm}
      />
    </Modal>
  );
}

export default PublicationErrorModal;
