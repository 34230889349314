import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputField from '../../../components/form/input/InputField';
import { ForgotPasswordStyles } from './ForgotPasswordStyles';
import TextButton from '../../../components/buttons/button/TextButton';
import Logo from '../../../assets/Logo';
import { useAppDispatch } from '../../../state/hooks';
import { loginUser } from '../../../state/slices/userSlice';
import PlayIcon from '../../../assets/icons/authentefication/PlayIcon';

import workWithResponse from '../../../helpers/workWithResponse';
import { Api } from '../../../api';
import { notification } from '../../../helpers/notifications/toastify';

function ForgotPassword() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    handleChange, handleSubmit, handleBlur, setValues, values, errors, touched, isSubmitting,
  } = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values) => {
      if (errorMessage) {
        setErrorMessage(null);
      }

      if (values) {
        workWithResponse(() => Api.passwordRecoveryEmail(values)).then((res) => {
          if (res.error) {
            setErrorMessage(res.error);
          } else if (res.data) {
            notification.success('Instructions to reset your password have been sent. Please check your email.');
            navigate('/');
          }
        });
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Email is not valid').required('Required field'),
    }),
  });

  return (
    <ForgotPasswordStyles>
      <div className="content">
        <Link to="/"><Logo /></Link>

        <div className="formWrapper">
          <div className="formFieldsWrapper">
            <div className="titleWrapper">
              <h1 className="textBold28">Forgot your password?</h1>

              <span className="textBase16">Enter the email address you used when you joined and we’ll send you instructions to reset your password.</span>
            </div>

            <div className="fieldsWrapper">
              <InputField
                label="Email"
                placeholder="Enter e-mail"
                name="email"
                onChange={handleChange}
                value={values.email}
                error={touched && touched.email && errors && errors.email ? errors.email : undefined}
              />
            </div>
          </div>

          <div>
            <div className="buttonsWrapper">
              <TextButton
                text="Send email"
                onClick={handleSubmit}
                fullWidth
                theme="mainFilled"
                error={!!errorMessage}
              />
            </div>

            {errorMessage && <span className="errorText textBase14">{errorMessage}</span>}
          </div>

          <Link className="textSemiBold16 animationUnderline goToSignInLink" to="/">
            Return to Sign in
          </Link>
        </div>

        <div />
      </div>

      <div className="rightSide">
        <div className="sloganContainer">
          <PlayIcon />

          <p className="textBold28">One-stop platform to boost your digital marketing</p>
        </div>
      </div>
    </ForgotPasswordStyles>
  );
}

export default ForgotPassword;
