import React from 'react';
import { TextButtonProps } from '../../../enteties/button/ButtonProps';
import Loader from '../../loader/Loader';
import { ButtonStyles } from '../ButtonStyles';

const buttonLoaderColors = {
  grey: '#8C8C8C',
  mainFilled: '#FDFDFD',
  mainNotFilled: '#FDB17B',
  red: '#FF7875',
};

function TextButton({
  icon,
  error,
  text,
  theme = 'grey',
  size = 'XL',
  onClick,
  isLoading,
  type = 'button',
  disabled,
  fullWidth,
  extraClasses,
  title,
  iconPosition = 'iconRight',
  children,
}: TextButtonProps) {
  return (
    <ButtonStyles title={title} onClick={onClick} className={`button textSemiBold16 ${iconPosition} ${error ? 'red' : theme} ${size} ${isLoading ? 'loading' : ''} ${extraClasses ?? ''}`} style={fullWidth ? { width: '100%' } : {}} disabled={disabled} type={type}>
      {isLoading && <Loader size={16} color={buttonLoaderColors[theme]} />}

      {text && text}
      {icon && !isLoading && icon}
      {children && children}

      {isLoading && <Loader size={16} color={buttonLoaderColors[theme]} />}
    </ButtonStyles>
  );
}

export default TextButton;
