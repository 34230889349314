import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';
import LinksPath from '../../../components/other/linksPath/LinksPath';
import {
  AvailableFormPurpose, TApiForm, TField, TForm,
} from '../../../enteties/types/forms.types';
import TextButton from '../../../components/buttons/button/TextButton';
import { ConstructorStyles } from './ConstructorStyles';
import Steps from '../../../components/forms/constructor/steps/Steps';
import StepFields from '../../../components/forms/constructor/stepFields/StepFields';
import StepPurpose from '../../../components/forms/constructor/stepPurpose/StepPurpose';
import StepDesign from '../../../components/forms/constructor/stepDesign/StepDesign';
import StepThankYou from '../../../components/forms/constructor/stepThankYou/StepThankYou';
import ModalUnsavedChanges from '../../../components/modals/modalUnsavedChanges/ModalUnsavedChanges';

import { useBeforeunload } from 'react-beforeunload';
import workWithResponse from '../../../helpers/workWithResponse';
import { Api } from '../../../api';
import { notification } from '../../../helpers/notifications/toastify';
import { formInitialData } from '../../../constants/constants';
import CloseEditingIcon from '../../../assets/icons/forms/constructor/CloseEditingIcon';
import Loader from '../../../components/loader/Loader';
import ErrorComponent from '../../../components/other/error/ErrorComponent';
import StepReadyQr from '../../../components/forms/constructor/stepReady/qr/StepReadyQR';
import PublicationErrorModal from '../../../components/forms/constructor/publicationErrorModal/PublicationErrorModal';
import { useAppSelector } from '../../../state/hooks';

export type TSteps = 'fields' | 'purpose' | 'design' | 'thankYou' | 'ready';

function Constructor() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: user } = useAppSelector((state) => state.user);

  const [activeStep, setActiveStep] = useState<TSteps>('fields');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataLoadingError, setDataLoadingError] = useState<boolean>(false);
  const [publicationError, setPublicationError] = useState<boolean>(false);

  const [formData, setFormData] = useState<TForm | null>(formInitialData);
  const [formDataInitialValues, setFormDataInitialValues] = useState<TForm | null>(formInitialData);

  const isPageLeaveBlocked = useMemo(() => (formData && formDataInitialValues ? JSON.stringify(formDataInitialValues) !== JSON.stringify(formData) : false), [formDataInitialValues, formData]);

  const blocker = useBlocker(
    ({ historyAction, currentLocation, nextLocation }) => isPageLeaveBlocked
      && currentLocation.pathname !== nextLocation.pathname,
  );

  useBeforeunload(isPageLeaveBlocked ? (event) => event.preventDefault() : undefined);

  const isNew = useMemo(() => id === 'new', [id]);

  async function getInitialData() {
    if (id && id !== 'new' && Number.isNaN(+id)) {
      navigate('/404');
    }

    if (id && id !== 'new') {
      setIsLoading(true);

      try {
        const form = await workWithResponse(() => Api.getForm(+id)).then((res) => {
          if (res.error) {
            notification.error(res.error);

            setDataLoadingError(true);

            return;
          }

          if (res.data) {
            return res.data;
          }
        });

        if (form) {
          const formFields = await Promise.all(form.fields.map(async (id) => workWithResponse(() => Api.getFormField(+id)).then((res) => {
            if (res.error) {
              notification.error(res.error);
              setDataLoadingError(true);
              return;
            }

            if (res.data) {
              return res.data;
            }
          }))).then((res) => res as TField[]);

          const formWithFields: TForm = { ...form, fields: formFields };

          setFormData(formWithFields);
          setFormDataInitialValues(formWithFields);
        }
      } catch (e: any) {
        notification.error(e.message);

        setDataLoadingError(true);
      } finally {
        setIsLoading(false);
      }

      return;
    }

    return null;
  }

  async function handleSaveForm() {
    try {
      setIsLoading(true);

      if (formData && formDataInitialValues && user) {
        const formDataToSubmit: Omit<TApiForm, 'id'> = {
          name: formData.name,
          thank_you: formData.thank_you,
          design: formData.design,
          purpose: formData.purpose!,
          submit_button: formData.submit_button,
          description: formData.description,
          title: formData.title,
          fields: [],
          user_id: user.id,
        };

        if (id === 'new') {
          formDataToSubmit.fields = await Promise.all(formData.fields.map(async (field) => (
            workWithResponse(() => Api.createFormField(field)).then((res) => {
              if (res.error) {
                notification.error(res.error);
                setPublicationError(true);
              }

              if (res.data) {
                return res.data.id;
              }
            })))) as number[];

          workWithResponse(() => Api.createForm(formDataToSubmit)).then((res) => {
            if (res.error) {
              notification.error(res.error);
              setPublicationError(true);

              return;
            }

            if (publicationError) {
              setPublicationError(false);
            }

            setActiveStep('ready');
            setFormDataInitialValues({ ...formData, id: res.data!.id });
            setFormData({ ...formData, id: res.data!.id });
          });
        }

        if (id !== 'new') {
          formDataToSubmit.fields = await Promise.all(formData.fields.map(async (field) => {
            if ('id' in field) {
              const fieldInInitialValuesWithSameId = formDataInitialValues.fields.find((initialDataField) => 'id' in initialDataField && initialDataField.id === field.id);

              if (JSON.stringify(fieldInInitialValuesWithSameId) === JSON.stringify(field)) {
                return field.id;
              }

              return workWithResponse(() => Api.createFormField(field)).then((res) => {
                if (res.error) {
                  notification.error(res.error);
                  setPublicationError(true);
                }

                if (res.data) {
                  return res.data.id;
                }
              });
            }

            return workWithResponse(() => Api.createFormField(field)).then((res) => {
              if (res.error) {
                notification.error(res.error);
                setPublicationError(true);
              }

              if (res.data) {
                return res.data.id;
              }
            });
          })) as number[];

          workWithResponse(() => Api.updateForm({ ...formDataToSubmit, id: +id! })).then((res) => {
            if (res.error) {
              notification.error(res.error);
              setPublicationError(true);

              return;
            }

            if (publicationError) {
              setPublicationError(false);
            }

            setActiveStep('ready');
            setFormDataInitialValues(formData);
          });
        }
      }
    } catch (e) {
      setPublicationError(true);
      console.error(`Error: ${e}`);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getInitialData();
  }, []);

  const isFormValid = useMemo(() => {
    if (formData && !!formData.title && !!formData.submit_button.text && formData.fields.length > 0 && formData.purpose) {
      return true;
    }

    return false;
  }, [formData]);

  useEffect(() => {
    if (id === 'new' && isFormValid) {
      notification.success('Now you can save a form', { type: 'info', autoClose: 2000, position: 'top-right' });
    }
  }, [isFormValid]);

  const handleChangeFormData = useCallback((data: Partial<TForm>) => {
    if (formData) {
      setFormData({ ...formData, ...data });
    }
  }, [formData]);

  if (dataLoadingError) {
    return <ErrorComponent />;
  }

  return (
    <ConstructorStyles>
      <ModalUnsavedChanges blocker={blocker} />
      {publicationError && <PublicationErrorModal handleSaveForm={handleSaveForm} handleClose={() => setPublicationError(false)} />}

      <div className="head">
        <div className="titleWrapper">
          <h2 className="textBold28">{isNew ? 'Create new form' : 'Edit form'}</h2>
          <LinksPath
            pathArr={[{ path: '/forms', name: 'Forms' }, { path: '', name: 'Constructor' }]}
          />
        </div>

        <div className="buttonsContainer">
          <TextButton
            text={isNew ? 'Close' : 'Close editing'}
            icon={CloseEditingIcon}
            onClick={() => navigate('/forms')}
            theme="mainNotFilled"
            extraClasses="closeEditingButton"
          />
          {isFormValid && (
            <TextButton
              text={isNew ? 'Save form' : 'Update form'}
              onClick={handleSaveForm}
              theme="mainFilled"
            />
          )}
        </div>
      </div>

      <div className="content">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="stepsWrapper">
              <Steps
                setActiveStep={setActiveStep}
                isNew={isNew}
                activeStep={activeStep}
              />
            </div>

            {formData && (
              <>
                {activeStep === 'fields' && (
                  <StepFields
                    stepFieldsData={{
                      fields: formData.fields, title: formData.title, description: formData.description, submit_button: formData.submit_button, name: formData.name,
                    }}
                    handleChangeFormData={handleChangeFormData}
                    setActiveStep={setActiveStep}
                  />
                )}

                {activeStep === 'purpose' && (
                  <StepPurpose
                    purpose={formData.purpose}
                    handleChangeFormData={handleChangeFormData}
                    setActiveStep={setActiveStep}
                  />
                )}

                {activeStep === 'design' && (
                  <StepDesign
                    formData={formData!}
                    handleChangeFormData={handleChangeFormData}
                    setActiveStep={setActiveStep}
                  />
                )}

                {activeStep === 'thankYou' && (
                  <StepThankYou
                    thankYouData={formData!.thank_you}
                    handleChangeFormData={handleChangeFormData}
                  />
                )}

                {activeStep === 'ready' && (
                  <>
                    {formData.purpose === AvailableFormPurpose.link_qr && <StepReadyQr id={formData.id!} />}

                    {formData.purpose === AvailableFormPurpose.website && 2}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </ConstructorStyles>
  );
}

export default Constructor;
