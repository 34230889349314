import styled from 'styled-components';

export const StepPurposeStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 60px;
  
  @media (min-width: 768px) {
    padding-top: 70px;
  }


  h3 {
    margin-bottom: 24px;

    @media (min-width: 768px) {
      margin-bottom: 32px;
    }
  }

  .buttonsContainer {
    width: 100%;
    display: grid;
    place-items: center;
    grid-template-columns: 1fr;
    
    gap: 24px;

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
      gap: 40px;
    }

    button {
      padding: 24px;

      border-radius: 4px;
      border: 1px solid var(--Stroke, #EEE);
      background: #FFF;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
      transition: 0.3s ease box-shadow;
      
      &:hover {
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
        
        .iconContainer {
          background-position: 100% 100%;
        }
      }

      .iconContainer {
        position: relative;

        padding: 14px;
        width: fit-content;
        margin: auto auto 16px auto;

        border-radius: 14px;

        background: linear-gradient(312deg, #00C8CB -37.73%, #D9FEFF 99.46%);
        background-size: 200% 200%;
        transition: .5s ease background-position;
      }

      .textContainer {
        text-align: center;

        h4 {
          margin-bottom: 4px;
          
          font-size: 18px;
          font-weight: 600;
          line-height: 26px;

          @media (min-width: 768px) {
            font-size: 22px;
            line-height: 34px;
          }
        }
      }
    }
  }
`;
