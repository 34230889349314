export const PublicationErrorIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="155" height="100" viewBox="0 0 155 100" fill="none">
    <path d="M1.65208 42.477C1.5148 42.477 1.39936 42.3725 1.3875 42.2338L0.000976628 25.6256C-0.0115034 25.48 0.0970726 25.3525 0.243713 25.3401C0.380993 25.3295 0.517648 25.4359 0.530128 25.582L1.91666 42.1902C1.92914 42.3358 1.82056 42.4633 1.67392 42.4757C1.66643 42.4764 1.65957 42.477 1.65208 42.477Z" fill="#D9D9D9" />
    <path d="M2.47457 52.3418C2.33729 52.3418 2.22185 52.2373 2.20999 52.0986L1.79004 47.0711C1.77756 46.9255 1.88614 46.798 2.03278 46.7856C2.17318 46.7675 2.30733 46.8807 2.31919 47.0275L2.73915 52.055C2.75163 52.2006 2.64305 52.3281 2.49641 52.3405C2.48955 52.3411 2.48206 52.3418 2.47457 52.3418Z" fill="#D9D9D9" />
    <path d="M142.855 99.6006H14.0149C10.7276 99.6006 7.81418 96.9198 7.54024 93.6426L0.284373 6.70603C0.0110608 3.42942 2.47648 0.748047 5.76434 0.748047H134.604C137.891 0.748047 140.805 3.42879 141.079 6.70603L148.335 93.6426C148.609 96.9192 146.143 99.6006 142.855 99.6006Z" fill="white" />
    <path d="M142.856 99.8489H14.0158C10.5938 99.8489 7.57803 97.0742 7.29348 93.6638L0.0376131 6.7273C-0.101539 5.06847 0.423244 3.50418 1.51337 2.32303C2.59851 1.14749 4.10859 0.5 5.76531 0.5H134.606C138.027 0.5 141.043 3.27529 141.327 6.68562L148.584 93.6216C148.723 95.2804 148.198 96.8441 147.108 98.0246C146.022 99.2008 144.512 99.8489 142.856 99.8489ZM5.76531 0.996963C4.24961 0.996963 2.86995 1.58723 1.88029 2.65953C0.885007 3.7368 0.40702 5.16674 0.533692 6.68625L7.78956 93.6222C8.05352 96.7819 10.8465 99.3519 14.0165 99.3519H142.856C144.373 99.3519 145.752 98.7616 146.743 97.6893C147.737 96.6114 148.215 95.1821 148.088 93.6632L140.832 6.7273C140.568 3.56762 137.776 0.996963 134.606 0.996963H5.76531Z" fill="#00C8CB" />
    <path d="M134.606 0.749023H5.76629C2.47906 0.749023 0.0130093 3.42978 0.286321 6.70701H141.08C140.807 3.42978 137.893 0.749023 134.606 0.749023Z" fill="#00C8CB" />
    <path d="M8.78212 3.7274C8.8414 4.43708 8.31225 5.01242 7.60026 5.01242C6.88828 5.01242 6.26303 4.43708 6.20375 3.7274C6.14447 3.01771 6.67363 2.44238 7.38561 2.44238C8.09759 2.44238 8.72284 3.01771 8.78212 3.7274Z" fill="white" />
    <path d="M14.0237 3.7274C14.083 4.43708 13.5538 5.01242 12.8418 5.01242C12.1298 5.01242 11.5052 4.43708 11.4459 3.7274C11.3867 3.01771 11.9158 2.44238 12.6278 2.44238C13.3398 2.44238 13.9644 3.01771 14.0237 3.7274Z" fill="white" />
    <path d="M19.2659 3.7274C19.3251 4.43708 18.796 5.01242 18.084 5.01242C17.372 5.01242 16.7468 4.43708 16.6881 3.7274C16.6288 3.01771 17.158 2.44238 17.87 2.44238C18.582 2.44238 19.2066 3.01771 19.2659 3.7274Z" fill="white" />
    <path d="M131.676 88.2078H23.2967C20.6834 88.2078 18.3677 86.0769 18.15 83.472L13.0887 22.8354C12.8716 20.2305 14.8315 18.0996 17.4448 18.0996H125.824C128.437 18.0996 130.753 20.2305 130.97 22.8354L136.032 83.472C136.249 86.0769 134.289 88.2078 131.676 88.2078Z" fill="#F0F0F0" />
    <path opacity="0.1" d="M98.1574 28.7169L64.5812 69.0109C59.9755 74.5384 62.8933 82.9645 69.9389 84.4833L124.397 96.2233C131.918 97.8448 138.202 90.4326 135.344 83.3115L114.462 31.2776C111.758 24.5396 102.809 23.1346 98.1574 28.7169Z" fill="#263238" />
    <path opacity="0.1" d="M23.4267 34.3665L11.7323 65.7556C10.1717 69.944 13.5507 74.3153 18.0116 73.8793L52.1625 70.5411C56.9511 70.0728 59.3136 64.5004 56.3128 60.7511L33.8569 32.6996C30.9128 29.0231 25.0697 29.9567 23.4267 34.3665Z" fill="#263238" />
    <path d="M109.741 18.5719L78.3529 63.9998C74.0773 70.1879 77.8649 78.6942 85.3367 79.685L143.035 87.3391C151.043 88.4015 156.998 80.1241 153.419 72.9041L127.109 19.8221C123.696 12.9355 114.112 12.2451 109.741 18.5719Z" fill="#F5222D" />
    <path d="M86.7055 75.6129C84.607 75.3349 82.783 74.0157 81.8264 72.0857C80.8698 70.155 80.9797 68.0167 82.121 66.3641L113.588 20.8218C114.729 19.1704 116.664 18.35 118.762 18.628C120.86 18.9067 122.684 20.2253 123.641 22.1553L150.017 75.371C150.974 77.3016 150.864 79.44 149.722 81.0926C148.581 82.744 146.646 83.5644 144.548 83.2864L86.7055 75.6129Z" fill="white" />
    <path d="M116.083 34.7265L119.757 35.2141C122.12 35.5276 123.939 37.6703 123.819 39.9997L123.573 44.7778C123.564 44.9532 123.543 45.1267 123.513 45.2971L120.898 59.696C120.521 61.771 118.619 63.1132 116.461 62.8271C114.299 62.5404 112.558 60.708 112.405 58.5572L111.345 43.6768C111.332 43.5046 111.331 43.3323 111.34 43.1612L111.586 38.3775C111.707 36.0476 113.72 34.413 116.083 34.7265ZM115.789 66.796L116.792 66.9291C118.906 67.2096 120.532 69.1259 120.424 71.2096C120.317 73.2932 118.516 74.7549 116.402 74.475L115.4 74.3419C113.286 74.0614 111.66 72.1451 111.767 70.0614C111.875 67.9778 113.676 66.5155 115.789 66.796Z" fill="#F5222D" />
    <path d="M35.4978 25.5155L26.8797 58.0913C25.7384 62.4048 29.5155 66.4228 33.9079 65.5682L67.3368 59.0666C72.0343 58.1529 73.8701 52.4313 70.5766 48.9699L45.7664 22.8957C42.4953 19.4586 36.7096 20.9346 35.4978 25.5155Z" fill="#F5222D" />
    <path d="M33.8451 62.9295C32.6296 63.1659 31.3647 62.7411 30.4631 61.7932C29.5608 60.8447 29.2088 59.5714 29.5227 58.3866L38.1626 25.7287C38.4758 24.5444 39.3888 23.696 40.6049 23.4597C41.8205 23.2233 43.0853 23.6481 43.987 24.5961L68.8603 50.7362C69.7626 51.6847 70.1145 52.9579 69.8006 54.1428C69.4874 55.327 68.5745 56.1754 67.3589 56.4118L33.8451 62.9295Z" fill="white" />
    <path d="M42.2253 33.3961L44.3544 32.9819C45.7234 32.7156 47.1443 33.6244 47.5268 35.0108L48.3124 37.8551C48.3411 37.9596 48.3635 38.0647 48.3791 38.1704L49.7089 47.0996C49.9005 48.3865 49.1061 49.5341 47.8562 49.7773C46.6032 50.0211 45.286 49.2791 44.7862 48.0494L41.3279 39.5376C41.288 39.4387 41.2537 39.3385 41.2256 39.2365L40.4394 36.3885C40.0569 35.0027 40.8562 33.6623 42.2253 33.3961ZM48.25 52.231L48.8309 52.1178C50.0558 51.8796 51.3263 52.6925 51.6689 53.9328C52.0115 55.1736 51.2963 56.3722 50.0714 56.6104L49.4911 56.7236C48.2662 56.9618 46.9957 56.1489 46.6532 54.9086C46.31 53.6678 47.0251 52.4692 48.25 52.231Z" fill="#F5222D" />
  </svg>
);
