import React from 'react';
import { TextWithDotStyles } from './TextWithDotStyles';

function TextWithDot({ text }: {text: string}) {
  return (
    <TextWithDotStyles className="textWithDot">
      <div className="dot" />

      <span className="textBase18">{text}</span>
    </TextWithDotStyles>
  );
}

export default TextWithDot;
