import styled from 'styled-components';

export const StepReadyQrStyles = styled.div`
  padding-bottom: 60px;
  padding-top: 30px;
  
  @media (min-width: 768px) {
    padding-top: 60px;
  }
  
  .qrCode {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    
    canvas {
      max-width: 400px;
      width: 100%;
    }
  }
  
  .content {
    padding: 24px;
    max-width: 688px;
    margin: auto;

    border-radius: 4px;
    border: 1px solid var(--Stroke, #EEE);
    background: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
    
    @media (min-width: 768px) {
      padding: 48px;
    }
    
    .head {
      display: flex;
      flex-direction: column;
      gap: 16px;
      
      margin-bottom: 24px;
      
      text-align: center;
    }
    
    .buttonsContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;
      
      max-width: 364px;
      width: 100%;
      margin: auto;
    }
  }
`;
