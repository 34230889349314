import React, { useEffect, useState } from 'react';
import { StepDesignStyles } from './StepDesignStyles';
import ColorPicker from '../../../form/colorPicker/ColorPicker';
import { TForm, TFormDesign } from '../../../../enteties/types/forms.types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import NumberInput from '../../../form/numberInput/NumberInput';
import FormPreview from '../formPreview/FormPreview';
import DropZone from '../../../form/dropzone/DropZone';
import { formInitialData } from '../../../../constants/constants';
import { TSteps } from '../../../../pages/forms/constructor/Constructor';
import TextButton from '../../../buttons/button/TextButton';
import { notification } from '../../../../helpers/notifications/toastify';

interface StepDesignProps {
  formData: Omit<TForm, 'id'>;
  handleChangeFormData: (data: Partial<TForm>) => void;
  setActiveStep: React.Dispatch<React.SetStateAction<TSteps>>;
}

function StepDesign({ formData, handleChangeFormData, setActiveStep }: StepDesignProps) {
  const [initialValues, setInitialValues] = useState<TFormDesign>(formData.design);

  const {
    values, setValues, setFieldValue, handleChange, handleSubmit, setSubmitting, errors, touched,
  } = useFormik({
    initialValues: formInitialData.design,
    onSubmit: (values) => {
      setSubmitting(true);

      if (values) {
        handleChangeFormData({ design: values });
        notification.success('Saved');
      }

      setSubmitting(false);
    },
    validationSchema: Yup.object().shape({

    }),
  });

  useEffect(() => {
    setInitialValues(formData.design);
    setValues(formData.design);
  }, [formData]);

  return (
    <StepDesignStyles>
      <div className="designContent">
        <h3 className="textBold24">Customize your form</h3>

        <div className="settingsAndPreviewWrapper">
          <div className="settings">
            <span className="textSemiBold22">Settings</span>

            <div className="block">
              <span className="textSemiBold18 blockTitle">Page</span>

              <div className="gridContainer">
                <ColorPicker label="Background:" value={values.page.background_color} setValue={(color) => setFieldValue('page.background_color', color)} />
              </div>
            </div>

            <div className="splitter" />

            <div className="block">
              <span className="textSemiBold18 blockTitle">Form</span>

              <div className="gridContainer">
                <ColorPicker label="Background:" value={values.form.background_color} setValue={(color) => setFieldValue('form.background_color', color)} />
              </div>

              <div className="gridContainer">
                <NumberInput min={0} max={10000} name="form.border_radius" label="Border radius:" value={values.form.border_radius} handleChange={handleChange} />
              </div>
            </div>

            <div className="splitter" />

            <div className="block">
              <span className="textSemiBold18 blockTitle">Image</span>

              <DropZone
                handleDelete={() => setFieldValue('image', { ...values.image, url: null })}
                previewImageUrl={(values.image && values.image.url) || null}
                onFileChanged={(file) => setFieldValue('image', { ...values.image, url: file.url })}
              />

              <div className="gridContainer">
                <NumberInput min={0} max={10000} name="image.border_radius" label="Border radius:" value={values.image.border_radius} handleChange={handleChange} />
              </div>
            </div>

            <div className="splitter" />

            <div className="block">
              <span className="textSemiBold18 blockTitle">Title</span>

              <div className="gridContainer">
                <ColorPicker value={values.title.color} setValue={(color) => setFieldValue('title.color', color)} />
              </div>
            </div>

            <div className="splitter" />

            <div className="block">
              <span className="textSemiBold18 blockTitle">Description</span>

              <div className="gridContainer">
                <ColorPicker value={values.description.color} setValue={(color) => setFieldValue('description.color', color)} />
              </div>
            </div>

            <div className="splitter" />

            <div className="block">
              <span className="textSemiBold18 blockTitle">Inputs</span>

              <div className="gridContainer">
                <ColorPicker label="Label:" value={values.inputs.label_color} setValue={(color) => setFieldValue('inputs.label_color', color)} />
                <ColorPicker label="Placeholder:" value={values.inputs.placeholder_color} setValue={(color) => setFieldValue('inputs.placeholder_color', color)} />
                <ColorPicker label="Entered text color:" value={values.inputs.text_color} setValue={(color) => setFieldValue('inputs.text_color', color)} />

                <div />
                <ColorPicker label="Border color:" value={values.inputs.border_color} setValue={(color) => setFieldValue('inputs.border_color', color)} />
                <ColorPicker label="Background color:" value={values.inputs.background_color} setValue={(color) => setFieldValue('inputs.background_color', color)} />
              </div>

              <div className="gridContainer">
                <NumberInput min={0} max={10000} name="inputs.border_radius" label="Border radius:" value={values.inputs.border_radius} handleChange={handleChange} />
              </div>
            </div>

            <div className="splitter" />

            <div className="block">
              <span className="textSemiBold18 blockTitle">Button</span>

              <div className="gridContainer">
                <ColorPicker label="Background color:" value={values.submit_button.background_color} setValue={(color) => setFieldValue('submit_button.background_color', color)} />
                <ColorPicker label="Text color:" value={values.submit_button.text_color} setValue={(color) => setFieldValue('submit_button.text_color', color)} />
              </div>

              <div className="gridContainer">
                <NumberInput min={0} max={10000} name="submit_button.border_radius" label="Border radius:" value={values.submit_button.border_radius} handleChange={handleChange} />
              </div>
            </div>
          </div>
          <div className="preview">
            <FormPreview formData={formData} formDesign={values} />
          </div>
        </div>
      </div>

      <TextButton
        text="Save"
        // icon={(
        //   <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
        //     <path d="M6.5 12L10.5 8L6.5 4" stroke="#FC812B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        //   </svg>
        // )}
        onClick={handleSubmit}
        type="button"
        theme={Object.values(touched).length > 0 && Object.values(errors).length > 0 ? 'red' : 'mainNotFilled'}
      />
    </StepDesignStyles>
  );
}

export default StepDesign;
