import React, { useEffect, useMemo, useState } from 'react';
import { TApiStatus } from '../../../enteties/types/statuses.types';
import { TApiForm } from '../../../enteties/types/forms.types';
import { useIsFirstRender, useWindowSize } from 'usehooks-ts';
import { useSearchParams } from 'react-router-dom';
import { TApiLead } from '../../../enteties/types/lead.types';
import Pagination from '../../table/pagination/Pagination';
import LeadsTableVersionTable from './ui/leadsTableVersionTable/LeadsTableVersionTable';
import LeadsTableVersionMobile from './ui/leadsTableVersionMobile/LeadsTableVersionMobile';

interface Props {
  statuses: TApiStatus[];
  forms: TApiForm[];
  leads: TApiLead[];
  onLeadDeleted(id: number): void;
}

export interface LeadsTableVersionFilters {
  form: number | undefined;
  statuses: number[];
}

function LeadsTableVersion({
  forms, statuses, leads, onLeadDeleted,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { width } = useWindowSize();
  const isFirstRender = useIsFirstRender();

  const [filters, setFilters] = useState<LeadsTableVersionFilters>(() => {
    const formId = searchParams.get('tableForm');
    const statusesIds = searchParams.get('tableStatuses');
    const updatedFilters: LeadsTableVersionFilters = {
      form: formId ? +formId : undefined,
      statuses: statusesIds ? statusesIds.split(',').map((id) => +id) : [],
    };

    return updatedFilters;
  });

  const [elementsPerPage, setElementsPerPage] = useState<number>(10);
  const [elementsCount, setElementsCount] = useState<number>(0);
  const [activePage, setActivePage] = useState<number>(() => {
    const page = searchParams.get('page');

    if (page) {
      return +page - 1;
    }

    return 0;
  });

  const formsBook: {[key: string]: TApiForm} = useMemo(() => forms.reduce((acc, form) => ({ ...acc, [form.id]: form }), {}), [forms]);
  const statusesBook: {[key: string]: TApiStatus} = useMemo(() => statuses.reduce((acc, status) => ({ ...acc, [status.id]: status }), {}), [statuses]);

  const screenSize = useMemo(() => (width >= 1024 ? 'desktop' : 'mobile'), [width]);

  useEffect(() => {
    const page = searchParams.get('page');

    if (page && +page !== activePage && Number.isNaN(+page)) {
      setActivePage(+page);
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(searchParams);

    params.set('page', String(activePage + 1));

    setSearchParams(params);
    window.history.pushState({}, '', `${window.location.pathname}?${params.toString()}`);
  }, [activePage]);

  useEffect(() => {
    if (!isFirstRender) {
      setActivePage(0);
    }
  }, [filters]);

  const filteredLeads = useMemo(() => {
    const leadsByFiltersParams = leads.filter((lead) => {
      if (filters.form && filters.form !== lead.form_id) return false;
      if (filters.statuses.length > 0 && !filters.statuses.includes(lead.status_id)) return false;

      return true;
    }, []);

    setElementsCount(leadsByFiltersParams.length);
    return leadsByFiltersParams.slice(activePage * elementsPerPage, (activePage + 1) * elementsPerPage);
  }, [leads, filters]);

  return (
    <div>
      <div className="filtersWrapper">
        {screenSize === 'mobile' && (
          <LeadsTableVersionMobile
            leads={filteredLeads}
            statusesBook={statusesBook}
            formsBook={formsBook}
            statuses={statuses}
            forms={forms}
            filters={filters}
            onLeadDeleted={onLeadDeleted}
            setFilters={setFilters}
          />
        )}

        {screenSize === 'desktop' && (
          <LeadsTableVersionTable
            leads={filteredLeads}
            statusesBook={statusesBook}
            formsBook={formsBook}
            statuses={statuses}
            forms={forms}
            onLeadDeleted={onLeadDeleted}
            setFilters={setFilters}
          />
        )}

        <Pagination
          activePage={activePage}
          total={elementsCount}
          setActivePage={(pageNumber) => setActivePage(pageNumber)}
        />
      </div>
    </div>
  );
}

export default LeadsTableVersion;
