export default (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_862_10745)">
      <path d="M13.8584 24V13.0533H17.5313L18.0824 8.78588H13.8584V6.06176C13.8584 4.82664 14.2 3.98492 15.9732 3.98492L18.231 3.98399V0.167076C17.8406 0.116334 16.5002 0 14.9403 0C11.6827 0 9.45258 1.98836 9.45258 5.63912V8.78588H5.76855V13.0533H9.45258V24H13.8584Z" fill="#005273" />
    </g>
    <defs>
      <clipPath id="clip0_862_10745">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
