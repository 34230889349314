import React, {
  useCallback, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { TApiStatus } from '../../enteties/types/statuses.types';
import workWithResponse from '../../helpers/workWithResponse';
import { Api } from '../../api';
import StatusesSelect from '../../components/leads/statusesSelect/StatusesSelect';
import { LeadsStyles } from './LeadsStyles';
import { TApiField, TApiForm } from '../../enteties/types/forms.types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ActiveTabIcon } from '../../assets/icons/leads/ActiveTabIcon';
import classNames from 'classnames';
import LeadsTableVersion from '../../components/leads/tableVersion/LeadsTableVersion';
import { TApiLead } from '../../enteties/types/lead.types';
import Loader from '../../components/loader/Loader';
import LeadsStickersVersion from '../../components/leads/sticketsVersion/LeadsStickersVersion';
import { useAppSelector } from '../../state/hooks';

async function getStatuses(setDataLoadingError: () => void, userID: number): Promise<Awaited<TApiStatus[]>> {
  return workWithResponse(() => Api.getUserStatuses({ userID })).then((res) => {
    if (res.data) {
      return res.data.data;
    }
    setDataLoadingError();
    return [];
  });
}

async function getForms(setDataLoadingError: () => void, userID: number): Promise<Awaited<TApiForm[]>> {
  return workWithResponse(() => Api.getUserForms({ userID, limit: 10000, offset: 0 })).then((res) => {
    if (res.data) {
      return res.data.data;
    }
    setDataLoadingError();
    return [];
  });
}

async function getLeads(setDataLoadingError: () => void, userID: number): Promise<Awaited<{data: TApiLead[], count: number }>> {
  // const { data: user } = useAppSelector((state) => state.user);
  return workWithResponse(() => Api.getUserLeads({ userID, limit: 10000, offset: 0 })).then((res) => {
    if (res.data) {
      return res.data;
    }
    setDataLoadingError();
    return { data: [], count: 0 };
  });
}

function Leads() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: user } = useAppSelector((state) => state.user);

  const [statuses, setStatuses] = useState<TApiStatus[]>([]);
  const [forms, setForms] = useState<TApiForm[]>([]);
  const [leads, setLeads] = useState<TApiLead[]>([]);
  const [formFields, setFormFields] = useState<{[key: string]: TApiField}>({});
  const [pageVersion, setPageVersion] = useState<'table' | 'stickers'>(() => {
    const pageVersionFromSearchParams = searchParams.get('pageVersion');

    if (pageVersionFromSearchParams && (pageVersionFromSearchParams === 'table' || pageVersionFromSearchParams === 'stickers')) {
      return pageVersionFromSearchParams;
    }

    return 'table';
  });
  const [dataLoadingError, setDataLoadingError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formFieldsRef = useRef<{[key: string]: TApiField} | null>(null);

  const onLeadDeleted = useCallback((deletedLeadId: number) => {
    setLeads((stateLeads) => stateLeads.filter(({ id }) => id !== deletedLeadId));
  }, [leads]);

  const onLeadChanged = useCallback((changedLead: TApiLead) => {
    setLeads((stateLeads) => stateLeads.map((lead) => (lead.id === changedLead.id ? changedLead : lead)));
  }, [leads]);

  useEffect(() => {
    async function fetchData() {
      try {
        const statuses = await getStatuses(() => setDataLoadingError(true), user!.id).then((res) => res);
        const forms = await getForms(() => setDataLoadingError(true), user!.id).then((res) => res);
        const { data: leadsDataFromServer, count: leadsCountFromServer } = await getLeads(() => setDataLoadingError(true), user!.id).then((res) => res);

        setLeads(leadsDataFromServer);
        setStatuses(statuses);
        setForms(forms);
      } catch (error) {
        setDataLoadingError(true);
      } finally {
        setIsLoading(false);
      }
    }

    if (user) {
      setIsLoading(true);

      fetchData();
    }
  }, [user?.id]);

  useEffect(() => {
    async function getNewFormFields() {
      const newFormFields: {[key: string]: TApiField} = {};

      const uniqueFieldIdsToFetch = new Set<number>();

      leads.forEach((lead) => {
        lead.answers.forEach((answer) => {
          const fieldId = answer.field_id;

          if ((!formFieldsRef.current || !formFieldsRef.current[fieldId]) && !newFormFields[fieldId]) {
            uniqueFieldIdsToFetch.add(fieldId);
          }
        });
      });

      await Promise.all(Array.from(uniqueFieldIdsToFetch).map(async (fieldId) => {
        try {
          const response = await Api.getFormField(fieldId);
          newFormFields[fieldId] = response.data;
        } catch (error) {
          console.error(`Error fetching field ${fieldId}:`, error);
        }
      }));

      setFormFields((prevFields) => ({ ...prevFields, ...newFormFields }));
      formFieldsRef.current = { ...formFieldsRef.current, ...newFormFields };
    }
    if (pageVersion === 'stickers') {
      getNewFormFields();
    }
  }, [pageVersion, leads]);

  useEffect(() => {
    if (searchParams.get('pageVersion') !== pageVersion) {
      const params = new URLSearchParams(searchParams);

      window.history.pushState({}, '', `${window.location.pathname}?${params.toString()}`);
    }
  }, [pageVersion]);

  useEffect(() => {
    const params = new URLSearchParams(searchParams);

    if (params.toString().length > 0) {
      window.history.pushState({}, '', `${window.location.pathname}?${params.toString()}`);
    } else {
      window.history.pushState({}, '', `${window.location.pathname}`);
    }
  }, [searchParams]);

  const handleUpdateStatuses = useCallback((updatedStatuses: TApiStatus[]) => {
    setStatuses(updatedStatuses);
  }, []);

  function handleChangePageVersion(version: 'table' | 'stickers') {
    setPageVersion(version);
    const params = new URLSearchParams(searchParams);

    params.set('pageVersion', version);

    setSearchParams(params);
  }

  return (
    <LeadsStyles>
      {isLoading && <Loader />}

      {!isLoading && leads && statuses && forms && (
        <>
          <div className="head">
            <h1 className="textBold28">Leads</h1>

            <div className="settings">
              <StatusesSelect
                statuses={statuses}
                updateStatuses={handleUpdateStatuses}
              />

              <div className="activeTabHandleButtonsWrapper">
                <button type="button" className={classNames('tableVersionButton', { active: pageVersion === 'table' })} onClick={() => handleChangePageVersion('table')}>
                  {ActiveTabIcon}
                </button>

                <button type="button" className={classNames('stickersVersionButton', { active: pageVersion === 'stickers' })} onClick={() => handleChangePageVersion('stickers')}>
                  {ActiveTabIcon}
                </button>
              </div>
            </div>
          </div>

          {pageVersion === 'table' && (
            <LeadsTableVersion
              forms={forms}
              statuses={statuses}
              leads={leads}
              onLeadDeleted={onLeadDeleted}
            />
          )}

          {pageVersion === 'stickers' && formFieldsRef.current && (
            <LeadsStickersVersion
              forms={forms}
              statuses={statuses}
              leads={leads}
              formFields={formFields}
              onLeadChanged={onLeadChanged}
            />
          )}
        </>
      )}

    </LeadsStyles>
  );
}

export default Leads;
