import React, { useMemo } from 'react';

import { TApiLead } from '../../../../../enteties/types/lead.types';
import { Link } from 'react-router-dom';
import { useLeadsStickersVersionContext } from '../../context/LeadsStickersVersionProvider';
import { format } from 'date-fns';

interface Props {
  lead: TApiLead;
}

function LeadItem({ lead }: Props) {
  const { formFields, formsBook } = useLeadsStickersVersionContext();

  const requiredQuestion = useMemo(() => (
    lead.answers.filter((answer) => formFields[answer.field_id] && formFields[answer.field_id].validation.required)
  ), [lead, formFields]);

  return (
    <Link target="_blank" to={`/leads/${lead.id}`}>
      {formsBook[lead.form_id] && (
        <div className="block textSemiBold16">
          Form -
          {' '}
          {formsBook[lead.form_id].name}
        </div>
      )}

      {requiredQuestion.length === 0 && <div className="block textBase16">{format(new Date(lead.created_at), 'dd/LL/Y h:mm a')}</div>}
      {requiredQuestion.map((answer) => <div className="block textBase16">{answer.value}</div>)}
    </Link>
  );
}

export default LeadItem;
