import React from 'react';
import { ButtonDesignProps, TFormSubmitButton } from '../../../../../enteties/types/forms.types';
import { PreviewButtonStyles } from './PreviewButtonStyles';

interface Props {
  styles: ButtonDesignProps;
  buttonData: TFormSubmitButton;
}

function PreviewButton({ styles, buttonData }: Props) {
  return (
    <PreviewButtonStyles className="textSemiBold16" styles={styles} type="button">
      {buttonData.text}
    </PreviewButtonStyles>
  );
}

export default PreviewButton;
