import styled from 'styled-components';

export const UTMStyles = styled.div`
  .openButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    width: 100%;
    
    margin-bottom: 16px;
    
    overflow: hidden;
    
    &.open {
      svg {
        transform: rotate(0);
      }
    }

    svg {
      transform: rotate(180deg);
      
      width: 24px;
      height: 24px;
      transition: .3s ease transform;
    }
  }
  
  .utmFields {
    display: flex;
    flex-direction: column;
    gap: 12px;
    
    max-height: 0;
    overflow: hidden;
    
    transition: .1s max-height, .3s ease opacity;
    opacity: 0;
    
    &.open {
      max-height: 222px;
      opacity: 1;
    }
    
    .utmFieldWrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      
      .value {
        padding: 10px 8px;

        border-radius: 4px;
        border: 1px solid var(--Gray-Light, #C2C9D1);
        background: var(--White-Default, #FFF);
        color: var(--Black-Light, rgba(0, 0, 0, 0.50));
        
        &.withValue {
          color: #000;
        }
      }
    }
  }
`;
