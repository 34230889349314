import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import CreateProperty from '../create/CreateProperty';
import { TApiProperty, TProperty } from '../../../../enteties/types/formProperties.types';
import workWithResponse from '../../../../helpers/workWithResponse';
import { Api } from '../../../../api';
import { notification } from '../../../../helpers/notifications/toastify';
import { unexpectedErrorMessage } from '../../../../constants/constants';
import Loader from '../../../loader/Loader';
import Property from '../property/Property';
import { PropertyStyles } from './PropertiesListStyles';

function PropertiesList({ formId }: {formId: number}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [properties, setProperties] = useState<TProperty[] | null>(null);

  async function getProperties() {
    try {
      setIsLoading(true);

      workWithResponse(() => Api.getFormProperties(formId)).then((res) => {
        if (res.data) {
          setProperties(res.data.data);

          return;
        }

        if (res.error) {
          // handleDataLoadingError();
          notification.error(res.error);
        }
      });
    } catch (e) {
      // handleDataLoadingError();*/
      notification.error(unexpectedErrorMessage);
    } finally {
      setIsLoading(false);
    }
  }

  const propertiesCount = useMemo(() => properties?.length || 0, [properties]);

  const handleAddProperty = useCallback((createdProperty: TProperty) => (properties ? setProperties([createdProperty, ...properties]) : null), [properties]);
  const handleDeleteProperty = useCallback((id: number) => (properties ? setProperties(properties.filter((property) => property.id !== id)) : null), [properties]);
  const handleUpdateProperty = useCallback((updatedProperty: TProperty) => {
    if (properties) {
      setProperties(properties.map((property) => (property.id === updatedProperty.id ? updatedProperty : property)));
    }
  }, [properties]);

  useEffect(() => {
    getProperties();
  }, []);

  return (
    <PropertyStyles className="properties">
      {isLoading && <Loader size={30} />}
      {properties && !isLoading && (
        <>
          <CreateProperty formID={formId} propertiesCount={propertiesCount} onPropertyCreated={handleAddProperty} />

          <ul>
            {properties.map((property) => <Property property={property} onPropertyDeleted={(id) => handleDeleteProperty(id)} onPropertyChanged={(property) => handleUpdateProperty(property)} />)}
          </ul>
        </>
      )}
    </PropertyStyles>
  );
}

export default PropertiesList;
