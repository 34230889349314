import { Api } from '../api';
import { NavigateFunction } from 'react-router-dom';

export async function logout(actionLogout: () => void, navigate: NavigateFunction) {
  Api.logout();

  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token_expired_at');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('refresh_token_expired_at');

  actionLogout();

  navigate('/');
}
