import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputField from '../../../components/form/input/InputField';
import { PasswordRecoveryStyles } from './PasswordRecoveryStyles';
import TextButton from '../../../components/buttons/button/TextButton';
import Logo from '../../../assets/Logo';
import { useAppDispatch } from '../../../state/hooks';
import PlayIcon from '../../../assets/icons/authentefication/PlayIcon';

import 'react-toastify/dist/ReactToastify.css';
import { RecoveryPasswordData } from '../../../enteties/types/auth.types';
import Loader from '../../../components/loader/Loader';
import workWithResponse from '../../../helpers/workWithResponse';
import { Api } from '../../../api';
import { notification } from '../../../helpers/notifications/toastify';

function PasswordRecovery() {
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string | null>(() => {
    if (searchParams.get('token')) {
      return searchParams.get('token');
    }

    return null;
  });

  const {
    handleChange, handleSubmit, handleBlur, setValues, values, errors, touched, isSubmitting, setSubmitting, setFieldValue,
  } = useFormik<RecoveryPasswordData>({
    initialValues: {
      password_confirmation: '',
      password: '',
      token: '',
    },
    onSubmit: async (values) => {
      if (values) {
        try {
          await workWithResponse(() => Api.passwordRecoveryChangePassword(values)).then((res) => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
              setSubmitting(false);
              navigate('/?password=changed');
            } else if (res.error) {
              notification.error(res.error);
              navigate('/404');
            }
          });
        } catch (e: any) {
          notification.error(e.message);
        } finally {
          setSubmitting(false);
        }
      }
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required('Required field'),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref('password'), undefined], 'Passwords don\'t match')
        .required('Required field'),
    }),
  });

  useEffect(() => {
    async function checkToken() {
      try {
        if (!token) {
          throw new Error('Token not set');
        }

        await workWithResponse(() => Api.passwordRecoveryCheckToken({ token })).then((res) => {
          if (res.statusCode >= 200 && res.statusCode < 300) {
            setFieldValue('token', token);
            setIsLoading(false);
            return;
          }

          throw new Error('Invalid token');
        });
      } catch (e: any) {
        notification.error(e);
        navigate('/404');
      } finally {
        setIsLoading(false);
      }
    }

    checkToken();
  }, []);

  return (
    <PasswordRecoveryStyles>
      <div className="content">
        {isLoading ? <Loader /> : (
          <>
            <Link to="/"><Logo /></Link>

            <div className="formWrapper">
              <div className="formFieldsWrapper">
                <h1 className="textBold28">Password recovery</h1>

                <div className="fieldsWrapper">
                  <InputField
                    label="Enter a new password"
                    placeholder="New password"
                    name="password"
                    type="password"
                    onChange={handleChange}
                    value={values.password}
                    autoComplete="current-password"
                    showPasswordButton
                    error={touched && touched.password && errors && errors.password ? errors.password : undefined}
                  />

                  <InputField
                    label="Confirm new password"
                    placeholder="New password"
                    name="password_confirmation"
                    type="password"
                    onChange={handleChange}
                    value={values.password_confirmation}
                    autoComplete="current-password"
                    showPasswordButton
                    error={touched && touched.password_confirmation && errors && errors.password_confirmation ? errors.password_confirmation : undefined}
                  />
                </div>
              </div>

              <div className="buttonsWrapper">
                <TextButton
                  text="Set new password"
                  onClick={handleSubmit}
                  fullWidth
                  theme="mainFilled"
                />
              </div>

              <div className="signInWrapper">
                <Link className="textSemiBold16 animationUnderline" to="/">
                  Return to Sign in
                </Link>
              </div>
            </div>

            <div />
          </>
        )}
      </div>

      <div className="rightSide">
        <div className="sloganContainer">
          <PlayIcon />

          <p className="textBold28">One-stop platform to boost your digital marketing</p>
        </div>
      </div>
    </PasswordRecoveryStyles>
  );
}

export default PasswordRecovery;
