import React, { ChangeEvent } from 'react';
import { TextAreaStyles } from './TextAreaStyles';
import ErrorIcon from '../../../assets/icons/formElements/ErrorIcon';
import { ErrorMessage, useFormik, useFormikContext } from 'formik';
import { get } from 'lodash';
import FormErrorContainer from '../formErrorContainer/FormErrorContainer';

interface TextareaProps {
  name: string;
  value: string;
  onChange: any;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  label?: string;
  maxRows?: number;
  maxCols?: number;
  error?: string;
  className?: string;
  disabled?: boolean;
  resize?: boolean;
  textAreaId?: string;
}

const Textarea: React.FC<TextareaProps> = ({
  name,
  value,
  onChange: handleChange,
  onKeyDown,
  placeholder,
  label,
  maxRows,
  maxCols,
  error,
  className,
  disabled,
  resize = false,
  textAreaId,
}) => {
  const formik = useFormikContext<any>();
  const textareaId = label ? `${label.toLowerCase().replace(/ /g, '-')}-textarea` : textAreaId || '';

  return (
    <TextAreaStyles className={className}>
      {label && <label className="textSemiBold12 colorSecondary8" htmlFor={textareaId}>{label}</label>}
      <textarea
        name={name}
        id={textareaId}
        value={value}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        rows={maxRows}
        cols={maxCols}
        disabled={disabled}
        className={error ? 'error' : disabled ? 'disabled' : ''}
        // style={{ width: '100%', height: '180px' }}
      />
      <div className="formErrorContainer">
        {error && (
          <>
            <ErrorIcon />
            <span className="textBase12">{error}</span>
          </>
        )}
        {!error && formik && get(formik.touched, name) && get(formik.errors, name) && <ErrorMessage name={name} component={FormErrorContainer} />}
      </div>
    </TextAreaStyles>
  );
};

export default Textarea;
