import React from 'react';
import { LeadsListStyles } from './LeadsListStyles';
import { TApiLead } from '../../../../../enteties/types/lead.types';
import { FormFieldsTypes, TApiForm } from '../../../../../enteties/types/forms.types';
import { TApiStatus } from '../../../../../enteties/types/statuses.types';
import Menu from '../menu/Menu';
import { format } from 'date-fns';

interface Props {
  leads: TApiLead[];
  formsBook: {[key: string]: TApiForm};
  statusesBook: {[key: string]: TApiStatus};
  forms: TApiForm[];
  onLeadDeleted(id: number): void;
}

function LeadsList({
  leads, formsBook, statusesBook, forms, onLeadDeleted,
}: Props) {
  return (
    <LeadsListStyles>
      {leads.map((lead) => (
        <li key={lead.id}>
          <div className="leadListItemHead">
            <div className="menuAndTitleWrapper">
              <span className="textSemiBold16">Form name</span>

              <Menu
                leadId={lead.id}
                onLeadDeleted={onLeadDeleted}
              />
            </div>

            <span className="textBase16">{formsBook[lead.form_id].name}</span>
          </div>

          <div className="listItemBlock">
            <span className="textSemiBold16">
              Email
            </span>

            <span className="textBase16">
              {lead.answers.find((answer) => answer.type === FormFieldsTypes.email)?.value || 'No data'}
            </span>
          </div>

          <div className="listItemBlock" style={{ backgroundColor: statusesBook[lead.status_id].color }}>
            <span className="textSemiBold16">
              Status
            </span>

            <span className="textBase16">
              {statusesBook[lead.status_id].name}
            </span>
          </div>

          <div className="listItemBlock">
            <span className="textSemiBold16">
              Date
            </span>

            <span className="textBase16">
              {format(new Date(lead.created_at), 'dd/LL/Y')}
            </span>
          </div>

          <div className="listItemBlock">
            <span className="textSemiBold16">
              Time
            </span>

            <span className="textBase16">
              {format(new Date(lead.created_at), 'h:mm a')}
            </span>
          </div>
        </li>
      ))}
    </LeadsListStyles>
  );
}

export default LeadsList;
