import styled from 'styled-components';

export const CommentsListStyles = styled.ul`
  display: flex;
  flex-direction: column;
  
  li {
    position: relative;
    
    padding: 8px 0;
    
    &:not(:last-child) {
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        
        width: 100%;
        height: 1px;
        background: #E0E0E0;
      }
    }
    
    .commentHead {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      
      margin-bottom: 4px;
      
      .userContainer {
        display: flex;
        align-items: center;
        gap: 14px;
      }
    }
  }
`;
