import styled from 'styled-components';

export const CreatePropertyStyles = styled.div`
  position: relative;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  .addButton {
    display: flex;
    align-items: center;
    gap: 6px;
    
    color: var(--Orange-1, #FC812B);
    
    transition: .3s ease color;

    &:hover {
      color: var(--orange-hover);

      svg {
        path {
          stroke: var(--orange-hover);
        }
      }
    }

    svg {
      path {
        transition: .3s ease stroke;
      }
    }
  }

  .tooltipIcon {
    display: flex;
    align-items: center;
  }
  
  .dropdown {
    left: 0;
    
    padding: 8px;
  }
  
  .selectTypeDropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    
    button {
      display: flex;
      align-items: center;
      gap: 6px;
      
      padding: 4px 5px;
      
      width: 100%;
    }
  }
`;
