import styled from 'styled-components';

export const HeaderStyles = styled.header`
  z-index: 1;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  
  .userMenuButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #2A7BCA;
    color: #2A7BCA;
    border-radius: 100%;
    padding: 4px 5px;
  }
  
  .logoContainer {
    display: flex;
    align-items: center;
    gap: 28px;
    
    .menuButton {
      @media (min-width: 1201px) {
        display: none;
      } 
    }
  }

  .userMenuButtonWrapper {
    position: relative;
    
    ul {
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      gap: 8px;

      li {
        color: var(--grey-8, #1C1C1C);
        
        .logout {
          color: #F5222D;
        }

        button, a {
          display: flex;
          align-items: center;
          gap: 8px;

          color: var(--grey-8, #1C1C1C);
        }
      }
    }
  }
`;
