import React, { useCallback, useEffect, useState } from 'react';
import { MobileFiltersStyles } from '../../../leads/filters/MobileFiltersStyles';
import classNames from 'classnames';
import { ArrowLeft } from '../../../../assets/icons/other/ArrowLeft';
import CheckMark from '../../../../assets/icons/other/CheckMark';
import { useSearchParams } from 'react-router-dom';
import TextButton from '../../../buttons/button/TextButton';

interface FiltersMobileSelectProps {
  multipleChoice: boolean;
  open: boolean;
  options: any[];
  nameKey: string;
  valueKey: string;
  searchParamsKey: string;
  handleClose: () => void;
}

function FiltersMobileSelect({
  multipleChoice, open, options, nameKey, valueKey, searchParamsKey, handleClose,
}: FiltersMobileSelectProps) {
  const [stateValue, setStateValue] = useState<number | number[] | undefined>(undefined);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const values = searchParams.get(searchParamsKey);

    if (multipleChoice) {
      setStateValue(values && values.length > 0 ? values.split(',').map((id) => +id) : []);
    }
  }, [open]);

  function handleChangeValue(value: number) {
    if (multipleChoice && stateValue && Array.isArray(stateValue)) {
      return setStateValue(stateValue.includes(value) ? stateValue.filter((stateValueItem) => stateValueItem !== value) : [...stateValue, value]);
    }

    if (value === stateValue) {
      setStateValue(undefined);
    }

    setStateValue(value);
  }

  const handleApplyFilters = useCallback(() => {
    const params = new URLSearchParams(searchParams);

    if (multipleChoice && Array.isArray(stateValue)) {
      if (stateValue.length > 0) {
        params.set(searchParamsKey, stateValue.join(','));
      }

      if (stateValue.length === 0 && searchParams.get(searchParamsKey)) {
        params.delete(searchParamsKey);
      }
    } else {
      if (stateValue) {
        params.set(searchParamsKey, String(stateValue));
      }

      if (!stateValue && searchParams.get(searchParamsKey)) {
        params.delete(searchParamsKey);
      }
    }

    setSearchParams(params);
    handleClose();
  }, [stateValue, searchParams]);

  return (
    <MobileFiltersStyles className={classNames({ open })}>
      <div className="filtersHead">
        <button type="button" className="closeButton" onClick={handleClose}>
          {ArrowLeft}
        </button>

        <span className="textSemiBold18">Filters</span>

        <div style={{ width: 24 }} />
        <button type="button" className="clearButton textSemiBold16" onClick={() => setStateValue(multipleChoice ? [] : undefined)}>
          Clear
        </button>
      </div>

      <div className="options">
        {options.map((option) => (
          <button className="textSemiBold18 withArrowAnimation" type="button" onClick={() => handleChangeValue(option[valueKey])}>
            {option[nameKey]}

            {multipleChoice && Array.isArray(stateValue) && stateValue.includes(option[valueKey]) && <CheckMark />}
            {!multipleChoice && stateValue === option[valueKey] && <CheckMark />}
          </button>
        ))}
      </div>

      <TextButton
        onClick={handleApplyFilters}
        text="Apply"
        extraClasses="applyButton"
        theme="mainFilled"
      />
    </MobileFiltersStyles>
  );
}

export default FiltersMobileSelect;
