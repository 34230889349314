import { useAppDispatch } from '../state/hooks';
import { useMemo } from 'react';
import { bindActionCreators } from 'redux';

import { actions as userSliceActions } from '../state/slices/userSlice';

const actions = {
  ...userSliceActions,
};

const rootActions = {
  ...actions,
};

export const useActions = () => {
  const dispatch = useAppDispatch();

  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch]);
};
