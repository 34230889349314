import React from 'react';
import TextButton from '../../../components/buttons/button/TextButton';
import { useNavigate } from 'react-router-dom';
import ArrowLeftIcon from '../../../assets/icons/errors/ArrowLeftIcon';
import { ErrorStyles } from '../ErrorStyles';
import ErrorIcon from '../../../assets/icons/errors/500/ErrorIcon';

function Error500() {
  const navigate = useNavigate();

  return (
    <ErrorStyles>
      <div className="container">
        <div className="iconWrapper">
          <ErrorIcon />
        </div>

        <div className="textWrapper">
          <h1 className="textBold28">Internal Server Error</h1>
          <span className="textBase16">
            The server encountered an internal error or misconfiguration and was unable to complete your request.
          </span>
        </div>

        <div className="buttonsWrapper">
          <TextButton
            text="Go back"
            iconPosition="iconLeft"
            onClick={() => navigate(-2)}
            theme="mainNotFilled"
            icon={<ArrowLeftIcon />}
          />

          <TextButton
            text="Take me home"
            theme="mainFilled"
            onClick={() => navigate('/')}
          />
        </div>
      </div>
    </ErrorStyles>
  );
}

export default Error500;
