import React, { useRef, useState } from 'react';
import DropDown from '../../other/dropDown/DropDown';
import UserIcon from '../../../assets/icons/sidebar/UserIcon';
import MenuIcon from '../../../assets/icons/sidebar/MenuIcon';
import { useAppSelector } from '../../../state/hooks';
import { UserMenuStyles } from './UserMenuStyles';
import { useNavigate } from 'react-router-dom';
import SettingsIcon from '../../../assets/icons/sidebar/SettingsIcon';
import LogoutIcon from '../../../assets/icons/sidebar/LogoutIcon';
import { useActions } from '../../../hooks/useActions';
import { logout } from '../../../helpers/logout';

function UserMenu({ setSidebarOpen }: {setSidebarOpen(): void}) {
  const { data: user } = useAppSelector((state) => state.user);
  const actions = useActions();

  const navigate = useNavigate();

  const [isOpened, setIsOpened] = useState<boolean>(false);

  const userMenuRef = useRef<HTMLDivElement | null>(null);

  function handleIsOpened() {
    setIsOpened(!isOpened);
  }

  return (
    <UserMenuStyles className="userMenuWrapper" ref={userMenuRef}>
      <DropDown
        isOpened={isOpened}
        onClose={handleIsOpened}
        exclude={userMenuRef}
      >
        <button
          className="settings textSemiBold12"
          type="button"
          onClick={() => {
            navigate('/profile');
            setSidebarOpen();
            setIsOpened(false);
          }}
        >
          {SettingsIcon}

          Settings
        </button>

        <button className="logout textSemiBold12" type="button" onClick={() => logout(() => actions.changeUser(null), navigate)}>
          {LogoutIcon}

          Log out
        </button>
      </DropDown>

      <button type="button" onClick={handleIsOpened} className="userMenuButton">
        <div className="userInfo">
          <UserIcon />

          <span className="textSemiBold16" title={user?.name}>{user?.name}</span>
        </div>

        <div className="menu">
          <MenuIcon />
        </div>
      </button>
    </UserMenuStyles>
  );
}

export default UserMenu;
