import React, { useState } from 'react';
import Modal from '../../modal/Modal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import workWithResponse from '../../../helpers/workWithResponse';
import { Api } from '../../../api';
import { notification } from '../../../helpers/notifications/toastify';
import { useActions } from '../../../hooks/useActions';
import TextButton from '../../buttons/button/TextButton';
import InputField from '../../form/input/InputField';
import { useNavigate } from 'react-router-dom';

type Props = {
  id: number,

  onClose: () => void,
}

function ModalDelete({ id, onClose }: Props) {
  const [deleted, setDeleted] = useState<boolean>(false);

  const actions = useActions();
  const navigate = useNavigate();

  const {
    handleChange, handleSubmit, handleBlur, setValues, values, errors, touched, isSubmitting,
  } = useFormik({
    initialValues: {
      password: '',
    },
    onSubmit: async (values) => {
      if (values) {
        await workWithResponse(() => Api.deleteAccount(id)).then((res) => {
          if (res.data) {
            setDeleted(true);
          } else if (res.error) {
            notification.error(res.error);
          }
        }).catch((e) => {
          console.error(e);

          notification.error('Oops something went wrong');
        });
      }
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required('Enter your password'),
    }),
  });

  function handleAfterDelete() {
    actions.changeUser(null);
    navigate('/');
  }

  return (
    <Modal onClose={deleted ? handleAfterDelete : onClose}>
      {!deleted ? (
        <div className="deleting">
          <div className="content">
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
              <path d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z" stroke="#F5222D" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M24 16V24" stroke="#F5222D" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M24 32H24.02" stroke="#F5222D" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

            <div className="textContainer">
              <h2 className="textBold28">Delete account?</h2>

              <span className="textBase16">
                This action is irreversible and all your data will be permanently removed. Please note that once deleted, you will not be able to recover your account or any associated information. To proceed with the account deletion, please enter your password below:
              </span>
            </div>

            <InputField
              placeholder="Enter password"
              label="Password"
              name="password"
              type="password"
              onChange={handleChange}
              value={values.password}
              autoComplete="current-password"
              showPasswordButton
              error={touched && touched.password && errors && errors.password ? errors.password : undefined}
              extraBlockStyles={{ width: '100%' }}
            />
          </div>

          <div className="buttonsContainer">
            <TextButton
              text="Delete"
              type="submit"
              theme="grey"
              fullWidth
              onClick={handleSubmit}
              title="Delete account"
            />

            <TextButton
              text="Cancel"
              type="button"
              theme="mainFilled"
              fullWidth
              onClick={onClose}
              title="Cancel"
            />
          </div>
        </div>
      ) : (
        <div className="afterDelete">
          <div className="content">
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
              <path d="M24 0C37.2458 0 48 10.7542 48 24C48 37.2458 37.2458 48 24 48C10.7542 48 0 37.2458 0 24C0 10.7542 10.7542 0 24 0ZM24 3.34884C12.6028 3.34884 3.34884 12.6028 3.34884 24C3.34884 35.3972 12.6028 44.6512 24 44.6512C35.3972 44.6512 44.6512 35.3972 44.6512 24C44.6512 12.6028 35.3972 3.34884 24 3.34884Z" fill="#00C8CB" />
              <path d="M34.268 31.9237C34.5351 32.2786 34.6501 32.7252 34.588 33.165C34.5258 33.6048 34.2914 34.0019 33.9365 34.269C33.5815 34.536 33.135 34.6511 32.6952 34.5889C32.2553 34.5268 31.8582 34.2924 31.5912 33.9374C29.8587 31.6312 27.1015 30.1399 24.0005 30.1399C20.8995 30.1399 18.1422 31.6312 16.4098 33.9374C16.1427 34.2924 15.7456 34.5268 15.3058 34.5889C14.866 34.6511 14.4194 34.536 14.0645 34.269C13.7095 34.0019 13.4752 33.6048 13.413 33.165C13.3508 32.7252 13.4659 32.2786 13.7329 31.9237C16.0771 28.807 19.8055 26.791 24.0005 26.791C28.1954 26.791 31.9238 28.807 34.268 31.9237Z" fill="#00C8CB" />
              <path d="M32.9298 20.6507C34.1628 20.6507 35.1624 19.6511 35.1624 18.4181C35.1624 17.1851 34.1628 16.1855 32.9298 16.1855C31.6968 16.1855 30.6973 17.1851 30.6973 18.4181C30.6973 19.6511 31.6968 20.6507 32.9298 20.6507Z" fill="#00C8CB" />
              <path d="M15.0695 20.6507C16.3025 20.6507 17.302 19.6511 17.302 18.4181C17.302 17.1851 16.3025 16.1855 15.0695 16.1855C13.8365 16.1855 12.8369 17.1851 12.8369 18.4181C12.8369 19.6511 13.8365 20.6507 15.0695 20.6507Z" fill="#00C8CB" />
            </svg>
            <div className="textContainer">
              <h2 className="textBold28">Your account has been deleted</h2>

              <span className="textBase16">We&#39;re sorry to see you go. If you change your mind in the future, you will need to create a new account. Thank you for being a part of our community.</span>
            </div>
          </div>

          <TextButton
            text="Go to the main page"
            theme="mainFilled"
            onClick={handleAfterDelete}
          />
        </div>
      )}
    </Modal>
  );
}

export default ModalDelete;
