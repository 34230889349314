import React from 'react';

function EmailConfirmedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="130"
      height="130"
      fill="none"
      viewBox="0 0 130 130"
    >
      <g fill="#00C8CB" clipPath="url(#clip0_828_3668)">
        <path d="M19.044 110.971a64.85 64.85 0 0020.66 13.935c8.012 3.391 16.525 5.108 25.296 5.108 8.77 0 17.284-1.717 25.296-5.108a64.616 64.616 0 0020.66-13.935 64.844 64.844 0 0013.936-20.66C128.283 82.297 130 73.784 130 65.013a64.97 64.97 0 00-3.305-20.503c-.701-2.132-3.005-3.276-5.137-2.575-2.131.701-3.276 3.005-2.575 5.136a56.77 56.77 0 012.89 17.942c0 15.195-5.923 29.474-16.668 40.219-10.745 10.745-25.025 16.669-40.22 16.669-15.194 0-29.473-5.924-40.218-16.669C14.036 94.488 8.112 80.195 8.112 65c0-15.195 5.924-29.474 16.669-40.219C35.526 14.036 49.805 8.112 65 8.112c9.944 0 19.73 2.604 28.286 7.526a4.053 4.053 0 005.537-1.502 4.054 4.054 0 00-1.502-5.537C87.535 2.976 76.361 0 65 0c-8.77 0-17.284 1.717-25.296 5.108a64.621 64.621 0 00-20.66 13.936 64.855 64.855 0 00-13.936 20.66C1.717 47.716 0 56.229 0 65c0 8.77 1.717 17.284 5.108 25.296a64.767 64.767 0 0013.936 20.675z" />
        <path d="M54.412 73.942s-7.726-4.907-17.04-12.433a4.544 4.544 0 00-6.482.801 4.533 4.533 0 00-.229 5.108c4.722 7.669 12.863 19.759 19.173 27.7 2.976 3.748 8.255 4.092 11.718.801 19.96-18.944 38.488-42.837 60.665-75.931 1.287-1.917.643-4.536-1.403-5.623a3.913 3.913 0 00-4.692.758c-24.61 26.069-37.23 38.445-61.71 58.82z" />
      </g>
      <defs>
        <clipPath id="clip0_828_3668">
          <path fill="#fff" d="M0 0H130V130H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EmailConfirmedIcon;
