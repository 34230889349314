import React, {
  CSSProperties, ReactElement, useMemo, useState,
} from 'react';
import { InputFieldStyles } from './InputFieldStyles';
import ErrorIcon from '../../../assets/icons/formElements/ErrorIcon';
import CrossedEyeIcon from '../../../assets/icons/formElements/CrossedEyeIcon';
import EyeIcon from '../../../assets/icons/formElements/EyeIcon';
import { Tooltip } from 'react-tooltip';
import TooltipIcon from '../../../assets/icons/other/TooltipIcon';
import 'react-tooltip/dist/react-tooltip.css';
import { useWindowSize } from 'usehooks-ts';
import { get } from 'lodash';
import { ErrorMessage, useFormikContext } from 'formik';
import FormErrorContainer from '../formErrorContainer/FormErrorContainer';

interface InputFieldProps {
  name: string;
  value: string | number | undefined;
  min?: number;
  max?: number;
  placeholder: string;
  // eslint-disable-next-line no-unused-vars
  onChange: any;
  error?: string | undefined;
  showError?: boolean;
  extraBlockStyles?: CSSProperties;
  label?: string;
  onKeyUp?: () => void;
  disabled?: boolean;
  type?: string;
  required?: boolean;
  children?: any;
  filledStyle?: boolean;
  replaceValueByStars?: boolean;
  autoComplete?: string | undefined;
  tooltip?: string;
  maxLength?: number;
  showPasswordButton?: boolean;
  extraClassName?: string;
  elementInInput?: ReactElement;
}

export default function InputField({
  name,
  placeholder,
  label,
  error,
  showError = true,
  value,
  min,
  max,
  onChange,
  onKeyUp,
  extraBlockStyles,
  disabled,
  type,
  required,
  children,
  filledStyle,
  replaceValueByStars,
  autoComplete,
  tooltip,
  maxLength,
  showPasswordButton = false,
  extraClassName,
  elementInInput,
}: InputFieldProps) {
  const [isFilled, setIsFilled] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const formik = useFormikContext<any>();
  const windowSize = useWindowSize();

  return (
    <InputFieldStyles className={extraClassName} style={extraBlockStyles}>
      <label htmlFor={name}>
        <div className="labelContainer">
          {label
            && (
              <span className="textSemiBold12 colorSecondary8 label">
                {label}
                {required && <span className="required"> *</span>}
              </span>
            )}

          {tooltip && (
            <a
              data-tooltip-id={name}
              data-tooltip-content={tooltip}
              data-tooltip-place="top"
            >
              <TooltipIcon />
            </a>
          )}

          {tooltip && (
            <Tooltip id={name} variant="info" style={{ zIndex: '3', maxWidth: '300px', whiteSpace: 'pre-wrap' }} openOnClick={windowSize.width < 1000} />
          )}
        </div>

        <div className="inputWrap">
          <div className="inputContainer">
            <input
              id={name}
              type={type === 'password' && showPassword ? 'text' : type || type || 'text'}
              min={type === 'number' ? min : undefined}
              max={type === 'number' ? max : undefined}
              maxLength={maxLength}
              value={value}
              onChange={onChange}
              autoComplete={autoComplete}
              onBlur={() => {
                if (filledStyle && value) {
                  // eslint-disable-next-line no-unused-expressions
                  value ? setIsFilled(true) : setIsFilled(false);
                }
              }}
              onKeyUp={onKeyUp}
              placeholder={placeholder}
              disabled={disabled}
              className={
                isFilled && filledStyle && error ? 'error filled'
                  : error ? 'error'
                    : disabled && filledStyle && isFilled ? 'disabled filled'
                      : filledStyle && isFilled ? 'filled'
                        : disabled ? 'disabled' : ''
              }
            />
            {type === 'password' && showPasswordButton && (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <button title={showPassword ? 'Hide symbols' : 'Show symbols'} type="button" className="showPasswordButton" onClick={() => (!disabled ? setShowPassword(!showPassword) : null)}>
                {showPassword ? <EyeIcon /> : <CrossedEyeIcon />}
              </button>
            )}
            {elementInInput && (
              <div className="buttonContainer">
                {elementInInput}
              </div>
            )}
          </div>
          <div className="childrenContainer">
            {children}
          </div>
        </div>

        {showError && (
          <div className="formErrorContainer">
            {error && (
              <>
                <ErrorIcon />
                <span className="textBase12">{error}</span>
              </>
            )}
            {/* {!error && touched && <ErrorMessage name={name} component={FormErrorMessage} />} */}
            {!error && formik && get(formik.touched, name) && get(formik.errors, name) && <ErrorMessage name={name} component={FormErrorContainer} />}
          </div>
        )}
      </label>
    </InputFieldStyles>
  );
}
