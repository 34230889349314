import styled from 'styled-components';

export const StepThankYouStyles = styled.div`
  padding-bottom: 60px;

  max-width: 1012px;
  
  .designContent {
    padding: 24px;
    margin-bottom: 24px;
    
    border-radius: 4px 4px 0px 0px;
    background: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
    
    @media (min-width: 768px) {
      padding: 32px;
    }

    @media (min-width: 768px) {
      padding: 40px;
    }
    
    h3 {
      margin-bottom: 28px;
      text-align: center;
    }
  }
  
  .settingsAndPreviewWrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;
    
    @media (min-width: 1200px) {
      grid-template-columns: 1fr 1.1fr;
    }
  }
  
  .settings {
    display: flex;
    flex-direction: column;
    gap: 24px;
    
    .splitter {
      height: 1px;
      width: 100%;
      background: #EEE;
    }
    
    .block {
      padding: 0 6px;
      
      .blockTitle {
        display: inline-block;
        margin-bottom: 16px;
      }
      
      &:not(:last-child) {
        .dropzone {
          margin-bottom: 12px;
        }
      }
      
      .gridContainer {
        display: grid;
        grid-template-columns: max-content;
        
        gap: 12px 40px;
        
        @media (min-width: 600px) {
          grid-template-columns: max-content max-content;
        }
        
        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }
  }
`;
