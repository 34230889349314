import React, { useState } from 'react';
import { CommunityStyles } from './CommunityStyles';
import TextButton from '../../components/buttons/button/TextButton';
import FacebookIcon from '../../assets/icons/community/FacebookIcon';
import InstagramIcon from '../../assets/icons/community/InstagramIcon';
import LinkedinIcon from '../../assets/icons/community/LinkedinIcon';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputField from '../../components/form/input/InputField';
import TextArea from '../../components/form/textArea/TextArea';
import { TCommunityFormData } from '../../enteties/types/community.types';
import workWithResponse from '../../helpers/workWithResponse';
import { Api } from '../../api';
import Modal from '../../components/modal/Modal';
import { notification } from '../../helpers/notifications/toastify';
import { unexpectedErrorMessage } from '../../constants/constants';

const socialMedias = [
  {
    path: process.env.REACT_APP_SOCIAL_MEDIAS_FACEBOOK,
    icon: FacebookIcon,
  },
  {
    path: process.env.REACT_APP_SOCIAL_MEDIAS_INSTAGRAM,
    icon: InstagramIcon,
  },
  {
    path: process.env.REACT_APP_SOCIAL_MEDIAS_LINKEDIN,
    icon: LinkedinIcon,
  },
];

function Community() {
  const [afterSubmitModalOpen, setAfterSubmitModalOpen] = useState<boolean>(false);

  const {
    handleChange, handleSubmit, handleBlur, setValues, values, errors, touched, isSubmitting, setSubmitting, resetForm,
  } = useFormik<TCommunityFormData>({
    initialValues: {
      name: '',
      message: '',
    },
    onSubmit: (values) => {
      if (values) {
        workWithResponse(() => Api.postCommunityMessage(values)).then((res) => {
          if (!res.error && res.data) {
            setAfterSubmitModalOpen(true);

            resetForm();

            return;
          }

          if (res.error) {
            notification.error(res.error);
          }
        }).catch((e) => {
          notification.error(unexpectedErrorMessage);

          console.error(`Error: ${e}`);
        });
      }

      setSubmitting(false);
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required field'),
      message: Yup.string().required('Required field').max(10000, 'The maximum number of characters allowed is 10,000'),
    }),
  });

  function handleCloseModal() {
    setAfterSubmitModalOpen(false);
  }

  return (
    <CommunityStyles>
      {afterSubmitModalOpen && (
        <Modal onClose={handleCloseModal}>
          <div className="afterSaveModalHead">
            <span className="textBold28">Thank you for contacting us</span>

            <span className="textBase16s">We will get in touch soon.</span>
          </div>

          <TextButton onClick={handleCloseModal} text="OK" theme="mainFilled" />
        </Modal>
      )}

      <h2 className="textBold28">Community</h2>

      <div className="content">
        <form onSubmit={handleSubmit}>
          <div className="formHead">
            <span className="textBold24">Send us a message</span>

            <p>Feel free to contact us, leave your feedback, share your thoughts and make any suggestions!</p>
          </div>

          <div className="formFields">
            <InputField
              placeholder="Enter full name"
              name="name"
              onChange={handleChange}
              value={values.name}
              label="Full name"
              error={touched && touched.name && errors && errors.name ? errors.name : undefined}
              disabled={isSubmitting}
            />

            <TextArea
              placeholder="Please let us know if you have any questions"
              name="message"
              onChange={handleChange}
              value={values.message}
              label="Message"
              error={touched && touched.message && errors && errors.message ? errors.message : undefined}
              disabled={isSubmitting}
            />
          </div>

          <TextButton type="submit" onClick={() => null} text="Send" theme="mainFilled" fullWidth />
        </form>

        <div className="socialMediasWrapper">
          <span className="textBold24">Follow us</span>

          <div className="socialMedias">
            {socialMedias.map((socialMedia) => (<a href={socialMedia.path} target="_blank" rel="noreferrer">{socialMedia.icon}</a>))}
          </div>
        </div>
      </div>
    </CommunityStyles>
  );
}

export default Community;
