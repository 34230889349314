import styled from 'styled-components';

export const SuggestFeatureModalStyles = styled.div`
  .modalBody {
    max-width: 630px;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .textWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    text-align: center;
  }
  
  .buttonsContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    
    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
  }
`;
