import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSearchParams } from 'react-router-dom';
import { TApiStatus } from '../../../../../enteties/types/statuses.types';
import { TApiForm } from '../../../../../enteties/types/forms.types';
import MultipleSelect from '../../../../form/selects/multipleSelect/MultipleSelect';
import CustomSelect from '../../../../form/selects/customSelect/CustomSelect';
import { DesktopFiltersStyles } from '../../DesktopFiltersStyles';
import TextButton from '../../../../buttons/button/TextButton';
import XIcon from '../../../../../assets/icons/leads/XIcon';
import CheckMark from '../../../../../assets/icons/other/CheckMark';
import IconButton from '../../../../buttons/iconButton/IconButton';
import { filtersKeys } from '../filtersKeys';

interface FormValues {
  form: TApiForm | undefined,
  statuses: number[],
}

interface Props {
  statuses: TApiStatus[];
  forms: TApiForm[];
  setFilters: (data: {
    form: number | undefined,
    statuses: number[],
  }) => void;
}

const initialValues: FormValues = {
  form: undefined,
  statuses: [],
};

function TableDesktopFilters({ statuses, forms, setFilters }:Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    handleChange, handleSubmit, handleBlur, setValues, values, errors, touched, isSubmitting, setFieldValue,
  } = useFormik<FormValues>({
    initialValues,
    onSubmit: (values) => {
      if (values) {
        const params = new URLSearchParams(searchParams);

        if (values.statuses.length > 0) {
          params.set(filtersKeys.statuses, values.statuses.join(','));
        }

        if (values.statuses.length === 0 && searchParams.get(filtersKeys.statuses)) {
          params.delete(filtersKeys.statuses);
        }

        if (values.form) {
          params.set(filtersKeys.form, String(values.form.id));
        }

        if (!values.form && searchParams.get(filtersKeys.form)) {
          params.delete(filtersKeys.form);
        }

        setSearchParams(params);

        setFilters({
          form: values.form?.id,
          statuses: values.statuses,
        });
      }
    },
  });

  useEffect(() => {
    const formId = searchParams.get(filtersKeys.form);
    const statuses = searchParams.get(filtersKeys.statuses);

    const initialValuesFromParams: FormValues = { ...initialValues };

    if (formId) {
      const form = forms.find((({ id }) => id === +formId));

      if (form) {
        initialValuesFromParams.form = form;
      }
    }

    if (statuses) {
      initialValuesFromParams.statuses = statuses.split(',').map((id) => +id);
    }

    setValues(initialValuesFromParams);
  }, [forms, statuses]);

  const handleClear = useCallback(() => {
    setValues(initialValues);

    const params = new URLSearchParams(searchParams);

    if (searchParams.get(filtersKeys.statuses)) {
      params.delete(filtersKeys.statuses);
    }

    if (searchParams.get(filtersKeys.form)) {
      params.delete(filtersKeys.form);
    }

    setSearchParams(params);
    setFilters({
      form: undefined,
      statuses: [],
    });
  }, []);

  const isFiltersApplied = useMemo(() => {
    const formId = searchParams.get(filtersKeys.form);
    const statusesIds = searchParams.get(filtersKeys.statuses);

    if ((values.form && formId && values.form.id !== +formId) || (!values.form && formId) || (!formId && values.form)) {
      return false;
    }

    if (statusesIds) {
      const searchStatusIds = statusesIds.split(',').map(Number);

      if (values.statuses.length > 0 && searchStatusIds.length === values.statuses.length) {
        return values.statuses.every((statusId) => searchStatusIds.includes(statusId));
      }

      return false;
    }

    if (!statusesIds && values.statuses.length > 0) {
      return false;
    }

    return true;
  }, [values, searchParams]);

  return (
    <DesktopFiltersStyles>
      <div className="filtersFields">
        <CustomSelect
          value={values.form}
          nameKey="name"
          valueKey="id"
          options={forms}
          onChange={(value) => setFieldValue('form', value)}
          placeholder="Choose form"
        />

        <MultipleSelect
          placeholder="Status"
          value={values.statuses}
          nameKey="name"
          valueKey="id"
          multiSelect
          options={statuses}
          onChange={(value) => setFieldValue('statuses', value)}
        />
      </div>

      <div className="buttons">
        <IconButton
          theme="mainNotFilled"
          icon={<XIcon color="#FC812B" />}
          onClick={handleClear}
          title="Clear filters"
        />

        <IconButton
          theme="mainFilled"
          icon={<CheckMark color="#FFF" size={16} />}
          onClick={handleSubmit}
          title="Apply filters"
        />

        {!isFiltersApplied && <span className="textSemiBold12 notAppliedText">Filters not applied</span>}
      </div>
    </DesktopFiltersStyles>
  );
}

export default TableDesktopFilters;
