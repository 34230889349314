import styled from 'styled-components';

export const PropertiesStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  .propertyDropdown {
    width: 100%;
    box-sizing: border-box;
  }
`;
