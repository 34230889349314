import styled from 'styled-components';

export const TermsStyles = styled.main`
  position: relative;
  overflow: hidden;

  padding: 32px 0;

  @media (min-width: 768px) {
    padding: 64px 0;
  }
  
  .container {
    position: relative;
    z-index: 1;
  }
  
  .background {
    top: -100px;
    position: absolute;
    
    svg {
      width: 100vw;
      height: auto;
    }
    
    svg:nth-child(1) {
      display: none;

      opacity: 0.3;


      @media (min-width: 1200px) {
        display: block;
      }
    }

    svg:nth-child(2) {
      display: none;
      opacity: 0.3;

      @media (min-width: 768px) {
        display: block;
      }

      @media (min-width: 1200px) {
        display: none;
      }
    }

    svg:nth-child(3) {
      opacity: 0.3;

      @media (min-width: 768px) {
        display: none;
      }
    }
  }
  
  .titleWrapper {
    text-align: center;
    
    .linksPath {
      margin: auto;
      width: fit-content;
    }
    
    h1 {
      margin-bottom: 16px;
    }
  }
  
  .container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    
    @media (min-width: 768px) {
      gap: 32px;
    }
    
    @media (min-width: 1024px) {
      gap: 40px;
    }
  }
  
  .textWrapper {
    display: flex;
    flex-direction: column;
    
    h2 {
      margin-bottom: 16px;
    }
    
    p {
      margin-bottom: 12px;
    }
    
    ul {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    
    .link {
      color: #007FD4;
      text-decoration: underline;
    }
  }
`;
