import styled from 'styled-components';

export const ColorPickerStyles = styled.div`
  .labelWrapper {
    margin-bottom: 14px;
  }
  
  .openerButton {
    display: flex;
    align-items: center;
    gap: 26px;
    
    .colorContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      
      .color {
        width: 24px;
        height: 24px;
        
        border: 0.5px solid #000;
      }
    }
  }
  
  .modalBody {
    display: flex;
    flex-direction: column;
    gap: 12px;
    
    width: fit-content;
    
    padding: 28px;
    
    .buttonsContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;
    }
  }
`;
