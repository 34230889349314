import styled from 'styled-components';

export const UpgradesStyles = styled.div`
  display: flex;
  flex-direction: column;
  
  flex-grow: 1;
  max-width: 1012px;
  
  padding-bottom: 60px;
  
  h1 {
    margin-bottom: 32px;
  }
  
  .head {
    display: flex;
    flex-direction: column;
    gap: 16px;
    
    margin-bottom: 24px;
    
    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      
      margin-bottom: 32px;
    }
    
    .descriptionWrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
  
  .content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
  
  .upgradesList {
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    margin-bottom: 20px;
    
    @media (min-width: 650px) {
      gap: 32px;
      
      margin-bottom: 32px;
    }
  }
`;
