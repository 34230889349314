import styled from 'styled-components';

export const ToggleButtonStyles = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  
  cursor: pointer;

  button {
    position: relative;

    height: 20px;
    width: 36px;
    padding: 2px;
    box-sizing: border-box;

    transition: .3s ease background-color;
    background: var(--Gray-100, #F2F4F7);
    border-radius: 12px;

    &.active {
      &.blue {
        background: #00C8CB;
      }
      
      &.orange {
        background: #FC812B;
      }
      
      
      .toggle {
        transform: translateY(-50%) translateX(100%);
      }
    }

    .toggle {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      width: 16px;
      height: 16px;

      transition: .3s ease transform; /* Зміна transition */
      border-radius: 100%;
      background: #FFF;
      filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06)) drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.10));
    }
  }
`;
