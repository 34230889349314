import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputField from '../../../components/form/input/InputField';
import { SignUpStyles } from './SignUpStyles';
import TextButton from '../../../components/buttons/button/TextButton';
import Logo from '../../../assets/Logo';
import { useAppDispatch } from '../../../state/hooks';
import { registerUser } from '../../../state/slices/userSlice';
import PlayIcon from '../../../assets/icons/authentefication/PlayIcon';
import RegisterData from '../../../state/entities/RegisterData';
import { notification } from '../../../helpers/notifications/toastify';
import { SignUpStep1Data } from '../../../enteties/types/auth.types';
import workWithResponse from '../../../helpers/workWithResponse';
import { Api } from '../../../api';

function SignUp() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    handleChange, handleSubmit, handleBlur, setValues, values, errors, touched, isSubmitting,
  } = useFormik<SignUpStep1Data>({
    initialValues: {
      email: '',
    },
    onSubmit: async (values) => {
      if (values) {
        await workWithResponse(() => Api.registrationEmail(values)).then((res) => {
          if (res.statusCode >= 200 && res.statusCode < 300) {
            notification.success('An email has been sent to your inbox to continue the registration. Please follow the link in the email.');
            navigate('/');
          } else {
            throw new Error(res.error!);
          }
        }).catch((error) => {
          notification.error(error);
        });
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Email is not valid').required('Required field'),
    }),
  });

  return (
    <SignUpStyles>
      <div className="content">
        <Link to="/"><Logo /></Link>

        <div className="formWrapper">
          <div className="formFieldsWrapper">
            <h1 className="textBold28">Sign up</h1>

            <div className="fieldsWrapper">
              <InputField
                placeholder="Enter e-mail"
                name="email"
                onChange={handleChange}
                value={values.email}
                label="Your e-mail"
                error={touched && touched.email && errors && errors.email ? errors.email : undefined}
              />

              <p className="textBase14">
                By creating an account, you agree to our
                {' '}
                <Link className="animationUnderline" to="/privacy-policy" target="_blank">Privacy Policy</Link>
                {' '}
                and
                {' '}
                <Link className="animationUnderline" to="/terms-of-use" target="_blank">Terms of Use.</Link>
              </p>
            </div>
          </div>

          <div className="buttonsWrapper">
            <TextButton
              text="Continue"
              onClick={handleSubmit}
              fullWidth
              theme="mainFilled"
            />
          </div>

          <div className="signInWrapper">
            <span className="textBase16">Already a member?</span>
            {' '}
            <Link className="textSemiBold16 animationUnderline" to="/">
              Sign in
            </Link>
          </div>
        </div>

        <div />
      </div>

      <div className="rightSide">
        <div className="sloganContainer">
          <PlayIcon />

          <p className="textBold28">One-stop platform to boost your digital marketing</p>
        </div>
      </div>
    </SignUpStyles>
  );
}

export default SignUp;
