import styled from 'styled-components';
import { ButtonDesignProps } from '../../../../../enteties/types/forms.types';
import { convertColorToCss } from '../../../../../utils/convertRGBAToCssColor';

interface Props {
  styles: ButtonDesignProps
}

export const PreviewButtonStyles = styled.button<Props>`
  padding: 12px;
  width: 100%;
  
  color: ${(props) => convertColorToCss(props.styles.text_color)};
  background-color: ${(props) => convertColorToCss(props.styles.background_color)};
  
  border-radius: ${(props) => `${props.styles.border_radius}px`};
`;
