import styled from 'styled-components';

export const CommentsStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  
  .messageInput {
    input {
      padding-right: 28px;
    }
    
    .sendMessageButton {
      transform: translateY(2px);
      
      svg {
        path {
          transition: .3s ease fill;
        }
      }
      
      &:hover {
        svg {
          path {
            fill: #FC812B;
          }
        }
      }
    }
  }
`;
