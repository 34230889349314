import React, { useRef, useState } from 'react';
import { CustomSelectStyles } from './CustomSelectStyles';
import CheckMark from '../../../../assets/icons/formElements/CheckMark';
import ArrowDownIcon from '../../../../assets/icons/table/ArrowDownIcon';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import ErrorIcon from '../../../../assets/icons/formElements/ErrorIcon';
import ErrorText from '../../../other/errorText/ErrorText';
import Loader from '../../../loader/Loader';

interface CustomSelectProps {
  options: any[];
  value: any;
  onChange: (option: any) => void;
  placeholder: string;
  label?: string;
  nameKey?: string;
  valueKey?: string;
  error?: string;
  showError?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
}

function CustomSelect({
  options,
  value,
  onChange,
  valueKey,
  nameKey,
  label,
  placeholder,
  error,
  showError = true,
  disabled,
  isLoading,
}: CustomSelectProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const selectTriggerRef = useRef<HTMLDivElement | null>(null);
  const selectOptionsRef = useRef<HTMLDivElement | null>(null);

  const handleSelectOption = (optionValue: number | string) => {
    if (value === optionValue) {
      onChange(undefined);
    } else {
      onChange(optionValue);
    }

    setIsOpen(false);
  };

  useClickOutside(selectOptionsRef, () => setIsOpen(false), selectTriggerRef);

  return (
    <CustomSelectStyles>
      {label && <span className="textSemiBold12 colorSecondary8">{label}</span>}
      <div className="customSelectWrapper">
        <div
          className={`customSelect ${isOpen ? 'open' : ''}`}
          onClick={() => (!disabled ? setIsOpen(!isOpen) : null)}
          ref={selectTriggerRef}
        >
          <div className={`customSelectTrigger ${disabled ? 'disabled' : ''} ${error ? 'error' : ''}`}>
            <span
              className={`textBase14 ${(nameKey && value ? value[nameKey] : value) ? 'withValue' : ''}`}
            >
              {(nameKey && value ? value[nameKey] : value) || placeholder}
            </span>

            <div className="arrow">
              {!isLoading ? <ArrowDownIcon /> : <Loader size={20} />}

            </div>
          </div>
          <div className="customOptions" ref={selectOptionsRef}>
            {options.map((option, index) => (
              <div
                key={valueKey ? option[valueKey] : option}
                className={`customOption ${
                  (valueKey ? option[valueKey] : option) === (valueKey && value ? value[valueKey] : value) ? 'selected' : ''
                }`}
                onClick={() => handleSelectOption(option)}
              >
                <span>{nameKey ? option[nameKey] : option}</span>

                {(valueKey ? option[valueKey] : option) === (valueKey && value ? value[valueKey] : value) ? <CheckMark /> : null}
              </div>
            ))}
          </div>
        </div>
      </div>
      {showError && (
        <div>
          {error && <ErrorText error={error} />}
          {/* {!error && touched && <ErrorMessage name={name} component={FormErrorMessage} />} */}
        </div>
      )}
    </CustomSelectStyles>
  );
}

export default CustomSelect;
