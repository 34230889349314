import React, { useEffect, useMemo, useState } from 'react';
import { TApiLead } from '../../../enteties/types/lead.types';
import { TApiStatus } from '../../../enteties/types/statuses.types';
import { TApiField, TApiForm } from '../../../enteties/types/forms.types';
import { Api } from '../../../api';
import { useWindowSize } from 'usehooks-ts';
import StickersMobileFilters from '../filters/stickers/mobile/StickersMobileFilters';
import StickersDesktopFilters from '../filters/stickers/desktop/StickersDesktopFilters';
import { LeadsTableVersionFilters } from '../tableVersion/LeadsTableVersion';
import { Link, useSearchParams } from 'react-router-dom';
import { filtersKeys } from '../filters/stickers/filtersKeys';
import { LeadsStickersVersionStyles } from './LeadsStickersVersionStyles';
import {
  DragDropContext, Draggable, Droppable, DropResult,
} from 'react-beautiful-dnd';
import workWithResponse from '../../../helpers/workWithResponse';
import { LeadsStickersVersionProvider } from './context/LeadsStickersVersionProvider';
import Lead from '../../../pages/leads/lead/Lead';
import LeadItem from './ui/leadItem/LeadItem';
import { notification } from '../../../helpers/notifications/toastify';
import { unexpectedErrorMessage } from '../../../constants/constants';

type StickersData = {
  status: TApiStatus;
  leads: TApiLead[];
  totalRequestsCurrency: number;
}[];

interface LeadsStickersVersionProps {
  statuses: TApiStatus[];
  leads: TApiLead[];
  forms: TApiForm[];
  formFields: {[p: string]: TApiField};
  onLeadChanged(changedLead: TApiLead): void;
}

export interface LeadsStickersVersionFilters {
  form: number | undefined;
  statuses: number[];
}

function sortRequestsByStatusesAndAddTotalCurrency(leadsToSort: TApiLead[], statuses: TApiStatus[], filters: LeadsStickersVersionFilters) {
  const requestsByStatuses: StickersData = statuses
    .filter((status) => (filters.statuses.length > 0 ? filters.statuses.includes(status.id) : true))
    .sort((a, b) => a.priority - b.priority)
    .reduce((prev: StickersData, status) => {
      const requestsByStatus: TApiLead[] = [];
      let currency = 0;

      leadsToSort.forEach((request) => {
        if (request.status_id === status.id) {
          requestsByStatus.push(request);
          currency += (request.price || 0);
        }
      });

      return (
        [
          ...prev,
          {
            status,
            leads: requestsByStatus,
            totalRequestsCurrency: currency,
          },
        ]
      );
    }, []);

  return requestsByStatuses;
}

function LeadsStickersVersion({
  statuses, leads, forms, formFields, onLeadChanged,
}: LeadsStickersVersionProps) {
  const { width } = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState<LeadsStickersVersionFilters>(() => {
    const formId = searchParams.get(filtersKeys.form);
    const statusesIds = searchParams.get(filtersKeys.statuses);
    const updatedFilters: LeadsTableVersionFilters = {
      form: formId ? +formId : undefined,
      statuses: statusesIds ? statusesIds.split(',').map((id) => +id) : [],
    };

    return updatedFilters;
  });
  const [stickersData, setStickersData] = useState<StickersData>([]);

  const screenSize = useMemo(() => (width >= 1024 ? 'desktop' : 'mobile'), [width]);
  const formsBook: {[key: string]: TApiForm} = useMemo(() => forms.reduce((acc, form) => ({ ...acc, [form.id]: form }), {}), [forms]);
  const statusesBook: {[key: string]: TApiStatus} = useMemo(() => statuses.reduce((acc, status) => ({ ...acc, [status.id]: status }), {}), [statuses]);

  const filteredLeads = useMemo(() => {
    if (Object.values(filters).filter((item) => {
      if (Array.isArray(item)) {
        return item.length > 0;
      }

      return !!item;
    }).length > 0) {
      const leadsFilteredByFilters = leads.filter((lead) => {
        if (filters.form && !(filters.form === lead.form_id)) {
          return false;
        }

        if (filters.statuses.length > 0 && !filters.statuses.includes(lead.status_id)) {
          return false;
        }

        return true;
      });

      return sortRequestsByStatusesAndAddTotalCurrency(leadsFilteredByFilters, statuses, filters);
    }

    return null;
  }, [filters, leads]);

  async function onDropEnd(result: DropResult) {
    if (!result.destination || result.destination.droppableId === result.source.droppableId) return null;

    try {
      const destinationItems = stickersData[+result.destination.droppableId];
      const itemToChange = stickersData[+result.source.droppableId].leads[result.source.index];

      const changedItem = await workWithResponse(() => Api.updateLead({
        ...itemToChange,
        id: +itemToChange.id,
        status_id: destinationItems.status.id,
      })).then((res) => res.data).catch((e) => {
        notification.error(`${unexpectedErrorMessage} on lead status changing`);

        console.error(`Error: ${e}`);
      });

      if (changedItem) {
        onLeadChanged({
          ...itemToChange,
          id: +itemToChange.id,
          status_id: destinationItems.status.id,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    setStickersData(sortRequestsByStatusesAndAddTotalCurrency(leads, statuses, filters));
  }, [leads, statuses, filters]);

  return (
    <LeadsStickersVersionStyles>
      {screenSize === 'mobile' ? (
        <StickersMobileFilters
          filters={filters}
          setFilters={setFilters}
          statuses={statuses}
          forms={forms}
        />
      ) : (
        <StickersDesktopFilters
          setFilters={setFilters}
          statuses={statuses}
          forms={forms}
        />
      )}
      <LeadsStickersVersionProvider formFields={formFields} formsBook={formsBook}>
        <DragDropContext onDragEnd={onDropEnd}>
          <div className="dragDropWrapper">
            <div className="listsWrapper">
              {(filteredLeads || stickersData).map((item, index) => (
                <Droppable key={item.status.id} droppableId={String(index)} direction={screenSize === 'mobile' ? 'horizontal' : 'vertical'}>
                  {(provided) => (
                    <div className="listWrapper" ref={provided.innerRef} {...provided.droppableProps}>
                      <div className="titleWrapper">
                        <span className="textSemiBold14 listTitle" style={{ backgroundColor: item.status.color }}>{item.status.name}</span>
                        {' '}
                        <span className="textSemiBold14">
                          &#8372;
                          {item.totalRequestsCurrency}
                        </span>
                      </div>
                      <ul className="statusesList">
                        {item.leads.map((lead, index) => (
                          <Draggable key={lead.id} draggableId={String(lead.id)} index={index}>
                            {(provided) => (
                              <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <LeadItem lead={lead} />
                              </li>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </ul>
                    </div>
                  )}
                </Droppable>
              ))}
            </div>
          </div>
        </DragDropContext>
      </LeadsStickersVersionProvider>
    </LeadsStickersVersionStyles>
  );
}

export default LeadsStickersVersion;
