import styled from 'styled-components';

export const LinksPathStyles = styled.div`
  ul {
    list-style: none;
    
    display: flex;
    align-items: center;
    
    li {
      margin: 0 4px;
    }
    
    li:first-child {
      margin-left: 0;
    }
  }
  
  //span {
  //  color: var(--grey-5, #8C8C8C);
  //}
  //
  a {
    color: var(--grey-5, #8C8C8C);
    transition: 0.3s ease color;
    
    &:hover {
      color: #141414;
    }
  }
  
  li:last-child {
    span {
      color: #000;
    }
  }
  
  
  li:first-child {
    svg {
      path {
        transition: 0.3s linear stroke;
      }
    }
    
    &:hover {
      svg {
        path {
          stroke: #141414;
        }
      }
    }
  }
  
  .homeLink {
    display: grid;
    grid-template-columns: min-content min-content;
    gap: 4px;
    align-items: center;
  }
`;
