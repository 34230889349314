import styled from 'styled-components';

export const PaginationStyles = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
  
  .pagination {
    display: flex;
    align-items: center;
    gap: 8px;
    
    .page-link {
      display: flex;
      
      color: var(--Grey-5, #8C8C8C);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    
    .page-item {
      cursor: pointer;
      
      a {
        transition: 0.3s ease color;
      }
      
      &.active, &:hover {
        a {
          color: #5BBCB2;
        }
      }
      
      &.active {
        a {
          font-weight: 600;
        }
      }
    }
    
    svg {
      path {
        transition: .3s ease stroke;
      }
      
      &:hover {
        path {
          stroke: #5BBCB2;
        }
      }
    }
    
    .disabled {
      cursor: not-allowed;
      
      svg {
        path {
          stroke: #8C8C8C;
        }
      }
    }
  }
  
  .page-item {
    a {
      color: #8C8C8C;
    }
  }
`;
