import React, { useEffect, useRef, useState } from 'react';
import {
  PropertyTypes,
  TLeadPropertyTextFieldTypes,
  TTextProperty,
} from '../../../../../enteties/types/formProperties.types';
import DropDown from '../../../../other/dropDown/DropDown';
import InputField from '../../../../form/input/InputField';
import { DropdownProps } from '../Property';
import { useParams } from 'react-router-dom';
import workWithResponse from '../../../../../helpers/workWithResponse';
import { Api } from '../../../../../api';
import { TextIcon } from '../../../../../assets/icons/properties/TextIcon';
import { TrashIconWithTwoLinesInCenter } from '../../../../../assets/icons/other/TrashIconWithTwoLinesInCenter';
import { notification } from '../../../../../helpers/notifications/toastify';
import ModalConfirmDecline from '../../../../modals/modalConfirmDecline/ModalConfirmDecline';
import { deleteProperty } from './helpers';

type TextDropdownProps = DropdownProps<TTextProperty, TLeadPropertyTextFieldTypes>;

let initialValue: TLeadPropertyTextFieldTypes | null = null;
let initialPropertyValue: TTextProperty | null = null;

function Text({ property, onPropertyChanged, onPropertyDeleted }: TextDropdownProps) {
  const { id: ticketId } = useParams();

  const editingButtonRef = useRef<HTMLButtonElement | null>(null);

  const [stateProperty, setStateProperty] = useState<TTextProperty>(property);
  const [statePropertyValue, setStatePropertyValue] = useState<TLeadPropertyTextFieldTypes | null>(null);
  const [editingOpen, setEditingOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [activeModalName, setActiveModalName] = useState<'deleting' | null>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  async function getPropertyValueData() {
    try {
      setIsLoading(true);

      await workWithResponse(() => Api.getTicketPropertyValue(property.id!, +ticketId!)).then((res) => {
        if (res.data && res.data.type === PropertyTypes.text) {
          setStatePropertyValue(res.data);
          initialValue = res.data;
        } else if (res.error === 'Not Found') {
          initialValue = {
            value: '',
            property_id: property.id,
            type: PropertyTypes.text,
            id: 0,
            lead_id: +ticketId!,
          };

          setStatePropertyValue(initialValue);
        }
      });
    } catch (e) {
      console.error(`Error: ${e}`);
    } finally {
      setIsLoading(false);
    }
  }

  async function changePropertyValue() {
    try {
      if (statePropertyValue) {
        if (statePropertyValue.id) {
          await workWithResponse(() => Api.updateTicketPropertyValue(statePropertyValue)).then((res) => {
            if (res.error) {
              notification.error(res.error);
              return;
            }

            if (res.data && res.data.type === PropertyTypes.text) {
              initialValue = res.data;
            }
          });
        } else {
          await workWithResponse(() => Api.createTicketPropertyValue(statePropertyValue)).then((res) => {
            if (res.error) {
              notification.error(res.error);

              return;
            }

            if (res.data && res.data.type === PropertyTypes.text) {
              initialValue = res.data;
            }
          });
        }
      }
    } catch (e) {
      console.error(`Error: ${e}`);
    }
  }

  async function handleCloseEditingModal() {
    try {
      if (JSON.stringify(stateProperty) !== JSON.stringify(initialPropertyValue)) {
        await workWithResponse(() => Api.updateFormProperty(stateProperty)).then((res) => {
          if (res.error) {
            notification.error(res.error);

            return;
          }

          if (res.statusCode >= 200 && res.statusCode < 300) {
            initialPropertyValue = stateProperty;

            onPropertyChanged(stateProperty);
          }
        });
      }
    } catch (e) {
      console.error(`Error: ${e}`);
    } finally {
      setEditingOpen(false);
    }
  }

  useEffect(() => {
    initialPropertyValue = property;

    if (ticketId && +ticketId) {
      getPropertyValueData();
    }
  }, []);

  useEffect(() => {
    if (!isLoading && statePropertyValue && initialValue && statePropertyValue.value !== initialValue.value) {
      const timer = setTimeout(() => {
        changePropertyValue();
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [statePropertyValue]);

  return (
    <li className="propertyItem">
      {activeModalName === 'deleting' && (
        <ModalConfirmDecline
          onClose={() => setActiveModalName(null)}
          title="Are you sure you want to delete property?"
          buttons={
            [
              {
                text: 'Cancel',
                onClick() {
                  setActiveModalName(null);
                },
                theme: 'mainFilled',
                fullWidth: true,
                isLoading: isDeleting,
              },
              {
                text: 'Delete',
                onClick() {
                  setIsDeleting(true);

                  deleteProperty(property.id!).then((res) => {
                    setIsDeleting(false);

                    if (res === 'Deleted') {
                      onPropertyDeleted(property.id!);
                    }
                  });
                },
                theme: 'grey',
                fullWidth: true,
                isLoading: isDeleting,
              },
            ]
          }
        />
      )}

      <div className="propertyItemHead">
        <button ref={editingButtonRef} className="openButton textBase14" type="button" onClick={() => setEditingOpen(!editingOpen)}>
          {TextIcon}
          {' '}
          <span>{property.name}</span>
        </button>

        <DropDown exclude={editingButtonRef} className="propertyDropdown" isOpened={editingOpen} onClose={handleCloseEditingModal}>
          <InputField
            name="name"
            placeholder="Enter property name"
            value={stateProperty.name}
            onChange={(e: React.BaseSyntheticEvent) => setStateProperty({ ...stateProperty, name: e.target.value })}
          />

          {/* <div className="settings"> */}
          {/*  1 */}
          {/* </div> */}

          <div className="splitter" />

          <div className="footer">
            <button type="button" onClick={() => setActiveModalName('deleting')}>
              {TrashIconWithTwoLinesInCenter}

              <span className="textSemiBold12">Delete property</span>
            </button>
          </div>
        </DropDown>
      </div>

      {statePropertyValue && (
        <InputField
          name="value"
          placeholder="Empty"
          value={statePropertyValue.value}
          onChange={(e: React.BaseSyntheticEvent) => setStatePropertyValue({ ...statePropertyValue, value: e.target.value })}
        />
      )}

    </li>
  );
}

export default Text;
