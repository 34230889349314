import React, { useRef, useState } from 'react';
import { TProperty, TPropertyTypes } from '../../../../enteties/types/formProperties.types';
import { CreatePropertyStyles } from './CreatePropertyStyles';
import TooltipIcon from '../../../../assets/icons/other/TooltipIcon';
import { Tooltip } from 'react-tooltip';
import DropDown from '../../../other/dropDown/DropDown';
import { createPropertyInitialDataBook, selectTypeDropDownData } from '../../../../constants/properties';
import { notification } from '../../../../helpers/notifications/toastify';
import { unexpectedErrorMessage } from '../../../../constants/constants';
import workWithResponse from '../../../../helpers/workWithResponse';
import { Api } from '../../../../api';
import Loader from '../../../loader/Loader';
import { TApiForm } from '../../../../enteties/types/forms.types';

interface CreatePropertyProps {
  propertiesCount: number;
  formID: number;
  onPropertyCreated(createdProperty: TProperty): void;
}

function CreateProperty({ onPropertyCreated, propertiesCount, formID }: CreatePropertyProps) {
  const [addPropertyTypeDropdownActive, setAddPropertyTypeDropdownActive] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const addPropertyTypeDropdownActiveButtonRef = useRef<HTMLButtonElement | null>(null);

  function handleCloseDropdown() {
    if (isCreating) {
      return null;
    }

    setAddPropertyTypeDropdownActive(false);
  }

  const handleCreateProperty = async (type: TPropertyTypes) => {
    try {
      setIsCreating(true);
      await workWithResponse(() => Api.createFormProperty(createPropertyInitialDataBook[type], formID)).then((res) => {
        if (!res.error && res.data) {
          onPropertyCreated(res.data);

          return;
        }

        notification.error(res.error!);
      });
    } catch (e) {
      notification.error(unexpectedErrorMessage);
      console.error(`Error: ${e}`);
    } finally {
      setIsCreating(false);
    }
    setAddPropertyTypeDropdownActive(false);
  };

  return (
    <CreatePropertyStyles>
      <button disabled={propertiesCount >= 3} ref={addPropertyTypeDropdownActiveButtonRef} className="textSemiBold14 addButton" type="button" onClick={addPropertyTypeDropdownActive ? () => handleCloseDropdown() : () => setAddPropertyTypeDropdownActive(true)}>
        Add property

        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
          <path d="M11.666 4.58301V17.4163" stroke="#FC812B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M5.25 11H18.0833" stroke="#FC812B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button>

      <div className="tooltip">
        <a
          className="tooltipIcon"
          data-tooltip-id="openButtonTooltip"
          data-tooltip-content="Up to 3 additional properties allowed on free version. Upgrade Advanced CRM to maximize CRM functions."
          data-tooltip-place="bottom"
        >
          <TooltipIcon size={18} fill="#F0F0F0" color="#3A5274" />
        </a>
        <Tooltip
          id="openButtonTooltip"
          variant="info"
          style={{
            textAlign: 'center', zIndex: '3', maxWidth: '300px', whiteSpace: 'pre-wrap', background: '#898989',
          }}
        />
      </div>

      <DropDown exclude={addPropertyTypeDropdownActiveButtonRef} className="selectTypeDropdown" isOpened={addPropertyTypeDropdownActive} onClose={handleCloseDropdown}>
        {isCreating ? <Loader size={30} /> : (
          selectTypeDropDownData.map((typeItem) => (
            <button
              className="dropDownButton greyOnHover"
              type="button"
              onClick={() => {
                handleCreateProperty(typeItem.type);
              }}
            >
              {typeItem.icon}
              {typeItem.name}
            </button>
          ))
        )}
      </DropDown>
    </CreatePropertyStyles>
  );
}

export default CreateProperty;
