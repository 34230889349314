import styled from 'styled-components';

export const ButtonStyles = styled.button`
  padding-left: 40px;
  padding-right: 40px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  
  .loaderItem {
    width: fit-content;
  }
  
  svg {
    transition: 0.3s ease transform;
    
    path {
      transition: 0.3s ease all;
    }
  }
  
  &.iconLeft {
    flex-direction: row-reverse; 
  }
  
  &.XL {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  
  &.L {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &.M {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  
  &.grey {
    color: #BFBFBF;

    border-radius: var(--corners_btn, 4px);
    border: 1px solid #BFBFBF;
    
    transition: 0.3s ease all;

    &:hover {
      color: var(--grey-6, #595959);

      border: 1px solid var(--grey-6, #595959);
    }
    
    &.loading {
      color: var(--grey-5, #8C8C8C);

      border: 1px solid var(--grey-5, #8C8C8C);
    }
    
    &:focus {
      color: var(--grey-5, #8C8C8C);

      outline: 2px solid var(--grey-6, #595959);
    }
    
    &:disabled {
      color: rgba(140, 140, 140, 0.35);
      
      border: 1px solid rgba(140, 140, 140, 0.35);
    }
  }

  &.mainFilled {
    color: #FFF;

    border: 1px solid #FC812B;
    border-radius: var(--corners_btn, 4px);
    background: var(--orange-1, #FC812B);
    box-shadow: 0px 2px 3px 1px rgba(252, 129, 43, 0.30), 0px 0px 2px 0px rgba(252, 129, 43, 0.40);

    transition: 0.3s ease all;

    &:hover {
      border: 1px solid #DC6F23;
      background: var(--orange-hover, #DC6F23);
      box-shadow: 0px 3px 6px 2px rgba(220, 111, 35, 0.40), 0px 1px 2px 0px rgba(220, 111, 35, 0.50);
    }

    &.loading {
      border: 1px solid #FDB17B;
      background: #FDB17B;
      box-shadow: 0px 3px 6px 2px rgba(253, 177, 123, 0.40), 0px 1px 2px 0px rgba(253, 177, 123, 0.50);
    }

    &:focus {
      outline: 2px solid var(--orange-2, #FEC9A4);

      background: var(--orange-1, #FC812B);
      box-shadow: 0px 1px 3px 1px rgba(252, 129, 43, 0.15), 0px 1px 2px 0px rgba(252, 129, 43, 0.30);
    }

    &:disabled {
      color: #FAFAFA;

      background: rgba(252, 129, 43, 0.35);
    }
  }

  &.mainNotFilled {
    color: var(--orange-1, #FC812B);

    border: 1px solid var(--orange-1, #FC812B);
    background: var(--grey-1, #FDFDFD);
    box-shadow: 0px 2px 3px 1px rgba(252, 129, 43, 0.30), 0px 0px 2px 0px rgba(252, 129, 43, 0.40);

    transition: 0.3s ease all;

    &:hover {
      color: var(--orange-hover, #DC6F23);

      border: 1px solid var(--orange-hover, #DC6F23);
      background: var(--grey-1, #FDFDFD);
      box-shadow: 0px 3px 6px 2px rgba(220, 111, 35, 0.40), 0px 1px 2px 0px rgba(220, 111, 35, 0.50);
    }

    &.loading {
      color: #FDB17B;

      border: 1px solid var(--Light-Blue, #75C2F6);
    }

    &:focus {
      color: var(--orange-1, #FC812B);

      outline: 2px solid var(--orange-2, #FEC9A4);
    }

    &:disabled {
      color: rgba(252, 129, 43, 0.35);

      border: 1px solid rgba(252, 129, 43, 0.35);
      background: rgba(253, 253, 253, 0.35);
    }
  }

  &.red {
    color: #F5222D;

    border-radius: var(--corners_btn, 4px);
    border: 1px solid var(--error-default, #F5222D);

    transition: 0.3s ease all;

    &:hover {
      color: var(--error-hover, #CF1322);

      border: 1px solid var(--error-hover, #CF1322);
    }

    &.loading {
      color: var(--red-error-1, #FF7875);

      border: 1px solid var(--error-loading, #FF7875);
    }

    &:focus {
      color: var(--red-error-2, #F5222D);

      outline: 2px solid var(--red-error-2, #F5222D);
    }

    &:disabled {
      color: rgba(245, 34, 45, 0.35);

      border: 1px solid rgba(245, 34, 45, 0.35);
    }
  }
`;
