import styled from 'styled-components';

export const ModalConfirmDeclineStyles = styled.div`
  .modalBody {
    display: flex;
    flex-direction: column;
    gap: 24px;
    
    .modalHead {
      text-align: center;
    }
    
    .modalButtons {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 12px;

      .button {
        flex-grow: 1;
      }
    }
  }
`;
