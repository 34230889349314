import React, { useState } from 'react';
import { ProfileStyles } from './ProfileStyles';
import { useFormik } from 'formik';
import { loginUser } from '../../state/slices/userSlice';
import * as Yup from 'yup';
import InputField from '../../components/form/input/InputField';
import { Link } from 'react-router-dom';
import TextButton from '../../components/buttons/button/TextButton';
import EditIcon from '../../assets/icons/other/EditIcon';
import TrashIcon from '../../assets/icons/other/TrashIcon';
import { useAppSelector } from '../../state/hooks';
import ModalDelete from '../../components/profile/modalDelete/ModalDelete';

function Profile() {
  const { data: user } = useAppSelector((state) => state.user);

  const [deletingModalOpen, setDeletingModalOpen] = useState(false);

  const {
    handleChange, handleSubmit, handleBlur, setValues, values, errors, touched, isSubmitting,
  } = useFormik({
    initialValues: {
      name: user?.name,
      email: user?.email,
      password: '12345678',
    },
    onSubmit: (values) => {
      if (values) {
        console.log('Profile');
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Email is not valid').required('Required field'),
      password: Yup.string().required('Required field'),
    }),
  });

  return (
    <ProfileStyles className="profile">
      {deletingModalOpen && <ModalDelete onClose={() => setDeletingModalOpen(false)} id={user!.id} />}

      <h1 className="textBold28">Profile</h1>

      <div className="formContainer">
        <div className="fieldsWrapper">
          <InputField
            placeholder="Enter name"
            name="name"
            onChange={handleChange}
            value={values.name}
            label="Name"
            error={touched && touched.name && errors && errors.name ? errors.name : undefined}
            disabled
          />

          <InputField
            placeholder="Enter e-mail"
            name="email"
            onChange={handleChange}
            value={values.email}
            label="Email"
            error={touched && touched.email && errors && errors.email ? errors.email : undefined}
            disabled
          />

          <InputField
            placeholder="Enter password"
            label="Password"
            name="password"
            type="password"
            onChange={handleChange}
            value={values.password}
            autoComplete="current-password"
              // showPasswordButton
            error={touched && touched.password && errors && errors.password ? errors.password : undefined}
            disabled
          />
        </div>

        <Link to="/profile/edit">
          <TextButton
            text="Edit profile"
            type="button"
            theme="mainFilled"
            fullWidth
            onClick={() => null}
            icon={EditIcon}
            title="Edit profile"
          />
        </Link>
      </div>

      <button title="Open deleting profile modal" onClick={() => setDeletingModalOpen(true)} type="button" className="textSemiBold16 deleteButton">
        {TrashIcon}

        <span>
          Delete profile
        </span>
      </button>
    </ProfileStyles>
  );
}

export default Profile;
