import React, { useEffect, useState } from 'react';
import { AnswersStyles } from './AnswersStyles';
import { format } from 'date-fns';
import { TAnswer } from '../../../enteties/types/lead.types';
import { FormFieldsTypes, TField } from '../../../enteties/types/forms.types';
import Loader from '../../loader/Loader';
import workWithResponse from '../../../helpers/workWithResponse';
import { Api } from '../../../api';
import { notification } from '../../../helpers/notifications/toastify';
import { unexpectedErrorMessage } from '../../../constants/constants';

interface AnswersProps {
  created_at: string;
  answers: TAnswer[];
  handleDataLoadingError(): void;
}

function getFieldByType({ question, answer }: {question: TField, answer: TAnswer}) {
  console.log(question, answer);

  if (question.type === FormFieldsTypes.text_input && answer.type === FormFieldsTypes.text_input) {
    return (
      <li className="answerWrapper">
        <span className="label textSemiBold12">{question.label}</span>

        <div className="fakeTextInput textBase14">
          {answer.value}
        </div>
      </li>
    );
  }

  if (question.type === FormFieldsTypes.textarea && answer.type === FormFieldsTypes.textarea) {
    return (
      <li className="answerWrapper">
        <span className="label textSemiBold12">{question.label}</span>

        <div className="fakeTextInput textBase14">
          {answer.value}
        </div>
      </li>
    );
  }

  if (question.type === FormFieldsTypes.email && answer.type === FormFieldsTypes.email) {
    return (
      <li className="answerWrapper">
        <span className="label textSemiBold12">{question.label}</span>

        <div className="fakeTextInput textBase14">
          <a href={`mailto:${answer.value}`}>{answer.value}</a>
        </div>
      </li>
    );
  }

  if (question.type === FormFieldsTypes.phone && answer.type === FormFieldsTypes.phone) {
    return (
      <li className="answerWrapper">
        <span className="label textSemiBold12">{question.label}</span>

        <div className="fakeTextInput textBase14">
          <a href={`tel:${answer.value}`}>{answer.value}</a>
        </div>
      </li>
    );
  }

  return null;
}

function Answers({
  created_at, answers, handleDataLoadingError,
}: AnswersProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [answersWithQuestions, setAnswersWithQuestions] = useState<{question: TField, answer: TAnswer}[] | null>(null);

  async function getQuestions() {
    try {
      setIsLoading(true);

      const answersWithQuestions: {question: TField, answer: TAnswer}[] = await Promise.all(answers.map(async (answer) => {
        const question = await workWithResponse(() => Api.getFormField(answer.field_id)).then((res) => {
          if (res.data) {
            return res.data;
          }

          if (res.error) {
            handleDataLoadingError();
          }
        });

        if (question) {
          return {
            question,
            answer,
          };
        }
      })) as {question: TField, answer: TAnswer}[];

      setIsLoading(false);
      setAnswersWithQuestions(answersWithQuestions);
    } catch (e) {
      handleDataLoadingError();
      notification.error(unexpectedErrorMessage);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getQuestions();
  }, []);

  console.log(answersWithQuestions);

  return (
    <AnswersStyles>
      <span className="blockTitle textSemiBold16">Lead data</span>
      <div className="textWrapper">
        <span className="textSemiBold14">Date created:</span>

        <span className="blockTitle">{format(new Date(created_at), 'dd/MM/yy HH:mm')}</span>
      </div>

      {isLoading && <Loader />}

      {!isLoading && answersWithQuestions && (
        <ul className="answersList">
          {answersWithQuestions.map((item) => getFieldByType(item))}
        </ul>
      )}

    </AnswersStyles>
  );
}

export default Answers;
