import React, { Fragment, useEffect, useState } from 'react';
import { UpgradesStyles } from './UpgradesStyles';
import { TUpgradeItem } from '../../enteties/types/upgrades.types';
import ErrorComponent from '../../components/other/error/ErrorComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import workWithResponse from '../../helpers/workWithResponse';
import { Api } from '../../api';
import { notification } from '../../helpers/notifications/toastify';
import TextButton from '../../components/buttons/button/TextButton';
import Loader from '../../components/loader/Loader';
import { Table } from '../../components/table/Table';
import Pagination from '../../components/table/pagination/Pagination';
import EmptyDataIcon from '../../assets/icons/forms/EmptyDataIcon';
import UpgradesItem from '../../components/upgrades/upgradesItem/UpgradesItem';
import { unexpectedErrorMessage } from '../../constants/constants';
import SuggestFeatureModal from '../../components/upgrades/suggestFeateruModal/SuggestFeatureModal';

function Upgrades() {
  const location = useLocation();
  const navigate = useNavigate();

  const [upgradesData, setUpgradesData] = useState<TUpgradeItem[]>([]);
  const [activePage, setActivePage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [dataLoadingError, setDataLoadingError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [suggestFeatureModalOpen, setSuggestFeatureModalOpen] = useState<boolean>(false);

  async function getForms() {
    try {
      setIsLoading(true);

      await workWithResponse(() => Api.getUpgrades({ offset: (activePage) * 12, limit: 12 })).then((res) => {
        if (res.data) {
          if (total !== res.data!.count) {
            setTotal(res.data!.count);
          }

          setUpgradesData(res.data.data);
          setIsLoading(false);

          return;
        }

        if (res.error) {
          throw new Error(res.error);
        }
      });
    } catch (e) {
      notification.error(unexpectedErrorMessage);
      setDataLoadingError(true);
      console.error(`Error: ${e}`);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const pageParam = searchParams.get('page');

    if (pageParam && !Number.isNaN(Number(pageParam))) {
      setActivePage(Number(pageParam) - 1);
    } else {
      setActivePage(0);
    }
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams();
    searchParams.set('page', String(activePage + 1));

    navigate({ search: `?${searchParams.toString()}` });

    getForms();
    window.scrollTo(0, 0);
  }, [activePage, navigate]);

  if (dataLoadingError) {
    return <ErrorComponent />;
  }

  function handleChangeUpgradeItemAddToWishlistStatus(id: number) {
    setUpgradesData(upgradesData.map((upgradesItem) => {
      if (upgradesItem.id === id) {
        const newAdditionsCount = upgradesItem.added_to_wish_list ? upgradesItem.wishlist_additions - 1 : upgradesItem.wishlist_additions + 1;
        const updatedItem: TUpgradeItem = { ...upgradesItem, added_to_wish_list: !upgradesItem.added_to_wish_list, wishlist_additions: newAdditionsCount };

        return updatedItem;
      }

      return upgradesItem;
    }));
  }

  return (
    <UpgradesStyles>
      <SuggestFeatureModal open={suggestFeatureModalOpen} handleCloseModal={() => setSuggestFeatureModalOpen(false)} />

      <h1 className="textBold28">Upgrades</h1>

      <div className="head">
        <div className="descriptionWrapper">
          <span className="textBold24">Explore new features</span>

          <span className="textBase16">Please, vote for features by clicking &rdquo;Add to wish list&rdquo;</span>
        </div>

        <TextButton
          text="Suggest feature"
          onClick={() => setSuggestFeatureModalOpen(true)}
          theme="mainNotFilled"
        />
      </div>

      <div className="content">
        {!dataLoadingError && (
          <>
            {isLoading && <Loader />}

            {!isLoading && total > 0 && (
              <>
                <div className="upgradesList">
                  {upgradesData.map((data) => (
                    <Fragment key={data.id}>
                      <UpgradesItem upgradeItemData={data} onAddToWishlistStatusChanged={(id) => handleChangeUpgradeItemAddToWishlistStatus(id)} />
                    </Fragment>
                  ))}
                </div>

                <Pagination
                  activePage={activePage}
                  total={total}
                  setActivePage={(pageNumber) => setActivePage(pageNumber)}
                  elementsPerPage={12}
                />
              </>
            )}

            {!isLoading && total === 0 && (
              <div className="noDataContainer">
                <div className="noDataTextContainer">
                  <h3 className="textSemiBold22 textBold">The upgrades section is currently empty. Please check back later.</h3>
                </div>

                <EmptyDataIcon />
              </div>
            )}
          </>
        )}
      </div>
    </UpgradesStyles>
  );
}

export default Upgrades;
