import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputField from '../../../components/form/input/InputField';
import { SignUpStep2Styles } from './SignUpStep2Styles';
import TextButton from '../../../components/buttons/button/TextButton';
import Logo from '../../../assets/Logo';
import { useAppDispatch } from '../../../state/hooks';
import { registerUser } from '../../../state/slices/userSlice';
import PlayIcon from '../../../assets/icons/authentefication/PlayIcon';
import RegisterData from '../../../state/entities/RegisterData';
import { notification } from '../../../helpers/notifications/toastify';
import { SignUpStep2Data } from '../../../enteties/types/auth.types';
import workWithResponse from '../../../helpers/workWithResponse';
import { Api } from '../../../api';
import Loader from '../../../components/loader/Loader';

function SignUpStep2() {
  const dispatch = useAppDispatch();
  const [searchParams, _] = useSearchParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string | null>(() => {
    if (searchParams.get('token')) {
      return searchParams.get('token');
    }

    return null;
  });

  const {
    handleChange, handleSubmit, handleBlur, setValues, values, errors, touched, isSubmitting, setSubmitting, setFieldValue,
  } = useFormik<SignUpStep2Data>({
    initialValues: {
      name: '',
      password: '',
      token: '',
      password_confirmation: '',
    },
    onSubmit: async (values) => {
      if (values) {
        try {
          await workWithResponse(() => Api.registrationUserData(values)).then((res) => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
              setSubmitting(false);
              notification.success('Account successfully registered');
              navigate('/');
            } else if (res.error) {
              throw new Error(res.error);
            }
          });
        } catch (e: any) {
          notification.error(e.message);
        } finally {
          setSubmitting(false);
        }
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required field'),
      password: Yup.string().required('Required field'),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref('password'), undefined], 'Passwords don\'t match')
        .required('Required field'),
    }),
  });

  useEffect(() => {
    async function checkToken() {
      try {
        if (!token) {
          throw new Error('Token not set');
        }

        await workWithResponse(() => Api.registrationCheckToken({ token })).then((res) => {
          if (res.statusCode >= 200 && res.statusCode < 300) {
            setFieldValue('token', token);
            setIsLoading(false);
            return;
          }

          throw new Error('Invalid token');
        });
      } catch (e: any) {
        notification.error(e);
        navigate('/404');
      } finally {
        setIsLoading(false);
      }
    }

    checkToken();
  }, []);

  return (
    <SignUpStep2Styles>
      <div className="content">
        {isLoading ? <Loader /> : (
          <>
            <Link to="/"><Logo /></Link>

            <div className="formWrapper">
              <div className="formFieldsWrapper">
                <h1 className="textBold28">Sign up</h1>

                <div className="fieldsWrapper">
                  <InputField
                    placeholder="Enter name"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    label="Your name"
                    error={touched && touched.name && errors && errors.name ? errors.name : undefined}
                  />

                  <InputField
                    placeholder="Enter password"
                    label="Password"
                    name="password"
                    type="password"
                    onChange={handleChange}
                    value={values.password}
                    autoComplete="current-password"
                    showPasswordButton
                    error={touched && touched.password && errors && errors.password ? errors.password : undefined}
                  />

                  <InputField
                    label="Confirm password"
                    placeholder="Repeat password"
                    name="password_confirmation"
                    type="password"
                    onChange={handleChange}
                    value={values.password_confirmation}
                    autoComplete="current-password"
                    showPasswordButton
                    error={touched && touched.password_confirmation && errors && errors.password_confirmation ? errors.password_confirmation : undefined}
                  />

                  <p className="textBase14">
                    By creating an account, you agree to our
                    {' '}
                    <Link className="animationUnderline" to="/privacy-policy" target="_blank">Privacy Policy</Link>
                    {' '}
                    and
                    {' '}
                    <Link className="animationUnderline" to="/terms-of-use" target="_blank">Terms of Use.</Link>
                  </p>
                </div>
              </div>

              <div className="buttonsWrapper">
                <TextButton
                  text="Creat account"
                  onClick={handleSubmit}
                  fullWidth
                  theme="mainFilled"
                />
              </div>
            </div>

            <div />
          </>
        )}
      </div>

      <div className="rightSide">
        <div className="sloganContainer">
          <PlayIcon />

          <p className="textBold28">One-stop platform to boost your digital marketing</p>
        </div>
      </div>
    </SignUpStep2Styles>
  );
}

export default SignUpStep2;
