import styled from 'styled-components';

export const FormsStyles = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  
  padding-bottom: 60px;
  
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    margin-bottom: 24px;
  }
  
  .noDataContainer {
    display: flex;
    flex-direction: column;
    margin: auto;
    
    text-align: center;
    
    .noDataTextContainer {
      margin-bottom: 24px;

      h3 {
        margin: 12px;
      }
    }
    
    svg {
      max-width: 496px;
      max-height: 383px;
      
      width: 100%;
      height: auto;
    }
  }
`;
