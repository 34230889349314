import React, { useEffect } from 'react';
import { ProfileStyles } from '../ProfileStyles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputField from '../../../components/form/input/InputField';
import TextButton from '../../../components/buttons/button/TextButton';
import { passwordRegExp } from '../../../constants/regExp';
import { useAppSelector } from '../../../state/hooks';
import workWithResponse from '../../../helpers/workWithResponse';
import { Api } from '../../../api';
import ChangeUserData from '../../../state/entities/ChangeUserData';
import { useActions } from '../../../hooks/useActions';
import { notification } from '../../../helpers/notifications/toastify';
import LinksPath from '../../../components/other/linksPath/LinksPath';
import { ChangePasswordData } from '../../../enteties/types/user.types';

let originalValues = {
  name: '',
  email: '',
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

function EditProfile() {
  const { data: user } = useAppSelector((state) => state.user);
  const actions = useActions();

  useEffect(() => {
    originalValues = {
      name: user?.name || '',
      email: user?.email || '',
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    };
  }, []);

  const {
    handleChange, handleSubmit, handleBlur, setValues, values, errors, touched, isSubmitting, setTouched, setErrors, setFieldValue,
  } = useFormik({
    initialValues: {
      name: user?.name,
      email: user?.email,
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    onSubmit: async (values) => {
      if (values) {
        const newUserData: ChangeUserData = {
          id: user!.id,
          name: values.name!,
          email: values.email!,
        };

        if (values.newPassword) {
          const changePasswordData: ChangePasswordData = {
            old_password: values.currentPassword,
            new_password: values.newPassword,
          };

          await workWithResponse(() => Api.changePassword(changePasswordData)).then((res) => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
              notification.success('Password changed');

              setFieldValue('confirmNewPassword', '');
              setFieldValue('newPassword', '');
              setFieldValue('currentPassword', '');
            } else if (res.error) {
              notification.error(res.error);
            }
          });
        }

        await workWithResponse(() => Api.changeMyAccount(newUserData)).then((res) => {
          if (res.data) {
            actions.setUser({ ...user!, name: values.name!, email: values.email! });

            setFieldValue('name', values.name);
            setFieldValue('email', values.email);

            originalValues = {
              name: values.name!, email: values.email!, confirmNewPassword: '', newPassword: '', currentPassword: '',
            };

            notification.success('Profile data successfully updated.');

            setTouched({});
          } else if (res.error) {
            throw new Error(res.error);
          }
        }).catch((e) => {
          console.error(e);

          notification.error('Oops something went wrong');
        });
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required field'),
      email: Yup.string().email('Email is not valid').required('Required field'),
      currentPassword: Yup.string().when(['newPassword'], {
        is: (newPassword: string) => !!newPassword,
        then: (schema) => schema.required('Required field'),
        otherwise: (schema) => schema,
      }),
      newPassword: Yup.string()
        .when(['confirmNewPassword'], {
          is: (confirmNewPassword: string) => !!confirmNewPassword,
          then: (schema) => schema.required('New password is required when new password is provided'),
          otherwise: (schema) => schema,
        })
        .matches(passwordRegExp, 'Password must be at least 8 characters long and include at least 1 lowercase and 1 uppercase letter.'),
      confirmNewPassword: Yup.string()
        .when(['newPassword'], {
          is: (newPassword: string) => newPassword,
          then: (schema) => schema.required('Confirmation is required when new password is provided'),
          otherwise: (schema) => schema,
        })
        .oneOf([Yup.ref('newPassword'), undefined], 'Passwords must match'),
    }, [['confirmNewPassword', 'newPassword']]),
  });

  function handleDiscardChanges() {
    setTouched({});
    setValues(originalValues);
  }

  return (
    <ProfileStyles className="editProfile">
      <div className="headWrapper">
        <h1 className="textBold28">Settings</h1>

        <LinksPath
          pathArr={[{ path: '/profile', name: 'Profile' }, { path: '', name: 'Settings' }]}
        />
      </div>

      <div className="formContainer">
        <div className="fieldsWrapper">
          <InputField
            placeholder="Enter name"
            name="name"
            onChange={handleChange}
            value={values.name}
            label="Name"
            error={touched && touched.name && errors && errors.name ? errors.name : undefined}
          />

          <InputField
            placeholder="Enter e-mail"
            name="email"
            onChange={handleChange}
            value={values.email}
            label="Email"
            error={touched && touched.email && errors && errors.email ? errors.email : undefined}
            disabled
          />

          <InputField
            placeholder="Enter current password"
            label="Current password"
            name="currentPassword"
            type="password"
            onChange={handleChange}
            value={values.currentPassword}
            autoComplete="current-password"
            showPasswordButton
            error={touched && touched.currentPassword && errors && errors.currentPassword ? errors.currentPassword : undefined}
          />

          <InputField
            placeholder="Enter new password"
            label="New password password"
            name="newPassword"
            type="password"
            onChange={handleChange}
            value={values.newPassword}
            autoComplete="current-password"
            showPasswordButton
            error={touched && touched.newPassword && errors && errors.newPassword ? errors.newPassword : undefined}
          />

          <InputField
            placeholder="Confirm new password"
            label="Confirm new password"
            name="confirmNewPassword"
            type="password"
            onChange={handleChange}
            value={values.confirmNewPassword}
            autoComplete="current-password"
            showPasswordButton
            error={touched && touched.confirmNewPassword && errors && errors.confirmNewPassword ? errors.confirmNewPassword : undefined}
          />
        </div>

        <div className="buttonsContainer">
          <TextButton
            text="Save changes"
            type="submit"
            theme="mainFilled"
            fullWidth
            onClick={handleSubmit}
            title="Save changes"
          />

          <TextButton
            text="Cancel"
            type="button"
            theme="grey"
            fullWidth
            onClick={handleDiscardChanges}
            title="Cancel"
          />
        </div>
      </div>
    </ProfileStyles>
  );
}

export default EditProfile;
