import React, { useState } from 'react';
import { PreviewTextAreaStyles } from './PreviewTextAreaStyles';
import { InputFormDesignProps } from '../../../../../enteties/types/forms.types';

interface Props {
  styles: InputFormDesignProps;
  label: string;
  placeholder: string;
}

function PreviewTextArea({ styles, label, placeholder }: Props) {
  const [value, setValue] = useState<string>('');

  return (
    <PreviewTextAreaStyles styles={styles}>
      <span className="textSemiBold12">{label}</span>

      <textarea
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
        rows={4}
        // cols={maxCols}
        // disabled={disabled}
      />
    </PreviewTextAreaStyles>
  );
}

export default PreviewTextArea;
