import React from 'react';

function CrossedEyeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g
        stroke="#727272"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_245_1531)"
      >
        <path d="M14.95 14.95A8.392 8.392 0 0110 16.667C4.167 16.667.833 10 .833 10A15.375 15.375 0 015.05 5.05m3.2-1.517a7.6 7.6 0 011.75-.2c5.833 0 9.167 6.667 9.167 6.667a15.423 15.423 0 01-1.8 2.658m-5.6-.891a2.5 2.5 0 11-3.534-3.534M.833.833l18.334 18.334" />
      </g>
      <defs>
        <clipPath id="clip0_245_1531">
          <path fill="#fff" d="M0 0H20V20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CrossedEyeIcon;
