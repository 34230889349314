import styled from 'styled-components';

export const DateTimePickerStyles = styled.div`
  position: relative;
  max-width: 357px;
  
  &.open {
    .calendarWrapper {
      border-bottom: 1px solid #F0F0F0;
      border-radius: 2px;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
      
      width: 100%;
      background-color: #FFF;
      z-index: 1;
    }
    
    .datePreviewWrapper {
      position: relative;
      
      background: #FFF;
      
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #F0F0F0;
      }
    }
  }
  
  .react-datepicker-wrapper {
    display: none;
  }
  
  .calendarWrapper {
    display: none;
    
    position: absolute;
    height: fit-content;
    background: #FFF;
    
    &.open {
      display: block;
    }
  }
  
  .react-datepicker__time-list-item {}
  
  .react-datepicker-popper {
    transform: none !important;
    position: static !important;
  }
  
  .react-datepicker__triangle {
    display: none; !important;
  }
  
  .react-datepicker {
    background-color: inherit;
    color: var(--Gray-gray-9, #262626);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    border-radius: 0;
    border: none;
  }
  
  .react-datepicker__header {
    border-bottom: none;
    background: inherit;
  }

  .react-datepicker__day {
    color: var(--Gray-gray-9, #262626);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  .react-datepicker__day--selected {
    background-color: var(--Orange-1, #FC812B);
    color: #FFF;
  }

  .react-datepicker__time-list-item--selected {
    background-color: var(--Orange-1, #FC812B) !important;
    color: #FFF;
  }
  
  .react-datepicker-time__header {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
  }

  .react-datepicker-popper {
    display: flex;
    justify-content: center;
  }
  
  .datePreviewWrapper {
    //padding: 9px 8px;
    
    .datePreview {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      
      width: 100%;
      padding: 12px 8px;

      border-radius: 4px;
      border: 1px solid #C2C9D1;
      text-align: center;
      
      .splitter {
        background: #D9D9D9;
        height: 20px;
        width: 1px;
      }
    }
  }
  
  .selectButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px;
    width: 100%;

    color: var(--Orange-1, #FC812B);
    transition: .3s ease background-color;
    
    &:hover {
      background: var(--grey-3);
    }
  }
  
  .datePickerFooter {
    padding-top: 9px;
  }

  .includeTimeHandlerWrapper {
    padding: 0 11px;
  }
  
  .pickerHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    padding: 8px 12px;
    
    .buttonsWrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      
      button {
        display: flex;
        align-items: center;
      }
    }
  }
`;
