import React from 'react';

function LogoWhite() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="145"
      height="39"
      fill="none"
      viewBox="0 0 145 39"
    >
      <path
        fill="#fff"
        d="M80.723 38.928c-1.542 0-2.916-.334-4.123-1.002a7.684 7.684 0 01-2.813-2.851c-.668-1.208-1.002-2.595-1.002-4.162V18.466h5.279v11.676c0 1.285.373 2.312 1.117 3.083.771.77 1.799 1.156 3.083 1.156.874 0 1.657-.167 2.35-.5.72-.36 1.31-.861 1.773-1.504v-13.91h5.28v20.076h-5.28v-1.619c-1.541 1.336-3.43 2.004-5.664 2.004zM106.917 38.928a17.75 17.75 0 01-6.396-1.194 17.738 17.738 0 01-5.55-3.468l3.199-4.008c1.567 1.387 3.07 2.39 4.509 3.006 1.464.616 3.005.924 4.624.924 1.13 0 2.106-.128 2.928-.385.822-.282 1.452-.68 1.888-1.194a2.618 2.618 0 00.694-1.812c0-.924-.334-1.63-1.002-2.119-.668-.514-1.811-.912-3.429-1.195l-5.357-.924c-2.286-.386-4.033-1.195-5.24-2.428-1.182-1.233-1.773-2.826-1.773-4.778 0-1.67.424-3.121 1.272-4.355.873-1.233 2.093-2.17 3.66-2.813 1.593-.667 3.481-1.001 5.665-1.001 1.978 0 3.943.334 5.896 1.002 1.978.667 3.725 1.592 5.24 2.774l-3.005 4.162c-2.826-2.158-5.665-3.237-8.516-3.237-1.002 0-1.876.128-2.621.385-.745.257-1.323.617-1.734 1.08-.385.436-.578.962-.578 1.579 0 .822.296 1.464.887 1.927.59.436 1.579.77 2.967 1.002l5.086.847c2.698.437 4.714 1.298 6.05 2.582 1.336 1.259 2.004 2.929 2.004 5.01 0 1.746-.463 3.275-1.387 4.585-.925 1.285-2.248 2.286-3.969 3.006-1.696.693-3.7 1.04-6.012 1.04zM129.545 38.543V16.5h-9.094V11.57h23.737V16.5h-9.056v22.042h-5.587z"
      />
      <path
        fill="#FC812B"
        fillRule="evenodd"
        d="M66.434 19.5c0 5.497-2.283 10.46-5.952 13.995l3.589 5.433H46.89a19.375 19.375 0 01-14.098-6.183H20.504a1.875 1.875 0 110-3.75h9.548a19.313 19.313 0 01-1.696-4.034H9.263a1.875 1.875 0 110-3.75h18.39a19.684 19.684 0 01.033-3.77h-8.633a1.875 1.875 0 010-3.75h9.408a19.333 19.333 0 011.848-4.129h-3.35a1.875 1.875 0 010-3.75h6.26A19.368 19.368 0 0147.006.072c10.73 0 19.428 8.698 19.428 19.428zM15.988 5.812a1.875 1.875 0 100 3.75h4.39a1.875 1.875 0 000-3.75h-4.39zm-5.983.161a1.875 1.875 0 000 3.75h.264a1.875 1.875 0 100-3.75h-.264zm-7.318 7.718a1.875 1.875 0 000 3.75h11.096a1.875 1.875 0 000-3.75H2.687zM44.75 9.103c.209-1.113.347-1.41.799-1.707.23-.152.471-.181 1.496-.182 1.777 0 2.024.184 2.295 1.713.173.977.208 1.216.34 1.298.051.032.116.04.208.059.167.033.67.233 1.12.444l.817.383.89-.617c.49-.34 1.043-.646 1.23-.68.525-.1 1.07.21 1.913 1.082 1.121 1.162 1.128 1.505.056 3.038l-.538.768.23.441c.127.242.346.754.488 1.137l.258.696.989.164c1.66.276 1.944.583 2.004 2.163.068 1.765-.255 2.179-1.926 2.473-.641.113-1.047.232-1.096.322-.092.171-.767 1.838-.836 2.064-.029.094.197.515.544 1.016.971 1.402.93 1.745-.362 3.01-1.238 1.214-1.555 1.233-3.02.187l-.801-.573-.666.317a9.727 9.727 0 01-1.087.442c-.231.07-.456.195-.5.28-.043.085-.156.595-.25 1.133-.16.907-.2 1.005-.54 1.332-.429.41-.696.48-1.85.48-.684 0-.93-.043-1.28-.221-.573-.29-.749-.629-.943-1.813l-.162-.987-.791-.304a13.62 13.62 0 01-1.133-.487l-.34-.182-.833.57c-1.514 1.038-1.82 1.029-2.969-.09-.891-.866-1.042-1.114-1.042-1.713 0-.379.086-.565.633-1.367l.633-.928-.378-.816c-.209-.449-.409-.957-.445-1.13-.073-.349-.094-.359-1.128-.52-.976-.154-1.512-.398-1.757-.8-.17-.279-.198-.492-.198-1.536 0-1.037.027-1.257.193-1.525.273-.443.693-.634 1.799-.82l.965-.163.22-.613c.122-.337.336-.844.476-1.126l.255-.513-.634-.93c-.564-.826-.634-.982-.634-1.4 0-.633.15-.886 1.014-1.707.805-.766 1.257-.97 1.799-.815.17.049.704.36 1.186.69.875.6.878.602 1.136.432.143-.093.64-.313 1.107-.489l.847-.319.199-1.06z"
        clipRule="evenodd"
      />
      <path
        fill="#FF8E3F"
        d="M47.087 15.388a4.113 4.113 0 00-4.105 4.111 4.113 4.113 0 004.105 4.111 4.112 4.112 0 004.104-4.11 4.112 4.112 0 00-4.104-4.112zm0 7.309a3.198 3.198 0 010-6.395 3.199 3.199 0 013.192 3.197 3.199 3.199 0 01-3.192 3.198z"
      />
    </svg>
  );
}

export default LogoWhite;
