import styled from 'styled-components';

export const MobileFiltersStyles = styled.div`
  position: fixed;
  left: 0;
  top: 60px;

  width: 100vw;
  height: calc(100dvh - 60px);
  padding: 35px 16px;
  box-sizing: border-box;

  display: none;
  opacity: 0;
  transition: .3s ease opacity;

  background: #FCFCFC;
  overflow: auto;
  
  z-index: 1;

  @media (max-width: 1023px) {
    &.open {
      display: block;
      opacity: 1;
    }
  }

  @media (min-width: 1024px) {
    display: none;
  }

  .filtersHead {
    position: relative;
    
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 24px;
    
    .clearButton {
      position: absolute;
      right: 0;
      
      color: var(--Orange-1, #FC812B);
    }

    .closeButton {
      svg {
        path {
          transition: .3s stroke;
        }
      }

      &:hover {
        svg {
          path {
            stroke: #00C8CB;
          }
        }
      }
    }
  }
  
  .options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    button {
      display: flex;
      justify-content: space-between;
      
      width: 100%;
      padding: 12px 0;

      border-bottom: 1px solid var(--Stroke, #EEE);
      
      .arrowLeft {
        transform: rotate(180deg);
      }
      
      &.withArrowAnimation {
        svg {
          path {
            transition: .3s stroke;
          }
        }

        &:hover {
          svg {
            path {
              stroke: #00C8CB;
            }
          }
        }
      }
    }
  }
  
  .applyButton {
    position: fixed;
    bottom: 20px;
    width: calc(100dvw - 32px);
  }
`;
