import React from 'react';
import { TLeadApiComment } from '../../../../enteties/types/comments.types';
import { useAppSelector } from '../../../../state/hooks';
import Avatar from '../../../other/avatar/Avatar';
import { CommentsListStyles } from './CommentsListStyles';
import { format } from 'date-fns';

function CommentsList({ comments }: {comments: TLeadApiComment[]}) {
  const { data: user } = useAppSelector((state) => state.user);

  return (
    <CommentsListStyles>
      {comments.map((comment) => (
        <li key={comment.id}>
          <div className="commentHead">
            <div className="userContainer">
              <Avatar url={null} />

              <span className="textSemiBold14">{user?.name}</span>
            </div>

            <span className="textBase12">{format(new Date(comment.created_at), 'dd/MM/yy HH:mm')}</span>
          </div>

          <div className="commentBody">
            <p className="textBase14">{comment.message}</p>
          </div>
        </li>
      ))}
    </CommentsListStyles>
  );
}

export default CommentsList;
