import { propertyDateAcceptedDateFormats, propertyDateAcceptedTimeFormats } from '../../constants/properties';

export type TBaseProperty = {
  id: number;
  form_id: number;
  name: string;
}

export type TDateAcceptedFormats = typeof propertyDateAcceptedDateFormats[number];
export type TTimeAcceptedFormats = typeof propertyDateAcceptedTimeFormats[number];

export enum PropertyTypes {
  number = 1,
  text = 2,
  date = 3
}

const propertyTypes = [PropertyTypes.number, PropertyTypes.text, PropertyTypes.date] as const;

export type TPropertyTypes = typeof propertyTypes[number];

export type TDateTimeProperty = TBaseProperty & {
  type: PropertyTypes.date;
  date_format: TDateAcceptedFormats;
  time_format: TTimeAcceptedFormats;
}

export type TNumberProperty = TBaseProperty & {
  type: PropertyTypes.number;
}

export type TTextProperty = TBaseProperty & {
  type: PropertyTypes.text;
}

export type TProperty = TDateTimeProperty | TNumberProperty | TTextProperty

export type TApiProperty = TProperty & {id: number, form_id: number}

export type TLeadPropertyBaseValueTypes = {
  id?: number;
  property_id: number;
  lead_id: number;
}

export type TLeadPropertyTextFieldTypes = TLeadPropertyBaseValueTypes & {
  value: string;
  type: PropertyTypes.text,
}

export type TLeadPropertyNumberFieldTypes = TLeadPropertyBaseValueTypes & {
  value: number;
  type: PropertyTypes.number,
}

export type TLeadPropertyDateFieldTypes = TLeadPropertyBaseValueTypes & {
  value: string;
  type: PropertyTypes.date,
}

export type TPropertyValuesData = TLeadPropertyTextFieldTypes | TLeadPropertyNumberFieldTypes | TLeadPropertyDateFieldTypes;

export type TApiPropertyValueData = TPropertyValuesData & {id: number};
