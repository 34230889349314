import {
  PropertyTypes,
  TDateAcceptedFormats,
  TDateTimeProperty,
  TNumberProperty,
  TPropertyTypes,
  TTextProperty,
  TTimeAcceptedFormats,
} from '../enteties/types/formProperties.types';
import { TextIcon } from '../assets/icons/properties/TextIcon';
import { NumberIcon } from '../assets/icons/properties/NumberIcon';
import { DateIcon } from '../assets/icons/properties/DateIcon';

// eslint-disable-next-line no-undef
export const selectTypeDropDownData: {name: string, type: TPropertyTypes, icon: JSX.Element}[] = [
  {
    name: 'Text',
    type: PropertyTypes.text,
    icon: TextIcon,
  },
  {
    name: 'Number',
    type: PropertyTypes.number,
    icon: NumberIcon,
  },
  {
    name: 'Date',
    type: PropertyTypes.date,
    icon: DateIcon,
  },
];

export const propertyDateAcceptedDateFormats = ['MMMM d, yyyy', 'MM/dd/yyyy', 'dd/MM/yyyy', 'yyyy/MM/dd'] as const;
export const propertyDateAcceptedTimeFormats = ['h:mm aa', 'HH:mm'] as const;

export const createTextPropertyInitialValues: TTextProperty = {
  name: 'Text',
  type: PropertyTypes.text,
  id: 0,
  form_id: 0,
};

export const createNumberPropertyInitialValues: TNumberProperty = {
  name: 'Number',
  type: PropertyTypes.number,
  form_id: 0,
  id: 0,
};

export const createDatePropertyInitialValues: TDateTimeProperty = {
  name: 'Date',
  date_format: 'dd/MM/yyyy',
  time_format: 'HH:mm',
  type: PropertyTypes.date,
  id: 0,
  form_id: 0,
};

export const dateFormatNamesBook: {[key in TDateAcceptedFormats]: string} = {
  'MMMM d, yyyy': 'Full date',
  'MM/dd/yyyy': 'Month/Day/Year',
  'dd/MM/yyyy': 'Day/Month/Year',
  'yyyy/MM/dd': 'Year/Month/Day',
};

export const timeFormatNamesBook: {[key in TTimeAcceptedFormats]: string} = {
  'h:mm aa': '12 hour',
  'HH:mm': '24 hour',
};

export const createPropertyInitialDataBook: {[key in TPropertyTypes]: TTextProperty | TNumberProperty | TDateTimeProperty} = {
  [PropertyTypes.text]: createTextPropertyInitialValues,
  [PropertyTypes.number]: createNumberPropertyInitialValues,
  [PropertyTypes.date]: createDatePropertyInitialValues,
};
