import React from 'react';
import { InputFormDesignProps } from '../../../../../enteties/types/forms.types';
import { PreviewInputStyles } from './PreviewInputStyles';

interface Props {
  styles: InputFormDesignProps;
  label: string;
  value?: string;
  placeholder: string;
  disabled?: boolean;
}

function PreviewInput({
  styles, label, placeholder, disabled, value,
}: Props) {
  return (
    <PreviewInputStyles styles={styles}>
      <span className="textSemiBold12">{label}</span>
      <input value={value} type="text" placeholder={placeholder} disabled={disabled} />
    </PreviewInputStyles>
  );
}

export default PreviewInput;
