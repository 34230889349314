import styled from 'styled-components';

export const MenuStyles = styled.div`
  position: relative;
  
  .openButton {
    display: flex;
    justify-content: center;
    
    @media (min-width: 600px) {
      width: 40px;
    }
  }
  
  .userMenu {
    display: flex;
    flex-direction: column;
    
    padding: 12px 0;
    
    right: 46px;
    top: 0;
    
    button {
      display: flex;
      align-items: center;
      gap: 8px;
      
      padding: 4px 12px;
      
      white-space: nowrap;
      
      transition: .3s ease background-color;
      
      &:hover {
        background: var(--grey-3);
      }
      
      &.red {
        color: var(--Red-2, #F5222D);
      }
    }
  }
`;
