import styled, { keyframes } from 'styled-components';

const animationRocketOnHover = keyframes`
  from {
    transform: rotate(0deg);
  }
  
  25% {
    transform: rotate(2deg);
  }
  
  75% {
    transform: rotate(-2deg);
  }

  to {
    transform: rotate(0);
  }
`;

const animationRocketCloudOnHover = keyframes`
  from {
    transform: translate(0, 0);
  }
  
  50% {
    transform: translate(-50%, 50%);
  }
  
  to {
    transform: translate(0, 0);
  }
`;

export const StepFieldsStyles = styled.div`
  max-width: 840px;

  padding-bottom: 160px;

  @media (min-width: 768px) {
    padding-bottom: 60px;
  }

  .step1Head {
    display: flex;
    flex-direction: column;
    gap: 16px;

    padding: 30px 24px 24px 24px;
    margin-bottom: 24px;

    border-radius: 8px 8px 0px 0px;
    overflow: hidden;

    border-top: 12px solid #FEC9A4;
    border-right: 1px solid var(--Stroke, #EEE);
    border-bottom: 1px solid var(--Stroke, #EEE);
    border-left: 1px solid var(--Stroke, #eee);

    @media (min-width: 1024px) {
      margin-bottom: 32px;
    }
    
    .splitter {
      width: calc(100% + 48px);
      height: 1px;
      transform: translateX(-24px);
      
      background: #FEC9A4;
    }

    .block {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .inputWrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    }

    .headTitle {
      font-size: 28px;
      font-weight: 700;
      line-height: 42px;
      letter-spacing: 0.56px;

      @media (min-width: 768px) {
        font-size: 38px;
        letter-spacing: 0.76px;
      }
    }

    input {
      font-size: 16px;
      box-sizing: border-box;
      padding: 10px 8px;
      transition: 0.3s all;
      font-family: 'Inter', sans-serif;
      outline: none;
      border: none;

      border-bottom: 1px solid var(--gray-light, #C2C9D1);
      background: #FDFDFD;

      &.error {
        border-bottom: 1px solid var(--error-default, #f03738);
      }

      &:focus {
        border-bottom: 1px solid var(--orange-1, #FC812B);
      }

      &::placeholder {
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        line-height: 28px;
        color: var(--black-light, rgba(0, 0, 0, 0.50));
      }
    }
  }

  .caption {
    display: inline-block;
    margin-bottom: 24px;
  }

  .fieldsWrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @media (min-width: 768px) {
      justify-content: space-between;
    }

    .emptyDiv {
      display: none;

      @media (min-width: 768px) {
        display: block;
      }
    }

    .addFieldPanel {
      position: fixed;
      bottom: 80px;

      display: flex;
      flex-direction: row;
      gap: 20px;

      width: fit-content;
      padding: 16px;
      border-radius: 8px;
      border: 1px solid var(--Stroke, #EEE);
      background: #FFF;
      height: fit-content;

      @media (min-width: 768px) {
        position: sticky;
        top: 100px;
        bottom: auto;

        flex-direction: column;
      }

      button {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:disabled {
          opacity: 0.3;
        }

        &:hover:not(:disabled) {
          svg {
            transform: scale(1.04);

            path, rect {
              stroke: #00C8CB;
            }
          }
        }

        svg {
          transition: .3s ease transform;

          path, rect {
            transition: 0.3s ease stroke, .3s ease fill;
          }
        }
      }

      @media (min-width: 768px) {
        flex-direction: column;
        gap: 20px;
      }
    }

    .fieldsContainer {
      display: flex;
      flex-direction: column;
      gap: 24px;

      width: 100%;

      .fieldArrayFieldWrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;

        padding: 24px;
        margin-bottom: 24px;

        border-radius: 8px;
        border: 1px solid var(--Stroke, #EEE);
        background: #FFF;

        .splitter {
          width: 100%;
          height: 1px;
          background: #EEE;
        }

        .fieldArrayFields {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }

        .fieldArrayFieldFooter {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .fieldArrayFieldButtons {
            display: flex;
            gap: 18px;

            .deleteButton {
              &:hover {
                svg {
                  path {
                    stroke: #F5222D;
                  }
                }
              }

              svg {
                path {
                  transition: .3s ease stroke;
                }
              }
            }

            .changePositionButtonsContainer {
              display: flex;
              flex-direction: column;

              button {
                &:hover:not(:disabled) {
                  svg {
                    path {
                      stroke: #00C8CB;
                    }
                  }
                }

                svg {
                  path {
                    transition: .3s ease stroke;
                  }
                }

                &:disabled {
                  opacity: 0.4;
                }
              }
            }
          }
        }
      }

      @media (min-width: 768px) {
        width: calc(100% - 20px - 66px);
      }

      .caption {
        text-align: left;
      }
    }

    .emptyListContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;

      .rocket {
        width: 159px;
        height: auto;
        
        &:hover {
          animation: ${animationRocketOnHover} 2s infinite ease-in-out;
          
          .cloud {
            animation: ${animationRocketCloudOnHover} 2s infinite ease-in-out;
          }
        }

        @media (min-width: 768px) {
          width: 192px;
        }
      }
    }
  }
`;
