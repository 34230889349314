import React from 'react';
import {
  FormFieldsTypes, TField, TForm, TFormDesign,
} from '../../../../enteties/types/forms.types';
import PreviewInput from '../inputsPreview/input/PreviewInput';
import { FormPreviewStyles } from './FormPreviewStyles';
import PreviewButton from '../previewElements/button/PreviewButton';
import PreviewTextArea from '../inputsPreview/textArea/PreviewTextArea';
import TelInput from '../inputsPreview/phoneInput/TelInput';

interface FormPreviewProps {
  formData: Omit<TForm, 'id'>;
  formDesign: TFormDesign;
}

function getFieldByType(field: TField, formDesign: TFormDesign) {
  switch (field.type) {
    case FormFieldsTypes.email:
      return <PreviewInput styles={formDesign.inputs} placeholder={field.placeholder} label={field.label} />;

    case FormFieldsTypes.phone:
      return <TelInput styles={formDesign.inputs} placeholder={field.placeholder} label={field.label} />;

    case FormFieldsTypes.text_input:
      return <PreviewInput styles={formDesign.inputs} placeholder={field.placeholder} label={field.label} />;

    case FormFieldsTypes.textarea:
      return <PreviewTextArea styles={formDesign.inputs} placeholder={field.placeholder} label={field.label} />;

    default:
      return null;
  }
}

function FormPreview({ formData, formDesign }: FormPreviewProps) {
  return (
    <FormPreviewStyles styles={formDesign}>
      <span className="textSemiBold22 title">Preview</span>

      <div className="formPreview">
        <div className="formHead">
          {formDesign.image.url && (
            <div>
              <img className="image" src={formDesign.image.url} alt="" />
            </div>
          )}

          <span className="title">{formData.title}</span>

          <span className="textBase16 description">{formData.description}</span>
        </div>

        <div className="fieldsWrapper">
          {formData.fields.map((field) => getFieldByType(field, formDesign))}

          <PreviewButton styles={formDesign.submit_button} buttonData={formData.submit_button} />
        </div>
      </div>
    </FormPreviewStyles>
  );
}

export default FormPreview;
