import React from 'react';

function PlayIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="26"
      fill="none"
      viewBox="0 0 23 26"
    >
      <path fill="#00C8CB" d="M23 13L.5 25.99V.01L23 13z" />
    </svg>
  );
}

export default PlayIcon;
