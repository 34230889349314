import React, { useCallback, useState } from 'react';
import TextButton from '../../../../buttons/button/TextButton';
import { FiltersIcon } from '../../../../../assets/icons/other/FiltersIcon';
import { MobileFiltersStyles } from '../../MobileFiltersStyles';
import { ArrowLeft } from '../../../../../assets/icons/other/ArrowLeft';
import classNames from 'classnames';
import FiltersMobileSelect from '../../../../filters/mobile/select/FiltersMobileSelect';
import { TApiStatus } from '../../../../../enteties/types/statuses.types';
import { TApiForm } from '../../../../../enteties/types/forms.types';
import { LeadsTableVersionFilters } from '../../../tableVersion/LeadsTableVersion';
import { filtersKeys } from '../filtersKeys';

type ScreensKeys = 'filters' | 'forms' | 'statuses' | 'date';

const settingsOptions: {name: string, key: ScreensKeys}[] = [
  {
    name: 'Form',
    key: 'forms',
  },
  {
    name: 'Status',
    key: 'statuses',
  },
  // {
  //   name: 'Date',
  //   key: 'date',
  // },
];

interface Props {
  statuses: TApiStatus[];
  forms: TApiForm[];
  setFilters: (data: LeadsTableVersionFilters) => void;
  filters: LeadsTableVersionFilters;
}

function TableMobileFilters({
  statuses, forms, setFilters, filters,
}: Props) {
  const [filtersActiveScreen, setFiltersActiveScreen] = useState<null | ScreensKeys>(null);

  const handleBack = useCallback(() => {
    setFiltersActiveScreen('filters');
  }, []);

  const handleApplyFilters = useCallback((newFiltersParams: Partial<LeadsTableVersionFilters>) => {
    setFilters({ ...filters, ...newFiltersParams });
  }, [filters]);

  return (
    <div>
      <TextButton
        text="Filters"
        icon={FiltersIcon}
        theme="mainFilled"
        onClick={() => setFiltersActiveScreen('filters')}
        fullWidth
      />

      <MobileFiltersStyles className={classNames({ open: filtersActiveScreen === 'filters' })}>
        <div className="filtersHead">
          <button type="button" className="closeButton" onClick={() => setFiltersActiveScreen(null)}>
            {ArrowLeft}
          </button>

          <span className="textSemiBold18">Filters</span>

          <div style={{ width: 24 }} />
        </div>

        <div className="options">
          {settingsOptions.map((option) => (
            <button className="textSemiBold18 withArrowAnimation" type="button" onClick={() => setFiltersActiveScreen(option.key)}>
              {option.name}

              {ArrowLeft}
            </button>
          ))}
        </div>
      </MobileFiltersStyles>

      <FiltersMobileSelect
        options={statuses}
        open={filtersActiveScreen === 'statuses'}
        multipleChoice
        nameKey="name"
        valueKey="id"
        handleClose={handleBack}
        searchParamsKey={filtersKeys.statuses}
      />

      <FiltersMobileSelect
        options={forms}
        open={filtersActiveScreen === 'forms'}
        multipleChoice
        nameKey="name"
        valueKey="id"
        handleClose={handleBack}
        searchParamsKey={filtersKeys.form}
      />
    </div>
  );
}

export default TableMobileFilters;
