import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, getMonth, getYear } from 'date-fns';
import { ArrowRight } from '../../../assets/icons/formElements/ArrowRight';
import { ArrowLeft } from '../../../assets/icons/formElements/ArrowLeft';
import { DateTimePickerStyles } from './DateTimePickerStyles';
import ToggleButton from '../toggleButton/ToggleButton';
import classNames from 'classnames';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { TDateAcceptedFormats, TTimeAcceptedFormats } from '../../../enteties/types/formProperties.types';
import { useIsFirstRender } from 'usehooks-ts';

interface Props {
  value: string | null;
  setValue(stingData: string): void;
  timeFormat: TTimeAcceptedFormats,
  dateFormat: TDateAcceptedFormats,
}

function DateTimePicker({
  value, setValue, timeFormat, dateFormat,
}: Props) {
  const isFirst = useIsFirstRender();

  const [date, setDate] = useState<Date | null>(new Date());
  const [includeTime, handleIncludeTime] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);

  const elementRef = useRef<null | HTMLDivElement>(null);

  const months = useMemo(() => [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ], []);

  const handleIncludeTimeChange = (newValue: boolean) => {
    handleIncludeTime(newValue);

    if (!newValue) {
      setDate((date) => {
        if (date) {
          return new Date(date.getFullYear(), date.getMonth(), date.getDate());
        }
        return null;
      });
    }
  };

  useEffect(() => {
    setDate(value ? new Date(value) : null);
  }, [value]);

  function onClickToSelectButton() {
    setValue(date?.toISOString() || '');

    setOpen(false);
  }

  useClickOutside(elementRef, () => (open ? setOpen(false) : null));

  return (
    <DateTimePickerStyles ref={elementRef} className={classNames({ open })}>
      <div className="datePreviewWrapper">
        <button
          type="button"
          className="datePreview textBase14"
          onClick={() => setOpen(!open)}
        >
          {value && format(new Date(value), dateFormat)}
          {includeTime && (
            <>
              <div className="splitter" />
              {value && format(new Date(value), timeFormat)}
            </>
          )}
        </button>
      </div>

      <div className={classNames('calendarWrapper', { open })}>
        <DatePicker
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              className="pickerHead"
            >
              <span className="textSemiBold14">
                {months[getMonth(date)]}
                {' '}
                {getYear(date)}
              </span>

              <div className="buttonsWrapper">
                <button className="arrowLeft" type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                  {ArrowLeft}
                </button>

                <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                  {ArrowRight}
                </button>
              </div>
            </div>
          )}
          customInput={(
            <span className="visually-hidden" />
          )}
          selected={date}
          onChange={(date) => setDate(date)}
          dateFormat="MMMM d, yyyy HH:mm"
          showTimeSelect={includeTime}
          // inline
          timeFormat={timeFormat}
          timeIntervals={5}
          open={open}
          onCalendarOpen={() => setOpen(true)}
          onCalendarClose={() => setOpen(false)}
        />

        <div className="datePickerFooter">
          <div className="includeTimeHandlerWrapper">
            <ToggleButton theme="orange" label="Include time" value={includeTime} onChange={() => handleIncludeTimeChange(!includeTime)} name="includeTime" />
          </div>

          <button type="button" className="selectButton textSemiBold14" onClick={onClickToSelectButton}>
            Select
          </button>
        </div>
      </div>
    </DateTimePickerStyles>
  );
}

export default DateTimePicker;
