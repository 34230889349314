import React from 'react';

function UserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill="#FCFCFC"
        d="M16 5.333A5.333 5.333 0 1116 16a5.333 5.333 0 010-10.666zm0 13.334c5.893 0 10.667 2.386 10.667 5.333v2.667H5.333V24c0-2.947 4.774-5.333 10.667-5.333z"
      />
    </svg>
  );
}

export default UserIcon;
