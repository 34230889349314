import styled, { keyframes } from 'styled-components';

const hearthAnimation = ({ positionX, positionY }: {positionX: number, positionY: number }) => (
  keyframes`
      0% {
        opacity: 0;
        transform: translate(0, 0);
      }
      25% {
        opacity: 0.75;
        transform: translate(${(positionX / 100) * 25}px, ${(positionY / 100) * 25}px);
      }
      50% {
          opacity: 1;
          transform: translate(${(positionX / 100) * 50}px, ${(positionY / 100) * 50}px);
      }
      75% {
        opacity: 0.75;
        transform: translate(${(positionX / 100) * 75}px, ${(positionY / 100) * 75}px);
      }
      100% {
        opacity: 0;
        transform: translate(${positionX}px, ${positionY}px);
      }
    `
);

export const UpgradesItemStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  
  padding: 16px;

  border-radius: 8px;
  border: 1px solid var(--Stroke, #EEE);
  background: #FFF;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  
  @media (min-width: 650px) {
    flex-direction: row;
    gap: 20px;
  }
  
  @media (min-width: 768px) {
    padding: 24px;
  }
  
   .rightPart {
     .textContainer {
       display: flex;
       flex-direction: column;
       gap: 8px;
       
       margin-bottom: 16px;
     }

     .hearthWrapper {
       position: relative;
       
       display: flex;
       align-items: center;
       
       .animationHearths {
         position: absolute;
         
         display: none;
         
         top: 0;
         
         &.showAnimation {
           display: block;
           
           svg {
             position: absolute;

             path {
               stroke: #FC812B;
             }

             opacity: 0;

             &:nth-of-type(1) {
               animation: ${hearthAnimation({ positionX: 25, positionY: -50 })} 0.5s linear forwards;
             }

             &:nth-of-type(2) {
               animation: ${hearthAnimation({ positionX: 0, positionY: -40 })} 0.5s linear forwards;
             }

             &:nth-of-type(3) {
               animation: ${hearthAnimation({ positionX: -25, positionY: -60 })} 0.5s linear forwards;
             }
           }
         }
       }
     }
     
     .addToWishlistButton {
       width: 100%;
       
       @media (min-width: 650px) {
         width: fit-content;
       }
       
       .children {
         display: flex;
         align-items: center;
         gap: 8px;
         
         span {
           transform: translateY(1px);
         }
       }
       
       &.added {
         svg {
           path {
             fill: #FFF;
           }
         }
       }
     }
   }
  
  img {
    width: 100%;
    height: auto;
    object-fit: cover;

    @media (min-width: 650px) {
      width: 238px;
      height: 180px;
    }
  }
`;
