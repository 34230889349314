import React, { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputField from '../../../components/form/input/InputField';
import { SignInStyles } from './SignInStyles';
import TextButton from '../../../components/buttons/button/TextButton';
import Logo from '../../../assets/Logo';
import { useAppDispatch } from '../../../state/hooks';
import { loginUser } from '../../../state/slices/userSlice';
import PlayIcon from '../../../assets/icons/authentefication/PlayIcon';

import { notification } from '../../../helpers/notifications/toastify';

function SignIn() {
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    handleChange, handleSubmit, handleBlur, setValues, values, errors, touched, isSubmitting,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values) => {
      if (values) {
        dispatch(loginUser(values)).unwrap().then((res) => console.log()).catch((error) => notification.error(error));
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Email is not valid').required('Required field'),
      password: Yup.string().required('Required field'),
    }),
  });

  useEffect(() => {
    if (searchParams.get('password')) {
      notification.success('Your password has been successfully updated.');

      searchParams.delete('password');

      const newSearchParams = new URLSearchParams(searchParams.toString());

      setSearchParams(newSearchParams);
    }
  }, []);

  return (
    <SignInStyles>
      <div className="content">
        <Link to="/"><Logo /></Link>

        <div className="formWrapper">
          <div className="formFieldsWrapper">
            <h1 className="textBold28">Sign in</h1>

            <div className="fieldsWrapper">
              <InputField
                placeholder="Enter e-mail"
                name="email"
                onChange={handleChange}
                value={values.email}
                label="Your e-mail"
                error={touched && touched.email && errors && errors.email ? errors.email : undefined}
              />

              <div className="passwordInputWrapper">
                <Link className="textBase12 animationUnderline" to="/forgot-password">Forgot your password?</Link>

                <InputField
                  placeholder="Enter password"
                  label="Password"
                  name="password"
                  type="password"
                  onChange={handleChange}
                  value={values.password}
                  autoComplete="current-password"
                  showPasswordButton
                  error={touched && touched.password && errors && errors.password ? errors.password : undefined}
                />
              </div>
            </div>
          </div>

          <div className="buttonsWrapper">
            <TextButton
              text="Sign in"
              onClick={handleSubmit}
              fullWidth
              theme="mainFilled"
            />
          </div>

          <div className="signInWrapper">
            <span className="textBase16">{'Don\'t have an account?'}</span>
            {' '}
            <Link className="textSemiBold16 animationUnderline" to="/sign-up">
              Sign up
            </Link>
          </div>
        </div>

        <div />
      </div>

      <div className="rightSide">
        <div className="sloganContainer">
          <PlayIcon />

          <p className="textBold28">One-stop platform to boost your digital marketing</p>
        </div>
      </div>
    </SignInStyles>
  );
}

export default SignIn;
