import styled from 'styled-components';

export const ListMenuStyles = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  margin-bottom: 32px;
  
  li {
    padding: 12px;
    
    border-radius: 8px;
    border: 1px solid #EAECF0;
    background: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  }
`;
