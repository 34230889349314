import styled, { keyframes } from 'styled-components';

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ModalStyles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(60, 60, 60, 0.60);
  display: grid;
  place-items: center;
  padding: 20px;
  box-sizing: border-box;

  animation: ${fadeInAnimation} 0.3s ease forwards;

  .modalBody {
    position: relative;
    
    background: #fff;
    padding: 32px;
    box-sizing: border-box;
    
    @media (min-width: 768px) {
      padding: 48px;
    }
    
    .closeButton {
      position: absolute;
      right: 10px;
      top: 10px;
      
    }
  }
`;
