import * as Yup from 'yup';
import { FormFieldsTypes, TField } from '../../../../enteties/types/forms.types';

export const validationSchema = Yup.object().shape({
  title: Yup.string().required('Required field'),
  name: Yup.string().required('Required field'),
  description: Yup.string(),
  fields: Yup.array().of(
    Yup.lazy((item: TField) => {
      const type = item.type;

      if (type === FormFieldsTypes.phone) {
        return Yup.object({
          label: Yup.string().required('Required field'),
          placeholder: Yup.string().required('Required field'),
        });
      }

      if (type === FormFieldsTypes.textarea) {
        return Yup.object({
          label: Yup.string().required('Required field'),
          placeholder: Yup.string().required('Required field'),
        });
      }

      if (type === FormFieldsTypes.text_input) {
        return Yup.object({
          label: Yup.string().required('Required field'),
          placeholder: Yup.string().required('Required field'),
        });
      }

      if (type === FormFieldsTypes.email) {
        return Yup.object({
          label: Yup.string().required('Required field'),
          placeholder: Yup.string().required('Required field'),
        });
      }

      return Yup.object();
    }),
  ),
  submit_button: Yup.object({
    text: Yup.string().required('Required field'),
  }),
});
