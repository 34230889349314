import styled from 'styled-components';

export const LeadsListStyles = styled.ul`
  padding: 24px 0;
  
  li {
    border-radius: 8px;
    border: 1px solid #EAECF0;

    background: #FFF;

    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
    
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    
    > div {
      padding: 12px;
      
      &:not(:last-child) {
        border-bottom: 1px solid #EAECF0;
      }
    }
    
    .leadListItemHead {
      .menuAndTitleWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        margin-bottom: 4px;
      }
    }
    
    .listItemBlock {
      display: flex;
      justify-content: space-between;
    }
  }
`;
