import React from 'react';
import ErrorIcon from '../../../assets/icons/formElements/ErrorIcon';
import { ErrorTextStyles } from './ErrorTextStyles';

function ErrorText({ error }: {error: string}) {
  if (!error) {
    return null;
  }

  return (
    <ErrorTextStyles className="errorContainer">

      <ErrorIcon />
      <span className="textBase12">{error}</span>

      {/* {!error && touched && <ErrorMessage name={name} component={FormErrorMessage} />} */}
    </ErrorTextStyles>
  );
}

export default ErrorText;
