import React from 'react';

function CommunityIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fff"
        d="M4.5 4.5a3 3 0 116 0 3 3 0 01-6 0zm3-1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm7.168 3.876a2.998 2.998 0 01.329-4.989 3 3 0 013.334.237 3 3 0 01-3.662 4.752zM16.5 3a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM3.75 9h4.005c-.165.47-.255.975-.255 1.5H3.75a.75.75 0 00-.75.75c0 1.226.487 2.134 1.257 2.752a4.22 4.22 0 001.259.683 3.75 3.75 0 00-.798 1.302 5.597 5.597 0 01-1.4-.815C2.19 14.265 1.5 12.924 1.5 11.25A2.25 2.25 0 013.75 9zm5.652 0a3.001 3.001 0 105.2 3 3.001 3.001 0 00-5.2-3zm2.262.038A1.504 1.504 0 0113.5 10.5a1.501 1.501 0 11-1.836-1.462zm8.079 4.964a4.22 4.22 0 01-1.258.683c.351.375.623.818.796 1.302a5.68 5.68 0 001.401-.815c1.128-.907 1.818-2.248 1.818-3.922A2.25 2.25 0 0020.25 9h-4.005c.165.47.255.975.255 1.5h3.75a.75.75 0 01.75.75c0 1.226-.488 2.134-1.257 2.752zM15.75 15A2.25 2.25 0 0118 17.25c0 1.674-.689 3.015-1.818 3.922C15.071 22.066 13.58 22.5 12 22.5c-1.58 0-3.07-.435-4.182-1.328C6.69 20.265 6 18.924 6 17.25A2.244 2.244 0 018.25 15h7.5zm0 1.5h-7.5a.75.75 0 00-.75.75c0 1.226.487 2.134 1.257 2.753.789.633 1.923.997 3.243.997 1.32 0 2.454-.364 3.243-.997.77-.619 1.257-1.527 1.257-2.753a.75.75 0 00-.75-.75z"
      />
    </svg>
  );
}

export default CommunityIcon;
