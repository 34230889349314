import React from 'react';
import { Blocker } from '@remix-run/router/dist/router';
import Modal from '../../modal/Modal';
import { SuggestFeatureModalStyles } from './ModalUnsavedChangesStyles';
import TextButton from '../../buttons/button/TextButton';

interface UnsavedChangesModalProps {
  blocker: Blocker;
}

function ModalUnsavedChanges({ blocker }: UnsavedChangesModalProps) {
  return (
    blocker.state === 'blocked' ? (
      <SuggestFeatureModalStyles>
        <Modal onClose={blocker.reset}>
          <div className="textWrapper">
            <span className="textBold28">Discard unsaved changes?</span>

            <span>If you proceed, you will lose all unsaved work. Are you sure you want to leave this page?</span>
          </div>

          <div className="buttonsContainer">
            <TextButton text="Leave this page" onClick={blocker.proceed} theme="mainFilled" />
            <TextButton text="Stay on this page" onClick={blocker.reset} theme="mainNotFilled" />
          </div>
        </Modal>
      </SuggestFeatureModalStyles>
    ) : null
  );
}

export default ModalUnsavedChanges;
