import React from 'react';

function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#1C1C1C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10 16.667h7.5M13.75 2.916a1.768 1.768 0 012.5 2.5L5.833 15.833l-3.333.833.833-3.333L13.75 2.916z"
      />
    </svg>
  );
}

export default EditIcon;
