import React, { useEffect, useState } from 'react';
import { StepThankYouStyles } from './StepThankYouStyles';
import ColorPicker from '../../../form/colorPicker/ColorPicker';
import { TForm, TFormDesign, TThankYou } from '../../../../enteties/types/forms.types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import NumberInput from '../../../form/numberInput/NumberInput';
import FormPreview from '../formPreview/FormPreview';
import ThankYouPreview from '../thankYouPreview/ThankYouPreview';
import InputField from '../../../form/input/InputField';
import Textarea from '../../../form/textArea/TextArea';
import DropZone from '../../../form/dropzone/DropZone';
import TextButton from '../../../buttons/button/TextButton';
import { notification } from '../../../../helpers/notifications/toastify';

interface StepDesignProps {
  thankYouData: TThankYou;
  handleChangeFormData: (data: Partial<TForm>) => void;
}

function StepThankYou({ thankYouData, handleChangeFormData }: StepDesignProps) {
  const [initialValues, setInitialValues] = useState<TThankYou>({
    description: {
      text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquid dolorum in neque velit? Accusantium alias cumque doloremque in praesentium!',
      color: {
        r: 0, g: 0, b: 0, a: 1,
      },
    },
    image: {
      url: null,
      border_radius: '0',
    },
    title: {
      text: 'Lorem ipsum dolor sit.',
      color: {
        r: 0, g: 0, b: 0, a: 1,
      },
    },
    form: {
      background_color: {
        r: 255, g: 255, b: 255, a: 1,
      },
      border_radius: '4',
    },
    page: {
      background_color: {
        r: 255, g: 255, b: 255, a: 1,
      },
    },
  });

  const {
    values, setValues, setFieldValue, handleChange, handleSubmit, setSubmitting, touched, errors, isSubmitting,
  } = useFormik({
    initialValues,
    onSubmit: (values) => {
      setSubmitting(true);

      if (values) {
        handleChangeFormData({ thank_you: values });
        notification.success('Saved');
      }

      setSubmitting(false);
    },
    validationSchema: Yup.object().shape({
      title: Yup.object().shape({
        text: Yup.string().required('Required field'),
      }),
    }),
  });

  useEffect(() => {
    setInitialValues(thankYouData);
    setValues(thankYouData);
  }, [thankYouData]);

  return (
    <StepThankYouStyles>
      <div className="designContent">
        <h3 className="textBold24">Customize “Thank You Page”</h3>

        <div className="settingsAndPreviewWrapper">
          <div className="settings">
            <span className="textSemiBold22">Settings</span>

            <div className="block">
              <span className="textSemiBold18 blockTitle">Page</span>

              <div className="gridContainer">
                <ColorPicker label="Background:" value={values.page.background_color} setValue={(color) => setFieldValue('page.background_color', color)} />
              </div>
            </div>

            <div className="splitter" />

            <div className="block">
              <span className="textSemiBold18 blockTitle">Form</span>

              <div className="gridContainer">
                <ColorPicker label="Background:" value={values.form.background_color} setValue={(color) => setFieldValue('form.background_color', color)} />
              </div>

              <div className="gridContainer">
                <NumberInput min={0} max={10000} name="form.border_radius" label="Border radius:" value={values.form.border_radius} handleChange={handleChange} />
              </div>
            </div>

            <div className="splitter" />

            <div className="block">
              <span className="textSemiBold18 blockTitle">Image</span>

              <DropZone
                handleDelete={() => setFieldValue('image', { ...values.image, url: null })}
                previewImageUrl={(values.image && values.image.url) || null}
                onFileChanged={(file) => setFieldValue('image', { ...values.image, url: file.url })}
              />

              <div className="gridContainer">
                <NumberInput min={0} max={10000} name="image.border_radius" label="Border radius:" value={values.image.border_radius} handleChange={handleChange} />
              </div>
            </div>

            <div className="splitter" />

            <div className="block">
              <span className="textSemiBold18 blockTitle">Title</span>

              <div className="gridContainer">
                <ColorPicker value={values.title.color} setValue={(color) => setFieldValue('title.color', color)} />
              </div>

              <InputField
                placeholder="Type title here"
                name="title.text"
                onChange={handleChange}
                value={values.title.text}
                error={
                  errors
                  && errors.title
                  // @ts-ignore
                  && errors.title.text
                  && touched
                  && touched.title
                  && touched.title.text
                    // @ts-ignore
                    ? errors.title.text
                    : undefined
                }
                disabled={isSubmitting}
              />
            </div>

            <div className="splitter" />

            <div className="block">
              <span className="textSemiBold18 blockTitle">Description</span>

              <div className="gridContainer">
                <ColorPicker value={values.description.color} setValue={(color) => setFieldValue('description.color', color)} />
              </div>

              <Textarea
                placeholder="Type description here"
                name="description.text"
                onChange={handleChange}
                value={values.description.text}
                error={
                  errors
                  && errors.description
                  // @ts-ignore
                  && errors.description.text
                  && touched
                  && touched.description
                  && touched.description.text
                    // @ts-ignore
                    ? errors.description.text
                    : undefined
                }
                disabled={isSubmitting}
              />
            </div>
          </div>
          <div className="preview">
            <ThankYouPreview thankYouData={values} />
          </div>
        </div>
      </div>

      <TextButton
        text="Save"
        // icon={(
        //   <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
        //     <path d="M6.5 12L10.5 8L6.5 4" stroke="#FC812B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        //   </svg>
        // )}
        onClick={handleSubmit}
        type="button"
        theme={Object.values(touched).length > 0 && Object.values(errors).length > 0 ? 'red' : 'mainNotFilled'}
      />
    </StepThankYouStyles>
  );
}

export default StepThankYou;
