import styled from 'styled-components';

export const TextAreaStyles = styled.div`
   display: grid;
   gap: 4px;

   textarea {
     font-size: 16px;
     color: #000;
     background: #FFF;
     box-sizing: border-box;
     padding: 10px 8px;
     transition: 0.3s all;
     font-family: 'Inter', sans-serif;
     outline: none;
     resize: none;
     width: 100%;
     height: 180px;

     border-radius: var(--corners_btn, 4px);
     border: 1px solid var(--gray-light, #C2C9D1);
     background: var(--white-default, #FFF);

     &:hover {
       border: 1px solid var(--orange-2, #FEC9A4);
     }

     &:focus {
       border: 1px solid var(--orange-1, #FC812B);
     }
    
     &::placeholder {
       font-family: 'Inter', sans-serif;
       font-size: 14px;
       line-height: 22px;
       color: var(--black-light, rgba(0, 0, 0, 0.50));
       white-space: pre-wrap;
     }
      
     &.filled {
       border-radius: var(--corners_btn, 4px);
       border: 1px solid var(--gray-light, #C2C9D1);
       background: var(--gray-lighter, linear-gradient(0deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.75) 100%), #C2C9D1);
     }

     &.disabled {
       border: 1px solid var(--gray-light, #C2C9D1);
       background: var(--gray-lighter, linear-gradient(0deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.75) 100%), #C2C9D1);
     }
   }

  .formErrorContainer {
    padding-top: 2px;

    display: flex;
    gap: 8px;

    color: #00000080;
  }
`;
