import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { EmailConfirmedStyles } from './EmailConfirmedStyles';
import TextButton from '../../../components/buttons/button/TextButton';
import Logo from '../../../assets/Logo';
import PlayIcon from '../../../assets/icons/authentefication/PlayIcon';

import 'react-toastify/dist/ReactToastify.css';
import EmailConfirmedIcon from '../../../assets/icons/authentefication/EmailConfirmedIcon';

function EmailConfirmed() {
  const navigate = useNavigate();

  return (
    <EmailConfirmedStyles>
      <div className="content">
        <Link to="/"><Logo /></Link>

        <div className="formWrapper">
          <div className="formFieldsWrapper">
            <div className="titleWrapper">
              <h1 className="textBold28">Verified!</h1>

              <EmailConfirmedIcon />

              <span className="textBase16">You have successfully verified your email address</span>
            </div>
          </div>

          <div>
            <div className="buttonsWrapper">
              <TextButton
                text="Go to sign in"
                onClick={() => navigate('/')}
                fullWidth
                theme="mainFilled"
              />
            </div>
          </div>
        </div>

        <div />
      </div>

      <div className="rightSide">
        <div className="sloganContainer">
          <PlayIcon />

          <p className="textBold28">One-stop platform to boost your digital marketing</p>
        </div>
      </div>
    </EmailConfirmedStyles>
  );
}

export default EmailConfirmed;
