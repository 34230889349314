import React, { useRef, useState } from 'react';
import { MultipleSelectStyles } from './MultipleSelectStyles';
import CheckMark from '../../../../assets/icons/formElements/CheckMark';
import ArrowDownIcon from '../../../../assets/icons/table/ArrowDownIcon';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import ErrorText from '../../../other/errorText/ErrorText';

interface CustomSelectProps {
  options: any[];
  value: any;
  onChange: (option: any) => void;
  placeholder: string;
  label?: string;
  nameKey?: string;
  valueKey?: string;
  error?: string;
  showError?: boolean;
  multiSelect?: boolean;
  disabled?: boolean;
}

function MultipleSelect({
  options,
  value,
  onChange,
  valueKey,
  nameKey,
  label,
  placeholder,
  error,
  showError = true,
  multiSelect,
  disabled,
}: CustomSelectProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const selectTriggerRef = useRef<HTMLDivElement | null>(null);
  const selectOptionsRef = useRef<HTMLDivElement | null>(null);

  const handleSelectOption = (optionValue: number | string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (multiSelect) {
      event.stopPropagation(); // Зупинка вспливання події

      const selectedValues = Array.isArray(value) ? [...value] : [];
      const index = selectedValues.indexOf(optionValue);
      if (index !== -1) {
        selectedValues.splice(index, 1);
      } else {
        selectedValues.push(optionValue);
      }

      onChange(selectedValues);
    } else {
      onChange(optionValue);
      // setIsOpen(false);
    }
  };

  const removeOption = (optionValue: any, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation(); // Зупинка вспливання події

    if (valueKey) {
      onChange(value.filter((item: any) => item !== optionValue[valueKey]));
    } else {
      onChange(value.filter((item: any) => item !== optionValue));
    }

    const selectedValues = Array.isArray(value) ? [...value] : [];
    const index = selectedValues.indexOf(optionValue);
    if (index !== -1) {
      selectedValues.splice(index, 1);
      onChange(selectedValues);
    }
  };

  useClickOutside(selectOptionsRef, () => setIsOpen(false), selectTriggerRef);

  return (
    <MultipleSelectStyles>
      {label && <span className="textSemiBold12 colorSecondary8">{label}</span>}
      <div className="customSelectWrapper">
        <div
          className={`customSelect ${isOpen ? 'open' : ''}`}
          onClick={() => (disabled ? null : setIsOpen(!isOpen))}
          ref={selectTriggerRef}
        >
          <div className={`customSelectTrigger ${error && 'error'}`}>
            {multiSelect && value.length > 0 ? (
              <div className="selectHead textBase14">
                {options.filter((option) => (valueKey ? value.includes(option[valueKey]) : value.includes(option))).map((option: any) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div onClick={(e) => removeOption(option, e)} key={nameKey ? option[nameKey] : option} style={{ background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.75) 100%), #C2C9D1' }}>
                    {nameKey ? option[nameKey] : option}
                    {/* eslint-disable-next-line react/button-has-type */}
                    <span className="removeOptionButton errorText">
                      &#10006;
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              <span
                className={`textBase14 ${(nameKey && value ? value[nameKey] : value) ? 'withValue' : ''}`}
              >
                {placeholder}
              </span>
            )}

            <div className="arrow">
              <ArrowDownIcon />
            </div>
          </div>
          <div className="customOptions" ref={selectOptionsRef}>
            {options.map((option, index) => (
              <div
                key={valueKey ? option[valueKey] : option}
                className={`customOption ${
                  (valueKey ? option[valueKey] : option) === (valueKey && value ? value[valueKey] : value) ? 'selected' : ''
                }`}
                onClick={(e) => handleSelectOption(valueKey ? option[valueKey] : option, e)}
              >
                <span>{nameKey ? option[nameKey] : option}</span>

                {multiSelect && value.includes(valueKey ? option[valueKey] : option) && <CheckMark />}

                {(!multiSelect && valueKey ? option[valueKey] : option) === (valueKey && value ? value[valueKey] : value) ? <CheckMark /> : null}
              </div>
            ))}
          </div>
        </div>
      </div>
      {error && <ErrorText error={error} />}
    </MultipleSelectStyles>
  );
}

export default MultipleSelect;
