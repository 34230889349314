import React, { useRef, useState } from 'react';
import { palette } from '../../../../constants/palette';
import { TApiStatus } from '../../../../enteties/types/statuses.types';
import { CheckMark } from '../../../../assets/icons/statuses/CheckMark';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import { CrossIcon } from '../../../../assets/icons/statuses/CrossIcon';

interface Props {
  status: TApiStatus;
  handleClose(): void;
  handleSave(status: TApiStatus): void;
}

function StatusChanging({ status, handleClose, handleSave }: Props) {
  const [value, setValue] = useState(status.name);
  const [color, setColor] = useState(status.color);

  const statusChangingRef = useRef<HTMLDivElement | null>(null);

  useClickOutside(statusChangingRef, handleClose);

  return (
    <div className="statusChanging" ref={statusChangingRef}>
      <div className="inputWrapper">
        <input type="text" value={value} onChange={(e) => setValue(e.target.value)} />

        <div className="inputButtons">
          {value.length > 0 && (
            <button type="button" onClick={() => handleSave({ ...status, name: value, color })}>{CheckMark}</button>
          )}

          <button type="button" onClick={handleClose}>{CrossIcon}</button>
        </div>
      </div>

      <div className="palette">
        <span className="textSemiBold16">Background color</span>

        <ul className="paletteList textBase16">
          {palette.map(({ color: paletteColor, name }) => (
            <li key={paletteColor}>
              <button type="button" onClick={() => setColor(paletteColor)}>
                <div className="leftPart">
                  <div className="colorPreview" style={{ background: paletteColor }} />

                  <span>{name}</span>
                </div>

                {paletteColor === color && CheckMark}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default StatusChanging;
