import React from 'react';

function CheckMark() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke="#0E9285"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 4.5l-8.25 8.25L3 9"
      />
    </svg>
  );
}

export default CheckMark;
