import styled from 'styled-components';

import bgImage from '../../../assets/images/login/login-bg.desktop.jpg';

export const ForgotPasswordStyles = styled.div`
  display: flex;
  box-sizing: border-box;
  min-height: 100vh;
  height: 100%;
  
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .rightSide {
    display: none;

    @media (min-width: 1024px) {
      display: flex;
      align-items: center;
      justify-content: center;
      
      padding: 0 30px;
      
      background-image: url(${bgImage});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      
      .sloganContainer {
        display: grid;
        grid-template-columns: 30px 1fr;
        gap: 16px;
        
        max-width: 650px;
        width: 100%;
        
        padding: 16px 24px;
        box-sizing: border-box;

        color: #FFF;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: 0.52px;
        background: rgba(255, 255, 255, 0.20);
        backdrop-filter: blur(10px);
        
        svg {
          transform: translateY(8px);
        }
      }
    }
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    padding: 24px 0;
    
    box-sizing: border-box;
    
    @media (min-width: 768px) {
      padding: 32px 0;
    }
    
    .formWrapper {
      display: flex;
      flex-direction: column;
      gap: 32px;
      
      width: 100%;
      padding: 0 16px;
      box-sizing: border-box;
      
      max-width: 690px;

      @media (min-width: 1024px) {
        max-width: 488px;
        width: 100%;
      }
    }
    
    .titleWrapper {
      h1 {
        margin-bottom: 12px;
      }
      
      text-align: center;
    }

    .formFieldsWrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;

      h1 {
        text-align: center;
      }
    }
    
    .fieldsWrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    
    .signInWrapper {
      text-align: center;
    }

    a {
      color: #FC812B;
    }
    
    .goToSignInLink {
      width: fit-content;
      margin: auto;
    }
  }
`;
