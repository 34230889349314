import React from 'react';
import { StepsStyles } from './StepsStyles';
import classNames from 'classnames';
import { TSteps } from '../../../../pages/forms/constructor/Constructor';

const newFormSteps: {text: string, key: TSteps}[] = [
  {
    key: 'fields',
    text: 'Form fields',
  },
  {
    key: 'purpose',
    text: 'Purpose',
  },
  {
    key: 'design',
    text: 'Design',
  },
  {
    key: 'thankYou',
    text: 'Thank you page',
  },
  {
    key: 'ready',
    text: 'Ready',
  },
];

const editFormSteps: {text: string, key: TSteps}[] = [
  {
    key: 'fields',
    text: 'Form fields',
  },
  {
    key: 'design',
    text: 'Design',
  },
  {
    key: 'thankYou',
    text: 'Thank you page',
  },
  {
    key: 'ready',
    text: 'Ready',
  },
];

interface Props {
  activeStep: TSteps;
  isNew: boolean;
  // formData: Omit<TForm, 'id'> | null;
  setActiveStep(step: TSteps): void;
}

function Steps({
  activeStep,
  isNew,
  // formData,
  setActiveStep,
}: Props) {
  return (
    <StepsStyles>
      {(isNew ? newFormSteps : editFormSteps).map((step, index) => (
        <button disabled={step.key === 'ready' || activeStep === 'ready'} className={classNames('textSemiBold18', { active: activeStep === step.key })} key={step.key} type="button" onClick={() => setActiveStep(step.key)}>
          <div className="number">{index + 1}</div>
          {' '}
          <span>{step.text}</span>
        </button>
      ))}
    </StepsStyles>
  );
}

export default Steps;
