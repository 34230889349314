const fieldTypes = ['email', 'phone', 'text_input', 'textarea'] as const;

export type FieldTypes = typeof fieldTypes[number];

type BaseTextInput = {
  label: string;
  placeholder: string;

  validation: {
    required: boolean;
  }
}

export enum FormFieldsTypes {
  email = 1,
  phone = 2,
  text_input = 3,
  textarea = 4,
}

export type EmailFieldData = BaseTextInput & {
  type: FormFieldsTypes.email;
}

export type PhoneFieldData = BaseTextInput & {
  type: FormFieldsTypes.phone;
}

export type TextInputFieldData = BaseTextInput & {
  type: FormFieldsTypes.text_input;
}

export type TextAreaFieldData = BaseTextInput & {
  type: FormFieldsTypes.textarea;
}

export type TField = EmailFieldData | PhoneFieldData | TextInputFieldData | TextAreaFieldData;

export type TApiField = TField & {
  id: number;
}

export type RGBA = {r: number; g: number; b: number; a: number};

export type TFormDesign = {
  page: {
    background_color: RGBA;
  };
  form: {
    background_color: RGBA;
    border_radius: string;
  };
  image: {
    url: string | null;
    border_radius: string;
  },
  title: {
    color: RGBA;
  };
  description: {
    color: RGBA;
  };
  inputs: {
    label_color: RGBA;
    placeholder_color: RGBA;
    border_color: RGBA;
    background_color: RGBA;
    text_color: RGBA;
    border_radius: string;
  };
  submit_button: {
    background_color: RGBA;
    text_color: RGBA;
    border_radius: string;
  };
}

export type TThankYou = {
  page: {
    background_color: RGBA;
  };
  form: {
    background_color: RGBA;
    border_radius: string;
  };
  image: {
    url: string | null;
    border_radius: string;
  },
  title: {
    color: RGBA;
    text: string;
  };
  description: {
    color: RGBA;
    text: string;
  };
}

export type InputFormDesignProps = Omit<TFormDesign['inputs'], 'inputs'>;
export type ButtonDesignProps = Omit<TFormDesign['submit_button'], 'submit_button'>;

export type TFormSubmitButton = {
  text: string;
}

export enum AvailableFormPurpose {
  link_qr = 1,
  website = 2,
}

export type TAvailablePurpose = AvailableFormPurpose.link_qr | AvailableFormPurpose.website;
// 1, 2

export type TForm = {
  id?: number;
  user_id: number;
  title: string;
  description: string;
  name: string;
  purpose: TAvailablePurpose | null;
  fields: (TField | TApiField)[];
  submit_button: TFormSubmitButton;
  design: TFormDesign;
  thank_you: TThankYou;
}

export type TApiForm = Omit<TForm, 'fields' | 'purpose'> & {
  id: number;
  fields: number[];
  purpose: TAvailablePurpose;
}
