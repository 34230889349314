import workWithResponse from '../../../../../helpers/workWithResponse';
import { Api } from '../../../../../api';
import { notification } from '../../../../../helpers/notifications/toastify';
import { unexpectedErrorMessage } from '../../../../../constants/constants';

export async function deleteProperty(id: number): Promise<'Deleted' | 'Error'> {
  try {
    const res = await workWithResponse(() => Api.deleteProperty(id)).then((res) => {
      if (res.data) {
        return 'Deleted';
      }

      if (res.error) {
        notification.error(res.error);
      }

      return 'Error';
    });

    return res!;
  } catch (e) {
    console.error(`Error: ${e}`);
    notification.error(unexpectedErrorMessage);
  }

  return 'Error';
}
