import styled from 'styled-components';

export const SuggestFeatureModalStyles = styled.div`
  .modalBody {
    max-width: 764px;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .modalHead {
    display: flex;
    flex-direction: column;
    gap: 8px;

    text-align: center;
  }

`;
