import React, { useCallback, useState } from 'react';
import { DropZoneStyles } from './DropZoneStyles';
import Loader from '../../loader/Loader';
import { Accept, useDropzone } from 'react-dropzone';
import { Tooltip } from 'react-tooltip';
import TooltipIcon from '../../../assets/icons/other/TooltipIcon';
import { useWindowSize } from 'usehooks-ts';
import ErrorText from '../../other/errorText/ErrorText';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { megaBiteInBites } from '../../../constants/constants';
import workWithResponse from '../../../helpers/workWithResponse';
import { Api } from '../../../api';
import { ApiFile } from '../../../api/entities/ApiFile';
import TrashIcon from '../../../assets/icons/other/TrashIcon';
import { bytesToMB } from '../../../utils/utils';

interface DropZoneProps {
  onFileChanged(file: ApiFile): void;
  handleDelete(): void;
  previewImageUrl: null | string;
  label?: string;
  propsAccept?: Accept;
  error?: string;
  extraDropzoneClasses?: string;
  disabled?: boolean;
  maxSizeMb?: number;
}

const DropFilesIcon = (
  <svg className="dropIcon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <path d="M41.4929 20.9207L41.4836 20.8832L36.2242 7.51914C35.9898 6.76445 35.2914 6.24414 34.4992 6.24414H13.1804C12.3836 6.24414 11.6757 6.77383 11.4507 7.53789L6.53355 20.766L6.51949 20.7988L6.51012 20.8363C6.44918 21.066 6.43043 21.3004 6.46324 21.5301C6.45855 21.6051 6.45387 21.6801 6.45387 21.7551V38.9066C6.45511 39.6621 6.75577 40.3863 7.28998 40.9205C7.82419 41.4547 8.54838 41.7554 9.30387 41.7566H38.7039C40.2742 41.7566 41.5539 40.477 41.5586 38.9066V21.7551C41.5586 21.6941 41.5586 21.6332 41.5539 21.5816C41.5726 21.352 41.5539 21.1316 41.4929 20.9207ZM27.6273 18.9051L27.6132 19.641C27.5757 21.7457 26.1226 23.1613 23.9992 23.1613C22.9632 23.1613 22.0726 22.8285 21.4304 22.1957C20.7882 21.5629 20.4367 20.6816 20.4179 19.641L20.4039 18.9051H10.757L14.4836 9.84414H33.1961L37.0257 18.9051H27.6273ZM10.0492 22.5051H17.4226C18.5617 25.1816 20.9851 26.7613 24.0039 26.7613C25.5836 26.7613 27.0507 26.3207 28.2367 25.4863C29.2773 24.7551 30.0882 23.7332 30.6132 22.5051H37.9492V38.1566H10.0492V22.5051Z" fill="#2A7BCA" />
  </svg>
);

function DropZone({
  onFileChanged, previewImageUrl, label, propsAccept, error, extraDropzoneClasses, disabled, maxSizeMb = process.env.REACT_APP_MAX_UPLOAD_SIZE ? +process.env.REACT_APP_MAX_UPLOAD_SIZE : 1, handleDelete,
}: DropZoneProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      setIsLoading(true);

      if (!maxSizeMb) {
        try {
          await workWithResponse(() => Api.createFile(acceptedFiles[0])).then((res) => {
            if (res.error) {
              toast.error(res.error);

              return;
            }

            if (res.data) {
              onFileChanged(res.data);
              setIsLoading(false);
            }
          });
        } catch (e: any) {
          console.error(`Error: ${e}`);
          toast.error(e.message);
        } finally {
          setIsLoading(false);
        }

        return;
      }

      if (maxSizeMb * megaBiteInBites >= acceptedFiles[0].size) {
        try {
          await workWithResponse(() => Api.createFile(acceptedFiles[0])).then((res) => {
            if (res.error) {
              toast.error(res.error);

              return;
            }

            if (res.data) {
              onFileChanged(res.data);
            }
          });
        } catch (e: any) {
          console.error(`Error: ${e}`);
          toast.error(e.message);
        } finally {
          setIsLoading(false);
        }
      } else {
        toast.error(`File size should not exceed ${maxSizeMb} megabytes`, {
          position: 'top-center',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });

        setIsLoading(false);
      }
    }
  }, []);

  const windowSize = useWindowSize();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: propsAccept || {
      'image/jpg': ['.jpg'],
      'image/png': ['.png'],
      'image/svg': ['.svg'],
    },
    disabled: disabled || isLoading,
  });

  return (
    <DropZoneStyles className="dropzone">
      {label
        && (
          <span className="textSemiBold12 colorSecondary8 label">
            {label}
          </span>
        )}

      <div className={`dropzone ${extraDropzoneClasses ?? ''}`} {...getRootProps()}>
        <input {...getInputProps()} />
        {isLoading ? (
          <Loader
            size={80}
          />
        ) : (
          <div className={classNames('dropzoneContent', { disabled })} style={previewImageUrl ? { backgroundImage: `url(${previewImageUrl})` } : undefined}>
            {!previewImageUrl && (
              <>
                {DropFilesIcon}

                <span className="textBase14">Click or drag a file to this area to upload</span>
              </>
            )}
            {previewImageUrl && (
              <a
                className="tooltipIcon"
                data-tooltip-id={previewImageUrl}
                data-tooltip-content="Click on the image or drag a file to update the image."
                data-tooltip-place="top"
              >
                <TooltipIcon size={32} fill="#F0F0F0" color="#3A5274" />
              </a>
            )}
            {previewImageUrl && (
              <Tooltip id={previewImageUrl} variant="info" style={{ zIndex: '3', maxWidth: '300px', whiteSpace: 'pre-wrap' }} openOnClick={windowSize.width < 1000} />
            )}

            {previewImageUrl && (
              <button
                title="Delete image"
                type="button"
                className="deleteButton"
                onClick={(e) => {
                  e.stopPropagation();

                  handleDelete();
                }}
              >
                {TrashIcon}
              </button>
            )}
          </div>
        )}
      </div>
      {error && <ErrorText error={error} />}
    </DropZoneStyles>
  );
}

export default DropZone;
