import React, { useRef, useState } from 'react';
import { TUpgradeItem } from '../../../enteties/types/upgrades.types';
import { UpgradesItemStyles } from './UpgradesItemStyles';
import TextButton from '../../buttons/button/TextButton';
import { notification } from '../../../helpers/notifications/toastify';
import { unexpectedErrorMessage } from '../../../constants/constants';
import workWithResponse from '../../../helpers/workWithResponse';
import { Api } from '../../../api';
import HearthIcon from '../../../assets/icons/upgrades/HearthIcon';

interface UpgradesItemProps {
  upgradeItemData: TUpgradeItem;
  onAddToWishlistStatusChanged(id: number): void;
}

function UpgradesItem({ upgradeItemData, onAddToWishlistStatusChanged }: UpgradesItemProps) {
  const [statusInChanging, setStatusInChanging] = useState<boolean>(false);

  const animationHearthRef = useRef<HTMLDivElement | null>(null);

  async function changeAddToWishlistStatus() {
    try {
      setStatusInChanging(true);

      if (upgradeItemData.added_to_wish_list) {
        await workWithResponse(() => Api.deleteUpgradeFromWishlist(upgradeItemData.id)).then((res) => {
          if (res.data) {
            onAddToWishlistStatusChanged(upgradeItemData.id);

            if (animationHearthRef && !!animationHearthRef.current && animationHearthRef.current?.classList.contains('showAnimation')) {
              animationHearthRef.current.classList.remove('showAnimation');
            }
          }

          if (res.error) {
            notification.error(res.error);
          }
        });

        return;
      }

      await workWithResponse(() => Api.addUpgradeToWishlist(upgradeItemData.id)).then((res) => {
        if (res.data) {
          onAddToWishlistStatusChanged(upgradeItemData.id);

          if (animationHearthRef && !!animationHearthRef.current) {
            animationHearthRef.current.classList.add('showAnimation');
          }
        }

        if (res.error) {
          notification.error(res.error);
        }
      });
    } catch (e) {
      notification.error(unexpectedErrorMessage);
      console.error(`Error: ${e}`);
    } finally {
      setStatusInChanging(false);
    }
  }

  return (
    <UpgradesItemStyles>
      <img src={upgradeItemData.image_url} alt={upgradeItemData.title} />

      <div className="rightPart">
        <div className="textContainer">
          <span className="textSemiBold18">
            {upgradeItemData.title}
          </span>

          <span className="textBase16">
            {upgradeItemData.description}
          </span>
        </div>

        <TextButton
          text="Add to wishlist"
          onClick={changeAddToWishlistStatus}
          theme="mainFilled"
          extraClasses={`addToWishlistButton ${upgradeItemData.added_to_wish_list ? 'added' : ''}`}
          isLoading={statusInChanging}
        >
          <div className="children">
            <div className="hearthWrapper">
              <HearthIcon />

              <div className="animationHearths" ref={animationHearthRef}>
                <HearthIcon animatedHearth />
                <HearthIcon animatedHearth />
                <HearthIcon animatedHearth />
              </div>
            </div>

            <span>{upgradeItemData.wishlist_additions}</span>
          </div>
        </TextButton>
      </div>

    </UpgradesItemStyles>
  );
}

export default UpgradesItem;
