import IApi from './IApi';
import ApiResponse from './entities/ApiResponse';
import ApiUser from './entities/ApiUser';
import ChangeUserData from '../state/entities/ChangeUserData';
import LoginData from '../state/entities/LoginData';
import RegisterData from '../state/entities/RegisterData';
import {
  fakeComment,
  fakeFile,
  fakeForm,
  fakeFormField,
  fakeLeadData,
  fakeLeadData2,
  fakeLeadData3,
  fakeLeadData4,
  fakeLoginResponse,
  fakePropertyResponse,
  fakeStatusesResponse,
  fakeStatusResponse,
  fakeTicketPropertyValue,
  fakeUserResponse,
  upgradesFakeData,
} from '../constants/fakeData';
import { TApiField, TApiForm, TField } from '../enteties/types/forms.types';
import { TCommunityFormData } from '../enteties/types/community.types';
import { TApiSuggestion, TSuggestion, TUpgradeItem } from '../enteties/types/upgrades.types';
import { mapFromFileToFormData } from './mappers/mappers';
import { ApiFile } from './entities/ApiFile';
import { TApiLead } from '../enteties/types/lead.types';
import { TApiStatus, TStatus } from '../enteties/types/statuses.types';
import { TLeadApiComment, TLeadComment } from '../enteties/types/comments.types';
import {
  TApiProperty,
  TApiPropertyValueData,
  TProperty,
  TPropertyValuesData,
} from '../enteties/types/formProperties.types';
import { ChangePasswordData } from '../enteties/types/user.types';
import ApiAuthResponse, {
  RecoveryPasswordData,
  RecoveryPasswordStep1Data,
  SignUpStep1Data,
  SignUpStep2Data,
} from '../enteties/types/auth.types';

export default class Api implements IApi {
  private baseUrl: string = process.env.REACT_APP_API_HOST!;

  private async fetchData(path: string, requestOptions: any): Promise<any> {
    try {
      // console.log(`${this.getBaseUrl(path)}${path}`, { ...requestOptions });
      const response = await fetch(`${this.baseUrl}${path}`, { ...requestOptions });
      const statusCode = response.status;
      const data = await response.json();
      return {
        data,
        statusCode,
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('FETCH ERROR:', e);
      throw new Error(`API Fetch error: ${e}`);
    }
  }

  private async getData(path: string, tokenRequired = true): Promise<any> {
    const myHeaders: { [key: string]: string } = {};

    if (tokenRequired) {
      const accessToken = localStorage.getItem('access_token');
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions: {
      method: string;
      redirect: 'follow' | 'error' | 'manual' | undefined;
      headers: { [key: string]: string };
    } = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };
    return this.fetchData(path, requestOptions);
  }

  private async postData(
    path: string,
    data?: any,
    formData?: any,
    tokenRequired = true,
  ): Promise<any> {
    const myHeaders: { [key: string]: string } = {};
    if (!formData) {
      myHeaders['Content-Type'] = 'application/json';
    }

    if (tokenRequired) {
      const accessToken = localStorage.getItem('access_token');
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions: {
      method: string;
      headers: { [key: string]: string };
      body: string;
      redirect: 'follow' | 'error' | 'manual' | undefined;

      mode: string,
      cache: string,
      credentials: string,
      referrerPolicy: string,
    } = {
      method: 'POST',
      headers: myHeaders,
      body: formData ? data : JSON.stringify(data),
      redirect: 'follow',

      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      referrerPolicy: 'no-referrer', // no-referrer, *client
    };

    return this.fetchData(path, requestOptions);
  }

  async putData(
    path: string,
    data: any,
    formData?: boolean,
  ) {
    const accessToken = localStorage.getItem('access_token');
    const myHeaders: { [key: string]: string } = {};
    if (!formData) {
      myHeaders['Content-Type'] = 'application/json';
    }

    if (accessToken) {
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: formData ? data : JSON.stringify(data),
      redirect: 'follow',
    };

    return this.fetchData(path, requestOptions);
  }

  private async deleteData(path: string, data?: any): Promise<any> {
    const accessToken = localStorage.getItem('access_token');
    const myHeaders: { [key: string]: string } = {};

    if (accessToken) {
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions: {
      method: string;
      redirect: 'follow' | 'error' | 'manual' | undefined;
      body: string,
      headers: { [p: string]: string }
    } = {
      method: 'DELETE',
      redirect: 'follow',
      body: JSON.stringify(data),
      headers: myHeaders,
    };

    return this.fetchData(path, requestOptions);
  }

  // eslint-disable-next-line class-methods-use-this
  // public async getMyAccount(): Promise<ApiResponse<ApiUser>> {
  //   return this.getData('/user/me');

  // return Promise.resolve(fakeUserResponse);
  // }

  // eslint-disable-next-line class-methods-use-this
  public async getMyAccount(): Promise<ApiResponse<ApiUser>> {
    return this.getData('/user/me');

    // return Promise.resolve({ data: fakeUserResponse, statusCode: 200 });
  }

  // eslint-disable-next-line class-methods-use-this
  public async deleteAccount(id: number): Promise<ApiResponse<{ message: string }>> {
    // return this.deleteData('/users/${id}');

    return Promise.resolve({ data: { message: 'OK' }, statusCode: 200 });
  }

  // eslint-disable-next-line class-methods-use-this
  public async changeUserData(data: ChangeUserData): Promise<ApiResponse<{ message: string }>> {
    return this.putData(`/user/${data.id}`, data);
  }

  // eslint-disable-next-line class-methods-use-this
  public async changeMyAccount(data: ChangeUserData): Promise<ApiResponse<{message: string}>> {
    return this.putData('/user/me', data);
  }

  // eslint-disable-next-line class-methods-use-this
  public async changePassword(data: ChangePasswordData): Promise<ApiResponse<{message: string}>> {
    return this.postData('/change-password', data);
  }

  // eslint-disable-next-line class-methods-use-this
  public async login(data: LoginData): Promise<ApiResponse<ApiAuthResponse>> {
    // const response: ApiResponse<ApiAuthResponse> = await this.postData('/login', data);
    // console.log(response);
    // return response;
    return this.postData('/login', data);
  }

  // eslint-disable-next-line class-methods-use-this
  public async logout(): Promise<ApiResponse<{ message: string }>> {
    return this.postData('/logout');

    // return Promise.resolve({ data: { message: 'OK' }, statusCode: 200 });
  }

  public async register(data: RegisterData): Promise<ApiResponse<ApiAuthResponse>> {
    return this.postData('/register', data);
  }

  public async registrationEmail(data: SignUpStep1Data): Promise<ApiResponse<{message: string}>> {
    return this.postData('/register', data);
  }

  public async registrationCheckToken(data: {token: string}): Promise<ApiResponse<{message: string}>> {
    return this.getData(`/register/validate?token=${data.token}`);
  }

  public async registrationUserData(data: SignUpStep2Data): Promise<ApiResponse<{message: string}>> {
    return this.postData('/register/complete', data);
  }

  public async passwordRecoveryEmail(data: RecoveryPasswordStep1Data): Promise<ApiResponse<{message: string}>> {
    return this.postData('/forgot-password', data);
  }

  public async passwordRecoveryCheckToken(data: {token: string}): Promise<ApiResponse<{message: string}>> {
    return this.getData(`/forgot-password/validate?token=${data.token}`);
  }

  public async passwordRecoveryChangePassword(data: RecoveryPasswordData): Promise<ApiResponse<{message: string}>> {
    return this.postData('/forgot-password/complete', data);
  }

  public async getForms({
    limit,
    offset,
  }: { limit?: number, offset: number }): Promise<ApiResponse<{ data: TApiForm[], count: number }>> {
    return this.getData(`/form/list?limit=${limit || 14}&offset=${offset || 0}`);
  }

  public async getUserForms({ limit, offset, userID }: {userID: number, limit?: number, offset: number}): Promise<ApiResponse<{data: TApiForm[], count: number}>> {
    return this.getData(`/form/user/${userID}?limit=${limit || 14}&offset=${offset || 0}`);
  }

  public async getForm(id: number): Promise<ApiResponse<TApiForm>> {
    return this.getData(`/form/${id}`);
  }

  // eslint-disable-next-line class-methods-use-this
  public async deleteForm(id: number): Promise<ApiResponse<{ message: string }>> {
    return Promise.resolve({ statusCode: 200, data: { message: 'OK' } });

    // return this.deleteData(`/forms/${id}`);
  }

  public async createForm(data: Omit<TApiForm, 'id'>): Promise<ApiResponse<TApiForm>> {
    return this.postData('/form/', data);
  }

  public async updateForm(data: TApiForm): Promise<ApiResponse<{ message: string }>> {
    return this.putData(`/form/${data.id}`, data);
  }

  public async createFormField(data: TField): Promise<ApiResponse<TApiField>> {
    return this.postData('/field/', data);
  }

  public async getFormField(id: number): Promise<ApiResponse<TApiField>> {
    return this.getData(`/field/${id}`);
  }

  public async createFormProperty(data: TProperty, formId: number): Promise<ApiResponse<TProperty>> {
    return this.postData('/property/', { ...data, form_id: formId });
  }

  public async updateFormProperty(data: TApiProperty): Promise<ApiResponse<TApiProperty>> {
    return this.putData(`/property/${data.id}`, data);
  }

  public async getFormProperties(formId: number): Promise<ApiResponse<{data: TApiProperty[], count: number }>> {
    return this.getData(`/property/form/${formId}?limit=100000&offset=0`);
  }

  public async deleteProperty(propertyId: number): Promise<ApiResponse<{ message: string }>> {
    return this.deleteData(`/property/${propertyId}`);
  }

  public async getTicketPropertyValue(propertyId: number, ticketId: number): Promise<ApiResponse<TApiPropertyValueData>> {
    return this.getData(`/lead/${ticketId}/property/${propertyId}`);
  }

  public async createTicketPropertyValue(data: TPropertyValuesData): Promise<ApiResponse<TApiPropertyValueData>> {
    return this.postData('/property_value/', data);
  }

  public async updateTicketPropertyValue(data: TApiPropertyValueData): Promise<ApiResponse<TApiPropertyValueData>> {
    return this.putData(`/property_value/${data.id}`, data);
  }

  // eslint-disable-next-line class-methods-use-this
  public async getLead(id: number): Promise<ApiResponse<TApiLead>> {
    // return Promise.resolve({ statusCode: 200, data: fakeLeadData });

    return this.getData(`/lead/${id}`);
  }

  // eslint-disable-next-line class-methods-use-this
  public async getLeads({
    offset,
    limit,
  }: { offset: number, limit?: number }): Promise<ApiResponse<{ data: TApiLead[], count: number }>> {
    // return Promise.resolve({
    //   statusCode: 200,
    //   data: { data: [fakeLeadData, fakeLeadData2, fakeLeadData3, fakeLeadData4], count: 4 },
    // });

    return this.getData(`/lead/list?limit=${limit || 14}&offset=${offset || 0}`);
  }

  // eslint-disable-next-line class-methods-use-this
  public async getUserLeads({ limit, offset, userID }: {userID: number, limit?: number, offset: number}): Promise<ApiResponse<{ data: TApiLead[], count: number }>> {
    // return Promise.resolve({
    //   statusCode: 200,
    //   data: { data: [fakeLeadData, fakeLeadData2, fakeLeadData3, fakeLeadData4], count: 4 },
    // });

    return this.getData(`/lead/user/${userID}?limit=${limit || 14}&offset=${offset || 0}`);
  }

  // eslint-disable-next-line class-methods-use-this
  public async updateLead(data: TApiLead): Promise<ApiResponse<{message: string}>> {
    // return Promise.resolve({ statusCode: 200, data: fakeLeadData });

    return this.putData(`/lead/${data.id}`, data);
  }

  // eslint-disable-next-line class-methods-use-this
  public async createComment(values: TLeadComment): Promise<ApiResponse<TLeadApiComment>> {
    // return Promise.resolve({ statusCode: 200, data: fakeComment });

    return this.postData('/comment/', values);
  }

  // eslint-disable-next-line class-methods-use-this
  public async getComments(id: number): Promise<ApiResponse<{count: number, data: TLeadApiComment[]}>> {
    // return Promise.resolve({ statusCode: 200, data: [fakeComment, fakeComment, fakeComment] });

    return this.getData(`/comment/lead/${id}`);
  }

  // eslint-disable-next-line class-methods-use-this
  public async getStatuses(): Promise<ApiResponse<{ data: TApiStatus[], count: number }>> {
    return Promise.resolve({
      statusCode: 200,
      data: { data: fakeStatusesResponse.sort((a, b) => a.priority - b.priority), count: 4 },
    });

    // return this.getData('/status/list?limit=100&offset=0&order=desc&sortBy=priority');
  }

  // eslint-disable-next-line class-methods-use-this
  public async getUserStatuses({ userID }:{userID: number}): Promise<ApiResponse<{ data: TApiStatus[], count: number }>> {
    // return Promise.resolve({
    //   statusCode: 200,
    //   data: { data: fakeStatusesResponse.sort((a, b) => a.priority - b.priority), count: 4 },
    // });

    return this.getData(`/status/user/${userID}?limit=100&offset=0&order=asc&sortBy=priority&sortBy=priority&order=asc`);
  }

  // eslint-disable-next-line class-methods-use-this
  public async createStatus(values: TStatus): Promise<ApiResponse<TApiStatus>> {
    // return Promise.resolve({ statusCode: 200, data: fakeStatusResponse });

    return this.postData('/status/', values);
  }

  // eslint-disable-next-line class-methods-use-this
  public async deleteStatus(id: number): Promise<ApiResponse<{ message: string }>> {
    // return Promise.resolve({ statusCode: 200, data: { message: 'OK' } });

    return this.deleteData(`/status/${id}`);
  }

  // eslint-disable-next-line class-methods-use-this
  public async updateStatus(values: TApiStatus): Promise<ApiResponse<{ message: string }>> {
    // return Promise.resolve({ statusCode: 200, data: { message: 'O K' } });

    return this.putData(`/status/${values.id}`, values);
  }

  // eslint-disable-next-line class-methods-use-this
  public async postCommunityMessage(data: TCommunityFormData): Promise<ApiResponse<{ message: string }>> {
    // return Promise.resolve({ statusCode: 200, data: { message: 'OK' } });

    return this.postData('/community/message/', data);
  }

  // eslint-disable-next-line class-methods-use-this
  public async getUpgrades(data: { limit?: number; offset: number }): Promise<ApiResponse<{ count: number, data: TUpgradeItem[] }>> {
    // return Promise.resolve({ statusCode: 200, data: { data: upgradesFakeData, count: 14 } });
    const { offset, limit } = data;

    return this.getData(`/feature/list?limit=${limit || 10}&offset=${offset || 0}`);
  }

  // eslint-disable-next-line class-methods-use-this
  public async addUpgradeToWishlist(id: number): Promise<ApiResponse<{ message: string }>> {
    // return Promise.resolve({ statusCode: 200, data: { message: 'OK' } });

    return this.postData('/feature/wishlist/add', { feature_id: id });
  }

  // eslint-disable-next-line class-methods-use-this
  public async deleteUpgradeFromWishlist(id: number): Promise<ApiResponse<{ message: string }>> {
    // return Promise.resolve({ statusCode: 200, data: { message: 'OK' } });

    return this.postData('/feature/wishlist/remove', { feature_id: id });
  }

  // eslint-disable-next-line class-methods-use-this
  public async suggestFeature(data: TSuggestion): Promise<ApiResponse<TApiSuggestion>> {
    // return Promise.resolve({ statusCode: 200, data: { message: 'OK' } });

    return this.postData('/feature/suggestion/', data);
  }

  // eslint-disable-next-line class-methods-use-this
  public async createFile(file: File): Promise<ApiResponse<ApiFile>> {
    const formData = mapFromFileToFormData(file);

    return this.postData('/files', formData, true);

    // return Promise.resolve({ data: fakeFile, statusCode: 200 });
  }

  // eslint-disable-next-line class-methods-use-this
  public async getFile(id: number): Promise<ApiResponse<ApiFile>> {
    // return this.getData(`/files/${id}`);

    return Promise.resolve({ data: fakeFile, statusCode: 200 });
  }

  public async updateFile(id: number, data: { file: File, width?: number, height?: number }): Promise<ApiResponse<ApiFile>> {
    const formDataFromData = mapFromFileToFormData(data.file);
    return this.putData(`/file/${id}`, formDataFromData, true);

    // return Promise.resolve({ data: undefined, statusCode: 200 });
  }

  // eslint-disable-next-line class-methods-use-this
  public async deleteFile(id: number): Promise<ApiResponse<{ message: string }>> {
    // return this.deleteData(`/file/${id}`);

    return Promise.resolve({ data: { message: 'OK' }, statusCode: 200 });
  }
}
