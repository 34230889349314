import styled from 'styled-components';

export const UserMenuStyles = styled.div`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -16px;
    width: calc(100% + 32px);
    height: 1px;
    background: rgba(252, 252, 252, 0.30);

    @media (min-width: 768px) {
      width: calc(100% + 64px);
      left: -32px;
    }

    @media (min-width: 768px) {
      width: 100%;
      left: 0;
    }
  }
  
  .userMenuButton {
    position: relative;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    padding-top: 16px;
    
    .userInfo {
      display: grid;
      grid-template-columns: min-content 1fr;
      align-items: center;
      gap: 8px;
      color: var(--grey-1, #FCFCFC);

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }
    }
    
    .menu {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
  .userMenu {
    top: 0;
    left: 50%;
    transform: translateY(-100%) translateX(-50%);
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    
    width: 100%;
    
    button {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      padding: 4px;
      
      transition: 0.3s ease background-color;
      
      &:hover {
        background-color: var(--grey-3);
        
        svg {
          .arrow {
            transform: translateX(2px);
          }
        }
      }
      
      &.logout {
        color: var(--red-2, #F5222D);
      }
      
      &.settings {
        &:hover {
          svg {
            transform: rotate(75deg);
          }
        }
      }
      
      svg {
        transition: 0.3s ease transform;
        
        .arrow {
          transition: 0.3s ease transform;
        }
      }
    }
  }
`;
