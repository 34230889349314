import React, {
  createContext, useContext, useMemo, ReactNode,
} from 'react';
import { TApiField, TApiForm } from '../../../../enteties/types/forms.types';

interface ContextProps {
  formFields: {[key: string]: TApiField};
  formsBook: {[key: string]: TApiForm};
}

const LeadsStickersVersionContext = createContext<ContextProps>({ formsBook: {}, formFields: {} });

export const useLeadsStickersVersionContext = () => useContext(LeadsStickersVersionContext);

export const LeadsStickersVersionProvider: React.FC<{children: ReactNode} & ContextProps> = ({ children, formFields, formsBook }) => {
  const contextValue = useMemo(() => ({ formFields, formsBook }), [formFields, formsBook]);

  return (
    <LeadsStickersVersionContext.Provider value={contextValue}>
      {children}
    </LeadsStickersVersionContext.Provider>
  );
};
