import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import LoginData from '../entities/LoginData';
import ChangeUserData from '../entities/ChangeUserData';
import RegisterData from '../entities/RegisterData';
import ApiResponse from '../../api/entities/ApiResponse';
import ApiUser from '../../api/entities/ApiUser';
import { Api } from '../../api';
import workWithResponse from '../../helpers/workWithResponse';
import { notification } from '../../helpers/notifications/toastify';

export interface UserState {
  data: ApiUser | null;
  loggedIn: boolean;
  status: 'idle' | 'loading' | 'failed';
  statusCode: number;
  message: string | null;
}

const initialState: UserState = {
  data: null,
  loggedIn: false,
  status: 'idle',
  statusCode: 200,
  message: null,
};

export const loginUser = createAsyncThunk(
  'users/login',
  async (data: LoginData, thunkAPI) => {
    try {
      // @ts-ignore
      const response = await workWithResponse(() => Api.login(data));

      let error = '';

      if (response.error) {
        error = response.error;
      }

      if (response.data) {
        localStorage.setItem('access_token', response.data.access_token!);
        localStorage.setItem('access_token_expired_at', response.data.access_token_expired_at!.toString());
        localStorage.setItem('refresh_token', response.data.refresh_token!);
        localStorage.setItem('refresh_token_expired_at', response.data.refresh_token_expired_at!.toString());
        const user = await workWithResponse(() => Api.getMyAccount());

        if (user.error) {
          error = user.error;
        }

        if (user.statusCode === 200) {
          return user;
        }

        notification.error(error);
        return thunkAPI.rejectWithValue(error);
      }

      notification.error(error);
      return thunkAPI.rejectWithValue(error);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error', e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const registerUser = createAsyncThunk(
  'users/register',
  async (data: RegisterData, thunkAPI) => {
    try {
      const response = await workWithResponse(() => Api.register(data));

      if (response.error) {
        return thunkAPI.rejectWithValue(response.error);
      }

      if (response.data) {
        localStorage.setItem('access_token', response.data.access_token!);
        localStorage.setItem('refresh_token_expired_at', response.data.refresh_token_expired_at!.toString());
        localStorage.setItem('refresh_token', response.data.refresh_token!);
        localStorage.setItem('refresh_token_expired_at', response.data.refresh_token_expired_at!.toString());
      }
      return await Api.getMyAccount();
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.log('Error', e);
      notification.error(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const changeUserData = createAsyncThunk(
  'users/changeData',
  async (data: ChangeUserData, thunkAPI) => {
    try {
      const response = await Api.changeUserData(data);
      return response.data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error', e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    changeUser: (state, action: PayloadAction<ApiUser | null>) => ({ ...state, data: action.payload, loggedIn: false }),
    setUser: (state, action: PayloadAction<ApiUser>) => ({ ...state, data: action.payload }),
    resetMessage: (state) => ({ ...state, message: null }),
    setCompanies: (state, action: PayloadAction<number[]>) => ({ ...state, companies: action.payload }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loggedIn = true;
        // @ts-ignore
        state.data = action.payload.data;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'failed';
        state.loggedIn = false;
      })
      .addCase(changeUserData.fulfilled, (state, action) => {
        // @ts-ignore
        state.message = action.payload.message;

        if (!action.payload) {
          state.loggedIn = false;
        }
      });
  },
});

export const { actions, reducer } = userSlice;
// Extract and export each action creator by name
export const {
  changeUser, setUser, resetMessage, setCompanies,
} = actions;
// Export the reducer, either as a default or named export
export default reducer;
