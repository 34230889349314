import React from 'react';

function TooltipIcon({ color = '#727272', size = 16, fill = 'none' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g
        stroke="#727272"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        clipPath="url(#clip0_1154_1443)"
      >
        <path d="M8 14.666A6.667 6.667 0 108 1.333a6.667 6.667 0 000 13.333zM8 10.667V8M8 5.333h.007" />
      </g>
      <defs>
        <clipPath id="clip0_1154_1443">
          <path fill="#fff" d="M0 0H16V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default TooltipIcon;
