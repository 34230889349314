import React, { useState } from 'react';
import { ToggleButtonStyles } from './ToggleButtonStyles';
import classNames from 'classnames';

interface ToggleButtonProps {
  name: string;
  label?: string;
  value: boolean;
  onChange?: (isToggled: boolean) => void;
  theme?: 'orange' | 'blue',
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
  name, label, value, onChange, theme = 'blue',
}) => {
  const handleToggle = () => {
    if (onChange) {
      onChange(!value);
    }
  };

  return (
    <ToggleButtonStyles htmlFor={name}>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button className={classNames(`${theme}`, { active: value })} id={name} type="button" onClick={handleToggle}>
        <div className="toggle" />
      </button>

      {label && <p>{label}</p>}
    </ToggleButtonStyles>
  );
};

export default ToggleButton;
