import ErrorIcon from '../../../assets/icons/formElements/ErrorIcon';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const FormErrorContainerStyles = styled.div`
  padding-top: 2px;

  display: flex;
  gap: 8px;

  color: #00000080;
`;

function FormErrorContainer({ children }: { children?: any }) {
  return (
    <FormErrorContainerStyles>
      <ErrorIcon />
      <span className="textBase12">{children}</span>
    </FormErrorContainerStyles>
  );
}

export default FormErrorContainer;
