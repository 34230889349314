import React, { useEffect } from 'react';
import './App.css';
import {
  createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider, useNavigate,
} from 'react-router-dom';
import SignIn from './pages/authentication/signIn/SignIn';
import { useAppSelector } from './state/hooks';
import SignUp from './pages/authentication/signUp/SignUp';
import ForgotPassword from './pages/authentication/forgotPassword/ForgotPassword';
import PasswordRecovery from './pages/authentication/passwordRecovery/PasswordRecovery';
import EmailConfirmed from './pages/authentication/emailConfirmed/EmailConfirmed';
import MainLayout from './components/layouts/mainLayout/MainLayout';
import Terms from './pages/gdpr/terms/Terms';
import PrivacyPolicy from './pages/gdpr/privacyPolicy/PrivacyPolicy';
import Error404 from './pages/errors/404/Error404';
import Error403 from './pages/errors/403/Error403';
import Error500 from './pages/errors/500/Error500';
import Profile from './pages/profile/Profile';
import EditProfile from './pages/profile/edit/EditProfile';
import Forms from './pages/forms/Forms';
import Constructor from './pages/forms/constructor/Constructor';
import Community from './pages/community/Community';
import Upgrades from './pages/upgrades/Upgrades';
import Lead from './pages/leads/lead/Lead';
import Leads from './pages/leads/Leads';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import workWithResponse from './helpers/workWithResponse';
import { Api } from './api';
import { useActions } from './hooks/useActions';
import { useDispatch } from 'react-redux';
import SignUpStep2 from './pages/authentication/signUpStep2/SignUpStep2';
import { bytesToMB } from './utils/utils';
import { megaBiteInBites } from './constants/constants';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<SignIn />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/registration/step-2" element={<SignUpStep2 />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/password-recovery/step-2" element={<PasswordRecovery />} />
      <Route path="/email-confirmed" element={<EmailConfirmed />} />

      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-use" element={<Terms />} />

      <Route path="/403" element={<Error403 />} />
      <Route path="/404" element={<Error404 />} />
      <Route path="/500" element={<Error500 />} />

      <Route path="*" element={<Navigate to="/404" replace />} />
    </>,
  ),
);

const routerLoggedIn = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<MainLayout />}>
        <Route path="/" element={<Profile />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/edit" element={<EditProfile />} />

        <Route path="/forms" element={<Forms />} />
        <Route path="/forms/constructor/:id" element={<Constructor />} />

        <Route path="/leads" element={<Leads />} />
        <Route path="/leads/:id" element={<Lead />} />

        <Route path="/community" element={<Community />} />

        <Route path="/upgrades" element={<Upgrades />} />
      </Route>

      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-use" element={<Terms />} />

      <Route path="/403" element={<Error403 />} />
      <Route path="/404" element={<Error404 />} />
      <Route path="/500" element={<Error500 />} />

      <Route path="/sign-up" element={<Navigate to="/" replace />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </>,
  ),
);

function App() {
  const userState = useAppSelector((state) => state.user);
  const actions = useActions();
  const dispatch = useDispatch();

  useEffect(() => {
    workWithResponse(() => Api.getMyAccount()).then((res) => {
      if (res.data) {
        dispatch(actions.setUser(res.data));

        return;
      }

      if (res.error) {
        throw new Error(res.error);
      }
    }).catch((e) => dispatch(actions.changeUser(null)));
  }, []);

  return (
    <>
      <ToastContainer
        limit={10}
        style={{
          maxWidth: '630px',
          width: '100%',
          paddingLeft: '16px',
          paddingRight: '16px',
          paddingTop: '20px',
        }}
        toastClassName="toastifyHideButton toastifyTextCenter"
      />

      <RouterProvider router={userState.loggedIn ? routerLoggedIn : router} />
    </>
  );
}

export default App;
