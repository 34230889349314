import React, { useMemo } from 'react';
import { PaginationStyles } from './PaginationStyles';
import ReactPaginate from 'react-paginate';

interface PaginationProps {
  total: number;
  activePage: number;
  elementsPerPage?: number;
  setActivePage(pageNumber: number): void;
  extraClasses?: string;
}

const CustomNextButton = () => (
  <svg className="lastButton" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M5 12H19" stroke="#141414" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 5L19 12L12 19" stroke="#141414" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const CustomPreviousButton = () => (
  <svg className="firstButton" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M19 12H5" stroke="#141414" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 19L5 12L12 5" stroke="#141414" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

function calculateVisibleRange(activePage: number, elementsPerPage: number, total: number) {
  if (!total) {
    return '';
  }

  const startRange = activePage === 0 ? 1 : (activePage * elementsPerPage);
  const endRange = Math.min((activePage + 1) * elementsPerPage, total);

  return `${startRange}-${endRange} of ${total}`;
}

function Pagination({
  total, activePage, elementsPerPage, setActivePage, extraClasses,
}: PaginationProps) {
  const pagesCount = useMemo(() => Math.ceil(total / (elementsPerPage || 14)), [total]);

  function handlePageClick({ selected }: {selected: number}) {
    setActivePage(selected);
  }

  return (
    <PaginationStyles className={`textBase14 pagination ${extraClasses ?? ''}`}>
      <span className="textBase16">{calculateVisibleRange(activePage, elementsPerPage || 14, total)}</span>

      <ReactPaginate
        forcePage={activePage}
        nextLabel={<CustomNextButton />}
        previousLabel={<CustomPreviousButton />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pagesCount}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </PaginationStyles>
  );
}

export default Pagination;
