import styled from 'styled-components';

export const CommunityStyles = styled.div`
  padding-bottom: 60px;
  
  h2 {
    margin-bottom: 32px;
  }
  
  .modalBody {
    display: flex;
    flex-direction: column;
    gap: 24px;
    
    .afterSaveModalHead {
      display: flex;
      flex-direction: column;
      gap: 8px;
      
      text-align: center;
    }
  }
  
  .content {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    
    @media (min-width: 1024px) {
      gap: 86px;
    }
    
    form {
      display: flex;
      flex-direction: column;
      gap: 24px;
      
      padding: 24px;

      border-radius: 8px;
      border: 1px solid var(--Stroke, #EEE);
      background: #FFF;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
      
      @media (min-width: 1024px) {
        padding: 32px;
        
        max-width: 582px;
      }
      
      .formHead {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
      
      .formFields {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }
    
    .socialMediasWrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
      
      .socialMedias {
        display: flex;
        gap: 16px;
        
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          
          width: 40px;
          height: 40px;
          
          border-radius: 20px;
          background: linear-gradient(312deg, #00C8CB -37.73%, #D9FEFF 99.46%);
          
          transition: .3s ease transform, .3s ease box-shadow;
          
          &:hover {
            transform: scale(1.04);
            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
          }
        }
      }
    }
  }
`;
