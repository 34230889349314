import React from 'react';
import { TApiLead } from '../../../../../enteties/types/lead.types';
import { TApiForm } from '../../../../../enteties/types/forms.types';
import { TApiStatus } from '../../../../../enteties/types/statuses.types';
import TableMobileFilters from '../../../filters/table/mobile/TableMobileFilters';
import { LeadsTableVersionFilters } from '../../LeadsTableVersion';
import LeadsList from '../leadsList/LeadsList';

interface Props {
  leads: TApiLead[];
  formsBook: {[key: string]: TApiForm};
  statusesBook: {[key: string]: TApiStatus};
  statuses: TApiStatus[];
  forms: TApiForm[];
  onLeadDeleted(id: number): void;
  setFilters: (data: {
    form: number | undefined,
    statuses: number[],
  }) => void;
  filters: LeadsTableVersionFilters;
}

function LeadsTableVersionMobile({
  leads, formsBook, statusesBook, statuses, forms, onLeadDeleted, setFilters, filters,
}: Props) {
  return (
    <>
      <TableMobileFilters filters={filters} setFilters={setFilters} statuses={statuses} forms={forms} />

      <LeadsList
        leads={leads}
        forms={forms}
        formsBook={formsBook}
        statusesBook={statusesBook}
        onLeadDeleted={onLeadDeleted}
      />
    </>
  );
}

export default LeadsTableVersionMobile;
