import styled from 'styled-components';

export const PropertyStyles = styled.div`
  .propertyItem {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .propertyItemHead {
    position: relative;
    
    .openButton {
      display: flex;
      align-items: center;
      gap: 6px;
    }
    
    .propertyDropdown {
      left: 0;
      right: 0;
      top: 0;
      
      display: flex;
      flex-direction: column;
      gap: 12px;
      
      .splitter {
        width: 100%;
        height: 1px;
        background: #EEE;
      }
      
      .settings {
        display: flex;
        flex-direction: column;
        gap: 8px;
        
        .settingOptionWrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          
          .settingOptionOpenButton {
            position: relative;
            
            display: flex;
            align-items: center;
            gap: 8px;
            
            overflow: visible;
            color: #595959;
            transition: .3s ease color;
            
            .dropdown {
              left: auto;
              right: 0;
            }
            
            svg {
              transition: .3s ease transform;
              width: 18px;
              height: 18px;
            }
            
            &.active {
              color: #141414;
              
              .arrowRight {
                transform: rotate(90deg) translateY(-4px);
              }
            }
          }
        }

        .propertyInnerDropdown {
          display: flex;
          flex-direction: column;
          gap: 4px;
          
          min-width: 180px;
          
          button {
            display: flex;
            justify-content: space-between;
            gap: 12px;
            white-space: nowrap;
          }
        }
      }
      
      .footer {
        button {
          display: flex;
          align-items: center;
          gap: 6px;
          
          padding: 4px;
          width: 100%;
          
          transition: .3s ease border-bottom-color, .3s ease background-color;
          
          &:hover {
            background-color: #F5F5F5;
            color: #F5222D;

            svg {
              path {
                stroke: #F5222D;
              }
            }
          }
          
          svg {
            path {
              transition: .3s ease stroke;
            }
          }
        }
        
        .trashIcon {
          path {
            stroke: #000;
          }
        }
      }
    }
  }
`;
