import styled from 'styled-components';

export const StatusesSelectStyles = styled.div`
  position: relative;
  
  .openerButton {
    display: flex;
    align-items: center;
    justify-content: center;
    
    transition: .3s transform;

    svg {
      path {
        transition: .3s stroke;
      }
    }
    
    &:hover {
      transform: rotate(90deg);
      svg {
        path {
          stroke: #00C8CB;
        }
      }
    }
  }
  
  .addStatusButton {
    color: var(--Orange-1, #FC812B);
    
    transition: .3s color;
    
    &:hover {
      color: var(--orange-hover);
    }
  }
  
  .desktop {
    display: none;
    
    width: 448px;
    max-height: calc(100dvh - 42px - 88px - 40px);
    
    overflow: auto;

    border-radius: 0px 0px 2px 2px;
    background: var(--Conditional-pop-over, #FFF);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
    
    @media (min-width: 1200px) {
      max-height: calc(100dvh - 42px - 88px);
    }
    
    @media (min-width: 1024px) {
      display: block;
    }
  }
  
  .mobile {
    position: fixed;
    left: 0;
    top: 60px;
    z-index: 1;
    
    width: 100vw;
    height: calc(100dvh - 60px);
    padding: 35px 16px;
    box-sizing: border-box;
    
    display: none;
    opacity: 0;
    transition: .3s ease opacity;

    background: #FCFCFC;
    overflow: auto;
    
    @media (max-width: 1023px) {
      &.open {
        display: block;
        opacity: 1;
      }
    }
    
    @media (min-width: 1024px) {
      display: none;
    }

    .statusesSelectHead {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 24px;
      
      .closeButton {
        svg {
          path {
            transition: .3s stroke;
          }
        }

        &:hover {
          svg {
            path {
              stroke: #00C8CB;
            }
          }
        }
      }
    }
  }

  .statusesList {
    display: flex;
    flex-direction: column;

    margin-bottom: 24px;

    .statusesListItem {
      display: flex;
      justify-content: space-between;

      padding: 12px 0;

      border-bottom: 1px solid var(--Stroke, #EEE);

      .statusesListItemButtons {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }
  
  .statusChanging {
    .inputWrapper {
      position: relative;

      margin-bottom: 12px;

      .inputButtons {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);

        display: flex;
        align-items: center;
        gap: 8px;

        button {
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }

      input {
        font-size: 16px;
        background-color: inherit;
        box-sizing: border-box;
        padding: 10px 8px;
        transition: 0.3s all;
        font-family: 'Inter', sans-serif;
        outline: none;

        width: 100%;

        border-radius: var(--corners_btn, 4px);
        border: 1px solid var(--gray-light, #C2C9D1);
        background: var(--white-default, #FFF);

        &:hover {
          border: 1px solid var(--orange-2, #FEC9A4);
        }

        &:focus {
          border: 1px solid var(--orange-1, #FC812B);
        }

        &::placeholder {
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          line-height: 22px;
          color: var(--black-light, rgba(0, 0, 0, 0.50));
        }
      }
    }

    .palette {
      display: flex;
      flex-direction: column;
      gap: 13px;

      .paletteList {
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;

          transition: .3s background-color;

          padding: 5px 16px 5px 0;

          &:hover {
            background: var(--Grey-3, #F5F5F5);
          }

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          button {
            display: flex;
            align-items: center;
            justify-content: space-between;

            width: 100%;
          }

          .leftPart {
            display: flex;
            align-items: center;
            gap: 8px;

            .colorPreview {
              width: 24px;
              height: 24px;

              border-radius: 4px;
              border: 1px solid rgba(0, 0, 0, 0.30);
            }
          }
        }
      }
    }
  }
`;
