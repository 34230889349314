import styled from 'styled-components';

export const ProfileStyles = styled.div`
  flex-grow: 1;
  
  &.profile {
    .formContainer {
      margin-bottom: 32px;
    }

    h1 {
      margin-bottom: 24px;
    }
    
    .modalBody {
      @media (min-width: 769px) {
        max-width: 632px;
      }
      
      .deleting {
        .buttonsContainer {
          display: flex;
          flex-direction: column;
          gap: 20px;

          @media (min-width: 768px) {
            flex-direction: row;
          }
        }
      }
      
      .afterDelete {
        .button {
          margin: auto;
        }
      }
      
      svg {
        margin-bottom: 12px;
      }
      
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        text-align: center;
        
        margin-bottom: 24px;
      }

      .textContainer {
        margin-bottom: 12px;
        
        h2 {
          margin-bottom: 4px;
        }
      }
    }
  }
  
  &.editProfile {
    .headWrapper {
      margin-bottom: 24px;
    }
    
    .buttonsContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;
      
      @media (min-width: 768px) {
        flex-direction: row;
      }
    }
  }
  
  .formContainer {
    width: 100%;
    
    padding: 24px;
    box-sizing: border-box;
    
    border-radius: 8px;
    background: #FFF;

    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
    
    @media (min-width: 769px) {
      max-width: 496px;
    }
    
    @media (min-width: 1024px) {
      padding: 32px;
    }
    
    .fieldsWrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      
      margin-bottom: 24px;
    }
  }
  
  .deleteButton {
    display: flex;
    align-items: center;
    gap: 10px;
    
    color: var(--orange-1, #FC812B);
    
    &:hover > svg {
      transform: rotate(8deg);
    }
    
    svg {
      transition: 0.3s ease transform;
    }
  }
`;
