import React, { useRef } from 'react';
import { SidebarStyles } from './SidebarStyles';
import { Link, useLocation } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';
import CloseMenuIcon from '../../assets/icons/sidebar/CloseMenuIcon';
import { useClickOutside } from '../../hooks/useClickOutside';
import LogoWhite from '../../assets/LogoWhite';
import FormsIcon from '../../assets/icons/sidebar/FormsIcon';
import LeadsIcon from '../../assets/icons/sidebar/LeadsIcon';
import CommunityIcon from '../../assets/icons/sidebar/CommunityIcon';
import UpgradesIcon from '../../assets/icons/sidebar/UpgradesIcon';
import classNames from 'classnames';

import imageMobile from '../../assets/images/sidebar/vote-image-mobile.jpg';
import imageTablet from '../../assets/images/sidebar/vote-image-tablet.jpg';
import imageDesktop from '../../assets/images/sidebar/vote-image-desktop.jpg';
import UserMenu from './userMenu/UserMenu';

const sidebarOptions: {name: string, path: string, icon: React.FC}[] = [
  {
    name: 'Forms',
    icon: FormsIcon,
    path: '/forms',
  },
  {
    name: 'Leads',
    icon: LeadsIcon,
    path: '/leads',
  },
  // {
  //   name: 'Analytics',
  //   icon: AnalyticsIcon,
  //   path: '/analytics',
  // },
];

const bottomOptions: {name: string, path: string, icon: React.FC}[] = [
  {
    name: 'Community',
    icon: CommunityIcon,
    path: '/community',
  },
  {
    name: 'Upgrades',
    icon: UpgradesIcon,
    path: '/upgrades',
  },
];

interface SidebarProps {
  setSidebarOpen(): void;
  isOpen: boolean;
}

function Sidebar({ isOpen, setSidebarOpen }: SidebarProps) {
  const { width } = useWindowSize();

  const location = useLocation();

  const sidebarRef = useRef<HTMLElement | null>(null);

  useClickOutside(sidebarRef, () => setSidebarOpen());

  return (
    <SidebarStyles className={width > 1200 ? 'open' : isOpen ? 'open' : ''}>
      <nav ref={sidebarRef} className="boxShadow1">
        <div className="logoContainer">
          <button onClick={() => setSidebarOpen()} className="menuButton" type="button">
            <CloseMenuIcon />
          </button>

          <Link to="/"><LogoWhite /></Link>
        </div>
        <div className="contentWrapper">
          <div className="content">
            <div className="optionsWrapper">
              <div className="options">
                {sidebarOptions.map((option) => {
                  const Icon = option.icon;

                  return (
                    <Link onClick={() => setSidebarOpen()} to={`${option.path}${location.pathname.startsWith(option.path) && location.search ? location.search : ''}`} className={location.pathname.startsWith(option.path) ? 'active' : ''} key={option.name} title={`Go to ${option.name}`}>
                      <Icon
                        // @ts-ignore
                        active={location.pathname.startsWith(option.path)}
                      />
                      <span className="textSemiBold16">{option.name}</span>
                    </Link>
                  );
                })}
              </div>

              <div className="bottomOptions">
                {bottomOptions.map((option) => {
                  const Icon = option.icon;

                  return (
                    <Link
                      onClick={() => setSidebarOpen()}
                      to={`${option.path}${location.pathname.startsWith(option.path) && location.search ? location.search : ''}`}
                      className={classNames(
                        { active: location.pathname.startsWith(option.path) },
                        { orange: option.path === '/upgrades' },
                      )}
                      key={option.name}
                      title={`Go to ${option.name}`}
                    >
                      <Icon
                        // @ts-ignore
                        active={location.pathname.startsWith(option.path)}
                      />
                      <span className="textSemiBold16">{option.name}</span>
                    </Link>
                  );
                })}
              </div>
            </div>

            <Link to="/upgrades" className="voteBannerWrapper">
              <div className="textWrapper">
                <span className="textSemiBold14">
                  Please, help us define the next feature
                </span>

                <span className="textBase14">
                  Push «Add to wishlist» button in Upgrades menu
                </span>
              </div>

              <picture>
                <source srcSet={imageDesktop} media="(min-width: 1201px)" />
                <source srcSet={imageTablet} media="(min-width: 768px)" />

                <img src={imageMobile} alt="Let's vote" />
              </picture>
            </Link>
          </div>
        </div>

        <UserMenu setSidebarOpen={setSidebarOpen} />
      </nav>
    </SidebarStyles>
  );
}

export default Sidebar;
