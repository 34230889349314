import styled from 'styled-components';

export const TextWithDotStyles = styled.div`
  display: grid;
  grid-template-columns: 10px 1fr;
  gap: 8px;
  
  .dot {
    width: 10px;
    height: 10px;
    background-color: #00C8CB;
    border-radius: 100%;
    transform: translateY(0.5em);
  }
`;
