import styled from 'styled-components';

export const StepsStyles = styled.div`
  position: relative;
  
  display: flex;
  justify-content: space-between;
  gap: 42px;
  
  overflow: auto;
  width: 1012px;
  
  @media (min-width: 768px) {
    gap: 12px;
  }
  
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    border-top: 1px dashed #D9D9D9;
  }
  
  button {
    position: relative;
    
    padding: 0 8px;
    
    display: flex;
    align-items: center;
    gap: 12px;
    background: #FDFDFD;
    color: #000;

    white-space: nowrap;
    
    &:disabled {
      cursor: not-allowed;
    }
    
    &:first-child {
      padding-left: 0;
    }
    
    &:last-child {
      padding-right: 0;
    }
    
    .number {
      display: flex;
      align-items: center;
      justify-content: center;
      
      width: 48px;
      height: 48px;
      border-radius: 100%;
      border: 1px solid #000;
      
      transition: .3s ease background-color, .3s ease border-color, .3s color;
    }
    
    &.active {
      .number {
        background: #00C8CB;
        border-color: #00C8CB;
        color: #FFF;
      }
    }
  }
`;
