import React, { useEffect, useState } from 'react';
import { PhoneInputStyles } from './PhoneInputStyles';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { InputFormDesignProps } from '../../../../../enteties/types/forms.types';

interface Props {
  styles: InputFormDesignProps;
  label: string;
  placeholder: string;

}

const TelInput = React.memo(({
  styles, label, placeholder,
}: Props) => {
  const [value, setValue] = useState<string>();

  return (
    <PhoneInputStyles styles={styles}>
      <span className="textSemiBold12">{label}</span>
      <PhoneInput
        defaultCountry="UA"
        value={value}
        onChange={(phone) => setValue(phone)}
        placeholder={placeholder}
        disableDialCodePrefill
      />
    </PhoneInputStyles>
  );
});

export default TelInput;
