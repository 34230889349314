import { AvailableFormPurpose, TAvailablePurpose, TForm } from '../enteties/types/forms.types';

export const unexpectedErrorMessage = 'Oops something goes wrong';
export const megaBiteInBites = 1048576;

export const purposes: { [key in TAvailablePurpose]: string } = {
  [AvailableFormPurpose.link_qr]: 'Link/QR-form',
  [AvailableFormPurpose.website]: 'For website',
};

export const formInitialData: TForm = {
  name: '',
  title: '',
  description: '',
  purpose: null,
  user_id: 0,
  submit_button: {
    text: 'Send',
  },
  design: {
    page: {
      background_color: {
        r: 255, g: 255, b: 255, a: 1,
      },
    },
    form: {
      border_radius: '8',
      background_color: {
        r: 255, g: 255, b: 255, a: 1,
      },
    },
    image: {
      url: null,
      border_radius: '0',
    },
    title: {
      color: {
        r: 0, g: 0, b: 0, a: 1,
      },
    },
    description: {
      color: {
        r: 0, g: 0, b: 0, a: 1,
      },
    },
    inputs: {
      border_color: {
        r: 194, g: 201, b: 209, a: 0.5,
      },
      background_color: {
        r: 255, g: 255, b: 255, a: 1,
      },
      border_radius: '4',
      label_color: {
        r: 20, g: 20, b: 20, a: 1,
      },
      placeholder_color: {
        r: 0, g: 0, b: 0, a: 0.5,
      },
      text_color: {
        r: 0, g: 0, b: 0, a: 1,
      },
    },
    submit_button: {
      border_radius: '4',
      background_color: {
        r: 0, g: 82, b: 115, a: 1,
      },
      text_color: {
        r: 255, g: 255, b: 255, a: 1,
      },
    },
  },
  thank_you: {
    description: {
      text: 'Your answer is important to us',
      color: {
        r: 0, g: 0, b: 0, a: 1,
      },
    },
    image: {
      url: null,
      border_radius: '0',
    },
    title: {
      text: 'Thank you',
      color: {
        r: 0, g: 0, b: 0, a: 1,
      },
    },
    form: {
      background_color: {
        r: 255, g: 255, b: 255, a: 1,
      },
      border_radius: '4',
    },
    page: {
      background_color: {
        r: 255, g: 255, b: 255, a: 1,
      },
    },
  },
  fields: [],
};
