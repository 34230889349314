import styled from 'styled-components';

export const LeadsStyles = styled.div`
  display: flex;
  flex-direction: column;
  
  flex-grow: 1;

  padding-bottom: 60px; 
  
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    margin-bottom: 24px;

    .settings {
      display: flex;
      align-items: center;
      gap: 16px;
      
      @media (min-width: 768px) {
        gap: 22px;
      }
      
      .activeTabHandleButtonsWrapper {
        display: flex;
        gap: 8px;
        
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          
          padding: 8px;

          border-radius: 2px;
          border: 1px solid #FCFCFC;
          
          transition: .3s background-color, .3s border-color;
          
          box-sizing: border-box;

          svg {
            path {
              transition: .3s stroke;
            }
          }
          
          &.active {
            border: 1px solid var(--Orange-1, #FC812B);
            background: rgba(254, 201, 164, 0.12);
            
            svg {
              path {
                stroke: #FC812B;
              }
            }
          }
        }
        
        .tableVersionButton {
          svg {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
`;
