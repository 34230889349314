import styled, { keyframes } from 'styled-components';

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const DropDownStyles = styled.div`
  position: absolute;
  right: 50%;
  top: calc(100% + 8px);
  z-index: 1;
  border-radius: 8px;
  background: #FEFEFE;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  padding: 8px;

  &.greyButtonOnHover {
    button {
      transition: .3s ease background-color;
      padding: 4px;
      width: 100%;
      &:hover {
        background-color: #F5F5F5;
      }
    }
  }

  animation: ${fadeInAnimation} 0.3s ease forwards;
`;
