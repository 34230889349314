import React, { useEffect, useState } from 'react';

import { RGBColor, SketchPicker } from 'react-color';
import Modal from '../../modal/Modal';
import { ColorPickerStyles } from './ColorPickerStyles';
import IconButton from '../../buttons/iconButton/IconButton';
import CheckMark from '../../../assets/icons/other/CheckMark';
import CrossIcon from '../../../assets/icons/other/CrossIcon';
import { RGBA } from '../../../enteties/types/forms.types';

const presetColors = [
  '#F1F0F0',
  '#E3E2E0',
  '#E9D8D1',
  '#FBDBBF',
  '#FFF3CA',
  '#DBFAD6',
  '#E1F3FF',
  '#F4EDFF',
  '#FFDFEF',
  '#FCD9D2',
];

function rgbToHex(r: number, g: number, b: number) {
  // eslint-disable-next-line no-bitwise
  let hex = ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);

  while (hex.length < 6) {
    hex = `0${hex}`;
  }

  return `#${hex}`;
}

interface Props {
  value: RGBA;
  label?: string;
  setValue(value: RGBA): void;
}

function ColorPicker({ value, label, setValue }: Props) {
  const [pickerOpened, setPickerOpened] = useState<boolean>(false);

  const [innerValue, setInnerValue] = useState<RGBA>({
    r: 194, g: 201, b: 209, a: 1,
  });

  useEffect(() => {
    setInnerValue(value);
  }, [value, pickerOpened]);

  return (
    <ColorPickerStyles>
      {label && <div className="labelWrapper"><span className="textSemiBold16">{label}</span></div>}

      <button className="openerButton" type="button" onClick={() => setPickerOpened(!pickerOpened)}>
        <div className="colorContainer">
          <div className="color" style={{ background: `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})` }} />

          <span className="textBase16">{rgbToHex(value.r, value.g, value.b)}</span>
        </div>

        <span className="textBase16">
          {value.a * 100}
          %
        </span>
      </button>

      {pickerOpened && (
        <Modal onClose={() => setPickerOpened(false)}>
          <SketchPicker color={innerValue} onChange={(value) => setInnerValue(value.rgb as RGBA)} presetColors={presetColors} />

          <div className="buttonsContainer">
            <IconButton onClick={() => setPickerOpened(false)} icon={<CrossIcon />} theme="grey" />

            <IconButton
              onClick={() => {
                setValue(innerValue);
                setPickerOpened(false);
              }}
              icon={<CheckMark color="#FC812B" />}
              theme="mainNotFilled"
            />
          </div>
        </Modal>
      )}
    </ColorPickerStyles>
  );
}

export default ColorPicker;
