import React, { ForwardedRef, forwardRef, Fragment } from 'react';
import { ButtonProps, TextButtonProps } from '../../../enteties/button/ButtonProps';
import Modal from '../../modal/Modal';
import { ModalConfirmDeclineStyles } from './ModalConfirmDeclineStyles';
import TextButton from '../../buttons/button/TextButton';

interface ModalConfirmDeclineProps {
  title: string;
  description?: string;

  onClose(): void;

  buttons: TextButtonProps[];
  error?: string;
}

const ModalConfirmDecline = forwardRef(({
  title, description, buttons, onClose, error,
}: ModalConfirmDeclineProps, ref: ForwardedRef<HTMLDivElement>) => (
  <ModalConfirmDeclineStyles ref={ref}>
    <Modal onClose={onClose}>
      <div className="modalHead">
        <span className="textBold28">{title}</span>

        {description && <span className="textSemiBold18">{description}</span>}
      </div>

      <div className="modalButtons">
        {buttons.map((button, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>
            <TextButton
              onClick={button.onClick}
              text={button.text}
              theme={button.theme}
              disabled={button.disabled}
              type={button.type}
              icon={button.icon}
              size={button.size}
              error={button.error}
              isLoading={button.isLoading}
            />
          </Fragment>
        ))}
      </div>

      {error && <span className="textBase16 errorText">{error}</span>}
    </Modal>
  </ModalConfirmDeclineStyles>
));

export default ModalConfirmDecline;
